
import React from "react";
import { Editor } from "@tinymce/tinymce-react";

const SpectrumEditer = (props) => {
	const {
		menu,
		minToolBar = false,
		input: { onChange, value }, // Destructure 'value' from 'input'
		onSelectMenu = () => { },
	} = props;

	const handleEditorChange = (content, editor) => {
		onChange(content);
	};

	const getmenuItem = (editor, list) => {
		const menus = list.map((menuItem) => {
			const { type, text } = menuItem;
			if (type === "menuitem") {
				const onAction = () => {
					onSelectMenu(menuItem, editor);
					editor.insertContent(menuItem.content);
				};
				return { type, text, onAction };
			} else {
				const getSubmenuItems = () => {
					return getmenuItem(editor, menuItem.subMenus);
				};
				return { type, text, getSubmenuItems };
			}
		});
		return menus;
	};

	return (
		<Editor
			tinymceScriptSrc={`/tinymce/tinymce.min.js`}
			init={{
				height: 500,
				menubar: false,
				branding: false,
				plugins: ["lists"],
				content_css: [
					"//fonts.googleapis.com/css?family=Indie+Flower",
					"//fonts.googleapis.com/css2?family=Chilanka&family=Sriracha&display=swap",
				],
				font_formats:
					"Arial Black=arial black,avant garde;Indie Flower=indie flower, cursive;Times New Roman=times new roman,times;Sriracha=Sriracha, cursive;Chilanka=Chilanka,cursive",
				toolbar: minToolBar
					? "bold italic | alignleft aligncenter alignright alignjustify | removeformat"
					: "bold italic strikethrough forecolor backcolor | fontsizeselect  | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | mybutton",
				setup: function (editor) {
					/* example, adding a toolbar menu button */
					menu &&
						menu !== "" &&
						editor.ui.registry.addMenuButton("mybutton", {
							text: menu.name,
							fetch: function (callback) {
								const items = getmenuItem(editor, menu.menuList);
								callback(items);
							},
						});
				},
			}}
			value={value} 
			onEditorChange={handleEditorChange}
		></Editor>
	);
};

export default SpectrumEditer;
