import { TASK } from "../types";

export default (
	state = {
		filters: {
			pageIndex: 1,
			pageSize: 10,
			searchKey: "",
		},
	},
	action
) => {
	switch (action.type) {
		case TASK.SET_FILTER:
			return {
				...state,
				filters: action.payload ? action.payload : {},
			};
		case TASK.GET_TASK_LIST:
			return {
				...state,
				taskList: action.payload ? action.payload.result : [],
			};

		default:
			return state;
	}
};
