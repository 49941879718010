import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ProfleDetails from "../userManagement/admin/AdminDetails";
import AddEdit from "../userManagement/admin/AddEdit";
import { showLoader, updateUser, getEntityByType, resetField, changePassword } from "../../store/actions";
import { errorMessage } from "../../utils/errorMessage";
import { successMessage } from "../../utils/successMessage";
import ResetPasswordSlider from "./ResetPasswordSlider";
import { logout } from "../../utils/helper";
import "./profile.scss";
import Otp from "./Otp";

const Profile = (props) => {
	const { userDetails, doShowLoader, doUpdateUser, doGetEntityByType, doChangePassword } = props;
	const [showPopup, setshowPopup] = useState(false);
	const [showVerificationSlider, setShowVerificationSlider] = useState(false)
	const [showResetSlider, setShowResetSlider] = useState(false);
	const [submitData, setSubmitData] = useState(false);
	const [companyList, setCompanyList] = useState({});

	let timer;
	useEffect(() => {
		return () => {
			if (timer) {
				clearTimeout(timer);
			}
		};
	}, [timer]);

	useEffect(() => {
		const successCB = (e) => {
			setCompanyList(e.result);
		};

		doGetEntityByType({ typeId: 229, isInactiveReq: "false" }, successCB);
	}, [doGetEntityByType]);

	const handleClosed = () => {
		setShowVerificationSlider(false);
		setshowPopup(true)
	}

	const handleClose = () => {
		if (showVerificationSlider) {
			setShowVerificationSlider(false);
			setshowPopup(false);
		} else if (showPopup) {
			setshowPopup(false);
		}
	};

	const handleShow = () => setshowPopup(true);

	const resetPassword = (value) => {
		if (value.newPassword !== value.confirmPassword) {
			errorMessage(104);
			return;
		}
		const { confirmPassword, ...reqBody } = value;
		doShowLoader(true);
		const successCB = (response) => {
			doShowLoader(false);
			setShowResetSlider(false);
			successMessage(104);
			timer = setTimeout(logout, 300);
		};
		const FailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};
		doChangePassword(reqBody, successCB, FailCB);
	};
	
	const handleSubmit = async (value, otp) => {
		doShowLoader(true);

		const successCB = (response) => {
			if (response.result.isOtpRequired === true) {
				setshowPopup(false);
				setShowVerificationSlider(true);
				successMessage(519);
			} else {
				successMessage(202);
				setTimeout(function () {
					window.location.reload();
				}, 2000);
			}
			doShowLoader(false);
		};

		const FailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		const postData = {
			...value,
			userRoleId: userDetails.userRoleId,
			userStatusInput: userDetails.userStatus,
			userName: userDetails.userName,
			isActive: userDetails.isActive,
			surname: userDetails.surname,
		};
		setSubmitData(postData)
		userDetails.id && doUpdateUser(postData, successCB, FailCB);
	};

	return (
		<>
			<ProfleDetails
				breadcrumbs={[{ label: "Profile" }]}
				user={userDetails}
				isProfile
				initialValues={userDetails}
				showEdit={handleShow}
				showResetPassword={() => setShowResetSlider(true)}
			/>
			{showPopup && (
				<AddEdit
					show={showPopup}
					initialValues={userDetails}
					isEdit={true}
					isProfile
					handleClose={handleClose}
					onSubmit={handleSubmit}
					companyList={companyList}
				/>
			)}
			{showVerificationSlider && (
				<Otp
					showVerificationSlider={showVerificationSlider}
					setShowVerificationSlider={setShowVerificationSlider}
					setshowPopup={setshowPopup}
					isEdit={true}
					isProfile
					handleClose={handleClose}
					companyList={companyList}
					submitData={submitData}
					handleClosed={handleClosed}
				/>
			)}

			{showResetSlider && (
				<ResetPasswordSlider
					onSubmit={resetPassword}
					show={showResetSlider}
					handleClose={() => setShowResetSlider(false)}
				/>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		Settings: { companyList = [] },
		Auth: { userDetails = {} },
	} = state;
	return {
		companyList,
		userDetails,
	};
};
const mapDispatchToProps = {
	doShowLoader: showLoader,
	doGetEntityByType: getEntityByType,
	doResetField: resetField,
	doUpdateUser: updateUser,
	doChangePassword: changePassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
