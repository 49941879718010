import FormControl from "../SharedComponts/FormControl";
import { required, passwordStrength } from "../../constant/formValidators";

export const formFields = (formValues) => [
	{
		name: "currentPassword",
		label: "Old Password",
		component: FormControl,
		type: "password",
		className: "form-control form-field",
		validate: [required],
	},
	{
		name: "newPassword",
		label: "New Password",
		component: FormControl,
		type: "password",
		className: "form-control form-field",
		validate: [required, passwordStrength],
	},
	{
		name: "confirmPassword",
		label: "Confirm Password",
		component: FormControl,
		type: "password",
		className: "form-control form-field",
		validate: [required],
	},
];
