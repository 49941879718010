import React from "react";

import { MoreOptions } from "../../SharedComponts/FormElements";
import FormControl from "../../SharedComponts/FormControl";
import { required, currency } from "../../../constant/formValidators";
import { Currency } from "../../../utils/formatter";

export const columns = ({ onEditFee = null, onDeleteFee = null }) => [
	{
		name: "Exchange Type",
		accessor: "exchangeType",
		className: "col-exchange-type",
	},
	{
		name: "Exchange Fee",
		accessor: "exchangeFee",
		className: "col-exchange-fee",
		sortable: false,
		render: (row) => <Currency amount={row.exchangeFee} />,
	},
	{
		name: "Property-wise Fee",
		accessor: "propertyWiseFee",
		className: "col-property-fee",
		sortable: false,
		render: (row) => <Currency amount={row.propertyWiseFee} />,
	},
	{
		name: "Actions",
		className: "col-actions text-right",
		headerClassName: "col-actions-header",
		sortable: false,
		render: (row) => {
			return (
				<MoreOptions
					options={[
						{ label: "Edit", onClick: () => onEditFee(row) },
						{ label: "Delete", onClick: () => onDeleteFee(row) },
					]}
				/>
			);
		},
	},
];

export const formFields = (exchangeTypes) => {
	return [
		{
			name: "exchangeType",
			label: "Exchange Type",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			options: exchangeTypes,
			validate: [required],
			required: true,
		},
		{
			name: "exchangeFee",
			label: "Exchange Fee",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			validate: [required, currency],
			required: true,
			maxLength: 15,
		},
		{
			name: "propertyWiseFee",
			label: "Property Wise Fee",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			validate: [currency],
			maxLength: 15,
		},
	];
};
