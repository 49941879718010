import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";

import Layout from "../../SharedComponts/Layout";
import { Table, Pagination } from "../../SharedComponts/FormElements/table";
import { columns as columnsFn } from "./helper";
import {
	setDefaultBank,
	setBankFilter,
	getBankList,
	getBank,
	updateBank,
	createBank,
	getStates,
	getCities,
	getCounties,
	showLoader,
	deleteBank,
} from "../../../store/actions";

import SearchField from "../../SharedComponts/FormElements/searchTable";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";

import AddEdit from "./AddEdit";
import "./banks.scss";
import Confirm from "../../SharedComponts/FormElements/confirm";

const BankList = ({
	doSetFilter,
	doGetBankList,
	filters,
	history,
	recordsTotal,
	bankList,
	doShowLoader,
	doCreateBank,
	doUpdateBank,
	doGetStates,
	doGetCities,
	doGetCounties,
	doGetBank,
	doDeleteBank,
	doSetDefaultBank,
}) => {
	const [showPopup, setshowPopup] = useState(false);
	const [isEdit, setIsEdit] = useState(true);
	const [currentRecord, setCurrentRecord] = useState({});
	// const [currentBank, setCurrentBank] = useState({ id: null });
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [listReload, setListReload] = useState(false);
	const [currentDeleteRecord, setCurrentDeleteRecord] = useState(null);

	const handleClose = () => setshowPopup(false);
	const handleShow = () => setshowPopup(true);
	const handleSubmit = async (value) => {
		doShowLoader(true);
		const successCB = (result) => {
			doShowLoader(false);
			successMessage(!isEdit ? 215 : 216);
			doSetFilter({
				...filters,
				pageIndex: 1,
			});
			handleClose();
		};
		const FailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		const postData = {
			...value,
		};

		currentRecord.id && isEdit && doUpdateBank({ id: currentRecord.id, isDefault: true, ...postData }, successCB, FailCB);
		(!currentRecord.id || !isEdit) && doCreateBank({ ...postData }, successCB, FailCB);
	};

	useEffect(() => {
		doShowLoader(true);
		const backendFilter = { ...filters };
		const successCB = (result) => {
			doShowLoader(false);
		};
		const FailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		doGetBankList(backendFilter, successCB, FailCB);
	}, [filters, listReload, doGetBankList, doShowLoader]);
	useEffect(() => {
		doGetStates();
	}, [doGetStates]);

	const onEditBank = (row) => {
		doShowLoader(true);
		const successCB = ({ result }) => {
			doShowLoader(false);
			setCurrentRecord(result);
		};
		const FailCB = (result) => {
			doShowLoader(false);
		};
		doGetBank(row.id, successCB, FailCB);
		setCurrentRecord({ ...row });
		setIsEdit(true);
		handleShow();
	};

	const addBank = () => {
		setIsEdit(false);
		setCurrentRecord({});
		handleShow();
	};
	const setDefaultBank = (item) => {
		doShowLoader(true);
		const successCB = (result) => {
			doShowLoader(false);
			successMessage(217);
			setListReload(!listReload);
		};
		const FailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
			setListReload(!listReload);
		};
		doSetDefaultBank(item, successCB, FailCB);
	};
	const loadCounties = (searchKey, callback) => {
		const successCB = ({ result }) => {
			callback(result);
		};
		const FailCB = (result) => {
			callback([]);
		};
		if (searchKey.length > 1) {
			doGetCounties(searchKey, successCB, FailCB);
		} else {
			callback([]);
		}
	};

	const onDeleteBank = (row) => {
		setCurrentDeleteRecord(row.id);
		setConfirmDelete(true);
	};
	const confirmDeleteAction = () => {
		doShowLoader(true);
		const failCB = (result) => {
			doShowLoader(false);
			setCurrentDeleteRecord(null);
			setConfirmDelete(false);
			errorMessage(result);
		};
		const successCB = (result) => {
			doShowLoader(false);
			setCurrentDeleteRecord(null);
			setConfirmDelete(false);
			successMessage(218);
			setListReload(!listReload);
		};
		doDeleteBank(currentDeleteRecord, successCB, failCB);
	};
	const rightActionButtons = [
		{
			component: () => {
				return (
					<Button className="form-button-primary" onClick={addBank}>
						<span className="add-new-plus">+</span> New Bank
					</Button>
				);
			},
		},
	];
	const columns = columnsFn({
		onEditBank,
		onDeleteBank,
		setDefaultBank,
	});
	return (
		<>
			<Layout
				title="Banks"
				breadcrumbs={[
					{
						label: "Configurations",
						onClick: () => {
							const path = `/settings-master`;
							history.push(path);
						},
					},
					{ label: "Banks" },
				]}
				rightActionButtons={rightActionButtons}
				className="bank-page">
				<>
					<SearchField
						initialValue={filters.searchKey}
						onSearch={(searchKey) =>
							doSetFilter({
								...filters,
								searchKey,
								pageIndex: 1,
							})
						}
						className={`flex-fill mb-0`}
						placeholder="Enter your query"
					/>
					<Table
						columns={columns}
						data={bankList}
						onSort={(e) =>
							e.by !== filters.sortColumn || e.order !== filters.sortDirection
								? doSetFilter({
										...filters,
										sortColumn: e.by,
										sortDirection: e.order,
										pageIndex: 1,
								  })
								: null
						}
						sortBy={filters.sortColumn}
						sortDir={filters.sortDirection}
					/>
					<Pagination
						total={recordsTotal}
						count={filters.pageSize}
						page={filters.pageIndex}
						onChange={(e) => {
							doSetFilter({ ...filters, pageIndex: e });
						}}
						onPageCountChange={(e) => {
							doSetFilter({ ...filters, pageSize: e, pageIndex: 1 });
						}}
					/>
				</>
				<Confirm
					onYes={confirmDeleteAction}
					onNo={(e) => {
						setConfirmDelete(false);
					}}
					show={confirmDelete ? true : false}
				/>
			</Layout>

			{showPopup ? (
				<AddEdit
					show={showPopup}
					loadCounties={loadCounties}
					doGetCities={doGetCities}
					initialValues={currentRecord}
					isEdit={isEdit}
					handleClose={handleClose}
					onSubmit={handleSubmit}
				/>
			) : null}
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		Bank: { bankList: { data: bankList = [], recordsTotal = 0 } = { data: [] }, filters = {} },
	} = state;
	return {
		bankList,
		filters,
		recordsTotal,
	};
};
const mapDispatchToProps = {
	doGetBankList: getBankList,
	doCreateBank: createBank,
	doUpdateBank: updateBank,
	doSetFilter: setBankFilter,
	doShowLoader: showLoader,
	doGetStates: getStates,
	doGetCities: getCities,
	doGetCounties: getCounties,
	doGetBank: getBank,
	doDeleteBank: deleteBank,
	doSetDefaultBank: setDefaultBank,
};

export default connect(mapStateToProps, mapDispatchToProps)(BankList);
