import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { reduxForm, Field, formValueSelector } from "redux-form";

import { Card, Button } from "react-bootstrap";
import Layout from "../../SharedComponts/Layout";
import { Heading } from "../../SharedComponts/FormElements";
import FormControl from "../../SharedComponts/FormControl";
import Confirm from "../../SharedComponts/FormElements/confirm";
import Tooltip from "../../SharedComponts/ToolTip";

// import { required } from "../../../constant/formValidators";
import { toBase64, getUtcDateFromDatePicker } from "../../../utils/helper";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";
import avatr from "../../../assets/images/avatar.png";
import { getActivityLog, showLoader, profileImageUpload, deleteProfileImage, resetField } from "../../../store/actions";

import { Table, Pagination } from "../../SharedComponts/FormElements/table";
import SearchField from "../../SharedComponts/FormElements/searchTable";
import { columnsActivityLog as columnsFn } from "./helper";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";

const fileSizeLimit = 400 * 1000;

const Filter = (props) => {
	const { handleSubmit, startDate, endDate, change } = props;
	return (
		<form className="filter" onSubmit={handleSubmit}>
			<div className="form-group">
				<div className="form-lable">Start Date</div>
				<Field
					name="startDate"
					onClear={() => {
						change("startDate", null);
					}}
					type="date"
					maxDate={endDate}
					component={FormControl}
					validate={[]}
				/>
			</div>
			<div className="form-group">
				<div className="form-lable">End Date</div>
				<Field
					name="endDate"
					type="date"
					minDate={startDate}
					maxDate={new Date()}
					component={FormControl}
					validate={[]}
					onClear={() => {
						change("endDate", null);
					}}
				/>
			</div>
			<Button className="form-button-primary icon-btn position-relative" type="submit">
				<span className="icon-search" />
			</Button>
		</form>
	);
};
const FilterForm = reduxForm({
	form: "adminAcivityFilter",
})(Filter);
const AdminDetails = (props) => {
	const initialFilterDates = { endDate: new Date(), startDate: moment(moment().subtract(7, "days")).toDate() };
	const {
		showEdit,
		breadcrumbs,
		isProfile,
		initialValues,
		showResetPassword,
		setIsEdit,
		doShowLoader,
		activityLog,
		doGetActivityLog,
		startDate,
		endDate,
		Modules,
		doProfileImageUpload,
		doDeleteProfileImage,
		doResetField,
		user,
	} = props;

	const history = useHistory();
	const [profilePic, setProfilePic] = useState(null);
	const [profileEditBlock, setProfileEditBlock] = useState(false);
	const [filters, doSetFilter] = useState({
		sortColumn: "createdOn",
		sortDirection: "desc",
		pageIndex: 1,
		pageSize: 10,
		searchKey: "",
	});
	const fileUploadRef = useRef(null);
	const [recordsTotal, doSetRecordsTotal] = useState(0);
	const [confirmDelete, setConfirmDelete] = useState(false);
	useEffect(() => {
		const userId = user.id;
		doShowLoader(true);
		const failCB = (result) => {
			doShowLoader(false);
			errorMessage(result);
		};
		const successCB = (result) => {
			doShowLoader(false);
			doSetRecordsTotal(result.result.recordsTotal);
		};
		const backendFilter = {
			...filters,
			userId,
			fromDate: getUtcDateFromDatePicker(startDate ? startDate : initialFilterDates.startDate),
			toDate: getUtcDateFromDatePicker(endDate ? endDate : initialFilterDates.endDate),
		};
		doGetActivityLog(backendFilter, successCB, failCB);
		if (user.profileImageUrl !== "") {
			setProfilePic(user.profileImageUrl);
		}
		// eslint-disable-next-line
	}, [doShowLoader, user, doGetActivityLog, filters]);

	const getExchangeModuleID = () => {
		return Modules.length ? Modules.find((element) => element.label === "Exchange").value : null;
	};

	const columns = columnsFn(getExchangeModuleID, history);

	const InfoItem = ({ label, value }) => {
		return (
			<div className="info-item col-md-3">
				<div className="label">{label} </div>
				<div className="value"> {value}</div>
			</div>
		);
	};

	const onProfileCloseIcon = () => {
		profilePic && setProfileEditBlock(false);
		user.profileImageUrl ? setProfilePic(user.profileImageUrl) : setProfilePic(null);
	};
	const onChooseProfile = async (e) => {
		const file = e.target.files[0];

		if (file && file.size < fileSizeLimit) {
			profileEditBlock === false && setProfileEditBlock(true);
			const result = await toBase64(file).catch((e) => Error(e));
			if (result instanceof Error) {
				console.error("image convert error: ", result.message);
				errorMessage(result.message);
				return;
			}
			setProfilePic(result);
		} else {
			file && errorMessage(103);
		}
	};
	const getActivityList = (params) => {
		doSetFilter({
			...filters,
			pageIndex: 1,
		});
	};

	const uploadProfileImage = async () => {
		doShowLoader(true);
		const {
			current: { files },
		} = fileUploadRef;

		setProfileEditBlock(false);
		const { name, type } = files[0];

		const base64Result = await toBase64(files[0]).catch((e) => Error(e));
		if (base64Result instanceof Error) {
			doShowLoader(false);
			console.error("image convert error: ", base64Result.message);
			errorMessage(base64Result.message);
			return;
		}
		const data = {
			userId: initialValues.id,
			id: 0,
			fileType: {
				value: 551,
				label: "Images",
			},
			uploadedFile64: base64Result.substr(base64Result.indexOf(",") + 1),
			fileName: name.substring(0, name.lastIndexOf(".")),
			fileExtension: name.substring(name.lastIndexOf(".")),
			fileContentType: type,
			fileURL: "string",
			preSignedFileURL: "string",
			preSignedImageThumbURL: "string",
		};
		const successCB = (e) => {
			doShowLoader(false);
			doResetField("Auth.userDetails.profileImageUrl", e && e.result && e.result.preSignedImageThumbURL);
			successMessage(105);
		};
		const failCB = (e) => {
			setProfilePic(user.profileImageUrl);
			doShowLoader(false);
			errorMessage(e);
		};
		doProfileImageUpload(data, successCB, failCB);
	};

	const onDeleteProfileImage = () => {
		setConfirmDelete(true);
	};

	const confirmDeleteProfileImage = () => {
		doShowLoader(true);
		setConfirmDelete(false);
		const successCB = (e) => {
			doShowLoader(false);

			doResetField("Auth.userDetails.profileImageUrl", null);
			successMessage(106);
		};
		const failCB = (e) => {
			setConfirmDelete(false);
			doShowLoader(false);
			errorMessage(e);
		};
		doDeleteProfileImage(initialValues.id, successCB, failCB);
	};
	return (
		<Layout
			breadcrumbs={breadcrumbs}
			className="user-details admin-details"
			title={isProfile ? `Profile` : `User - ${initialValues.name}`}>
			<Card>
				<Card.Title className="info-card-title">
					<Heading title="Basic Information" />
					<div className="user-action-icons">
						<span
							className="user-action-icon"
							onClick={() => {
								!isProfile && setIsEdit(true);
								showEdit();
							}}>
							<span className="icon-icon-edit" />
						</span>

						{isProfile ? (
							<Tooltip
								message={"Reset password"}
								component={
									<span className="user-action-icon" onClick={showResetPassword}>
										<span className="icon-icon-change-password" />
									</span>
								}
							/>
						) : null}
					</div>
				</Card.Title>
				<Card.Body className="info-card-body">
					<div className="profile-pic-container">
						<span className="profile-pic-wrap">
							<img className="profile-avatar" src={profilePic ? profilePic : avatr} alt="profile pic" />
						</span>
						{isProfile && !profileEditBlock && (
							<>
								<label htmlFor="image-upload" className="user-action-icon">
									<span className="icon-icon-edit" />
								</label>
								{user.profileImageUrl && user.profileImageUrl !== null && (
									<label className="user-action-icon-close" onClick={() => onDeleteProfileImage()}>
										<span className="icon-close" />
									</label>
								)}
							</>
						)}
						{/* Spinner while image upload */}
						{/* {isProfile && profileEditBlock === false && profilePic !== profileImageUrl && (
							<label className="user-action-icon">
								<div className="spinner"></div>
							</label>
						)} */}

						{isProfile && profilePic && profileEditBlock && (
							<div className="profile-confirm-block">
								<span className="icon-close" onClick={() => onProfileCloseIcon()} />
								<span className="icon-tick" onClick={() => uploadProfileImage()} />
							</div>
						)}
					</div>

					{isProfile ? (
						<input
							id="image-upload"
							className="image-upload-input"
							type="file"
							onChange={onChooseProfile}
							accept="image/*"
							ref={fileUploadRef}
						/>
					) : null}

					<Confirm
						onYes={confirmDeleteProfileImage}
						onNo={(e) => {
							setConfirmDelete(false);
						}}
						show={confirmDelete ? true : false}
					/>

					<div className="user-info-wrap">
						<InfoItem label="Name" value={initialValues.name} />
						<InfoItem label="Role" value={initialValues.userRoleId ? initialValues.userRoleId.label : ""} />
						<InfoItem label="Email" value={initialValues.emailAddress} />
						<InfoItem label="Phone" value={initialValues.phoneNumber} />
						<InfoItem label="Job Title" value={initialValues.jobTitle} />
						{isProfile ? null : (
							<InfoItem label="Status" value={initialValues.status ? initialValues.status.label : ""} />
						)}
						<InfoItem label="Company" value={initialValues.companyInput ? initialValues.companyInput.label : ""} />
					</div>
				</Card.Body>
			</Card>
			<Card>
				<Card.Title className="info-card-title">
					<Heading title="Activity Log" />
				</Card.Title>
				<Card.Body className="info-card-body">
					<FilterForm
						startDate={startDate}
						endDate={endDate}
						initialValues={initialFilterDates}
						onSubmit={getActivityList}
					/>

					{Modules.length ? (
						<>
							<SearchField
								initialValue={filters.searchKey}
								onSearch={(searchKey) =>
									doSetFilter({
										...filters,
										searchKey,
										pageIndex: 1,
									})
								}
								className={`flex-fill mb-0 activity-log-search`}
								placeholder="Enter your query"
							/>
							<Table
								columns={columns}
								data={activityLog}
								onSort={(e) =>
									e.by !== filters.sortColumn || e.order !== filters.sortDirection
										? doSetFilter({
												...filters,
												sortColumn: e.by,
												sortDirection: e.order,
												pageIndex: 1,
										  })
										: null
								}
								sortBy={filters.sortColumn}
								sortDir={filters.sortDirection}
							/>
							<Pagination
								total={recordsTotal}
								count={filters.pageSize}
								page={filters.pageIndex}
								onChange={(e) => {
									doSetFilter({ ...filters, pageIndex: e });
								}}
								onPageCountChange={(e) => {
									doSetFilter({ ...filters, pageSize: e, pageIndex: 1 });
								}}
							/>
						</>
					) : null}
				</Card.Body>
			</Card>
		</Layout>
	);
};
const selector = formValueSelector("adminAcivityFilter");
const mapStateToProps = (state) => {
	const { startDate, endDate } = selector(state, "startDate", "endDate");
	const {
		User: { activityLog = {} },
	} = state;

	const {
		Common: { Modules = [] },
	} = state;

	return {
		startDate,
		endDate,
		activityLog: activityLog.data,
		Modules,
	};
};
const mapDispatchToProps = {
	doGetActivityLog: getActivityLog,
	doShowLoader: showLoader,
	doProfileImageUpload: profileImageUpload,
	doDeleteProfileImage: deleteProfileImage,
	doResetField: resetField,
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminDetails);
