export const successCodes = [
	{
		code: 101,
		message: "Signin successful!",
	},
	{
		code: 102,
		message: "Logged out successfully!",
	},
	{ code: 103, message: "We have e-mailed your password reset link!" },
	{ code: 104, message: "Your password has been changed successfully!" },
	{ code: 105, message: "Your profile photo has been changed successfully!" },
	{ code: 106, message: "Your profile photo has been deleted successfully!" },
	{ code: 201, message: "User has been created successfully!" },
	{ code: 202, message: "User has been updated successfully!" },
	{ code: 203, message: "Exchanger has been created successfully!" },
	{ code: 204, message: "Exchanger has been updated successfully!" },
	{ code: 306, message: "Escrow has been created successfully!" },
	{ code: 307, message: "Escrow has been updated successfully!" },
	{ code: 205, message: "User removed!" },
	{ code: 206, message: "Entity has been created successfully!" },
	{ code: 207, message: "Entity has been updated successfully!" },
	{ code: 208, message: "Entity removed!" },
	{ code: 209, message: "Task has been created successfully!" },
	{ code: 210, message: "Task has been updated successfully!" },
	{ code: 211, message: "Task removed!" },
	{ code: 212, message: "Fee has been created successfully!" },
	{ code: 213, message: "Fee has been updated successfully!" },
	{ code: 214, message: "Fee removed!" },
	{ code: 215, message: "Bank has been created successfully!" },
	{ code: 216, message: "Bank has been updated successfully!" },
	{ code: 217, message: "Default Bank has been updated successfully!" },
	{ code: 218, message: "Bank removed!" },
	{ code: 219, message: "Exchanger removed!" },
	{ code: 220, message: "Escrow removed!" },
	{ code: 221, message: "Contact has been created successfully!" },
	{ code: 222, message: "Contact has been updated successfully!" },
	{ code: 223, message: "Contact removed!" },
	{ code: 230, message: "Documents has been uploaded successfully" },
	{ code: 231, message: "Document removed!" },
	{ code: 401, message: "Email sent successfully!" },
	{ code: 224, message: "PDF Configuration has been updated succesfully!" },
	{ code: 402, message: "Exchange Unlocked!" },
	{ code: 403, message: "Exchange removed!" },
	{ code: 404, message: "Basic Information updated successfully!" },
	{ code: 405, message: "Note has been created successfully!" },
	{ code: 406, message: "Note removed!" },
	{ code: 407, message: "Bank Transactions and Charges updated successfully!" },
	{ code: 408, message: "Task updated successfully!" },
	{ code: 409, message: "Task removed!" },
	{ code: 410, message: "Task has been created successfully!" },
	{ code: 411, message: "Exchange has been updated successfully!" },
	{ code: 501, message: "Bank Transaction removed!" },
	{ code: 502, message: "Income removed!" },
	{ code: 503, message: "Bank Transaction has been created successfully!" },
	{ code: 504, message: "Income has been created successfully!" },
	{ code: 505, message: "Bank Transaction updated successfully!" },
	{ code: 506, message: "Income updated successfully!" },
	{ code: 507, message: "Exchange Info updated successfully!" },
	{ code: 508, message: "Relinquished Property Info updated successfully!" },
	{ code: 509, message: "Replacement Property Info updated successfully!" },
	{ code: 601, message: "Notifications updated successfully!" },
	{ code: 602, message: "Notifications removed!" },
	{ code: 603, message: "All Notifications cleared!" },
	{ code: 510, message: "Template published successfully!" },
	{ code: 511, message: "Template saved as draft successfully!" },
	{ code: 512, message: "Template removed!" },
	{ code: 513, message: "Template copied successfully!" },
	{ code: 514, message: "Reset password link send to email successfully!" },
	{ code: 515, message: "Document shared to docusign successfully!" },
	{ code: 516, message: "Settlement company has been created successfully!" },
	{ code: 517, message: "Settlement company and excrow created successfully!" },
	{ code: 518, message: "Excrow agent has been created successfully!" },
	{ code: 519, message: "An otp has been sucessfully sent to your email. Please verify to proceed." },
	{ code: 520, message: "An otp has been sent to your registered email address. Please use the otp to login into your account." },
	{ code: 521, message: "If your email is associated with an active registered account, you will receive a password reset link via email" },
];
