import React from "react";

import Slider from "../SharedComponts/Slider";
import SliderForm from "../SharedComponts/SliderForm";
const renderItem = (item) => {
	return item && item !== "" ? item : "---";
};
const NoteSlider = (props) => {
	const { show, handleClose, note } = props;
	return (
		<Slider
			show={show}
			title={`More Details`}
			closeButton={true}
			handleClose={() => {
				handleClose();
			}}>
			<SliderForm handleClose={handleClose} hideSubmit={true}>
				<div className="form-group">
					<div className="form-lable">State</div>
					<div>{renderItem(note.stateName)}</div>
				</div>
				<div className="form-group">
					<div className="form-lable">City</div>
					<div>{renderItem(note.cityName)}</div>
				</div>
				<div className="form-group">
					<div className="form-lable">County</div>
					<div>{renderItem(note.county)}</div>
				</div>
				<div className="form-group">
					<div className="form-lable">Zip Code</div>
					<div>{renderItem(note.zipCode)}</div>
				</div>
				<div className="form-group">
					<div className="form-lable">Escrow Number</div>
					<div>{renderItem(note.escrowNumber)}</div>
				</div>
				<div className="form-group">
					<div className="form-lable">Account Number</div>
					<div>{renderItem(note.accountNumber)}</div>
				</div>
			</SliderForm>
		</Slider>
	);
};

export default NoteSlider;
