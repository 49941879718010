import { USER } from "../types";
import fetchHandler from "../../utils/fetchHandler";
import { serialize, getRoleIDs } from "../../utils/helper";
export function setAdminFilter(filter, successHandler) {
	successHandler && successHandler();
	return {
		type: USER.SET_ADMIN_FILTER,
		payload: filter,
	};
}
export function setExchangerFilter(filter, successHandler) {
	successHandler && successHandler();
	return {
		type: USER.SET_EXCHANGER_FILTER,
		payload: filter,
	};
}
export function setEscrowFilter(filter, successHandler) {
	successHandler && successHandler();
	return {
		type: USER.SET_ESCROW_FILTER,
		payload: filter,
	};
}
export function setContactFilter(filter, successHandler) {
	successHandler && successHandler();
	return {
		type: USER.SET_CONTACT_FILTER,
		payload: filter,
	};
}
export const searchUsers = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `usersbyrole?${serialize(values)}`,
		method: "GET",
		secure: true,
		actionType: USER.SEARCH_USERS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const getUsers = (values, successHandler, errorHandler) => {
	let extra = "";
	if (values.isContact) {
		getRoleIDs(["Attorney", "Legal Advisor", "Sales Rep", "Seller", "Buyer", "Tax Consultant", "Co-Exchanger"]).forEach(
			(element) => (extra += `&ExtraParams.RoleId=${element.value}`)
		);
	}
	const fetchOptions = {
		url: `users?${serialize(values)}${extra}`,
		method: "GET",
		secure: true,
		actionType: USER.GET_USER_LIST,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getExchangers = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `exchangers`,
		method: "GET",
		secure: true,
		actionType: USER.GET_EXCHANGER_LIST,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const getActivityLog = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `user/activitylog`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: USER.GET_ACTIVITY_LOG,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const createUser = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `user`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: USER.CREATE_USER,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const updateUser = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `user/${values.id}`,
		method: "PUT",
		secure: true,
		body: JSON.stringify(values),
		actionType: USER.UPDATE_USER,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getUser = (userId, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `user/${userId}`,
		method: "GET",
		secure: true,
		actionType: USER.GET_USER,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const createExchanger = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `exchanger`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: USER.CREATE_EXCHANGER,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const updateExchanger = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `exchanger/${values.id}`,
		method: "PUT",
		secure: true,
		body: JSON.stringify(values),
		actionType: USER.UPDATE_EXCHANGER,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getExchanger = (exchangerId, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `exchanger/${exchangerId}`,
		method: "GET",
		secure: true,
		actionType: USER.GET_EXCHANGER,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const createEscrow = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `escrow`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: USER.CREATE_ESCROW,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const updateEscrow = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `escrow/${values.id}`,
		method: "PUT",
		secure: true,
		body: JSON.stringify(values),
		actionType: USER.UPDATE_ESCROW,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getEscrow = (exchangerId, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `escrow/${exchangerId}`,
		method: "GET",
		secure: true,
		actionType: USER.GET_ESCROW,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const createContact = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `contact`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: USER.CREATE_CONTACT,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const updateContact = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `contact/${values.id}`,
		method: "PUT",
		secure: true,
		body: JSON.stringify(values),
		actionType: USER.UPDATE_CONTACT,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getContact = (id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `contact/${id}`,
		method: "GET",
		secure: true,
		actionType: USER.GET_CONTACT,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getContactRoles = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `general/contactRoles`,
		method: "GET",
		secure: true,
		actionType: USER.GET_CONTACT_ROLES,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const relatedExchangesExists = ({ id, ...params }, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `relatedExchangesExists/${id}?${serialize(params)}`,
		method: "GET",
		secure: true,
		actionType: USER.CHECK_RELATED_EXCHANGE,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const deleteUser = (Id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `user/${Id}`,
		method: "DELETE",
		secure: true,
		actionType: USER.CHECK_RELATED_EXCHANGE,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getAdminusers = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `adminusers`,
		method: "GET",
		secure: true,
		actionType: USER.GET_ADMIN_USERS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const sendPasswordresetmail = (id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `passwordresetmail/${id}`,
		method: "POST",
		secure: true,
		actionType: USER.PASSWORD_RESET_MAIL,
		body: JSON.stringify({}),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const profileImageUpload = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `user/profileimage`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: USER.UPLOAD_PROFILE_IMAGE,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const deleteProfileImage = (Id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `/user/profileimage?id=${Id}`,
		method: "DELETE",
		secure: true,
		actionType: USER.DELETE_PROFILE_IMAGE,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
