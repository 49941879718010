import { PDF } from "../types";
import fetchHandler from "../../utils/fetchHandler";

export const getPDFConfig = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `pdfconfig`,
		method: "GET",
		secure: true,
		actionType: PDF.GET_PDF_CONFIG,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const updatePDFConfig = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `pdfconfig`,
		method: "POST",
		secure: true,
		actionType: PDF.UPDATE_PDF_CONFIG,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
