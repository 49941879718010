import { SETTINGS } from "../types";

export default (state = {}, action) => {
	switch (action.type) {
		case SETTINGS.GET_COMPANY_LIST:
			return {
				...state,
				companyList: action.payload ? action.payload : {},
			};
		case SETTINGS.GET_EXCHANGE_TYPES:
			return {
				...state,
				exchangeTypes: action.payload ? action.payload.result : [],
			};
		case SETTINGS.GET_COMPANY_TYPES:
			return {
				...state,
				companyTypes: action.payload ? action.payload.result : [],
			};
		default:
			return state;
	}
};
