import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";

import Layout from "../../SharedComponts/Layout";
import { Table, Pagination } from "../../SharedComponts/FormElements/table";
import { columns as columnsFn } from "./helper";
import {
	getTemplateList,
	deleteTemplate,
	setTemplateFilter,
	setTemplateFormInputs,
	showLoader,
	copyTemplate,
} from "../../../store/actions";

import SearchField from "../../SharedComponts/FormElements/searchTable";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";

import AddEdit from "./AddEdit";
// import "./banks.scss";
import Confirm from "../../SharedComponts/FormElements/confirm";
import EditTemplate from "./EditTemplate";
import CopyTemplateForm from "./CopyTemplateForm";

import ViewTemplate from "./ViewTemplate";

import "./template.scss";
const TemplateList = (props) => {
	const {
		doSetFilter,
		doDeleteTemplate,
		doCopyTemplate,
		doSetTemplateFormInputs,
		doGetTemplateList,
		filters,
		history,
		recordsTotal,
		templateList,
		doShowLoader,
		formInputs,
	} = props;
	const [showPopup, setshowPopup] = useState(false);
	const [showCopyPopup, setShowCopyPopup] = useState(false);
	const [isEdit, setIsEdit] = useState(true);
	const [isList, setIsList] = useState(true);
	const [currentRecord, setCurrentRecord] = useState({});
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [listReload, setListReload] = useState(false);
	const [isView, setView] = useState(false);
	const [currentDeleteRecord, setCurrentDeleteRecord] = useState(null);
	const handleClose = () => setshowPopup(false);
	const handleShow = () => setshowPopup(true);
	const handleSubmit = (value) => {
		doSetTemplateFormInputs(value);
		setCurrentRecord(value);
		handleClose();
		setIsList(false);
	};

	useEffect(() => {
		doShowLoader(true);

		const successCB = (result) => {
			doShowLoader(false);
		};
		const FailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		doGetTemplateList({ ...filters }, successCB, FailCB);
	}, [filters, listReload, doGetTemplateList, doShowLoader]);

	const addTemplate = () => {
		setIsEdit(false);
		setCurrentRecord({});
		handleShow();
	};

	const editTemplate = (row) => {
		setIsEdit(true);
		setCurrentRecord(row);
		setIsList(false);
	};

	const onDeleteTemplate = (row) => {
		setCurrentDeleteRecord(row.id);
		setConfirmDelete(true);
	};
	const confirmDeleteAction = () => {
		doShowLoader(true);
		const failCB = (result) => {
			doShowLoader(false);
			setCurrentDeleteRecord(null);
			setConfirmDelete(false);
			errorMessage(result);
		};
		const successCB = (result) => {
			doShowLoader(false);
			setCurrentDeleteRecord(null);
			setConfirmDelete(false);
			successMessage(512);
			setListReload(!listReload);
		};
		doDeleteTemplate(currentDeleteRecord, successCB, failCB);
	};
	const reload = () => {
		setListReload(!listReload);
	};

	const handleCloseCopy = () => {
		setShowCopyPopup(false);
	};
	const onClickCopy = (row) => {
		setShowCopyPopup(true);
		setCurrentRecord(row);
	};

	const handleSubmitCopy = (values) => {
		doShowLoader(true);
		const failCB = (result) => {
			doShowLoader(false);
			setCurrentDeleteRecord(null);
			setConfirmDelete(false);
			errorMessage(result);
		};
		const successCB = (result) => {
			handleCloseCopy();
			doShowLoader(false);
			setCurrentDeleteRecord(null);
			setConfirmDelete(false);
			successMessage(513);
			setListReload(!listReload);
		};
		doCopyTemplate(values, successCB, failCB);
	};

	const onClickView = (row) => {
		setView(true);
		setCurrentRecord(row);
	};
	const onDownloadTemplate = (row) => {
		fetch(row.templateUrl)
			.then((response) => {
				return response.blob();
			})
			.then((blob) => {
				const url = URL.createObjectURL(blob);
				const a = document.createElement("a");
				a.style.display = "none";
				a.href = url;
				a.download = "template.pdf";
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
			});
	};

	const rightActionButtons = [
		{
			component: () => {
				return (
					<Button className="form-button-primary" onClick={addTemplate}>
						<span className="add-new-plus">+</span> New Template
					</Button>
				);
			},
		},
	];
	const columns = columnsFn({
		onDeleteTemplate,
		onClickCopy,
		onClickView,
		editTemplate,
		onDownloadTemplate,
	});
	return (
		<>
			{!isView &&
				(isList ? (
					<Layout
						title="Templates"
						breadcrumbs={[
							{
								label: "Configurations",
								onClick: () => {
									const path = `/settings-master`;
									history.push(path);
								},
							},
							{ label: "Templates" },
						]}
						rightActionButtons={rightActionButtons}
						className="bank-page template-page">
						<>
							<SearchField
								initialValue={filters.searchKey}
								onSearch={(searchKey) =>
									doSetFilter({
										...filters,
										searchKey,
										pageIndex: 1,
									})
								}
								className={`flex-fill mb-0`}
								placeholder="Enter your query"
							/>
							<Table
								columns={columns}
								data={templateList}
								onSort={(e) =>
									e.by !== filters.sortColumn || e.order !== filters.sortDirection
										? doSetFilter({
												...filters,
												sortColumn: e.by,
												sortDirection: e.order,
												pageIndex: 1,
										  })
										: null
								}
								sortBy={filters.sortColumn}
								sortDir={filters.sortDirection}
							/>
							<Pagination
								total={recordsTotal}
								count={filters.pageSize}
								page={filters.pageIndex}
								onChange={(e) => {
									doSetFilter({ ...filters, pageIndex: e });
								}}
								onPageCountChange={(e) => {
									doSetFilter({ ...filters, pageSize: e, pageIndex: 1 });
								}}
							/>
						</>
						<Confirm
							onYes={confirmDeleteAction}
							onNo={(e) => {
								setConfirmDelete(false);
							}}
							show={confirmDelete ? true : false}
						/>
					</Layout>
				) : (
					<EditTemplate
						isEdit={isEdit}
						initialValues={isEdit ? currentRecord : formInputs}
						currentRecord={currentRecord}
						setIsList={setIsList}
						reload={reload}
						history={history}
					/>
				))}
			{isView && <ViewTemplate history={history} setView={setView} currentRecord={currentRecord} />}
			{showPopup ? (
				<AddEdit
					show={showPopup}
					initialValues={currentRecord}
					isEdit={isEdit}
					handleClose={handleClose}
					onSubmit={handleSubmit}
				/>
			) : null}
			{showCopyPopup ? (
				<CopyTemplateForm
					show={showCopyPopup}
					initialValues={{
						baseTemplateId: currentRecord.id,
						newTemplateName: `${currentRecord.templateName}_copy`,
					}}
					handleClose={handleCloseCopy}
					onSubmit={handleSubmitCopy}
				/>
			) : null}
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		Template: { templateList: { data: templateList = [], recordsTotal = 0 } = { data: [] }, formInputs = {}, filters = {} },
	} = state;
	return {
		formInputs,
		templateList,
		filters,
		recordsTotal,
	};
};
const mapDispatchToProps = {
	doGetTemplateList: getTemplateList,
	doSetFilter: setTemplateFilter,
	doShowLoader: showLoader,
	doDeleteTemplate: deleteTemplate,
	doSetTemplateFormInputs: setTemplateFormInputs,
	doCopyTemplate: copyTemplate,
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateList);
