import { EXCHANGE } from "../types";
import fetchHandler from "../../utils/fetchHandler";
import { serialize } from "../../utils/helper";

export function setExchangeFilter(filter, successHandler) {
	successHandler && successHandler();
	return {
		type: EXCHANGE.SET_FILTER,
		payload: filter,
	};
}

export const getEntityList = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `entityNames?${serialize(values)}`,
		method: "GET",
		secure: true,
		actionType: EXCHANGE.GET_ENTITY_LIST,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const createExchange = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `exchange`,
		method: "POST",
		secure: true,
		actionType: EXCHANGE.CREATE_EXCHANGE,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const updateExchange = ({ guid, ...values }, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `exchange/${guid}`,
		method: "PUT",
		secure: true,
		actionType: EXCHANGE.CREATE_EXCHANGE,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getExchangeList = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `exchanges`,
		method: "POST",
		secure: true,
		actionType: EXCHANGE.GET_EXCHANGE_LIST,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getExchangeDetails = (id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `exchange/${id}`,
		method: "GET",
		secure: true,
		actionType: EXCHANGE.GET_EXCHANGE_DETAIL,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const exchangeExport = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `exchangesExport`,
		method: "POST",
		secure: true,
		actionType: EXCHANGE.EXCHANGE_EXPORT,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const userExport = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `usersExport`,
		method: "POST",
		secure: true,
		actionType: EXCHANGE.EXCHANGE_USERS_EXPORT,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const updateExchangerInfo = ({ guid, ...values }, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `exchangeInfo/${guid}`,
		method: "PUT",
		secure: true,
		actionType: EXCHANGE.EXCHANGER_INFO_UPDATE,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const sendEmail = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `general/email`,
		method: "POST",
		secure: true,
		actionType: EXCHANGE.SEND_MAIL,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const createExchangeContact = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `exchangerContactByExchange`,
		method: "POST",
		secure: true,
		actionType: EXCHANGE.CREATE_CONTACT,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const exchangeUnlock = (id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `exchangelock/${id}/${false}`,
		method: "POST",
		secure: true,
		actionType: EXCHANGE.UNLOCK,
		body: JSON.stringify({ id }),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const deleteExchange = (id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `exchange/${id}`,
		method: "DELETE",
		secure: true,
		actionType: EXCHANGE.DELETE_EXCHANGE,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getNotes = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `general/notes/${values.Id}/${values.TypeId}`,
		method: "GET",
		secure: true,
		actionType: EXCHANGE.GET_NOTES,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const createNote = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `general/notes`,
		method: "POST",
		secure: true,
		actionType: EXCHANGE.CREATE_NOTE,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const deleteNote = (id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `general/note/${id}`,
		method: "DELETE",
		secure: true,
		actionType: EXCHANGE.DELETE_EXCHANGE,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getExchangeBankList = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `BankList?${serialize(values)}`,
		method: "GET",
		secure: true,
		actionType: EXCHANGE.EXCHANGE_BANK_LIST,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const createBankBasicInfo = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `exchangeBankBasicInfo`,
		method: "POST",
		secure: true,
		actionType: EXCHANGE.CREATE_BANK_BASIC_INFO,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const updateBankBasicInfo = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `exchangeBankBasicInfo?id=${values.id}`,
		method: "PUT",
		secure: true,
		actionType: EXCHANGE.UPDATE_BANK_BASIC_INFO,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getBankBasicInfo = (id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `exchangeBankBasicInfo/${id}`,
		method: "GET",
		secure: true,
		actionType: EXCHANGE.GET_BANK_BASIC_INFO,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const getExchangeTaskList = (id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `exchangetasks/${id}`,
		method: "GET",
		secure: true,
		actionType: EXCHANGE.GET_EXCHANGE_TASK_LIST,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const createExchangeTask = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `exchangeTask`,
		method: "POST",
		secure: true,
		actionType: EXCHANGE.CREATE_EXCHANGE_TASK,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const updateExchangeTask = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `exchangeTask/${values.id}`,
		method: "PUT",
		secure: true,
		actionType: EXCHANGE.UPDATE_EXCHANGE_TASK,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const deleteExchangeTask = (id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `exchangeTask/${id}`,
		method: "DELETE",
		secure: true,
		actionType: EXCHANGE.DELETE_EXCHANGE_TASK,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getRelinquishedProperty = (exchangeId, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `exchangeProperties/${exchangeId}/251`,
		method: "GET",
		secure: true,
		actionType: EXCHANGE.GET_RELINQUISHED_PROPERTY,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const getReplacementProperty = (exchangeId, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `exchangeProperties/${exchangeId}/252`,
		method: "GET",
		secure: true,
		actionType: EXCHANGE.GET_REPLACEMENT_PROPERTY,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const createRelinquishedProperty = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `property`,
		method: "POST",
		secure: true,
		actionType: EXCHANGE.CREATE_RELINQUISHED_PRPERTY,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const createProperty = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `property`,
		method: "POST",
		secure: true,
		actionType: EXCHANGE.CREATE_PRPERTY,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const updateProperty = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `property/${values.id}`,
		method: "PUT",
		secure: true,
		actionType: EXCHANGE.UPDATE_PROPERTY,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const deleteProperty = (id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `property/${id}`,
		method: "DELETE",
		secure: true,
		actionType: EXCHANGE.DELETE_PROPERTY,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const createCoexchanger = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `coexchanger`,
		method: "POST",
		secure: true,
		actionType: EXCHANGE.CREATE_COEXCHANGER,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const deleteCoexchanger = (id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `coexchanger/${id}`,
		method: "DELETE",
		secure: true,
		actionType: EXCHANGE.DELETE_COEXCHANGER,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const createBuyer = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `dealer`,
		method: "POST",
		secure: true,
		actionType: EXCHANGE.CREATE_BUYER,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const createSeller = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `dealer`,
		method: "POST",
		secure: true,
		actionType: EXCHANGE.CREATE_SELLER,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const deleteDealer = (id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `dealer/${id}`,
		method: "DELETE",
		secure: true,
		actionType: EXCHANGE.DELETE_DEALER,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const submitProperty = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `exchangeProperties`,
		method: "POST",
		secure: true,
		actionType: EXCHANGE.SUBMIT_PROPERTY,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const searchExcrowByCompany = ({ searchKey, companyId }, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `escrows/${companyId}/${searchKey}`,
		method: "GET",
		secure: true,
		actionType: EXCHANGE.SEARCH_EXCROW,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const excrowByCompany = ( companyId , successHandler, errorHandler) => {
	const fetchOptions = {
		url: `escrows/${companyId}`,
		method: "GET",
		secure: true,
		actionType: EXCHANGE.EXCROW_BY_COMPANY,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const getExchangePropertyAddressList = (id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `propertyAddress/${id}`,
		method: "GET",
		secure: true,
		actionType: EXCHANGE.GET_EXCHANGE_ADDRESS_LIST,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const getExchangePropertyAddressListByType = ({ id, typeId }, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `exchangePropertyAddressByTemplateType/${id}/${typeId}`,
		method: "GET",
		secure: true,
		actionType: EXCHANGE.GET_EXCHANGE_ADDRESS_LIST_BY_TYPE,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const getExchangeFromToList = (id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `sourceDetailsByProperty/${id}`,
		method: "GET",
		secure: true,
		actionType: EXCHANGE.GET_EXCHANGE_TO_FROM_LIST,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getExchangedocumentList = (id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `exchangedocuments/${id}`,
		method: "GET",
		secure: true,
		actionType: EXCHANGE.GET_EXCHANGE_DOCUMENT_LIST,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getDocumentList = (id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `document/${id}`,
		method: "GET",
		secure: true,
		actionType: EXCHANGE.GET_DOCUMENT_LIST,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const updateDocument = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `document/${values.id}`,
		method: "PUT",
		secure: true,
		actionType: EXCHANGE.UPDATE_DOCUMENT,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const updateDocumentStatus = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `documentstatusupdate/${values.id}`,
		method: "PUT",
		secure: true,
		actionType: EXCHANGE.UPDATE_DOCUMENT_STATUS,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const deleteDocument = (id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `document/${id}`,
		method: "DELETE",
		secure: true,
		actionType: EXCHANGE.DELETE_DOCUMENT,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getExchangePropAddressList = (id, typeId, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `exchangePropertyAddress/${id}/${typeId}`,
		method: "GET",
		secure: true,
		actionType: EXCHANGE.GET_PROPERTY_ADDRESS,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const uploadDocument = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `UploadDocument`,
		method: "POST",
		secure: true,
		actionType: EXCHANGE.UPLOAD_DOCUMENT,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getSignatureMenu = ({ exchangeId, propertyId, documentId }, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `signatureDropdown/${exchangeId}/${propertyId}/${documentId}`,
		method: "GET",
		secure: true,
		actionType: EXCHANGE.GET_SIGNATURE_MENU,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const createAdditionalSign = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `addAdditionalSignature`,
		method: "POST",
		secure: true,
		actionType: EXCHANGE.CREATE_ADDITIONAL_SIGN,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const generateDocument = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `generateDocument`,
		method: "POST",
		secure: true,
		actionType: EXCHANGE.GENERATE_DOCUMENT,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getDocument = (id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `document/${id}`,
		method: "GET",
		secure: true,
		actionType: EXCHANGE.GET_DOCUMENT,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const shareViaDocusign = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `shareViaDocusign`,
		method: "POST",
		secure: true,
		actionType: EXCHANGE.GET_DOCUMENT,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const getDocusigntoemaillist = (documentId, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `docusigntoemaillist/${documentId}`,
		method: "GET",
		secure: true,
		actionType: EXCHANGE.GET_DOCUMENT,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const exchangeNumbersSearch = (searchKey, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `/exchangeNumbers/${searchKey}`,
		method: "GET",
		secure: true,
		actionType: EXCHANGE.EXCHANGE_NUMBER_SEARCH,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getSalesRepoList = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `usersbyrole?roleId=9`,
		method: "GET",
		secure: true,
		actionType: EXCHANGE.GET_SALES_REPO_LIST,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};