import React from "react";
import { connect } from "react-redux";
const PreloaderInner = ({ showLoader }) => {
	return showLoader > 0 ? (
		<div className="app_loader">
			<div className="css-loader"></div>
		</div>
	) : null;
};

const mapStateToProps = (state) => {
	return {
		showLoader: state.UI.showLoader,
	};
};

export default connect(mapStateToProps, null)(PreloaderInner);
