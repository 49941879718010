export const errorCodes = [
	// custom messages
	{
		code: 10114,
		reason: "LoginFailed",
		message: "Your email and/or password do not match",
	},
	{
		code: 102,
		reason: "LoginFailed",
		message: "Session expired.",
	},
	{ code: 103, reason: "file size exeeds limit", message: "File is too big." },
	{ code: 104, reason: "Password Missmatch", message: "Your password and confirmation password do not match." },
	{ code: 105, reason: "Date Filter Issue", message: "From date or to date is required" },
	{ code: 201, reason: "Data validation failed", message: "Either exchange number or exchanger is required" },
	{ code: 202, reason: "Data validation failed", message: "Exchange number is required" },
	{ code: 301, reason: "Data validation failed", message: "Completed Date is required" },
	{ code: 302, reason: "Data validation failed", message: "Completed By is required" },
	{ code: 401, reason: "Data validation failed", message: "Document is required" },
	{ code: 402, reason: "file size exeeds limit", message: "File size exeeds limit" },
	{ code: 501, reason: "template is required", message: "Please choose a template" },
	{ code: 502, reason: "property is required", message: "Please select a property" },
	{ code: 503, reason: "property is required", message: "Maximum 4 lines allowed in header" },
	{ code: 506, reason: "signature is required", message: "Add signature to share via docusign" },
	{ code: 507, reason: "document is required", message: "Document name is mandatory" },
	{ code: 508, message: "Same coexchanger added multiple times." },
	{ code: 509, message: "Same seller added to a property multiple  times." },
	{ code: 510, message: "Same buyer added to a property multiple times." },

	{
		code: "FETCH_FAILED",
		reason: "Network issue",
		message: "Sorry, application was unable to process this request. Please check your network.",
	},
	{ code: 10003, message: "Failed to Upload File" },
	{ code: 10004, message: "Failed to Delete File" },
	{
		code: 10101,
		message:
			"Please enter a password with a minimum length of 8 characters and a combination of uppercase, lowercase, numeric and special characters.",
	},
	{
		code: 10102,
		message: "We couldn't find an account associated with the email id entered",
	},
	{
		code: 10103,
		message: "Inactive account",
	},
	{
		code: 10104,
		message:
			"The account associated with this email address is not active at the moment. Please get in touch with Spectrum team for assistance.",
	},
	{
		code: 10105,
		message:
			"The account associated with this email address is not active at the moment. Please get in touch with Spectrum team to reactivate account.",
	},
	{
		code: 10106,
		message: "The link has expired. Please login the system and reset your password or contact the admin.",
	},
	{
		code: 10107,
		message: "We could not change your password due to an unexpected problem. Please try again",
	},
	{
		code: 10108,
		message: "Reset link not sent",
	},
	{
		code: 10109,
		message: "We could not change your password due to an unexpected problem. Please try again",
	},
	{
		code: 10110,
		message: "The password reset link could not be sent due to an unexpected problem. Please try again after sometime",
	},
	{
		code: 10111,
		message:
			"The account associated with this email address is not active at the moment. Please get in touch with Spectrum team to reactivate account",
	},
	{
		code: 10114,
		message: "Try that again",
	},
	{
		code: 10115,
		message: "Some values did not load properly. Please try again",
	},
	{
		code: 10116,
		message: "Your email and/or password do not match",
	},
	{
		code: 10117,
		message: "Please enter a valid County for the specified address",
	},
	{
		code: 10118,
		message: "Please enter a valid State for the specified address",
	},
	{
		code: 10119,
		message: "Please enter a valid city for the specified address",
	},
	{
		code: 10120,
		message: "Please enter a valid address ",
	},
	{
		code: 10121,
		message: "Please enter a valid state for the specified address",
	},
	{
		code: 10122,
		message: "The page could not be loaded either due to a database or network error. Please try again after a while",
	},
	{
		code: 10123,
		message: "The page could not be loaded either due to a database or network error. Please try again after a while",
	},
	{
		code: 10124,
		message: "The page could not be loaded either due to a database or network error. Please try again after a while",
	},
	{
		code: 10125,
		message: "The page could not be loaded either due to a database or network error. Please try again after a while",
	},
	{
		code: 10126,
		message: "The page could not be loaded either due to a database or network error. Please try again after a while",
	},
	{
		code: 10127,
		message: "We could not save escrow information due to an unexpected issue. Please try again",
	},
	{
		code: 10128,
		message: "We could not save escrow information due to an unexpected issue. Please try again",
	},
	{
		code: 10129,
		message:
			"The escrow information could not be loaded either due to a database or network error. Please try again after a while",
	},
	{
		code: 10130,
		message: "We could not save address due to an unexpected issue. Please try again",
	},
	{ code: 10131, message: "We could not save contact due to an unexpected issue. Please try again" },
	{
		code: 10132,
		message:
			"The contact details could not be loaded either due to a database or network error. Please try again after a while",
	},
	{
		code: 10133,
		message:
			"The exchanger list could not be loaded either due to a database or network error. Please try again after a while",
	},
	{
		code: 10134,
		message: "The User could not be deleted either due to a database or network error. Please try again after a while",
	},
	{
		code: 10135,
		message: "The properties could not be saved either due to a database or network error. Please try again after a while",
	},
	{
		code: 10136,
		message:
			"The exchange details could not be loaded either due to a database or network error. Please try again after a while",
	},
	{ code: 10137, message: "The property is not available in the application" },
	{ code: 10138, message: "This Exchange is not available in the application" },
	{ code: 10139, message: "We couldn't find buyer/seller in the application." },
	{
		code: 10140,
		message:
			"The exchange property details could not be loaded either due to a database or network error. Please try again after a while",
	},
	{ code: 10141, message: "We couldn't find relinquished/replacement property in the application." },
	{ code: 10142, message: "We couldn't find buyer/seller type in the application." },
	{ code: 10143, message: "Please input a message before saving notes" },
	{ code: 10144, message: "Note type is not valid." },
	{
		code: 10145,
		message: "Notes could not be loaded either due to a database or network error. Please try again after a while",
	},
	{ code: 10147, message: "Failed to unlock exchange due to an unexpected issue.Please try again" },
	{
		code: 10148,
		message: "The Task could not be deleted either due to a database or network error. Please try again after a while",
	},
	{
		code: 10149,
		message:
			"The bank information could not be saved either due to a database or network error. Please try again after a while",
	},
	{
		code: 10150,
		message:
			"The exchange bank information could not be saved either due to a database or network error. Please try again after a while",
	},
	{ code: 10151, message: "The selected exchange was either removed or is not valid anymore. Please refresh and try again." },
	{ code: 10152, message: "The bank was either removed or is not valid anymore. Please refresh and try again." },
	{
		code: 10153,
		message:
			"The bank details could not be loaded either due to a database or network error. Please try again after a while",
	},
	{
		code: 10154,
		message: "The exchange income information was either removed or is not valid anymore. Please refresh and try again.",
	},
	{
		code: 10155,
		message:
			"The exchange income information could not be saved either due to a database or network error. Please try again after a while",
	},
	{
		code: 10156,
		message:
			"The exchange income list could not be listed either due to a database or network error. Please try again after a while",
	},
	{
		code: 10157,
		message:
			"The exchange report could not be loaded either due to a database or network error. Please try again after a while",
	},
	{ code: 10158, message: "We could not delete property due to an unexpected issue.Please try again" },
	{ code: 10159, message: "We could not delete property buyer or seller due to an unexpected issue.Please try again" },
	{ code: 10160, message: "We could not save property buyer or seller due to an unexpected issue.Please try again" },
	{
		code: 10161,
		message:
			"The information in this exchange is being edited by another user and is locked currently. Please try again after a while",
	},
	{
		code: 10162,
		message: "The report could not be listed either due to a database or network error. Please try again after a while",
	},
	{
		code: 10163,
		message:
			"The Signature list could not be loaded either due to a database or network error. Please try again after a while",
	},
	{
		code: 10164,
		message:
			"The Additional Signature could not be saved either due to a database or network error. Please try again after a while",
	},
	{
		code: 10165,
		message: "The document could not be created either due to a database or network error. Please try again after a while",
	},
	{
		code: 10166,
		message: "The document could not be loaded either due to a database or network error. Please try again after a while",
	},
	{ code: 10167, message: "We couldn't find exchange document" },
	{
		code: 10168,
		message: "The document could not be shared either due to a database or network error. Please try again after a while",
	},
	{
		code: 10169,
		message:
			"The related exchange could not be loaded either due to a database or network error. Please try again after a while",
	},
	{ code: 10170, message: "WebHook Faild" },
	{
		code: 10171,
		message: "Email list could not be loaded either due to a database or network error.Please try again after a while",
	},
	{
		code: 10172,
		message:
			"Notification list could not be marked as read either due to a database or network error.Please try again after a while",
	},
	{ code: 10173, message: "The additional signature with same the email address already exists" },
	{ code: 10175, message: "The link has expired. Please login the system and reset your password or contact the admin." },
	{ code: 10176, message: "You don't have enough balance in your account to make this payment." },
	{ code: 10177, message: "The Escrow is associated with an exchange and hence cannot be deleted." },
	{ code: 10178, message: "Invalid file type." },
	{ code: 10179, message: "Your account has been locked out for 15 minutes due to multiple failed login attempts." },
	{ code: 10180, message: "The Contact is associated with an exchange and hence cannot be deleted." },
	{
		code: 10201,
		message: "The page could not be loaded either due to a database or network error. Please try again after a while",
	},
	{
		code: 10202,
		message:
			"Agent with this email address already exists. Please use another email address to save the Agent information.",
	},
	{
		code: 10203,
		message: "Invalid user role",
	},
	{
		code: 10204,
		message: "The selected User Role was either removed or is not valid anymore. Please refresh and try again",
	},
	{
		code: 10205,
		message: "We could not save changes due to an unexpected issue. Please try again",
	},
	{
		code: 10206,
		message:
			"The user information could not be loaded either due to a database or network error. Please try again after a while",
	},
	{
		code: 10207,
		message: "We could not save the user due to an unexpected issue. Please try again",
	},
	{
		code: 10208,
		message: "We could not save the company due to an unexpected issue. Please try again",
	},
	{
		code: 10209,
		message: "Name of the Company is required",
	},
	{
		code: 10210,
		message:
			"The company list could not be loaded either due to a database or network error. Please try again after a while",
	},
	{
		code: 10211,
		message:
			"The company information could not be loaded either due to a database or network error. Please try again after a while",
	},
	{
		code: 10212,
		message: "The selected Company was either removed or is not valid anymore. Please refresh and try again",
	},
	{
		code: 10213,
		message: "This has associated exchange(s) and cannot be deleted",
	},
	{
		code: 10214,
		message: "The Company could not be deleted either due to a database or network error. Please try again after a while",
	},
	{
		code: 10215,
		message: "A Company with the name already exists",
	},
	{
		code: 10216,
		message: "We could not save company changes due to an unexpected issue. Please try again",
	},
	{
		code: 10217,
		message: "Name of Bank is required",
	},
	{
		code: 10218,
		message: "We could not save the bank information due to an unexpected issue. Please try again",
	},
	{
		code: 10219,
		message: "The selected bank was either removed or is not valid anymore. Please refresh and try again",
	},
	{
		code: 10220,
		message: "This is used in exchange(s) and cannot be deleted",
	},
	{
		code: 10221,
		message:
			"The Bank information could not be deleted either due to a database or network error. Please try again after a while",
	},
	{
		code: 10222,
		message: "The Bank list could not be loaded either due to a database or network error. Please try again after a while",
	},
	{
		code: 10223,
		message:
			"The Bank information could not be loaded either due to a database or network error. Please try again after a while",
	},
	{
		code: 10224,
		message: "We could not save the fee structure due to an unexpected issue. Please try again",
	},
	{
		code: 10225,
		message:
			"The fee information could not be loaded either due to a database or network error. Please try again after a while",
	},
	{
		code: 10226,
		message: "The page could not be loaded either due to a database or network error. Please try again after a while",
	},
	{
		code: 10227,
		message: "The selected fee was either removed or is not valid anymore. Please refresh and try again",
	},
	{
		code: 10228,
		message:
			"Fee details already exists for this exchange type. Please edit the current fee information or remove the existing to create new fee",
	},
	{
		code: 10229,
		message: "The fee list could not be loaded either due to a database or network error. Please try again after a while",
	},
	{
		code: 10230,
		message: "We could not save Fee changes due to an unexpected issue. Please try again",
	},

	{
		code: 10231,
		message: "The Fee could not be deleted either due to a database or network error. Please try again after a while",
	},
	{
		code: 10232,
		message: "We could not save the exchange due to an unexpected issue. Please try again",
	},
	{
		code: 10248,
		message: "The bill due could not send either due to a database or network error. Please try again after a while",
	},
	{
		code: 10249,
		message: "The Exchanger is associated with an exchange and hence the Exchanger cannot be deleted.",
	},
	{
		code: 10250,
		message: "This Company  is associated with an exchange and hence the company cannot be deleted.",
	},
	{
		code: 10251,
		message: "The user is associated with an exchange and hence cannot be deleted.",
	},
	{
		code: 10252,
		message: "The user is associated with an exchange and hence cannot be deleted.",
	},
	{
		code: 10301,
		message: "Current user did not login to the application, Please login and try again",
	},
	{
		code: 10302,
		message: "The password you have entered is incorrect.  Please try again",
	},
	{
		code: 10303,
		message: "We could not save the task due to an unexpected issue. Please try again",
	},
	{
		code: 10304,
		message: "The page could not be loaded either due to a database or network error. Please try again after a while",
	},
	{
		code: 10305,
		message: "The page could not be loaded either due to a database or network error. Please try again after a while",
	},
	{
		code: 10306,
		message: "We could not save task changes due to an unexpected issue. Please try again",
	},
	{
		code: 10307,
		message: "The page could not be loaded either due to a database or network error. Please try again after a while",
	},
	{
		code: 10308,
		message: "The page could not be loaded either due to a database or network error. Please try again after a while",
	},
	{ code: 10401, message: "Please select the file to upload" },
	{ code: 10402, message: "Key name length is invalid" },
	{ code: 10403, message: "This document is too large, maximum allowed file size for upload is 25MB." },
	{ code: 10501, message: "DollarsIn and DollarsOut are not allowed together, Either one should be 0 " },
	{ code: 10502, message: "The Decimal should be in the range of Decimal(10,2)" },
	{ code: 10503, message: "We couldn't find this Note" },
	{ code: 10504, message: "We could not delete this Note due to an unexpected issue.Please try again" },
	{
		code: 10505,
		message:
			"We could not create the Template coz an active template already exist. Please try deleting the active one to create new",
	},
	{ code: 10506, message: "We could not save the Template due to an unexpected issue. Please try again" },
	{ code: 10507, message: "We could not Update the Template  due to an unexpected issue. Please try again" },
	{ code: 10508, message: "The selected Template either removed or is not valid anymore. Please refresh and try again" },
	{
		code: 10509,
		message: "The Template could not be deleted either due to a database or network error. Please try again after a while",
	},
	{ code: 10510, message: "We could not save the Template due to an unexpected issue. Please try again" },
	{ code: 10512, message: "We could not Create Template due to an unexpected issue.Please try again" },
	{ code: 10513, message: "We could not Upload The Document due to an unexpected issue.Please try again" },
	{
		code: 10514,
		message:
			"The Exchange Documents list could not be loaded either due to a database or network error. Please try again after a while",
	},
	{
		code: 10515,
		message:
			"The Recently Modified Exchange list could not be loaded either due to a database or network error. Please try again after a while",
	},
	{
		code: 10516,
		message:
			"The Top Escrows List list could not be loaded either due to a database or network error. Please try again after a while",
	},
	{
		code: 10517,
		message:
			"The Top Sales Representatives list could not be loaded either due to a database or network error. Please try again after a while",
	},
	{
		code: 10518,
		message:
			"The Billed vs Paid list could not be loaded either due to a database or network error. Please try again after a while",
	},
	{
		code: 10519,
		message:
			"The DoughnutData could not be loaded either due to a database or network error. Please try again after a while",
	},
	{
		code: 10520,
		message:
			"The ExchangeSummary could not be loaded either due to a database or network error. Please try again after a while",
	},
	{
		code: 10521,
		message: "The NextEvents could not be loaded either due to a database or network error. Please try again after a while",
	},
	{
		code: 10522,
		message: "The selected document could not be deleted or is not valid anymore. Please refresh and try again",
	},
	{ code: 10523, message: "We couldn't generate password reset mail due to an unexpected issue. Please try again" },
	{
		code: 10524,
		message:
			"The Activities list could not be loaded either due to a database or network error. Please try again after a while",
	},
	{ code: 10525, message: "Template with same name already exists. Please try another name" },
	{ code: 10528, message: "Oops! you have entered an invalid otp Please check again" },
];
