import React from "react";

import { MoreOptions } from "../../SharedComponts/FormElements";
import FormControl from "../../SharedComponts/FormControl";
import { required, USZipCode } from "../../../constant/formValidators";

export const columns = ({ onEditBank = () => {}, onDeleteBank = () => {}, setDefaultBank }) => [
	{
		name: "Choose default",
		className: "col-bank-choose",
		sortable: false,
		render: (row) => (
			<FormControl
				type="radio"
				input={{
					name: "default",
					id: row.id,
					checked: row.isDefault,
					value: row.bankName,
					onChange: (val) => {
						setDefaultBank(row);
					},
				}}
				meta={{ touched: false, error: false, warning: false }}
			/>
		),
	},
	{
		name: "Bank Name",
		accessor: "bankName",
		className: "col-bank-name",
	},
	{
		name: "Address",
		accessor: "streetAddress",
		className: "col-address",
		sortable: false,
	},
	{
		name: "City",
		accessor: "cityName",
		sortable: false,
		className: "col-city",
	},
	{
		name: "State",
		accessor: "stateName",
		sortable: false,
		className: "col-state",
	},
	{
		name: "ZIP",
		accessor: "zipCode",
		sortable: false,
		className: "col-zip",
	},
	{
		name: "Actions",
		className: "col-actions text-right",
		headerClassName: "col-actions-header",
		sortable: false,
		render: (row) => {
			return (
				<MoreOptions
					options={[
						{ label: "Edit", onClick: () => onEditBank(row) },
						{ hide: row.isDefault, label: "Delete", onClick: () => onDeleteBank(row) },
					]}
				/>
			);
		},
	},
];

export const formFields = (states, cities, doGetCities, loadCounties, change) => {
	return [
		{
			label: "Bank Name",
			name: "bankName",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			validate: [required],
			required: true,
		},

		{
			name: "address.streetAddress",
			label: "Address",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
		},
		{
			name: "address.state",
			label: "State",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			options: states,
			onChange: (selected) => {
				doGetCities(selected.value);
				change("address.city", null);
			},
		},
		{
			name: "address.city",
			label: "City",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			options: cities,
		},

		{
			name: "address.zipCode",
			label: "ZIP Code",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			validate: [USZipCode],
		},
	];
};
