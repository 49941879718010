import { FEE } from "../types";

export default (
	state = {
		filters: {
			sortColumn: "exchangeType",
			sortDirection: "asc",
			searchKey: "",
		},
	},
	action
) => {
	switch (action.type) {
		case FEE.SET_FILTER:
			return {
				...state,
				filters: action.payload ? action.payload : {},
			};
		case FEE.GET_FEE_LIST:
			return {
				...state,
				feeList: action.payload ? action.payload.result : [],
			};

		default:
			return state;
	}
};
