import FormControl from "../SharedComponts/FormControl";
import { required } from "../../constant/formValidators";

export const formFields = (
	weekRange,
	getExchangeNumber,
	getExchangerName,
	formValues,
	exchangeNumberDropdown,
	exchangerNameDropdown,
	change
) => {
	return [
		{
			name: "report.exchangeNumber",
			label: "Exchange Number",
			component: FormControl,
			type: "select",
			className: "col-md-2 pr-4",
			required: true,
			options: exchangeNumberDropdown,
			fieldForRpt: 1,
			isDisabled: formValues && formValues.report.exchangerName && exchangeNumberDropdown.length <= 1,
			onChange: (selected) => {
				getExchangerName(selected);
			},
		},
		{
			name: "report.exchangerName",
			label: "Exchanger",
			component: FormControl,
			type: "select",
			className: "col-md-2 pr-4",
			options: exchangerNameDropdown,
			fieldForRpt: 1,
			isDisabled: formValues && formValues.report.exchangeNumber && exchangerNameDropdown.length === 1,
			onChange: (selected) => {
				getExchangeNumber(selected);
			},
		},
		{
			name: "report.dateFrom",
			label: "Date From",
			component: FormControl,
			type: "date",
			className: "col-md-2 pr-4",
			options: [],
			maxDate: formValues && formValues.report.dateTo,
			fieldForRpt: 1,
			onClear: () => {
				change("report.dateFrom", null);
			},
			disabled: formValues && formValues.report.quickFilter && formValues.report.quickFilter.value,
		},
		{
			name: "report.dateTo",
			label: "Date To",
			component: FormControl,
			type: "date",
			className: "col-md-2 pr-4",
			options: [],
			minDate: formValues && formValues.report.dateFrom,
			fieldForRpt: 1,
			onClear: () => {
				change("report.dateTo", null);
			},
			disabled: formValues && formValues.report.quickFilter && formValues.report.quickFilter.value,
		},
		{
			name: "report.quickFilter",
			label: "Weeks Range",
			component: FormControl,
			type: "select",
			className: "col-md-2 pr-4",
			options: weekRange,
			fieldForRpt: 1,
			isDisabled: formValues && (formValues.report.dateTo || formValues.report.dateFrom),
		},
		{
			name: "report.bankExchangeNumber",
			label: "Exchange Number",
			component: FormControl,
			type: "select",
			className: "col-md-2 pr-4",
			validate: [required],
			required: true,
			options: exchangeNumberDropdown,
			isDisabled: formValues && formValues.report.bankExchangeName && exchangeNumberDropdown.length === 1,
			onChange: (selected) => {
				getExchangerName(selected);
			},
			fieldForRpt: 2,
		},
		{
			name: "report.bankExchangeName",
			label: "Exchanger",
			component: FormControl,
			type: "select",
			className: "col-md-2 pr-4",
			options: exchangerNameDropdown,
			onChange: (selected) => {
				getExchangeNumber(selected);
			},
			fieldForRpt: 2,
		},
	];
};
