import React from "react";

import { MoreOptions } from "../../SharedComponts/FormElements";
import FormControl from "../../SharedComponts/FormControl";
import { max200, required } from "../../../constant/formValidators";

export const columns = ({ onEditTask = null, onDeleteTask }) => [
	{
		name: "Task Name",
		accessor: "taskName",
		className: "col-task-name",
	},
	{
		name: "Exchange Type",
		accessor: "exchangeType",
		className: "col-exchange-type",
		sortable: false,
	},
	{
		name: "Description",
		accessor: "description",
		sortable: false,
		className: "col-description",
		headerClassName: "col-description-header",
	},
	{
		name: "Actions",
		className: "col-actions text-right",
		headerClassName: "col-actions-header",
		sortable: false,
		render: (row) => {
			return (
				<MoreOptions
					options={[
						{ label: "Edit", onClick: () => onEditTask(row) },
						{ label: "Delete", onClick: () => onDeleteTask(row) },
					]}
				/>
			);
		},
	},
];

export const formFields = (exchangeTypes) => {
	return [
		{
			name: "taskName",
			label: "Task Name",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			validate: [required],
			required: true,
		},

		{
			name: "exchangeTypeData",
			label: "Exchange Type",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			options: exchangeTypes,
			validate: [required],
			required: true,
		},
		{
			name: "description",
			label: "Description",
			component: FormControl,
			validate: [max200],
			type: "text",
			className: "form-control form-field",
		},
	];
};
