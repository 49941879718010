import { DASHBOARD } from "../types";

export default (
	state = {
		common: {
			activities: [],
			doughnutData: {},
			valuesExchangeSummary: [],
			valuesNextEvents: {},
			valuesTopEscrows: [],
			valuesTopSales: [],
		},
	},
	action
) => {
	switch (action.type) {
		case DASHBOARD.GET_MARKET_DETAILS:
			return {
				...state,
				marketDetails: action.payload ? action.payload.result : {},
			};
		case DASHBOARD.GET_DASHBOARD_DATA: {
			if (action.payload && action.payload.result && action.payload && action.payload.result.doughnutData) {
				const doughnutData = action.payload.result.doughnutData;
				if (doughnutData.datasets && doughnutData.datasets[0]) {
					const data = doughnutData.datasets[0].data;
					for (const item in data) {
						data[item] = Number(data[item]).toFixed(2);
					}
				}
			}
			return {
				...state,
				common: action.payload && action.payload.result ? action.payload.result : {},
			};
		}
		case DASHBOARD.GET_BARCHART_DATA: {
			let barchart = {};
			if (action.payload && action.payload.result) {
				barchart = action.payload.result;
				if (action.payload.result.titles && action.payload.result.titles.length) {
					const titles = action.payload.result.titles.sort();
					barchart.titles = [`${titles[0]} - ${titles[titles.length - 1]}`];
				}
			}
			return {
				...state,
				barchart,
			};
		}
		case DASHBOARD.GET_LAST_ACTIVITIES:
			return {
				...state,
				lastActivities: action.payload && action.payload.result ? action.payload.result : [],
			};
		default:
			return state;
	}
};
