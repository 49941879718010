import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import logoIcon from "../../../../assets/images/menu-logo.png";

import MenuItem from "./MenuItem";

export const menuItems = {
	admin: [
		{
			label: "Dashboard",
			link: "/dashboard",
			iconClass: "icon-dashboard",
		},
		{
			label: "Exchanges",
			link: "/exchanges",
			iconClass: "icon-exchange-1",
		},
		{
			label: "Properties",
			link: "/properties",
			iconClass: "icon-menu-properties",
		},
		{
			label: "Transactions",
			key: "/accounting",
			iconClass: "icon-menu-transactions",
			subMenu: [
				{
					label: "Bank",
					link: "/accounting-transactions",
				},
				{
					label: "Income",
					link: "/accounting-income",
				},
			],
		},

		{
			label: "People",
			key: "/people",
			iconClass: "icon-menu-users",
			subMenu: [
				{
					label: "Exchangers",
					link: "/people-exchangers",
				},
				{
					label: "Escrow",
					link: "/people-escrow",
				},
				{
					label: "Contacts",
					link: "/people-contacts",
				},
			],
		},
		{
			label: "Reports",
			link: "/reports",
			iconClass: "icon-menu-reports",
		},
		{
			label: "Settings",

			key: "/settings",
			iconClass: "icon-settings",
			subMenu: [
				{
					label: "Users",
					link: "/settings-admin",
				},
				{
					label: "Configurations",
					link: "/settings-master",
				},
			],
		},
	],
};

const Logo = () => (
	<div className="logo">
		<img src={logoIcon} alt="logo" />
	</div>
);

const SideMenu = () => {
	const role = "admin";
	return (
		<div className="side-menu">
			<Scrollbars autoHide>
				<Logo />
				{menuItems[role].map((item, index) => (
					<MenuItem key={`${index}`} menu={item} />
				))}
			</Scrollbars>
		</div>
	);
};
export default SideMenu;
