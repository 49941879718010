import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import Slider from "../../../SharedComponts/Slider";
import { formFieldsContact } from "./helper";
import SliderForm from "../../../SharedComponts/SliderForm";
const AddEntity = (props) => {
	const { show, states, cites, doGetCities, handleClose, change, handleSubmit, submitting, reset, loadCounties } = props;

	return (
		<Slider
			show={show}
			title={"Add Contact"}
			handleClose={() => {
				reset();
				handleClose();
			}}>
			<SliderForm handleSubmit={handleSubmit} submitting={submitting} handleClose={handleClose}>
				{formFieldsContact(states, cites, doGetCities, change, loadCounties).map((item, index) => {
					return item.hideField ? null : (
						<div key={index} className="form-group">
							<div className="form-lable">
								{item.label}
								{item.required ? <span className="required-label">*</span> : null}
							</div>
							<Field {...item} />
						</div>
					);
				})}
			</SliderForm>
		</Slider>
	);
};
const mapStateToProps = ({ Common: { states = [], counties = [], cites = [] } }) => ({ states, counties, cites });

export default connect(mapStateToProps)(
	reduxForm({
		form: "ContactAddForm",
		enableReinitialize: true,
	})(AddEntity)
);
