import React from "react";
import { TimeIST } from "../../utils/formatter";
import FormControl from "../SharedComponts/FormControl";
import Tooltip from "../SharedComponts/ToolTip";

export const columns = ({ onNotificationCheck = () => {}, onMasterCheckbox = () => {}, masterCheckbox = false, history }) => [
	{
		name: (
			<FormControl
				type="checkbox"
				input={{
					name: "checkbox-master",
					id: "checkbox-master",
					checked: masterCheckbox,
					value: "checkbox-master",
					onChange: (val) => {
						onMasterCheckbox(val.target.checked);
					},
				}}
				meta={{ touched: false, error: false, warning: false }}
			/>
		),
		className: "col-notif-checkbox",
		sortable: false,
		headerClassName: "col-user-notif-checkbox-master",
		hideToolTip: true,
		render: (row) => (
			<FormControl
				type="checkbox"
				input={{
					name: row.id,
					id: row.id,
					checked: row.isSelected ? row.isSelected : false,
					value: row.id,
					onChange: (val) => {
						onNotificationCheck(val);
					},
				}}
				meta={{ touched: false, error: false, warning: false }}
			/>
		),
	},
	{
		name: "Notification",
		className: "col-notif",
		sortable: false,
		headerClassName: "col-user-notif-header",
		hideToolTip: true,
		render: (row) => row.message,
	},
	{
		name: "Time",
		className: "col-time",
		sortable: false,
		headerClassName: "col-time-header",
		render: (row) => <TimeIST utcTime={row.creationTime.split(".")[0]} noConvert={false} />,
	},
	{
		name: "",
		className: "col-actions text-right",
		headerClassName: "col-actions-header",
		sortable: false,
		render: (row) => {
			return row.exchangeGuid && row.exchangeGuid !== "" ? (
				<Tooltip
					message={"Click to view exchange"}
					component={
						<span className="note-icon-span" onClick={() => history.push(`/exchanges/${row.exchangeGuid}`)}>
							<i className="icon icon-eye"></i>
						</span>
					}
				/>
			) : (
				""
			);
		},
	},
];
