import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Card } from "react-bootstrap";
import { errorMessage } from "../../../../utils/errorMessage";
import { successMessage } from "../../../../utils/successMessage";
import { formatDateToISOString, getDateFromDatePicker, getDatePickerDateFromtDate } from "../../../../utils/helper";
import { Button } from "react-bootstrap";

import {
	showLoader,
	getExchangeTaskList,
	createExchangeTask,
	updateExchangeTask,
	deleteExchangeTask,
} from "../../../../store/actions";
import { columns as columnsFn } from "./helper";
import InlineForm from "./inlineForm";
import AddTask from "./AddTask";
import Confirm from "../../../SharedComponts/FormElements/confirm";
import "./tasks.scss";
import { getFormValues } from "redux-form";

const Tasks = (props) => {
	const {
		exchangeDetail,
		doShowLoader,
		doGetTaskList,
		taskList,
		adminUsers,
		taskStatusList,
		formValues,
		doUpdateExchangeTask,
		doDeleteExchangeTask,
		doCreateExchangeTask,
		isLocked,
	} = props;
	const [editMode, setEditMode] = useState(false);
	const [editId, setEditId] = useState(0);
	const [reload, setReload] = useState(false);
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [showSlider, setShowSlider] = useState(false);

	const saveTask = (row) => {
		const taskCompleted = formValues && formValues.taskStatus && formValues.taskStatus.value === 502;
		if (taskCompleted) {
			if (!formValues.completedDate) {
				errorMessage(301);
				return;
			}
			if (!formValues.completedBy || !formValues.completedBy.value) {
				errorMessage(302);
				return;
			}
		}
		doShowLoader(true);
		const successCB = (result) => {
			doShowLoader(false);
			successMessage(408);
			setEditMode(false);
			setEditId(0);
			setReload(!reload);
		};
		const FailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		const postData = {
			...formValues,
			taskName: row.taskName,
			completedDate: getDateFromDatePicker(formValues.completedDate),
			id: editId,
		};
		doUpdateExchangeTask(postData, successCB, FailCB);
	};
	const deleteItem = (id) => {
		setEditMode(false);
		setEditId(id);
		setConfirmDelete(true);
	};

	const confirmDeleteAction = () => {
		doShowLoader(true);
		const successCB = (result) => {
			setConfirmDelete(false);
			doShowLoader(false);
			successMessage(409);
			setReload(!reload);
		};
		const FailCB = (result) => {
			setConfirmDelete(false);
			errorMessage(result);
			doShowLoader(false);
		};
		doDeleteExchangeTask(editId, successCB, FailCB);
	};

	const handleClose = () => {
		setShowSlider(false);
	};

	const handleSubmitTask = (values) => {
		doShowLoader(true);
		const successCB = (result) => {
			setShowSlider(false);
			doShowLoader(false);
			successMessage(410);
			setReload(!reload);
		};
		const FailCB = (result) => {
			setShowSlider(false);
			errorMessage(result);
			doShowLoader(false);
		};

		const postData = {
			...values,
			completedDate: values.completedDate ? formatDateToISOString(values.completedDate) : null,
			exchangeId: exchangeDetail.id,
		};
		doCreateExchangeTask(postData, successCB, FailCB);
	};

	const columns = columnsFn({
		editMode,
		setEditMode,
		setEditId,
		saveTask,
		editId,
		adminUsers,
		taskStatusList,
		deleteItem,
		isLocked,
		formValues,
	});

	useEffect(() => {
		if (exchangeDetail && exchangeDetail.id) {
			doShowLoader(true);
			const successCB = (response) => {
				doShowLoader(false);
			};
			const failCB = (result) => {
				errorMessage(result);
				doShowLoader(false);
			};

			doGetTaskList(exchangeDetail.guid, successCB, failCB);
		}
	}, [doGetTaskList, doShowLoader, exchangeDetail, reload]);
	const renderRow = (row) => {
		return columns.length ? (
			editMode && editId === row.id ? (
				<InlineForm
					initialValues={{ ...row, completedDate: getDatePickerDateFromtDate(row.completedDate) }}
					columns={columns}
					data={row}
				/>
			) : (
				columns.map(({ accessor, width, render, className }, index) => {
					if (render) {
						return (
							<div
								key={index}
								className={`table-col ${className ? className : ""}`}
								style={width ? { width } : {}}>
								{render(row)}
							</div>
						);
					}
					return (
						<div key={index} className={`table-col ${className ? className : ""}`} style={width ? { width } : {}}>
							{row[accessor] ? row[accessor] : "-"}
						</div>
					);
				})
			)
		) : null;
	};

	return (
		<>
			{!isLocked && (
				<div className="row w-100 add-row">
					<Button
						className="form-button-secondary small btn btn-primary"
						onClick={() => {
							setShowSlider(true);
						}}>
						+ Add Task
					</Button>
				</div>
			)}
			<Card.Body className="info-card-body d-flex flex-row">
				{taskList && taskList.length ? (
					<div className="task-table">
						<div className="table-head">
							{columns.length
								? columns.map(({ label, className, width }, index) => (
										<div
											key={index}
											className={`head-item ${className ? className : ""}`}
											style={width ? { width } : {}}>
											{label}
										</div>
								  ))
								: null}
						</div>
						{taskList &&
							taskList.map((row, index) => {
								return (
									<div key={index} className={`table-row w-100 ${index % 2 === 0 ? "row-even" : "row-odd"}`}>
										{renderRow(row)}
									</div>
								);
							})}
					</div>
				) : (
					<div className="no-data-table no-data-table-task">No items found</div>
				)}
			</Card.Body>
			<Confirm
				onYes={confirmDeleteAction}
				onNo={(e) => {
					setConfirmDelete(false);
				}}
				show={confirmDelete ? true : false}
			/>

			{showSlider ? <AddTask show={showSlider} handleClose={handleClose} onSubmit={handleSubmitTask} /> : null}
		</>
	);
};
const mapStateToProps = (state) => {
	const {
		Exchange: { exchangeDetail = {}, taskList, isLocked },
		Common: { AdminUsers = [], TaskStatus = [] },
	} = state;
	return {
		exchangeDetail,
		taskList,
		adminUsers: AdminUsers,
		taskStatusList: TaskStatus,
		formValues: getFormValues("TaskInlineForm")(state),
		isLocked,
	};
};

const mapDispatchToProps = {
	doShowLoader: showLoader,
	doGetTaskList: getExchangeTaskList,
	doUpdateExchangeTask: updateExchangeTask,
	doDeleteExchangeTask: deleteExchangeTask,
	doCreateExchangeTask: createExchangeTask,
};
export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
