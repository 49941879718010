import React from "react";

import { MoreOptions } from "../SharedComponts/FormElements";

export const columns = ({ onSelectUser, onEditUser = null, onDeleteUser = null }) => [
	{
		name: "User",
		accessor: "fullName",
		className: "col-user-id",
		headerClassName: "col-user-id-header",
		render: (row) => (
			<div className="link" onClick={() => onSelectUser(row)}>
				{row.fullName}
			</div>
		),
	},
	{
		name: "Market",
		accessor: "market",
		className: "col-market",
		headerClassName: "col-market-header",
		sortable: false,
	},
	{
		name: "Email ID",
		accessor: "emailAddress",
		className: "col-email-address",
		headerClassName: "col-email-address-header",
	},
	{
		name: "Actions",
		className: "col-actions text-right",
		headerClassName: "col-actions-header",
		sortable: false,
		render: (row) => {
			return (
				<MoreOptions
					options={[
						{ label: "Edit", onClick: () => onEditUser(row) },
						{ label: "Delete", onClick: () => onDeleteUser(row) },
					]}
				/>
			);
		},
	},
];
export const userDataColumn = [
	{ name: "Exchange No", accessor: "no", className: "exchange-no", width: 150 },
	{
		name: "Exchange Type",
		accessor: "type",
	},
];
