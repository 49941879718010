import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { get } from "lodash";

import Layout from "../../SharedComponts/Layout";
import { Table, Pagination } from "../../SharedComponts/FormElements/table";
import { columns as columnsFn } from "./helper";
import {
	setContactFilter,
	getUsers,
	showLoader,
	createContact,
	getContact,
	updateContact,
	deleteUser,
	getContactRoles,
	getExchangers,
	userExport,
} from "../../../store/actions";

import Confirm from "../../SharedComponts/FormElements/confirm";
import SearchField from "../../SharedComponts/FormElements/searchTable";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";
import { userTypes } from "../../../constant/common";
import { downloadFile, getDateFromDatePicker, getRoleID } from "../../../utils/helper";
import AddEdit from "./AddEdit";

import "../admin.scss";
import "../userdetail.scss";

const AdminList = ({
	doSetFilter,
	filters,
	history,
	userDetails,
	recordsTotal,
	userList,
	doShowLoader,
	doGetUsers,
	doCreateContact,
	doGetContact,
	doUpdateContact,
	doDeleteUser,
	doGetContactRoles,
	doGetExchangers,
	cardTotalCount,
	doUserExport,
}) => {
	const [showPopup, setshowPopup] = useState(false);
	const [isEdit, setIsEdit] = useState(true);
	const [currentRecord, setCurrentRecord] = useState({});
	const [listReload, setListReload] = useState(false);
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [currentDeleteRecord, setCurrentDeleteRecord] = useState(null);
	const [contactRoles, setContactRoles] = useState([]);
	const [exchangerList, setExchangerList] = useState([]);

	const handleClose = () => setshowPopup(false);
	const handleShow = () => setshowPopup(true);
	const handleSubmit = async (value) => {
		doShowLoader(true);
		const successCB = (result) => {
			doShowLoader(false);
			successMessage(!isEdit ? 221 : 222);
			setListReload(!listReload);
			handleClose();
		};
		const failCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		currentRecord.id && isEdit && doUpdateContact(value, successCB, failCB);
		(!currentRecord.id || !isEdit) && doCreateContact(value, successCB, failCB);
	};

	useEffect(() => {
		doShowLoader(true);
		const backendFilter = { ...filters, isContact: true };
		const successCB = (result) => {
			doShowLoader(false);
		};
		const failCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		userDetails.id && doGetUsers(backendFilter, successCB, failCB);
	}, [filters, userDetails, doGetUsers, doShowLoader, listReload]);

	useEffect(() => {
		const successCB = (e) => {
			setContactRoles(e.result);
		};
		doGetContactRoles(successCB);

		const SubSuccessCB = (e) => {
			e && e.result && setExchangerList(e.result);
		};
		const backendFilter = { "ExtraParams.RoleId": getRoleID("Exchanger") };
		doGetExchangers(backendFilter, SubSuccessCB);
	}, [doGetContactRoles, doGetExchangers]);

	const loggedInAs = (type) => {
		return userDetails.userType === userTypes[type];
	};
	const exportUsers = () => {
		doShowLoader(true);

		const extraParams = {
			...filters.extraParams,
			fromDate:
				filters.extraParams && filters.extraParams.fromDate
					? getDateFromDatePicker(filters.extraParams.fromDate)
					: null,
			toDate:
				filters.extraParams && filters.extraParams.toDate ? getDateFromDatePicker(filters.extraParams.toDate) : null,
		};
		const backendFilter = { ...filters, extraParams };
		const successCB = (result) => {
			doShowLoader(false);
			downloadFile(result.result, "Contact Details");
		};
		const FailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		doUserExport(backendFilter, successCB, FailCB);
	};
	const onEditUser = (row) => {
		doShowLoader(true);
		const successCB = (e) => {
			setIsEdit(true);
			setCurrentRecord(e.result);
			doShowLoader(false);
			handleShow();
		};
		const failCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		doGetContact(row.id, successCB, failCB);
	};

	const onDeleteUser = (row) => {
		setCurrentDeleteRecord(row.id);
		setConfirmDelete(true);
	};

	const confirmDeleteAction = () => {
		doShowLoader(true);
		const failCB = (result) => {
			doShowLoader(false);
			setCurrentDeleteRecord(null);
			setConfirmDelete(false);
			errorMessage(result);
		};
		const successCB = (result) => {
			doShowLoader(false);
			setCurrentDeleteRecord(null);
			setConfirmDelete(false);
			successMessage(223);
			setListReload(!listReload);
		};
		doDeleteUser(currentDeleteRecord, successCB, failCB);
	};

	const addUser = () => {
		setIsEdit(false);
		setCurrentRecord({});
		handleShow();
	};

	const rightActionButtons = [
		{
			component: () => {
				return (
					<Button className="form-button-primary" onClick={addUser}>
						<span className="add-new-plus">+</span> New Contact
					</Button>
				);
			},
		},
	];
	const columns = columnsFn({
		onEditUser,
		onDeleteUser,
	});
	return loggedInAs("SA") ? (
		<>
			<Layout
				title="Contacts"
				breadcrumbs={[{ label: "Contact Listing" }]}
				rightActionButtons={rightActionButtons}
				className="user-list user-listing-page">
				<div className="info-button filter-option text-highlighted" onClick={exportUsers}>
					<span className={`icon-excel`}></span>Export
				</div>
				<>
					<SearchField
						initialValue={filters.searchKey}
						onSearch={(searchKey) =>
							doSetFilter({
								...filters,
								searchKey,
								pageIndex: 1,
							})
						}
						className={`flex-fill mb-0`}
						placeholder="Enter your query"
					/>

					<Table
						columns={columns}
						total={cardTotalCount}
						data={userList}
						onSort={(e) =>
							e.by !== filters.sortColumn || e.order !== filters.sortDirection
								? doSetFilter({
									...filters,
									sortColumn: e.by,
									sortDirection: e.order,
									pageIndex: 1,
								})
								: null
						}
						sortBy={filters.sortColumn}
						sortDir={filters.sortDirection}
					/>
					<Pagination
						total={recordsTotal}
						count={filters.pageSize}
						page={filters.pageIndex}
						onChange={(e) => {
							doSetFilter({ ...filters, pageIndex: e });
						}}
						onPageCountChange={(e) => {
							doSetFilter({ ...filters, pageSize: e, pageIndex: 1 });
						}}
					/>
				</>
			</Layout>

			{showPopup ? (
				<AddEdit
					show={showPopup}
					initialValues={currentRecord}
					isEdit={isEdit}
					handleClose={handleClose}
					onSubmit={handleSubmit}
					exchangerList={exchangerList}
					contactRoles={contactRoles}
				/>
			) : null}
			<Confirm
				onYes={confirmDeleteAction}
				onNo={(e) => {
					setConfirmDelete(false);
				}}
				show={confirmDelete ? true : false}
			/>
		</>
	) : null;
};

const mapStateToProps = (state) => {
	return {
		userList: get(state, "User.userList.data", null),
		filters: get(state, "User.contactFilter", {}),
		userDetails: get(state, "Auth.userDetails", {}),
		recordsTotal: get(state, "User.userList.recordsTotal", 0),
		cardTotalCount: get(state, "User.userList.additionalParam.totalCount", 0),
	};
};
const mapDispatchToProps = {
	doSetFilter: setContactFilter,
	doGetUsers: getUsers,
	doShowLoader: showLoader,
	doCreateContact: createContact,
	doGetContact: getContact,
	doUpdateContact: updateContact,
	doDeleteUser: deleteUser,
	doGetContactRoles: getContactRoles,
	doGetExchangers: getExchangers,
	doUserExport: userExport,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminList);
