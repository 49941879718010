import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import Slider from "../../../SharedComponts/Slider";
import FormControl from "../../../SharedComponts/FormControl";
import { email, required } from "../../../../constant/formValidators";
import SliderForm from "../../../SharedComponts/SliderForm";

const EmailSlider = (props) => {
	const { show, handleClose, handleSubmit, submitting, document, reset } = props;
	const fields = [
		{
			name: "emailAddress",
			label: "Email Address",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			validate: [required, email],
			required: true,
		},
		{
			name: "subject",
			label: "Subject",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			validate: [required],
			required: true,
		},
		{
			name: "message",
			label: "Description",
			component: FormControl,
			type: "textarea",
			className: "form-control form-field",
			rows: "8",
			validate: [required],
			required: true,
		},
	];
	return (
		<Slider
			show={show}
			title={"Send Email"}
			handleClose={() => {
				reset();
				handleClose();
			}}>
			<SliderForm handleSubmit={handleSubmit} submitting={submitting} handleClose={handleClose}>
				{fields.map((item, index) => {
					return item.hideField ? null : (
						<div key={index} className="form-group">
							<div className="form-lable">
								{item.label}
								{item.required ? <span className="required-label">*</span> : null}
							</div>
							<Field {...item} />
						</div>
					);
				})}
				<div className="file">
					<div className="file-item">
						<span className="icon-notes"></span>
						<span className="file-name ml-1">{document.documentName}</span>
					</div>
				</div>
			</SliderForm>
		</Slider>
	);
};

export default connect(
	null,
	null
)(
	reduxForm({
		form: "EmailSliderForm",
		enableReinitialize: true,
	})(EmailSlider)
);
