import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { get } from "lodash";
import { Table, Pagination } from "../SharedComponts/FormElements/table";
import Layout from "../SharedComponts/Layout";
import SummaryCards from "../SharedComponts/SummaryCards";
import SearchField from "../SharedComponts/FormElements/searchTable";
import { errorMessage } from "../../utils/errorMessage";
import { columns as columnsFn, advancedFilterFormFields } from "./helper";
import { downloadFile } from "../../utils/helper";
import { getFormValues } from "redux-form";

import inIcon from "../../assets/images/relinquish.png";
import outIcon from "../../assets/images/replacement.png";

import "./properties.scss";
import { getDateFromDatePicker } from "../../utils/helper";
import AdvancedFilter from "../SharedComponts/AdvancedFilter/advancedFilter";
import AppliedFilter from "../SharedComponts/AdvancedFilter/appliedFilter";
import NoteSlider from "./NoteSlider";
import { useHistory } from "react-router-dom";

import {
	showLoader,
	setPropertyFilter,
	getFullPropertyList,
	propertyExport,
	getExchangerNames,
	getBuyerSellerList,
	getEntityByType,
	getEscrowNumber
} from "../../store/actions";

const Exchanges = ({
	filters,
	additionalParam,
	recordsTotal,
	propertyList,
	buyerSellerList,
	escrowNumber,
	formValues,
	doGetExchangerNames,
	doGetBuyerSellerList,
	doGetEscrowNumber,
	doPropertyExport,
	doGetFullPropertyList,
	doSetFilter,
	doShowLoader,
	doGetEntityByType,
}) => {
	const [showNotePopup, setNoteshowPopup] = useState(false);
	const [noteData, setNoteData] = useState({});

	const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);
	const [exchangerNameList, setExchangerNameList] = useState([]);
	const [companyList, setCompanyList] = useState([]);

	const history = useHistory();

	const handleClose = () => {
		setNoteshowPopup(false);
	};

	const showNote = (data) => {
		setNoteData(data);
		setNoteshowPopup(true);
	};

	useEffect(() => {
		doShowLoader(true);
		const extraParams = {
			...filters.extraParams,
			dueDateFrom:
				filters.extraParams && filters.extraParams.fromDate
					? getDateFromDatePicker(filters.extraParams.fromDate)
					: null,
			dueDateTo:
				filters.extraParams && filters.extraParams.toDate ? getDateFromDatePicker(filters.extraParams.toDate) : null,
		};
		const backendFilter = { ...filters, ...extraParams };
		const successCB = (result) => {
			doShowLoader(false);
		};
		const FailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		doGetFullPropertyList(backendFilter, successCB, FailCB);
	}, [filters, doGetFullPropertyList, doShowLoader]);

	const columns = columnsFn({
		showNote,
		history,
	});

	useEffect(() => {
		const successCBSub = (result) => {
			setExchangerNameList(result.result);
			doGetEntityByType(
				{ typeId: 227 },
				(result) => setCompanyList(result.result),
				(result) => errorMessage(result)
			);
		};
		const FailCBSub = (result) => {
			errorMessage(result);
		};
		doGetExchangerNames(null, successCBSub, FailCBSub);
	}, [doGetExchangerNames, doGetEntityByType]);

	useEffect(() => {
		const FailCBSub = (result) => {
			errorMessage(result);
		};
		doGetBuyerSellerList(null, FailCBSub);
	}, [doGetBuyerSellerList]);

	useEffect(() => {
		const FailCBSub = (result) => {
			errorMessage(result);
		};
		doGetEscrowNumber(null, FailCBSub);
	}, [doGetEscrowNumber]);


	const exportProperties = () => {
		doShowLoader(true);
		const extraParams = {
			...filters.extraParams,
			dueDateFrom:
				filters.extraParams && filters.extraParams.fromDate
					? getDateFromDatePicker(filters.extraParams.fromDate)
					: null,
			dueDateTo:
				filters.extraParams && filters.extraParams.toDate ? getDateFromDatePicker(filters.extraParams.toDate) : null,
		};
		const backendFilter = { ...filters, ...extraParams };
		const successCB = (result) => {
			doShowLoader(false);
			downloadFile(result.result, "Properties List");
		};
		const FailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		doPropertyExport(backendFilter, successCB, FailCB);
	};

	const advancedFilters = advancedFilterFormFields(exchangerNameList, buyerSellerList, formValues, escrowNumber, companyList);
	const handleCloseFilter = () => {
		setShowAdvancedFilter(false);
	};
	const handleCancelFilter = (key) => {
		const extraParams = { ...filters.extraParams };
		delete extraParams[key];

		doSetFilter({
			...filters,
			extraParams: extraParams,
			pageIndex: 1,
		});
	};
	const clearAllFilter = () => {
		doSetFilter({
			...filters,
			extraParams: {},
			pageIndex: 1,
		});
	};
	const handleSubmitFilter = (values) => {
		doSetFilter({
			...filters,
			extraParams: values,
			pageIndex: 1,
		});
	};
	const ifFilterExist = () => {
		return (
			filters.extraParams &&
			(filters.extraParams.dealerIds || filters.extraParams.exchangerIds || filters.extraParams.settlementCompanyIds)
		);
	};
	return (
		<Layout
			breadcrumbs={[{ label: "Properties" }]}
			title="Properties"
			rightActionButtons={[]}
			className="exchange-listing-page properties">
			{propertyList ? (
				<>
					<SummaryCards
						cards={[
							{
								label: "Relinquished Properties",
								count: additionalParam && additionalParam.relinquishedPropertiesCount,
								countColor: "#4899f2",
								icon: inIcon,
								className: "col-xl-4 col-lg-5 col-md-6 col-sm-12 mb-sm-2 mb-xs-2",
							},
							{
								label: "Replacement Properties",
								count: additionalParam && additionalParam.replacementPropertiesCount,
								icon: outIcon,
								countColor: "#00aa63",
								className: "col-xl-4 col-lg-5 col-md-6 col-sm-12 mb-sm-2 mb-xs-2",
							},
						]}
					/>
					<div className="info-button filter-option text-highlighted" onClick={exportProperties}>
						<span className={`icon-excel`}></span>Export
					</div>
					<div
						onClick={() => {
							setShowAdvancedFilter(true);
						}}
						className={`info-button filter-option text-highlighted ${ifFilterExist() ? "filter-applied" : null}`}>
						<span className={`icon-filter`}></span>Filter
					</div>

					<SearchField
						initialValue={filters.searchKey}
						onSearch={(searchKey) =>
							doSetFilter({
								...filters,
								searchKey,
								pageIndex: 1,
							})
						}
						className={`flex-fill mb-0`}
						placeholder="Enter your query"
					/>

					{showAdvancedFilter ? (
						<AdvancedFilter
							onSubmit={handleSubmitFilter}
							handleClose={handleCloseFilter}
							formFields={advancedFilters}
							initialValues={filters.extraParams}
							clearAllFilter={clearAllFilter}
						/>
					) : ifFilterExist() ? (
						<AppliedFilter
							handleClose={handleCancelFilter}
							advancedFilters={advancedFilters}
							values={filters.extraParams}
						/>
					) : null}

					<Table
						columns={columns}
						total={additionalParam.totalCount}
						totalLabel="Total Properties"
						data={propertyList}
						onSort={(e) =>
							e.by !== filters.sortColumn || e.order !== filters.sortDirection
								? doSetFilter({
									...filters,
									sortColumn: e.by,
									sortDirection: e.order,
									pageIndex: 1,
								})
								: null
						}
						sortBy={filters.sortColumn}
						sortDir={filters.sortDirection}
					/>
					<Pagination
						total={recordsTotal}
						count={filters.pageSize}
						page={filters.pageIndex}
						onChange={(e) => {
							doSetFilter({ ...filters, pageIndex: e });
						}}
						onPageCountChange={(e) => {
							doSetFilter({ ...filters, pageSize: e, pageIndex: 1 });
						}}
					/>
				</>
			) : null}
			{showNotePopup ? <NoteSlider show={showNotePopup} handleClose={handleClose} note={noteData} /> : null}
		</Layout>
	);
};

const mapStateToProps = (state) => {
	return {
		buyerSellerList: get(state, "Properties.buyerSellerList", []),
		escrowNumber: get(state, "Properties.escrowNumber", []),
		recordsTotal: get(state, "Properties.propertyList.recordsTotal", 0),
		additionalParam: get(state, "Properties.propertyList.additionalParam", {}),
		propertyList: get(state, "Properties.propertyList.data", []),
		filters: get(state, "Properties.propertyFilters", []),
		formValues: getFormValues("advancedFilterForm")(state),
		weekRange: get(state, "Common.WeekRange", []),
	};
};

const mapDispatchToProps = {
	doSetFilter: setPropertyFilter,
	doGetFullPropertyList: getFullPropertyList,
	doShowLoader: showLoader,
	doPropertyExport: propertyExport,
	doGetExchangerNames: getExchangerNames,
	doGetBuyerSellerList: getBuyerSellerList,
	doGetEscrowNumber: getEscrowNumber,
	doGetEntityByType: getEntityByType,
};

export default connect(mapStateToProps, mapDispatchToProps)(Exchanges);
