import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { get } from "lodash";
import { Table, Pagination } from "../SharedComponts/FormElements/table";
import Layout from "../SharedComponts/Layout";
import SummaryCards from "../SharedComponts/SummaryCards";
import SearchField from "../SharedComponts/FormElements/searchTable";
import { errorMessage } from "../../utils/errorMessage";
import { columns as columnsFn, advancedFilterFormFields } from "./helper";
import { downloadFile } from "../../utils/helper";
import { getFormValues } from "redux-form";

import inIcon from "../../assets/images/dl-in.png";
import outIcon from "../../assets/images/dl-out.png";
import fundIcon from "../../assets/images/fund.png";

import "./transactions.scss";
import { getDateFromDatePicker } from "../../utils/helper";
import AdvancedFilter from "../SharedComponts/AdvancedFilter/advancedFilter";
import AppliedFilter from "../SharedComponts/AdvancedFilter/appliedFilter";
import NoteSlider from "./NoteSlider";
import { useHistory } from "react-router-dom";

import { Currency } from "../../utils/formatter";

import {
	showLoader,
	setTransactionFilter,
	getFullTransactionList,
	transactionExport,
	getExchangerNames,
	getExchangeNumbers,
	getAllPropertyAddress,
} from "../../store/actions";

const Exchanges = ({
	filters,
	additionalParam,
	recordsTotal,
	transactionList,
	addressList,
	weekRange,
	formValues,
	doGetExchangerNames,
	doGetExchangeNumbers,
	doTransactionExport,
	doGetFullTransactionList,
	doSetFilter,
	doShowLoader,
	doGetAllPropertyAddress,
}) => {
	const [showNotePopup, setNoteshowPopup] = useState(false);
	const [noteData, setNoteData] = useState("");

	const history = useHistory();

	const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);
	const [exchangerNameList, setExchangerNameList] = useState([]);
	const [exchangeNumberList, setExchangeNumberList] = useState([]);

	const handleClose = () => {
		setNoteshowPopup(false);
	};

	const showNote = (data) => {
		setNoteData(data);
		setNoteshowPopup(true);
	};

	useEffect(() => {
		doShowLoader(true);
		const extraParams = {
			...filters.extraParams,
			dueDateFrom:
				filters.extraParams && filters.extraParams.fromDate
					? getDateFromDatePicker(filters.extraParams.fromDate)
					: null,
			dueDateTo:
				filters.extraParams && filters.extraParams.toDate ? getDateFromDatePicker(filters.extraParams.toDate) : null,
		};
		const backendFilter = { ...filters, ...extraParams, extraParams };
		const successCB = (result) => {
			doShowLoader(false);
		};
		const FailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		doGetFullTransactionList(backendFilter, successCB, FailCB);
	}, [filters, doGetFullTransactionList, doShowLoader]);

	const columns = columnsFn({
		showNote,
		history,
	});

	useEffect(() => {
		const successCBSub = (result) => {
			setExchangerNameList(result.result);
			doGetExchangeNumbers(
				null,
				(result) => setExchangeNumberList(result.result),
				(result) => errorMessage(result)
			);
		};
		const FailCBSub = (result) => {
			errorMessage(result);
		};
		doGetExchangerNames(null, successCBSub, FailCBSub);
	}, [doGetExchangerNames, doGetExchangeNumbers]);
	useEffect(() => {
		const FailCBSub = (result) => {
			errorMessage(result);
		};
		doGetAllPropertyAddress(null, FailCBSub);
	}, [doGetAllPropertyAddress]);

	const exportTransactions = () => {
		doShowLoader(true);
		const extraParams = {
			...filters.extraParams,
			dueDateFrom:
				filters.extraParams && filters.extraParams.fromDate
					? getDateFromDatePicker(filters.extraParams.fromDate)
					: null,
			dueDateTo:
				filters.extraParams && filters.extraParams.toDate ? getDateFromDatePicker(filters.extraParams.toDate) : null,
		};
		const backendFilter = { ...filters, ...extraParams, extraParams };
		const successCB = (result) => {
			doShowLoader(false);
			downloadFile(result.result, "Transaction List");
		};
		const FailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		doTransactionExport(backendFilter, successCB, FailCB);
	};

	const advancedFilters = advancedFilterFormFields(exchangerNameList, exchangeNumberList, addressList, formValues, weekRange);
	const handleCloseFilter = () => {
		setShowAdvancedFilter(false);
	};
	const handleCancelFilter = (key) => {
		const extraParams = { ...filters.extraParams };
		delete extraParams[key];

		doSetFilter({
			...filters,
			extraParams: extraParams,
			pageIndex: 1,
		});
	};
	const clearAllFilter = () => {
		doSetFilter({
			...filters,
			extraParams: {},
			pageIndex: 1,
		});
	};
	const handleSubmitFilter = (values) => {
		doSetFilter({
			...filters,
			extraParams: values,
			pageIndex: 1,
		});
	};
	const ifFilterExist = () => {
		return (
			filters.extraParams &&
			(filters.extraParams.fromDate ||
				filters.extraParams.toDate ||
				filters.extraParams.property ||
				filters.extraParams.exchangeNumber ||
				filters.extraParams.exchanger ||
				filters.extraParams.transactionPeriod)
		);
	};
	return (
		<Layout
			breadcrumbs={[{ label: "Bank Transactions" }]}
			title="Bank Transactions"
			rightActionButtons={[]}
			className="exchange-listing-page">
			{transactionList ? (
				<>
					<SummaryCards
						cards={[
							{
								label: "Dollars In",
								count: additionalParam && <Currency amount={additionalParam.dollarsIn} />,
								countColor: "#4899f2",
								icon: inIcon,
								className: "col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-sm-2 mb-xs-2",
							},
							{
								label: "Dollars Out",
								count: additionalParam && <Currency amount={additionalParam.dollarsOut} />,
								icon: outIcon,
								countColor: "#00aa63",
								className: "col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-sm-2 mb-xs-2",
							},
							{
								label: "Funds to Forward",
								count: additionalParam && <Currency amount={additionalParam.fundsToForward} />,
								icon: fundIcon,
								className: "col-lg-4 col-md-6 col-sm-12 mb-sm-2 mb-xs-2",
							},
						]}
					/>
					<div className="info-button filter-option text-highlighted" onClick={exportTransactions}>
						<span className={`icon-excel`}></span>Export
					</div>
					<div
						onClick={() => {
							setShowAdvancedFilter(true);
						}}
						className={`info-button filter-option text-highlighted ${ifFilterExist() ? "filter-applied" : null}`}>
						<span className={`icon-filter`}></span>Filter
					</div>

					<SearchField
						initialValue={filters.searchKey}
						onSearch={(searchKey) =>
							doSetFilter({
								...filters,
								searchKey,
								pageIndex: 1,
							})
						}
						className={`flex-fill mb-0`}
						placeholder="Enter your query"
					/>

					{showAdvancedFilter ? (
						<AdvancedFilter
							onSubmit={handleSubmitFilter}
							handleClose={handleCloseFilter}
							formFields={advancedFilters}
							initialValues={filters.extraParams}
							clearAllFilter={clearAllFilter}
						/>
					) : ifFilterExist() ? (
						<AppliedFilter
							handleClose={handleCancelFilter}
							advancedFilters={advancedFilters}
							values={filters.extraParams}
						/>
					) : null}

					<Table
						columns={columns}
						total={<Currency amount={additionalParam.dollarsIn - additionalParam.dollarsOut} />}
						totalLabel="Total Exchange Balance"
						data={transactionList}
						onSort={(e) =>
							e.by !== filters.sortColumn || e.order !== filters.sortDirection
								? doSetFilter({
										...filters,
										sortColumn: e.by,
										sortDirection: e.order,
										pageIndex: 1,
								  })
								: null
						}
						sortBy={filters.sortColumn}
						sortDir={filters.sortDirection}
					/>
					<Pagination
						total={recordsTotal}
						count={filters.pageSize}
						page={filters.pageIndex}
						onChange={(e) => {
							doSetFilter({ ...filters, pageIndex: e });
						}}
						onPageCountChange={(e) => {
							doSetFilter({ ...filters, pageSize: e, pageIndex: 1 });
						}}
					/>
				</>
			) : null}
			{showNotePopup ? <NoteSlider show={showNotePopup} handleClose={handleClose} note={noteData} /> : null}
		</Layout>
	);
};

const mapStateToProps = (state) => {
	return {
		addressList: get(state, "Accounting.addressList", []),
		recordsTotal: get(state, "Accounting.transactionList.recordsTotal", 0),
		additionalParam: get(state, "Accounting.transactionList.additionalParam", {}),
		transactionList: get(state, "Accounting.transactionList.data", []),
		filters: get(state, "Accounting.transactionFilters", []),
		formValues: getFormValues("advancedFilterForm")(state),
		weekRange: get(state, "Common.WeekRange", []),
	};
};

const mapDispatchToProps = {
	doSetFilter: setTransactionFilter,
	doGetFullTransactionList: getFullTransactionList,
	doShowLoader: showLoader,
	doTransactionExport: transactionExport,
	doGetExchangerNames: getExchangerNames,
	doGetExchangeNumbers: getExchangeNumbers,
	doGetAllPropertyAddress: getAllPropertyAddress,
};

export default connect(mapStateToProps, mapDispatchToProps)(Exchanges);
