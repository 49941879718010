import { ENTITY } from "../types";
import fetchHandler from "../../utils/fetchHandler";
import { serialize } from "../../utils/helper";

export function setEntityFilter(filter, successHandler) {
	successHandler && successHandler();
	return {
		type: ENTITY.SET_FILTER,
		payload: filter,
	};
}
export const getEntities = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `companies?${serialize(values)}`,
		method: "GET",
		secure: true,
		actionType: ENTITY.GET_ENTITY_LIST,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const createEntity = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `company`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: ENTITY.CREATE_ENTITY,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const updateEntity = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `company/${values.id}`,
		method: "PUT",
		secure: true,
		body: JSON.stringify(values),
		actionType: ENTITY.UPDATE_ENTITY,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const deleteEntity = (id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `company/${id}`,
		method: "DELETE",
		secure: true,
		actionType: ENTITY.DELETE_ENTITY,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getEntity = (entityId, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `company/${entityId}`,
		method: "GET",
		secure: true,
		actionType: ENTITY.GET_ENTITY_DETAILS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getEntityByType = ({ typeId, isInactiveReq }, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `companies/${typeId}?${serialize({ isInactiveReq })}`,
		method: "GET",
		secure: true,
		actionType: ENTITY.GET_ENTITIES_BY_TYPE,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};


export const getEscrowAgent = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `Escrows`,
		method: "GET",
		secure: true,
		actionType: ENTITY.GET_ENTITIES_BY_ESCROW,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
}

export const relatedExchangeExistsForCompany = ({ id, ...params }, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `relatedExchangesExistsForCompany/${id}?${serialize(params)}`,
		method: "GET",
		secure: true,
		actionType: ENTITY.CHECK_RELATED_EXCHANGE,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
