import { COMMON } from "../types";
import fetchHandler from "../../utils/fetchHandler";
import { serialize } from "../../utils/helper";
export const getCounties = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `general/counties/${values}`,
		method: "GET",
		secure: true,
		actionType: COMMON.GET_COUNTIES_LIST,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const getStates = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `general/states`,
		method: "GET",
		secure: true,
		actionType: COMMON.GET_STATE_LIST,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const getCities = (stateId, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `general/${stateId}/Cities`,
		method: "GET",
		secure: true,
		actionType: COMMON.GET_CITY_LIST,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const getLookupValues = (typeId, typeKey, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `general/types/${typeId}`,
		method: "GET",
		secure: true,
		actionType: COMMON.GET_LOOKUP,
		extraParams: { typeKey },
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const getLookupValuesList = (list, typeKey, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `general/types`,
		method: "POST",
		secure: true,
		body: JSON.stringify(list),
		actionType: COMMON.GET_LOOKUP_LIST,
		addUtcOffset: false,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const searchContacts = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `contacts?${serialize(values)}`,
		method: "GET",
		secure: true,
		actionType: COMMON.SEARCH_CONTACTS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const updateAddress = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `general/Address/${values.id}`,
		method: "PUT",
		secure: true,
		actionType: COMMON.UPDATE_ADDRESS,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const createAddress = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `general/Address`,
		method: "POST",
		secure: true,
		actionType: COMMON.CREATE_ADDRESS,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
