import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, getFormValues } from "redux-form";
import { Link } from "react-router-dom";
import { sendResetPasswordCredentials, checkResetPasswordLink, showLoader } from "../../store/actions";

import FormControl from "../SharedComponts/FormControl";
import { errorMessage } from "../../utils/errorMessage";
import { successMessage } from "../../utils/successMessage";
import { required, passwordStrength } from "../../constant/formValidators";

import { Tooltip, OverlayTrigger } from "react-bootstrap";

import "./session.scss";
const ResetPassword = ({
	set,
	doSendResetPasswordCredentials,
	doCheckResetPasswordLink,
	handleSubmit,
	history,
	doShowLoader,
	formValues,
}) => {
	useEffect(() => {
		const search = window.location.search;
		const params = new URLSearchParams(search);
		const confirmationCode = params.get("confirmationCode");
		const userId = params.get("id");
		const successCb = (respone) => {
			doShowLoader(false);
		};
		const faliCb = (event) => {
			doShowLoader(false);
			history.push("/signin");
			errorMessage(event);
		};
		doShowLoader(true);
		doCheckResetPasswordLink({ userId, token: confirmationCode }, successCb, faliCb);
	}, [doCheckResetPasswordLink, doShowLoader, history]);

	const submitForgotAction = (values) => {
		if (values.newPassword !== values.confirmPassword) {
			errorMessage(104);
			return;
		}
		doShowLoader(true);
		const successHandler = () => {
			doShowLoader(false);
			history.push("/signin");
			successMessage(104);
		};
		const errorHandler = (event) => {
			errorMessage(event);
			doShowLoader(false);
		};

		const search = window.location.search;
		const params = new URLSearchParams(search);
		const confirmationCode = params.get("confirmationCode");
		const userId = params.get("id");

		doSendResetPasswordCredentials(
			{ userId, token: confirmationCode, newPassword: values.newPassword },
			successHandler,
			errorHandler
		);
	};

	return (
		<div className="sign-in-wrap">
			<div className="sign-in-container ">
				<div className="login-logo"></div>
				<form className="sign-in-form mt-0 ml-4 mr-4" onSubmit={handleSubmit(submitForgotAction)}>
					<div className="row">
						<div className="col-12">
							<h2>{set ? "Reset" : "Set"} Password</h2>
							<hr className="heading-hr" />
						</div>
						<div className="col-12">
							<div className="form-group">
								<div className="form-lable">
									Password
									<OverlayTrigger
										placement="right"
										overlay={
											<Tooltip
												style={{
													fontSize: "12px",
													fontWeight: "500",
												}}>
												Please enter a password with a minimum length of 8 characters and a combination
												of uppercase, lowercase, numeric and special characters.
											</Tooltip>
										}>
										<span className="newPassword-info">
											<i className="fa fa-info-circle" aria-hidden="true"></i>
										</span>
									</OverlayTrigger>
								</div>

								<Field
									name="newPassword"
									component={FormControl}
									type="password"
									className="form-control form-field"
									placeholder="Password"
									validate={[required, passwordStrength]}
								/>
							</div>
						</div>
						<div className="col-12">
							<div className="form-group">
								<div className="form-lable ">Confirm Password</div>
								<Field
									name="confirmPassword"
									component={FormControl}
									type="password"
									className="form-control form-field"
									placeholder="Confirm Password"
									validate={[required]}
								/>
							</div>
						</div>

						<div className="col-12">
							<button className="form-button-primary w-100" type="submit">
								Submit
							</button>
						</div>
						<div className="col-12 content-center">
							<Link className="forgot-password mt-4" to="/login">
								Back to Login
							</Link>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

const mapDispatchToProps = {
	doSendResetPasswordCredentials: sendResetPasswordCredentials,
	doShowLoader: showLoader,
	doCheckResetPasswordLink: checkResetPasswordLink,
};

const mapStateToProps = (state) => ({
	formValues: getFormValues("SignInForm")(state),
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "SignInForm",
	})(ResetPassword)
);
