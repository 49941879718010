import { TASK } from "../types";
import fetchHandler from "../../utils/fetchHandler";
import { serialize } from "../../utils/helper";
export function setTaskFilter(filter, successHandler) {
	successHandler && successHandler();
	return {
		type: TASK.SET_FILTER,
		payload: filter,
	};
}
export const getTaskList = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `tasks?${serialize(values)}`,
		method: "GET",
		secure: true,
		actionType: TASK.GET_TASK_LIST,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const createTask = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `task`,
		method: "POST",
		secure: true,
		actionType: TASK.CREATE_TASK,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const updateTask = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `task`,
		method: "PUT",
		secure: true,
		actionType: TASK.UPDATE_TASK,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const deleteTask = (id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `task/${id}`,
		method: "DELETE",
		secure: true,
		actionType: TASK.DELETE_TASK,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
