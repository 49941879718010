import React from "react";

import { Date } from "../../utils/formatter";
import { dateFormat } from "../../constant/common";

import FormControl from "../SharedComponts/FormControl";
import Tooltip from "../SharedComponts/ToolTip";

export const columns = ({ showNote = null, history }) => [
	{
		name: "Property #",
		accessor: "propertyId",
		className: "col-propertyId",
	},
	{
		name: "Property Address",
		accessor: "streetAddress",
		className: "col-streetAddress",
	},
	{
		name: "Type",
		accessor: "dealType",
		className: "col-dealType",
	},
	{
		name: "Exchange #",
		accessor: "exchangeNumber",
		className: "col-exchangeNumber",
		render: (row) => (
			<div
				className="text-highlighted"
				onClick={() => {
					history.push(`/exchanges/${row.exchangeGuid}`);
				}}>
				{row.exchangeNumber}
			</div>
		),
	},
	{
		name: "Exchanger",
		accessor: "exchanger",
		className: "col-exchanger",
	},
	{
		name: "Settlement Company",
		accessor: "escrowCompanyName",
		className: "col-escrowCompanyName",
	},
	{
		name: "Created Date",
		accessor: "lastModificationTime",
		className: "col-lastModificationTime",
		render: (row) => <Date utcTime={row.lastModificationTime} format={dateFormat} />,
	},
	{
		name: "More Info",
		className: "col-actions text-right",
		headerClassName: "col-actions-header",
		sortable: false,
		render: (row) => {
			return (
				<Tooltip
					message={"More info"}
					component={
						<span className="note-icon-span" onClick={() => showNote(row)}>
							<i className="icon icon-eye"></i>
						</span>
					}
				/>
			);
		},
	},
];

export const advancedFilterFormFields = (exchangerNameList, buyerSellerList, formValues, escrowNumber, companyList) => {
	return [
		{
			name: "dealerIds",
			label: "Seller / Buyer Name",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			options: buyerSellerList,
			onChange: (selected) => { },
		},
		{
			name: "exchangerIds",
			label: "Exchanger",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			options: exchangerNameList,
			onChange: (selected) => { },
		},
		{
			name: "settlementCompanyIds",
			label: "Settlement Company",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			options: companyList,
			onChange: (selected) => { },
		},
		{
			name: "escrowNumbers",
			label: "Escrow Number",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			options: escrowNumber,
			onChange: (selected) => { },
		},
	];
};
