import React from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { useState } from "react";
import { Col } from "react-bootstrap";
import { Pagination } from "../../SharedComponts/FormElements/table";
import "./pdfViewer.scss";
export default function PdfViewer({ pdf, width, showPagination = true }) {
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const [errMsg, setErrorMsg] = useState(null);

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
		setPageNumber(1);
	}

	function onDocumentLoadError(error) {
		setErrorMsg(error.message);
	}

	const setPageNum = (num) => {
		setPageNumber(num);
	};

	const error = errMsg ? <div className="w-100 m-auto error">{errMsg}</div> : null;
	const loadingPdf = <p className="loading">Loading PDF...</p>;
	const pagination =
		showPagination && numPages ? (
			<Pagination total={numPages} count={1} page={pageNumber} onChange={setPageNum} onPageCountChange={null} />
		) : null;
	return (
		<>
			<Col className="pdf-viewer pb-3">
				<Document
					file={pdf}
					onLoadSuccess={onDocumentLoadSuccess}
					onLoadError={onDocumentLoadError}
					loading={loadingPdf}
					error="">
					<Page pageNumber={pageNumber} width={width ? width : 800} className="pdfViewCard pb-3" />
				</Document>
				{error}
				{pagination}
			</Col>
		</>
	);
}
