import React from "react";
const renderField = ({ id, input, label, type, placeholder, meta: { touched, error, warning } }) => {
	return (
		<>
			<label htmlFor={id} className="custom-check">
				<input className="check-input" type={"checkbox"} {...input} />
				<span className={`checkmark ${touched && error && "form-error"}`} />
				<div className="form-lable pl-5">{label}</div>
			</label>

			{touched &&
				((error && <span className="form-error-span d-flex">{error}</span>) || (warning && <span>{warning}</span>))}
		</>
	);
};
export default renderField;
