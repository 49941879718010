import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import Slider from "../../../SharedComponts/Slider";
import { settlementFormFields } from "./helper";
import SliderForm from "../../../SharedComponts/SliderForm";
import { showLoader } from "../../../../store/actions";
const AddEdit = (props) => {
	const {
		show,
		doShowLoader,
		companyTypes,
		states,
		cities,
		doGetCities,
		handleClose,
		change,
		handleSubmit,
		submitting,
		reset,
		isEdit,
		loadCounties,
	} = props;

	return (
		<Slider
			show={show}
			title={"Add Settlement Company"}
			handleClose={() => {
				reset();
				handleClose();
			}}>
			<SliderForm handleSubmit={handleSubmit} submitting={submitting} handleClose={handleClose}>
				{settlementFormFields({
					states,
					cities,
					companyTypes,
					doGetCities,
					doShowLoader,
					change,
					loadCounties,
					isEdit,
				}).map((item, index) => {
					return item.hideField ? null : (
						<div key={index} className="form-group">
							<div className="form-lable">
								{item.label}
								{item.required ? <span className="required-label">*</span> : null}
							</div>
							<Field {...item} />
						</div>
					);
				})}
			</SliderForm>
		</Slider>
	);
};
const mapStateToProps = ({ Common: { states = [], counties = [], cites: cities = [] } }) => ({ states, counties, cities });
const mapDispatchToProps = {
	doShowLoader: showLoader,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "SettlementAddform",
		enableReinitialize: true,
	})(AddEdit)
);
