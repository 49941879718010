import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";

import { resetField, sendLoginCredentials, updateLoginCredentials, showLoader } from "../../store/actions";

import FormControl from "../SharedComponts/FormControl";
import { errorMessage } from "../../utils/errorMessage";
import { required, email } from "../../constant/formValidators";

import "./session.scss";
import { successMessage } from "../../utils/successMessage";
const LSVariable = process.env.REACT_APP_LS_VAR;

export const SignIn = ({
	doSendLoginCredentials,
	doUpdateLoginCredentials,
	handleSubmit,
	history,
	doShowLoader,
	doResetField,
}) => {

	const submitLogin = (values) => {
		doShowLoader(true);

		const successHandler = (event) => {
			if (event.result && event.result.isTwoFactorEnabled === true) {
				history.push("/authentication");
				successMessage(520);
				doShowLoader(false);
				const userId = event.result.userId;
				localStorage.setItem('userId', userId);
				return;
			} else {
				if (event && event.result.isTwoFactorEnabled === false) {
					doResetField("Auth.accessToken", event.result);
					const now = new Date();
					const item = {
						...event.result,
						expiry: now.getTime() + 86370 * 1000,
					};
					localStorage.setItem(LSVariable, JSON.stringify(item));
					doShowLoader(false);
					const updateSuccessHandler = () => {
						history.push("/");
					};
					doUpdateLoginCredentials(item, updateSuccessHandler);
				}
			}
		};

		const errorHandler = (event) => {
			doShowLoader(false);
			errorMessage(event);
		};

		doSendLoginCredentials({ ...values, rememberClient: true }, successHandler, errorHandler);
	};




	return (
		<div className="sign-in-wrap">
			<div className="sign-in-container">
				<div className="login-logo"></div>

				<form className="sign-in-form sign-in-form mt-0 ml-4 mr-4 " onSubmit={handleSubmit(submitLogin)}>
					<div className="row">
						<div className="col-12 sub-title">
							<h2>Login</h2>
							<hr className="heading-hr" />
						</div>
						<div className="col-12">
							<div className="form-group">
								<div className="form-lable">Email Address</div>

								<Field
									name="userNameOrEmailAddress"
									component={FormControl}
									type="text"
									className="form-control form-field"
									placeholder="Email"
									validate={[required, email]}
								/>
							</div>
						</div>

						<div className="col-12">
							<div className="form-group">
								<div className="form-lable">Password</div>
								<Field
									name="password"
									label="Password"
									showPassword={true}
									type="password"
									component={FormControl}
									validate={[required]}
								/>
							</div>
						</div>

						<div className="col-12">
							<button className="form-button-primary w-100" type="submit">
								Login
							</button>
						</div>
						<div className="col-12 content-center">
							<Link className="forgot-password mt-4" to="/forgot-password">
								Forgot password?
							</Link>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

const mapDispatchToProps = {
	doSendLoginCredentials: sendLoginCredentials,
	doUpdateLoginCredentials: updateLoginCredentials,
	doShowLoader: showLoader,
	doResetField: resetField,
};

export default connect(
	null,
	mapDispatchToProps
)(
	reduxForm({
		form: "SignInForm",
	})(SignIn)
);
