import React from "react";

import { MoreOptions } from "../SharedComponts/FormElements";

import { Date, Currency } from "../../utils/formatter";
import { dateFormat } from "../../constant/common";

import FormControl from "../SharedComponts/FormControl";
import Tooltip from "../SharedComponts/ToolTip";
export const columns = ({
	onSelect,
	onEdit = null,
	onDelete = null,
	showNote = null,
	showEmail = null,
	changeLock = null,
	userDetails,
}) => [
		{
			name: "Exchange info",
			accessor: "exchangeNumber",
			className: "col-exchangeNumber",
			render: (row) => (
				<>
					<div className="text-highlighted" onClick={() => onSelect(row)}>
						<Tooltip message={`Type: ${row.exchangeTypeName}`} component={<span>{row.exchangeNumber}</span>} />
					</div>
					{`${row.exchanger}`}
				</>
			),
		},
		// {
		// 	name: "Exchanger",
		// 	accessor: "exchanger",
		// 	className: "col-Exchanger",
		// },
		{
			name: "Open Date",
			accessor: "openDate",
			className: "col-openDate",
			render: (row) => <Date utcTime={row.openDate} format={dateFormat} noConvert={true} />,
		},
		{
			name: "45th Day",
			accessor: "day45",
			className: "col-day45",
			render: (row) => <Date utcTime={row.day45} format={dateFormat} noConvert={true} />,
		},
		{
			name: "180th Day",
			accessor: "day180",
			className: "col-day180",
			render: (row) => <Date utcTime={row.day180} format={dateFormat} noConvert={true} />,
		},
		{
			name: "Last Edited",
			accessor: "lastModificationTime",
			className: "col-lastModificationTime",
			render: (row) => <Date utcTime={row.lastModificationTime} format={dateFormat} />,
		},
		{
			name: "Status",
			accessor: "exchangeStatusName",
			className: "col-status",
			sortable: false,
			render: (row) => <span className={`status-card status-${row.exchangeStatusName}`}>{row.exchangeStatusName}</span>,
		},
		{
			name: "A/C Balance",
			accessor: "accountBalance",
			className: "col-accountBalance",
			render: (row) => <Currency amount={row.accountBalance} />,
			sortable: false,
		},
		{
			name: "Actions",
			className: "col-actions text-right",
			headerClassName: "col-actions-header",
			sortable: false,
			render: (row) => {
				return (
					<>
						<MoreOptions
							options={[
								{ label: "Email", onClick: () => showEmail(row) },
								{ hide: row.isLocked, label: "Delete", onClick: () => onDelete(row) },
							]}
						/>
						<Tooltip
							message={"Click add note"}
							component={
								<span className="note-icon-span" onClick={() => showNote(row)}>
									<i className="icon icon-notes"></i>
								</span>
							}
						/>
						{row.isLocked && userDetails && userDetails.unlockPermission && (
							<Tooltip
								message={"Click to unlock"}
								component={
									<span
										className={`note-icon-span lock-button-span ${row.isLocked ? "hand" : "no-hand"} `}
										onClick={() => changeLock(row)}>
										<i className={`icon ${row.isLocked ? "icon-lock" : "icon-unlock"}`}></i>
									</span>
								}
							/>
						)}
						{row.isLocked && userDetails && !userDetails.unlockPermission && (
							<span className={`note-icon-span lock-button-span ${row.isLocked ? "hand" : "no-hand"} `}>
								<i className={`icon ${row.isLocked ? "icon-lock" : "icon-unlock"}`}></i>
							</span>
						)}
					</>
				);
			},
		},
	];

export const advancedFilterFormFields = (
	exchangeType,
	exchangers,
	settlementCompanies,
	formValues,
	exchangeDateOptions,
	change,
	salesRepoList
) => {
	return [
		{
			name: "filterBy",
			label: "Filter By",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			options: exchangeDateOptions,
			onChange: (selected) => { },
		},
		{
			name: "fromDate",
			label: "From Date",
			type: "date",
			maxDate: formValues && formValues.toDate,
			component: FormControl,
			className: "form-control form-field",
			disabled: formValues && !formValues.filterBy,
		},
		{
			name: "toDate",
			label: "To Date",
			type: "date",
			component: FormControl,
			className: "form-control form-field",
			minDate: formValues && formValues.fromDate,
			disabled: formValues && !formValues.filterBy,
		},
		{
			name: "exchangeType",
			label: "Exchange Type",
			component: FormControl,
			type: "select",
			value: {},
			className: "form-control form-field",
			options: exchangeType,
			onChange: (selected) => { },
		},
		{
			name: "exchangerIds",
			label: "Exchanger",
			component: FormControl,
			type: "select",
			options: exchangers,
			className: "form-control form-field",
		},
		{
			name: "settlementCompanyIds",
			label: "Settlement Company",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			options: settlementCompanies,
		},
		{
			name: "salesRepIds",
			label: "Sales Rep",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			options: salesRepoList,
		},
	];
};
