import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import Slider from "../SharedComponts/Slider";
import OtpInput from 'react-otp-input';
import OtpForm from "./OtpForm";

const Otp = (props) => {
    const { handleSubmit, submitting,  showVerificationSlider, setShowVerificationSlider, setshowPopup, submitData ,handleClosed } = props;
    const [otp, setOtp] = useState('');
    const [timer, setTimer] = useState(120);
    const [otpCompleted, setOtpCompleted] = useState(false);
    const intervalRef = useRef(null);

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            setTimer(prevTimer => {
                if (prevTimer === 0) {
                    clearInterval(intervalRef.current);
                    setShowVerificationSlider(false);
                    setshowPopup(true);
                }
                return prevTimer - 1;
            });
        }, 1000);
        return () => clearInterval(intervalRef.current);
    }, [setShowVerificationSlider,setshowPopup]);

    const handleOtpChange = (otpValue) => {
        setOtp(otpValue);
        setOtpCompleted(otpValue.length === 4);
    };

    const submitOtp = () => {    handleSubmit(submitData, otp);}
    

    return (
        <React.Fragment>
            {showVerificationSlider && (
                <Slider
                    show={showVerificationSlider}
                    title="OTP VERIFICATION"
                    handleClose={() => {
                        setShowVerificationSlider(false);
                    }}
                >
                    <OtpForm handleSubmit={submitOtp} submitting={submitting} handleClosed={handleClosed} otpCompleted={otpCompleted} submitData={submitData} otp={otp}>
                        <OtpInput
                            value={otp}
                            onChange={handleOtpChange}
                            numInputs={4}
                            renderSeparator={<span>-</span>}
                            renderInput={(props) => <input {...props} />}
                            inputStyle={{ width: "50%", height: "70px" }}
                            containerStyle={{ marginBottom: "20px", padding: "25px" }}
                        />
                        <div style={{ color: "#5CA9FF", textAlign: "center" }}>Please Enter OTP : {Math.floor(timer / 60)}:{timer % 60 < 10 ? '0' + timer % 60 : timer % 60}</div>
                    </OtpForm>
                </Slider>
            )}
        </React.Fragment>
    );
};

export default connect(
    null,
    null
)(
    reduxForm({
        form: "Otpform",
        enableReinitialize: true,
    })(Otp)
);
