import React from "react";
import { Card } from "react-bootstrap";
import Layout from "../../SharedComponts/Layout";
import PdfViewer from "../../SharedComponts/PdfViewer";

const ViewTemplate = (props) => {
	const { history, currentRecord, setView } = props;

	return (
		<>
			<Layout
				title="Templates"
				breadcrumbs={[
					{
						label: "Configurations",
						onClick: () => {
							const path = `/settings-master`;
							history.push(path);
						},
					},
					{
						label: "Templates",
						onClick: () => {
							setView(false);
						},
					},
					{ label: "View Template" },
				]}
				// rightActionButtons={rightActionButtons}
				className="bank-page">
				<Card>
					<div className="col-12 editor-head mb-3">
						<div className="head-item">
							<span className="label">Template Name :</span>
							<span className="value"> {currentRecord?.templateName}</span>
						</div>
						<div className="head-item">
							<span className="label">Exchange Type :</span>
							<span className="value"> {currentRecord?.exchangeType?.label}</span>
						</div>
					</div>
					<PdfViewer pdf={currentRecord.templateUrl} width={1100} />
				</Card>
			</Layout>
		</>
	);
};

export default ViewTemplate;
