import { PROPERTIES } from "../types";
import fetchHandler from "../../utils/fetchHandler";

export const getFullPropertyList = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `properties`,
		method: "POST",
		secure: true,
		actionType: PROPERTIES.GET_ALL_PROPERTY_LIST,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export function setPropertyFilter(filter, successHandler) {
	successHandler && successHandler();
	return {
		type: PROPERTIES.SET_PROPERTY_FILTER,
		payload: filter,
	};
}

export const propertyExport = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `propertiesExport`,
		method: "POST",
		secure: true,
		actionType: PROPERTIES.PROPERTY_EXPORT,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getBuyerSellerList = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `usersbyrole?roleId=10&roleId=11`,
		method: "GET",
		secure: true,
		actionType: PROPERTIES.GET_BUYER_SELLER_LIST,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const getEscrowNumber = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `escrownumbers`,
		method: "GET",
		secure: true,
		actionType: PROPERTIES.GET_ESCROW_NUMBER,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
