import React from "react";
import startImage from "../../../assets/images/New.png";
import endImage from "../../../assets/images/Closed.png";

import "./timeline.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const Point = ({ position, label, active, tooltipText, index, labelPosition }) => {
	const renderTooltip = (props) => (
		<Tooltip id="button-tooltip" className="timeline-tooltip" {...props}>
			{tooltipText}
		</Tooltip>
	);
	const pointRender = () => {
		return (
			<div className={`point ${active ? "active" : ""}`} style={{ left: `${position}%` }}>
				{!!label && (
					<div
						className="label"
						style={
							labelPosition
								? { left: `-${labelPosition}px`, top: index % 2 === 0 ? -27 : null }
								: { top: index % 2 === 0 ? -27 : null }
						}>
						{label}
					</div>
				)}
			</div>
		);
	};
	return (
		<React.Fragment>
			{tooltipText && tooltipText.length > 0 ? (
				<OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
					{pointRender()}
				</OverlayTrigger>
			) : (
				pointRender()
			)}
		</React.Fragment>
	);
};
const TimeLine = (props) => {
	const { points, now, startLabel, endLabel } = props;

	return (
		<div className="time-line">
			<div className="start-img">
				<img src={startImage} alt="start" />
				{!!startLabel && <div className="label">{startLabel} </div>}
			</div>
			<div className="time-progress" now={now}>
				<div className="progress-bar" style={{ width: `${now}%` }} />
				<div className="start-point" />

				{!!points &&
					points.map((item, index) => {
						return <Point key={index} index={index} {...item} />;
					})}
			</div>
			<div className="end-img">
				<img src={endImage} alt="start" />
				<div className="label">{endLabel}</div>
			</div>
		</div>
	);
};
export default TimeLine;
