import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Select from "react-select";

import { connect } from "react-redux";
import PdfViewer from "../../../../SharedComponts/PdfViewer";
import {
	createAdditionalSign,
	getSignatureMenu,
	showLoader,
	generateDocument,
	getExchangePropertyAddressListByType,
} from "../../../../../store/actions";
import { errorMessage } from "../../../../../utils/errorMessage";

const SecondStage = (props) => {
	const { exchangeDetail, setProperty, template, doShowLoader, property, doGetExchangePropertyAddressListByType } = props;
	const [exchangePropertyAddressList, setExchangePropertyAddressList] = useState([]);
	useEffect(() => {
		doShowLoader(true);
		const successCB = (response) => {
			doShowLoader(false);
			setExchangePropertyAddressList(response.result);
		};
		const FailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};
		doGetExchangePropertyAddressListByType(
			{ id: exchangeDetail.id, typeId: template.templateType.value },
			successCB,
			FailCB
		);
	}, [doShowLoader, exchangeDetail, doGetExchangePropertyAddressListByType, template]);
	return (
		<>
			<Card>
				{template && template.isPropertyAllowed && (
					<div className="col-4 mb-2">
						<Select
							placeholder="Choose Property"
							value={property}
							onChange={(val) => setProperty(val)}
							options={exchangePropertyAddressList}
						/>
					</div>
				)}

				<div className="col-12 editor-head mb-2">
					<div className="head-item">
						<span className="label">Template Name :</span>
						<span className="value"> {template?.templateName}</span>
					</div>
					<div className="head-item">
						<span className="label">Exchange Type :</span>
						<span className="value"> {exchangeDetail?.exchangeType?.label}</span>
					</div>
				</div>
				<PdfViewer pdf={template.templateUrl} showPagination={true} />
			</Card>
		</>
	);
};

const mapDispatchToProps = {
	doGetSignatureMenu: getSignatureMenu,
	doCreateAdditionalSign: createAdditionalSign,
	doShowLoader: showLoader,
	doGenerateDocument: generateDocument,
	doGetExchangePropertyAddressListByType: getExchangePropertyAddressListByType,
};
const mapStateToProps = (state) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SecondStage);
