import { EXCHANGE } from "../types";
import { getDatePickerDateFromtDate } from "../../utils/helper";
export default (
	state = {
		filters: {
			sortColumn: "exchangeNumber",
			sortDirection: "desc",
			pageIndex: 1,
			pageSize: 10,
			searchKey: "",
			extraParams: {},
		},
		exchangeNotes: [],
		relinquishedProperties: { properties: [{ id: 0 }] },
	},
	action
) => {
	switch (action.type) {
		case EXCHANGE.SET_FILTER:
			return {
				...state,
				filters: action.payload ? action.payload : {},
			};
		case EXCHANGE.GET_EXCHANGE_LIST:
			return {
				...state,
				exchangeList: action.payload ? action.payload.result : [],
			};
		case EXCHANGE.GET_NOTES:
			return {
				...state,
				exchangeNotes: action.payload && action.payload.result ? action.payload.result.data : [],
			};
		case EXCHANGE.GET_BANK_BASIC_INFO:
		case EXCHANGE.CREATE_BANK_BASIC_INFO:
			return {
				...state,
				bankBasicInfo: action.payload && action.payload.result ? action.payload.result : [],
			};
		case EXCHANGE.GET_EXCHANGE_TASK_LIST:
			return {
				...state,
				taskList: action.payload && action.payload.result ? action.payload.result : [],
			};
		case EXCHANGE.GET_EXCHANGE_TRANSACTION_LIST:
			return {
				...state,
				exchangeTransactionList: action.payload && action.payload.result ? action.payload.result : [],
			};
		case EXCHANGE.GET_EXCHANGE_INCOME_LIST:
			return {
				...state,
				exchangeIncomeList: action.payload && action.payload.result ? action.payload.result : [],
			};
		case EXCHANGE.GET_EXCHANGE_TO_FROM_LIST:
			return {
				...state,
				transactionFromToList: action.payload && action.payload.result ? action.payload.result : [],
			};
		case EXCHANGE.GET_EXCHANGE_ADDRESS_LIST:
			return {
				...state,
				exchangeAddressList: action.payload && action.payload.result ? action.payload.result : [],
			};
		case EXCHANGE.EXCHANGE_BANK_LIST:
			return {
				...state,
				bankList: action.payload && action.payload.result ? action.payload.result : [],
			};
		case EXCHANGE.UNLOCK:
			return {
				...state,
				isLocked: false,
				exchangeDetail: { ...state.exchangeDetail, isLocked: false },
			};
		case EXCHANGE.GET_EXCHANGE_DETAIL: {
			let data = {};
			if (action.payload.result) {
				data = action.payload.result;
				data.exchangeDetail.day45 = getDatePickerDateFromtDate(data.exchangeDetail.day45);
				data.exchangeDetail.openDate = getDatePickerDateFromtDate(data.exchangeDetail.openDate);
				data.exchangeDetail.rel1CloseDate = getDatePickerDateFromtDate(data.exchangeDetail.rel1CloseDate);
				data.exchangeDetail.day180 = getDatePickerDateFromtDate(data.exchangeDetail.day180);
				data.exchangeDetail.actualDDate = getDatePickerDateFromtDate(data.exchangeDetail.actualDDate);
				data.exchangeDetail.finalCloseDate = getDatePickerDateFromtDate(data.exchangeDetail.finalCloseDate);
				data.isLocked = data.exchangeDetail.isLocked;
			}
			return {
				...state,
				...data,
			};
		}
		case EXCHANGE.GET_RELINQUISHED_PROPERTY: {
			let relinquishedProperties = {};
			if (action.payload.result) {
				relinquishedProperties = action.payload.result;
				if (action.payload.result.properties && action.payload.result.properties.length > 0) {
					relinquishedProperties.properties = relinquishedProperties.properties.map((item) => {
						if (item.settlementInformation && item.settlementInformation.contractDate) {
							item.settlementInformation.contractDate = getDatePickerDateFromtDate(
								item.settlementInformation.contractDate
							);
						}
						if (item?.closeDate) {
							item.closeDate = getDatePickerDateFromtDate(item.closeDate);
						}
						
						return item;
					});
				}
			}
			return {
				...state,
				relinquishedProperties,
			};
		}
		case EXCHANGE.GET_REPLACEMENT_PROPERTY: {
			let replacementProperties = {};
			if (action.payload.result) {
				replacementProperties = action.payload.result;
				if (action.payload.result.properties && action.payload.result.properties.length > 0) {
					replacementProperties.properties = replacementProperties.properties.map((item) => {
						if (item.settlementInformation && item.settlementInformation.contractDate) {
							item.settlementInformation.contractDate = getDatePickerDateFromtDate(
								item.settlementInformation.contractDate
							);
						}
						if (item?.closeDate) {
							item.closeDate = getDatePickerDateFromtDate(item.closeDate);
						}
						
						return item;
					});
				}
			}
			return {
				...state,
				replacementProperties,
			};
		}
		/*
		case EXCHANGE.CREATE_BUYER: {
			const relinquishedProperties = getCopy(state.relinquishedProperties);

			if (action.payload.result) {
				const index = relinquishedProperties.properties.findIndex((item) => {
					return item.id === action.payload.result.propertyId;
				});
				relinquishedProperties.properties[index].dealerDtoList = [
					...relinquishedProperties.properties[index].dealerDtoList,
					action.payload.result,
				];
			}
			return {
				...state,
				relinquishedProperties,
			};
		}
		case EXCHANGE.CREATE_SELLER: {
			const replacementProperties = getCopy(state.replacementProperties);

			if (action.payload.result) {
				const index = replacementProperties.properties.findIndex((item) => {
					return item.id === action.payload.result.propertyId;
				});
				replacementProperties.properties[index].dealerDtoList = [
					...replacementProperties.properties[index].dealerDtoList,
					action.payload.result,
				];
			}
			return {
				...state,
				replacementProperties,
			};
		}
		*/

		default:
			return state;
	}
};
