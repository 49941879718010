export const COMMON = {
	RESET_FIELD: "RESET_FIELD",
	SHOW_LOADER: "SHOW_LOADER",
	FUN_FACT: "FUN_FACT",
	GET_COUNTIES_LIST: "GET_COUNTIES_LIST",
	GET_STATE_LIST: "GET_STATE_LIST",
	GET_CITY_LIST: "GET_CITY_LIST",
	GET_LOOKUP: "GET_LOOKUP",
	GET_LOOKUP_LIST: "GET_LOOKUP_LIST",
	SEARCH_CONTACTS: "SEARCH_CONTACTS",
	UPDATE_ADDRESS: "UPDATE_ADDRESS",
	CREATE_ADDRESS: "CREATE_ADDRESS",
};
export const DASHBOARD = {
	GET_MARKET_DETAILS: "GET_MARKET_DETAILS",
	GET_DASHBOARD_DATA: "GET_DASHBOARD_DATA",
	GET_BARCHART_DATA: "GET_BARCHART_DATA",
	GET_LAST_ACTIVITIES: "GET_LAST_ACTIVITIES",
};

export const USER = {
	SET_ADMIN_FILTER: "SET_ADMIN_FILTER",
	SET_EXCHANGER_FILTER: "SET_EXCHANGER_FILTER",
	SET_ESCROW_FILTER: "SET_ESCROW_FILTER",
	SET_CONTACT_FILTER: "SET_CONTACT_FILTER",
	GET_USER_LIST: "GET_USER_LIST",
	CREATE_USER: "CREATE_USER",
	GET_USER: "GET_USER",
	UPDATE_USER: "UPDATE_USER",
	CREATE_EXCHANGER: "CREATE_EXCHANGER",
	GET_EXCHANGER: "GET_EXCHANGER",
	UPDATE_EXCHANGER: "UPDATE_EXCHANGER",
	GET_EXCHANGER_LIST: "GET_EXCHANGER_LIST",
	CREATE_ESCROW: "CREATE_ESCROW",
	GET_ESCROW: "GET_ESCROW",
	UPDATE_ESCROW: "UPDATE_ESCROW",
	CREATE_CONTACT: "CREATE_CONTACT",
	GET_CONTACT: "GET_CONTACT",
	GET_CONTACT_ROLES: "GET_CONTACT_ROLES",
	UPDATE_CONTACT: "UPDATE_CONTACT",
	CHECK_RELATED_EXCHANGE: "CHECK_RELATED_EXCHANGE",
	DELETE_USER: "DELETE_USER",
	GET_ACTIVITY_LOG: "GET_ACTIVITY_LOG",
	SEARCH_USERS: "SEARCH_USERS",
	GET_ADMIN_USERS: "GET_ADMIN_USERS",
	UPLOAD_PROFILE_IMAGE: "UPLOAD_PROFILE_IMAGE",
	DELETE_PROFILE_IMAGE: "DELETE_PROFILE_IMAGE",
	PASSWORD_RESET_MAIL: "PASSWORD_RESET_MAIL",
};

export const SESSION = {
	GET_USER_DETAILS: "GET_USER_DETAILS",
	SEND_LOGIN_CREDENTIALS: "SEND_LOGIN_CREDENTIALS",
	SEND_OTP_CREDENTIALS: "SEND_OTP_CREDENTIALS",
	UPDATE_LOGIN_CREDENTIALS: "UPDATE_LOGIN_CREDENTIALS",
	SEND_FORGOT_PASSWORD_CREDENTIALS: "SEND_FORGOT_PASSWORD_CREDENTIALS",
	SEND_RESET_PASSWORD_CREDENTIALS: "SEND_RESET_PASSWORD_CREDENTIALS",
	CHANGE_PASSWORD: "CHANGE_PASSWORD",
	CHECK_RESET_LINK: "CHECK_RESET_LINK",
};

export const SETTINGS = {
	GET_COMPANY_LIST: "GET_COMPANY_LIST",
	GET_EXCHANGE_TYPES: "GET_EXCHANGE_TYPES",
	GET_COMPANY_TYPES: "GET_COMPANY_TYPES",
};
export const TASK = {
	GET_TASK_LIST: "GET_TASK_LIST",
	SET_FILTER: "SET_TASK_FILTER",
	CREATE_TASK: "CREATE_TASK",
	UPDATE_TASK: "UPDATE_TASK",
	DELETE_TASK: "DELETE_TASK",
};
export const FEE = {
	GET_FEE_LIST: "GET_FEE_LIST",
	SET_FILTER: "SET_FEE_FILTER",
	CREATE_FEE: "CREATE_FEE",
	UPDATE_FEE: "UPDATE_FEE",
	DELETE_FEE: "DELETE_FEE",
	GET_FEE: "GET_FEE",
};
export const BANK = {
	GET_BANK_LIST: "GET_BANK_LIST",
	SET_FILTER: "SET_BANK_FILTER",
	SET_DEFAULT: "SET_DEFAULT_BANK",
	CREATE_BANK: "CREATE_BANK",
	UPDATE_BANK: "UPDATE_BANK",
	DELETE_BANK: "DELETE_BANK",
	GET_BANK: "GET_BANK",
};
export const ENTITY = {
	GET_ENTITY_LIST: "GET_ENTITY_LIST",
	GET_ENTITY_DETAILS: "GET_ENTITY_DETAILS",
	SET_FILTER: "SET_ENTITY_FILTER",
	CREATE_ENTITY: "CREATE_ENTITY",
	UPDATE_ENTITY: "UPDATE_ENTITY",
	DELETE_ENTITY: "DELETE_ENTITY",
	GET_ENTITIES_BY_TYPE: "GET_ENTITIES_BY_TYPE",
	GET_ENTITIES_BY_ESCROW:"GET_ENTITIES_BY_ESCROW",
	CHECK_RELATED_EXCHANGE: "COMPANY_CHECK_RELATED_EXCHANGE",
};

export const PDF = {
	GET_PDF_CONFIG: "GET_PDF_CONFIG",
	UPDATE_PDF_CONFIG: "UPDATE_PDF_CONFIG",
};
export const EXCHANGE = {
	GET_EXCHANGE_LIST: "GET_EXCHANGE_LIST",
	CREATE_COEXCHANGER: "CREATE_COEXCHANGER",
	DELETE_COEXCHANGER: "DELETE_COEXCHANGER",
	DELETE_PROPERTY: "DELETE_PROPERTY",
	CREATE_BUYER: "CREATE_BUYER",
	CREATE_SELLER: "CREATE_SELLER",
	DELETE_DEALER: "DELETE_DEALER",
	SET_FILTER: "SET_EXCHANGE_FILTER",
	GET_ENTITY_LIST: "GET_ENTITY_LIST",
	CREATE_EXCHANGE: "CREATE_EXCHANGE",
	GET_EXCHANGE_DETAIL: "GET_EXCHANGE_DETAIL",
	UPDATE_EXCHANGE: "UPDATE_EXCHANGE",
	EXCHANGE_EXPORT: "EXCHANGE_EXPORT",
	EXCHANGE_USERS_EXPORT:"EXCHANGE_USERS_EXPORT",
	EXCHANGER_INFO_UPDATE: "EXCHANGER_INFO_UPDATE",
	GET_RELINQUISHED_PROPERTY: "GET_RELINQUISHED_PROPERTY",
	GET_REPLACEMENT_PROPERTY: "GET_REPLACEMENT_PROPERTY",
	CREATE_PRPERTY: "CREATE_PRPERTY",
	UPDATE_PROPERTY: "UPDATE_PROPERTY",
	SUBMIT_PROPERTY: "SUBMIT_PROPERTY",
	SEND_MAIL: "SEND_MAIL",
	CREATE_CONTACT: "CREATE_EXCHANGE_CONTACT",
	UNLOCK: "UNLOCK",
	DELETE_EXCHANGE: "DELETE_EXCHANGE",
	GET_NOTES: "GET_NOTES",
	CREATE_NOTE: "CREATE_NOTE",
	DELETE_NOTE: "DELETE_NOTE",
	EXCHANGE_BANK_LIST: "EXCHANGE_BANK_LIST",
	CREATE_BANK_BASIC_INFO: "CREATE_BANK_BASIC_INFO",
	GET_BANK_BASIC_INFO: "GET_BANK_BASIC_INFO",
	UPDATE_BANK_BASIC_INFO: "UPDATE_BANK_BASIC_INFO",
	SEARCH_EXCROW: "SEARCH_EXCROW",
	EXCROW_BY_COMPANY: "EXCROW_BY_COMPANY",
	GET_EXCHANGE_TRANSACTION_LIST: "GET_EXCHANGE_TRANSACTION_LIST",
	CREATE_EXCHANGE_TRANSACTION: "CREATE_EXCHANGE_TRANSACTION",
	UPDATE_EXCHANGE_TRANSACTION: "UPDATE_EXCHANGE_TRANSACTION",
	DELETE_EXCHANGE_TRANSACTION: "DELETE_EXCHANGE_TRANSACTION",
	GET_EXCHANGE_TRANSACTION: "GET_EXCHANGE_TRANSACTION",
	GET_EXCHANGE_ADDRESS_LIST: "GET_EXCHANGE_ADDRESS_LIST",
	GET_EXCHANGE_TO_FROM_LIST: "GET_EXCHANGE_TO_FROM_LIST",
	GET_EXCHANGE_TASK_LIST: "GET_EXCHANGE_TASK_LIST",
	CREATE_EXCHANGE_TASK: "CREATE_EXCHANGE_TASK",
	UPDATE_EXCHANGE_TASK: "UPDATE_EXCHANGE_TASK",
	DELETE_EXCHANGE_TASK: "DELETE_EXCHANGE_TASK",
	GET_EXCHANGE_INCOME_LIST: "GET_EXCHANGE_INCOME_LIST",
	CREATE_EXCHANGE_INCOME: "CREATE_EXCHANGE_INCOME",
	UPDATE_EXCHANGE_INCOME: "UPDATE_EXCHANGE_INCOME",
	DELETE_EXCHANGE_INCOME: "DELETE_EXCHANGE_INCOME",
	GET_EXCHANGE_INCOME: "GET_EXCHANGE_INCOME",
	GET_EXCHANGE_DOCUMENT_LIST: "GET_EXCHANGE_DOCUMENT_LIST",
	GET_DOCUMENT_LIST: "GET_DOCUMENT_LIST",
	UPDATE_DOCUMENT: "UPDATE_DOCUMENT",
	UPDATE_DOCUMENT_STATUS: "UPDATE_DOCUMENT_STATUS",
	DELETE_DOCUMENT: "DELETE_DOCUMENT",
	GET_PROPERTY_ADDRESS: "GET_PROPERTY_ADDRESS",
	UPLOAD_DOCUMENT: "UPLOAD_DOCUMENT",
	GET_SIGNATURE_MENU: "GET_SIGNATURE_MENU",
	CREATE_ADDITIONAL_SIGN: "CREATE_ADDITIONAL_SIGN",
	GENERATE_DOCUMENT: "GENERATE_DOCUMENT",
	GET_DOCUMENT: "GET_DOCUMENT",
	GET_EXCHANGE_ADDRESS_LIST_BY_TYPE: "GET_EXCHANGE_ADDRESS_LIST_BY_TYPE",
	EXCHANGE_NUMBER_SEARCH: "EXCHANGE_NUMBER_SEARCH",
	GET_SALES_REPO_LIST:"GET_SALES_REPO_LIST"
};

export const REPORTS = {
	GET_EXCHANGER_NAME: "GET_EXCHANGER_NAME",
	GET_EXCHANGE_NBR: "GET_EXCHANGE_NBR",
	GET_EXCHANGER_NAMES: "GET_EXCHANGER_NAMES",
	GET_EXCHANGE_NBRS: "GET_EXCHANGE_NBRS",
	GET_WEEK_RANGE: "GET_WEEK_RANGE",
	GET_EXCHANGE_REPORT: "GET_EXCHANGE_REPORT",
	GET_TRANSACTION_REPORT: "GET_TRANSACTION_REPORT",
	SEND_MAIL: "SEND_MAIL",
};

export const ACCOUNTING = {
	GET_ALL_ADDRESS_LIST: "GET_ALL_ADDRESS_LIST",
	GET_ALL_TRANSACTION_LIST: "GET_ALL_TRANSACTION_LIST",
	GET_ALL_INCOME_LIST: "GET_ALL_INCOME_LIST",
	SET_TRANSACTION_FILTER: "SET_TRANSACTION_FILTER",
	SET_INCOME_FILTER: "SET_INCOME_FILTER",
	TRANSACTION_EXPORT: "TRANSACTION_EXPORT",
	INCOME_EXPORT: "INCOME_EXPORT",
};

export const PROPERTIES = {
	GET_ALL_PROPERTY_LIST: "GET_ALL_PROPERTY_LIST",
	PROPERTY_EXPORT: "PROPERTY_EXPORT",
	SET_PROPERTY_FILTER: "SET_PROPERTY_FILTER",
	GET_BUYER_SELLER_LIST: "GET_BUYER_SELLER_LIST",
	GET_ESCROW_NUMBER: "GET_ESCROW_NUMBER",
};

export const TEMPLATES = {
	GET_TEMPLATE_LIST: "GET_TEMPLATE_LIST",
	SET_FILTER: "SET_TEMPLATE_FILTER",
	SET_TEMPLATE_FORM_INPUT: "SET_TEMPLATE_FORM_INPUT",
	CREATE_TEMPLATE: "CREATE_TEMPLATE",
	UPDATE_TEMPLATE: "UPDATE_TEMPLATE",
	DELETE_TEMPLATE: "DELETE_TEMPLATE",
	GET_PLACEHOLDER_MENU: "GET_PLACEHOLDER_MENU",
	COPY_TEMPLATE: "COPY_TEMPLATE",
};
export const NOTIFICATION = {
	GET_UNREAD_NOTIF_COUNT: "GET_UNREAD_NOTIF_COUNT",
	FETCH_NOTIFICATION: "FETCH_NOTIFICATION",
	FETCH_NOTIFICATION_LIST: "FETCH_NOTIFICATION_LIST",
	UPDATE_NOTIFICATION: "UPDATE_NOTIFICATION",
	DELETE_NOTIFICATION: "DELETE_NOTIFICATION",
	SET_FILTER: "SET_NOTIFICATION_FILTER",
	SET_FLAG: "SET_NOTIFICATION_FLAG",
};
