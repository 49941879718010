import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { update } from "lodash";

import Dashboard from "./dashboardReducer";
import Auth from "./authReducer";
import UI from "./uiReducer";
import User from "./userReducer";
import Settings from "./settingsReducer";
import Common from "./commonReducer";
import Task from "./taskReducer";
import Fees from "./feeReducer";
import Bank from "./bankReducer";
import Entity from "./entityReducer";
import Exchange from "./exchangeReducer";
import Accounting from "./accountingReducer";
import Properties from "./propertiesReducer";
import Template from "./templateReducer";

import { COMMON } from "../types";
import Notification from "./notificationReducer";

const allReducers = combineReducers({
	form: formReducer,
	Dashboard,
	Auth,
	UI,
	User,
	Settings,
	Common,
	Task,
	Fees,
	Bank,
	Entity,
	Exchange,
	Accounting,
	Properties,
	Template,
	Notification,
});

const rootReducer = (state, action) => {
	let newState = { ...state };
	switch (action.type) {
		case COMMON.RESET_FIELD:
			if (action.key) {
				newState = update(state, action.key, () => action.value);
			}
			return {
				...newState,
			};
		default:
		// do nothing;
	}
	return allReducers(newState, action);
};

export default rootReducer;
