import React from "react";
import { Modal } from "react-bootstrap";
import { Heading } from "./index";

const Prompt = ({ children, show, title = null, message = null, onYes, onNo, positiveText, negativeText }) => {
	return (
		<Modal className="confirm-modal" backdrop="static" centered show={show ? true : false} onHide={onNo}>
			<Modal.Header>
				<Heading title={title ? title : "Confirm delete"} />
			</Modal.Header>

			<Modal.Body>{children}</Modal.Body>
			<Modal.Footer className="confirm-footer">
				<button type="button" className="form-button-light  mr-2 btn btn-primary" onClick={onNo}>
					{negativeText ? negativeText : "No"}
				</button>
				<button type="button" className="form-button-primary btn btn-primary" onClick={onYes}>
					{positiveText ? positiveText : "Yes"}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default Prompt;
