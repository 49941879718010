import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { get } from "lodash";
import { getFormValues } from "redux-form";

import Layout from "../../SharedComponts/Layout";
import Confirm from "../../SharedComponts/FormElements/confirm";
import { Table, Pagination } from "../../SharedComponts/FormElements/table";
import { columns as columnsFn } from "./helper";
import {
	setExchangerFilter,
	getUsers,
	showLoader,
	createExchanger,
	getExchanger,
	updateExchanger,
	getCounties,
	getStates,
	getCities,
	relatedExchangesExists,
	deleteUser,
	deleteEntity,
} from "../../../store/actions";

import SearchField from "../../SharedComponts/FormElements/searchTable";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";
import { userTypes } from "../../../constant/common";
import { getRoleID, getRole, getExchangerTypeId, getStatus } from "../../../utils/helper";
import Details from "./Details";
import AddEdit from "./AddEdit";

import "../admin.scss";
import "../userdetail.scss";

const AdminList = ({
	doSetFilter,
	filters,
	userDetails,
	doGetUsers,
	recordsTotal,
	userList,
	doShowLoader,
	doCreateExchanger,
	doGetExchanger,
	doUpdateExchanger,
	doGetCounties,
	doGetStates,
	doGetCities,
	formValues,
	getRelatedExchangesExists,
	doDeleteUser,
	cardTotalCount,
	doDeleteEntity,
}) => {
	const [isList, setIsList] = useState(true);
	const [showPopup, setshowPopup] = useState(false);
	const [isEdit, setIsEdit] = useState(true);
	const [currentRecord, setCurrentRecord] = useState({});
	const [listReload, setListReload] = useState(false);
	const [relatedExchangesExistsConfirm, setRelatedExchangesExistsConfirm] = useState(false);
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [currentDeleteRecord, setCurrentDeleteRecord] = useState(null);

	const handleClose = () => setshowPopup(false);
	const handleShow = () => setshowPopup(true);

	const handleSubmit = async (value) => {
		doShowLoader(true);

		if (currentRecord.id && isEdit) {
			const successCB = (result) => {
				doShowLoader(false);

				result.result ? setRelatedExchangesExistsConfirm(true) : confirmUpdate(false);
			};
			const failCB = (result) => {
				doShowLoader(false);
				confirmUpdate(false);
			};
			getRelatedExchangesExists({ id: currentRecord.userId }, successCB, failCB);
		}

		const successCB = (result) => {
			doShowLoader(false);
			successMessage(!isEdit ? 203 : 204);
			setListReload(!listReload);
			handleClose();
			!isEdit && setIsList(true);
		};
		const failCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};
		const postData = {
			...value,
			userRoleId: !isEdit ? getRole("Exchanger") : currentRecord.userRoleId,
			exchangerType: { value: getExchangerTypeId("Individual"), label: "Individual" },
			status: !isEdit ? getStatus("Active") : value.status,
		};
		(!currentRecord.id || !isEdit) && doCreateExchanger(postData, successCB, failCB);
	};

	const confirmUpdate = (isUpdate) => {
		doShowLoader(true);
		setRelatedExchangesExistsConfirm(false);
		const postData = {
			...formValues,
			userRoleId: !isEdit ? getRole("Exchanger") : currentRecord.userRoleId,
			exchangerType: { value: getExchangerTypeId("Individual"), label: "Individual" },
			address: { ...formValues.address, isUpdateRelatedAddress: isUpdate },
		};
		const successCB = (result) => {
			doShowLoader(false);
			successMessage(!isEdit ? 203 : 204);
			setListReload(!listReload);
			handleClose();
			!isEdit && setIsList(true);
			!isList && onSelectUser({ exchangerId: postData.id });
		};
		const failCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};
		doUpdateExchanger(postData, successCB, failCB);
	};

	const confirmDeleteAction = () => {
		doShowLoader(true);
		const failCB = (result) => {
			doShowLoader(false);
			setCurrentDeleteRecord(null);
			setConfirmDelete(false);
			errorMessage(result);
		};
		const successCB = (result) => {
			doShowLoader(false);
			setCurrentDeleteRecord(null);
			setConfirmDelete(false);
			successMessage(219);
			setListReload(!listReload);
		};
		if (currentDeleteRecord.exchangerType === "Individual") {
			doDeleteUser(currentDeleteRecord.id, successCB, failCB);
		} else {
			doDeleteEntity(currentDeleteRecord.id, successCB, failCB);
		}
	};

	useEffect(() => {
		doShowLoader(true);
		const backendFilter = { ...filters, "ExtraParams.RoleId": getRoleID("Exchanger") };
		const successCB = (result) => {
			doShowLoader(false);
		};
		const failCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		userDetails.id && doGetUsers(backendFilter, successCB, failCB);
	}, [filters, userDetails, doGetUsers, doShowLoader, listReload]);

	useEffect(() => {
		doGetStates();
	}, [doGetStates]);

	const loggedInAs = (type) => {
		return userDetails.userType === userTypes[type];
	};

	const onSelectUser = (row) => {
		doShowLoader(true);
		const successCB = (e) => {
			setCurrentRecord(e.result);
			doShowLoader(false);
			setIsList(false);
		};
		const failCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		doGetExchanger(row.exchangerId, successCB, failCB);
	};
	const onEditUser = (row) => {
		doShowLoader(true);
		const successCB = (e) => {
			setIsEdit(true);
			setCurrentRecord(e.result);
			doShowLoader(false);
			handleShow();
		};
		const failCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		doGetExchanger(row.exchangerId, successCB, failCB);
	};
	const onDeleteUser = (row) => {
		setCurrentDeleteRecord(row);
		setConfirmDelete(true);
	};

	const addUser = () => {
		setIsEdit(false);
		setCurrentRecord({});
		handleShow();
	};

	const loadCounties = (searchKey, callback) => {
		const successCB = ({ result }) => {
			callback(result);
		};
		const FailCB = (result) => {
			callback([]);
		};
		if (searchKey.length > 1) {
			doGetCounties(searchKey, successCB, FailCB);
		} else {
			callback([]);
		}
	};

	const rightActionButtons = [
		{
			component: () => {
				return (
					<Button className="form-button-primary" onClick={addUser}>
						<span className="add-new-plus">+</span> New Exchanger
					</Button>
				);
			},
		},
	];
	const columns = columnsFn({
		onSelectUser,
		onEditUser,
		onDeleteUser,
	});
	return loggedInAs("SA") ? (
		<>
			{isList ? (
				<Layout
					title="Exchangers"
					breadcrumbs={[{ label: "Exchanger Listing" }]}
					rightActionButtons={rightActionButtons}
					className="user-list user-listing-page">
					<>
						<SearchField
							initialValue={filters.searchKey}
							onSearch={(searchKey) =>
								doSetFilter({
									...filters,
									searchKey,
									pageIndex: 1,
								})
							}
							className={`flex-fill mb-0`}
							placeholder="Enter your query"
						/>
						<Table
							columns={columns}
							total={cardTotalCount}
							data={userList}
							onSort={(e) =>
								e.by !== filters.sortColumn || e.order !== filters.sortDirection
									? doSetFilter({
											...filters,
											sortColumn: e.by,
											sortDirection: e.order,
											pageIndex: 1,
									  })
									: null
							}
							sortBy={filters.sortColumn}
							sortDir={filters.sortDirection}
						/>
						<Pagination
							total={recordsTotal}
							count={filters.pageSize}
							page={filters.pageIndex}
							onChange={(e) => {
								doSetFilter({ ...filters, pageIndex: e });
							}}
							onPageCountChange={(e) => {
								doSetFilter({ ...filters, pageSize: e, pageIndex: 1 });
							}}
						/>
					</>
				</Layout>
			) : (
				<Details
					user={currentRecord}
					breadcrumbs={[
						{
							label: "Exchanger Listing",
							onClick: () => {
								setIsList(true);
							},
						},
						{ label: "Exchanger Details" },
					]}
					initialValues={currentRecord}
					setIsList={setIsList}
					showEdit={handleShow}
					setIsEdit={setIsEdit}
				/>
			)}
			{showPopup ? (
				<AddEdit
					loadCounties={loadCounties}
					show={showPopup}
					initialValues={currentRecord}
					isEdit={isEdit}
					handleClose={handleClose}
					onSubmit={handleSubmit}
					doGetCities={doGetCities}
					doShowLoader={doShowLoader}
				/>
			) : null}
			<Confirm
				message={`There are active or new exchanges related to this address, Do you want to continue?`}
				title={`Confirm`}
				onYes={() => confirmUpdate(true)}
				onNo={(e) => {
					handleClose();
					setRelatedExchangesExistsConfirm(false);
				}}
				show={relatedExchangesExistsConfirm ? true : false}
			/>
			<Confirm
				onYes={confirmDeleteAction}
				onNo={(e) => {
					setConfirmDelete(false);
				}}
				show={confirmDelete ? true : false}
			/>
		</>
	) : null;
};

const mapStateToProps = (state) => {
	return {
		userList: get(state, "User.userList.data", null),
		filters: get(state, "User.exchangerFilter", {}),
		userDetails: get(state, "Auth.userDetails", {}),
		recordsTotal: get(state, "User.userList.recordsTotal", 0),
		cardTotalCount: get(state, "User.userList.additionalParam.totalCount", 0),
		formValues: getFormValues("ExchangerForm")(state),
	};
};
const mapDispatchToProps = {
	doSetFilter: setExchangerFilter,
	doGetUsers: getUsers,
	doShowLoader: showLoader,
	doCreateExchanger: createExchanger,
	doGetExchanger: getExchanger,
	doUpdateExchanger: updateExchanger,
	doGetCounties: getCounties,
	doGetStates: getStates,
	doGetCities: getCities,
	doDeleteUser: deleteUser,
	getRelatedExchangesExists: relatedExchangesExists,
	doDeleteEntity: deleteEntity,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminList);
