import React from "react";
import Tooltip from "../ToolTip";
const renderField = ({ value = true, onChange, isDisabled = false, tooltip = "" }) => {
	return tooltip !== "" ? (
		<Tooltip
			message={tooltip}
			component={
				<div
					className={`custom-toggle-btn  ${value ? "active" : ""} `}
					onClick={isDisabled ? null : () => onChange(!value)}>
					<span className="toggle-btn" />
				</div>
			}
		/>
	) : (
		<div className={`custom-toggle-btn ${value ? "active" : ""} `} onClick={isDisabled ? null : () => onChange(!value)}>
			<span className="toggle-btn" />
		</div>
	);
};
export default renderField;
