import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import "./dropzone.scss";
import { Button } from "react-bootstrap";
import Tooltip from "../ToolTip";

const Dropzone = (props) => {
	const {
		input: { onChange },
		multiple = false,
		accept = null,
		maxSize = null,
		rejectedFileMessage = null,
		meta: { touched, error, warning },
	} = props;

	const [files, setFiles] = useState([]);
	const [rejectedfiles, setRejectedfiles] = useState([]);
	useEffect(() => {
		onChange(files);
	}, [files, onChange]);

	const onDrop = useCallback(
		(acceptedFiles) => {
			setFiles([...acceptedFiles, ...files]);
		},
		[files]
	);
	const onDropRejected = useCallback(
		(rejectedFiles) => {
			setRejectedfiles([...rejectedFiles, ...rejectedfiles]);
		},
		[rejectedfiles]
	);

	const { getRootProps, acceptedFiles, inputRef, getInputProps, isDragActive } = useDropzone({
		onDrop,
		onDropRejected,

		maxSize,
		multiple,
		accept,
	});

	const deleteFile = (index) => {
		files.splice(index, 1);
		acceptedFiles.length && acceptedFiles.splice(index, 1);

		if (acceptedFiles.length === 0) {
			inputRef.current.value = "";
		}
		setFiles([...files]);
	};

	let customError = null;

	const fileErrorMessage = (error) => {
		// console.log(error);
		switch (error) {
			case "file-too-large":
				customError = "file size too large";
				break;
			case "file-invalid-type":
				customError = "invalid file type";
				break;
			default:
				customError = "invalid file";
				break;
		}
	};

	return (
		<div className="dropzone-wrap">
			<div className="dropzone" {...getRootProps()}>
				<input {...getInputProps()} />
				{isDragActive ? (
					<p>Drag the files here ...</p>
				) : (
					<>
						<p>Drag and Drop</p>
						<p>or</p>
						{isDragActive && ""}
						<Button className="btn-browse">Browse</Button>
						{maxSize && (
							<p className="note">
								<i className="fa fa-info-circle" aria-hidden="true"></i>
								{` Please ensure that the file type is 
								${accept} and not more than ${maxSize / 1024000} Mb.`}
							</p>
						)}
					</>
				)}
			</div>
			{touched && ((error && <span className="form-error-span">{error}</span>) || (warning && <span>{warning}</span>))}

			<div className="file-list pt-2">
				{files.map(({ name }, index) => (
					<div className="file" key={index}>
						<div className="file-item">
							<span className="icon-notes"></span>
							<span className="file-name">
								<Tooltip message={name} component={<span className="breadcrumbs-link">{name}</span>} />
							</span>
							<span className="delete-icon" onClick={() => deleteFile(index)}>
								<span className="icon-icon-delete" />
							</span>
						</div>
					</div>
				))}

				{rejectedfiles.map(({ file: { name }, errors }, index) => {
					fileErrorMessage(errors[0].code);
					return (
						<div className="file" key={index}>
							<div className="file-item rejected-item">
								<span className="icon-notes"></span>
								<span className="file-name">
									<Tooltip message={name} component={<span className="breadcrumbs-link">{name}</span>} />{" "}
								</span>
								<span className="file-name">
									<Tooltip
										message={
											rejectedFileMessage
												? rejectedFileMessage
												: customError
												? customError
												: errors[0].message
										}
										component={
											<span className="breadcrumbs-link">
												{rejectedFileMessage
													? rejectedFileMessage
													: customError
													? customError
													: errors[0].message}
											</span>
										}
									/>
								</span>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};
export default Dropzone;
