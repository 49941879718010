import FormControl from "../../../SharedComponts/FormControl";
import {
	normalizeSSN,
	normalizePhone,
	required,
	email,
	USZipCode,
	normalizeNumber,
	percent,
} from "../../../../constant/formValidators";

import { getRoleID } from "../../../../utils/helper";
export const basicInfoFormFields = ({
	loadContacts,
	exchangerType,
	showContactPopup,
	popupUserDetails,
	saveValues,
	isLocked = false,
}) => {
	return [
		{
			name: "contactInformation.contactName",
			label: "Contact Name",
			component: FormControl,
			accessor: "contactInformation.contactName",
			type: "asyncSelect",
			loadOptions: loadContacts,
			className: "form-control form-field",
			isEdit: true,
			isEditable: exchangerType && exchangerType.label !== "Individual" && !isLocked,
			addable: true,
			addLabel: "Add Contact",
			onChange: (e) => {
				if (e && e.value) {
					popupUserDetails(e.value);
					saveValues([{ key: "contactInformation.contactName", value: e }]);
				}
			},
			addAction: () => {
				showContactPopup();
			},
		},
		{
			name: "contactInformation.title",
			label: "Title",
			accessor: "contactInformation.title",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			isEdit: true,
			isEditable: false,
			onBlur: () => {
				saveValues();
			},
		},
		{
			name: "spouseOrVesting",
			label: "Spouse / Vesting",
			accessor: "spouseOrVesting",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			isEdit: true,
			isEditable: !isLocked,
			onBlur: () => {
				saveValues();
			},
		},
		{
			name: "percentageOwnership",
			accessor: "percentageOwnership",
			label: "Percent Ownership (%)",
			component: FormControl,
			className: "form-control form-field",
			type: "text",
			isEdit: true,
			isEditable: !isLocked,
			normalize: normalizeNumber,
			onBlur: () => {
				saveValues();
			},
			validate: [percent],
		},
		{
			name: "ssnOrTaxId",
			accessor: "ssnOrTaxId",
			label: "SSN/ Tax Id",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			normalize: normalizeSSN,
			isEdit: true,
			isEditable: !isLocked,
			readOnly: true,
			onBlur: () => {
				saveValues();
			},
		},
		{
			name: "attention",
			accessor: "attention",
			label: "Attention",
			component: FormControl,
			className: "form-control form-field",
			type: "text",
			isEdit: true,
			isEditable: !isLocked,
			onBlur: () => {
				saveValues();
			},
		},
	];
};
export const addressInfoForm = (isEdit = true, states, cities, doGetCities, change, loadCounties, isEditable = true) => {
	return [
		{
			name: "addressInformation.streetAddress",
			accessor: "addressInformation.streetAddress",
			label: "Address",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			isEdit,
			isEditable,
		},
		{
			name: "addressInformation.state",
			accessor: "addressInformation.state",
			label: "State",
			component: FormControl,
			type: "select",
			options: states,
			className: "form-control form-field",
			onChange: (selected) => {
				doGetCities(selected.value);
				change("addressInformation.city", null);
			},
			isEdit,
			isEditable,
		},
		{
			name: "addressInformation.city",
			accessor: "addressInformation.city",
			label: "City",
			options: cities,
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			isEdit,
			isEditable,
		},
		{
			name: "addressInformation.zipCode",
			accessor: "addressInformation.zipCode",
			label: "Zip Code",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			isEdit,
			isEditable,
			validate: [USZipCode],
		},
	];
};
export const contactInfoForm = ({ exchangerType, formValues, saveValues, isLocked }, isEdit = true, isEditable = true) => {
	return [
		{
			name: "contactInformation.primaryPhoneNumber",
			accessor: "contactInformation.primaryPhoneNumber",
			label: "Phone 1",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			isEdit,
			isEditable:
				formValues &&
				formValues.contactInformation &&
				formValues.contactInformation.contactName &&
				exchangerType &&
				exchangerType.label !== "Individual" &&
				!isLocked,
			normalize: normalizePhone,
			onBlur: () => {
				saveValues();
			},
		},
		{
			name: "contactInformation.secondaryPhoneNumber",
			accessor: "contactInformation.secondaryPhoneNumber",
			label: "Phone 2",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			isEdit,
			isEditable:
				formValues &&
				formValues.contactInformation &&
				formValues.contactInformation.contactName &&
				exchangerType &&
				exchangerType.label !== "Individual" &&
				!isLocked,
			normalize: normalizePhone,
			onBlur: () => {
				saveValues();
			},
		},
		{
			name: "contactInformation.fax",
			accessor: "contactInformation.fax",
			label: "Fax",
			component: FormControl,
			type: "text",
			isEditable:
				formValues &&
				formValues.contactInformation &&
				formValues.contactInformation.contactName &&
				exchangerType &&
				exchangerType.label !== "Individual" &&
				!isLocked,
			className: "form-control form-field",
			isEdit,
			onBlur: () => {
				saveValues();
			},
		},
		{
			name: "contactInformation.emailAddress",
			accessor: "contactInformation.emailAddress",
			label: "Email",
			component: FormControl,
			className: "form-control form-field",
			type: "text",
			validate: [email],
			isEditable:
				formValues &&
				formValues.contactInformation &&
				formValues.contactInformation.contactName &&
				exchangerType &&
				exchangerType.label !== "Individual" &&
				!isLocked,
			isEdit,
			onBlur: () => {
				saveValues();
			},
		},
		{
			name: "contactInformation.additionalEmail",
			accessor: "contactInformation.additionalEmail",
			label: "Additional Email",
			component: FormControl,
			className: "form-control form-field",
			type: "text",
			validate: [email],
			isEditable:
				formValues &&
				formValues.contactInformation &&
				formValues.contactInformation.contactName &&
				exchangerType &&
				exchangerType.label !== "Individual" &&
				!isLocked,
			isEdit,
			onBlur: () => {
				saveValues();
			},
		},
	];
};

export const formFieldsContact = (exchangerType, isEdit = true, isEditable = true) => {
	return [
		{
			name: "contactName",
			label: "Contact Name",
			component: FormControl,
			accessor: "contactInformation.contactName",
			type: "text",
			className: "form-control form-field",
			validate: [required],
			required: true,
		},
		{
			name: "title",
			label: "Job Title",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			validate: [required],
			required: true,
		},
		{
			name: "primaryPhoneNumber",

			label: "Phone 1",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			isEdit,
			isEditable: exchangerType && exchangerType.label !== "Individual",
			normalize: normalizePhone,
		},
		{
			name: "secondaryPhoneNumber",
			label: "Phone 2",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			isEdit,
			isEditable: exchangerType && exchangerType.label !== "Individual",
			normalize: normalizePhone,
		},
		{
			name: "fax",
			accessor: "contactInformation.fax",
			label: "Fax",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
		},
		{
			name: "emailAddress",
			label: "Email",
			component: FormControl,
			className: "form-control form-field",
			type: "text",
			validate: [email],
		},
		{
			name: "AdditionalEmail",
			label: "Additional Email",
			component: FormControl,
			className: "form-control form-field",
			type: "text",
			validate: [email],
		},
	];
};
export const coExchangerFormFields = (
	{ coExchanger, loadUsers, saveValues, change, populateCoexchanger, isLocked = false },
	isEdit = true,
	isEditable = !isLocked
) => {
	return [
		{
			name: `${coExchanger}.name`,
			accessor: `name`,
			label: "Name",
			type: "asyncCreatableSelect",
			className: "form-control form-field",
			loadOptions: (searchKey, callback) => loadUsers(getRoleID("Co-Exchanger"), searchKey, callback),
			component: FormControl,
			isEdit,
			isEditable,
			onChange: (val) => {
				populateCoexchanger(coExchanger, val);
				if (val.label === val.value) {
					const keyValues = [
						{ key: `${coExchanger}.name`, value: { label: val.label, value: 0 } },
						{ key: `${coExchanger}.phoneNumber`, value: null },
						{ key: `${coExchanger}.emailAddress`, value: null },
						{ key: `${coExchanger}.ssnOrTaxId`, value: null },
					];
					saveValues(keyValues, false, true);
				}
				change(`${coExchanger}.phoneNumber`, null);
				change(`${coExchanger}.emailAddress`, null);
				change(`${coExchanger}.ssnOrTaxId`, null);
			},
		},
		{
			name: `${coExchanger}.phoneNumber`,
			accessor: `phoneNumber`,
			label: "Phone",
			component: FormControl,
			normalize: normalizePhone,
			type: "text",
			className: "form-control form-field",
			isEdit,
			isEditable,
			onBlur: () => {
				saveValues();
			},
		},

		{
			name: `${coExchanger}.emailAddress`,
			accessor: `emailAddress`,
			label: "Email",
			component: FormControl,
			className: "form-control form-field",
			type: "text",
			isEdit,
			isEditable,
			validate: [email],
			onBlur: () => {
				saveValues();
			},
		},
		{
			name: `${coExchanger}.ssnOrTaxId`,
			accessor: `ssnOrTaxId`,
			label: "SSN",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			isEdit,
			normalize: normalizeSSN,
			isEditable,
			onBlur: () => {
				saveValues();
			},
		},
	];
};
export const teamInfoForm = (
	{ loadUsers, qiCompanies, change, saveValues, isLocked = false },
	isEdit = true,
	isEditable = !isLocked
) => {
	return [
		{
			name: "teamInformation.coordinatorName",
			accessor: "teamInformation.coordinatorName",
			label: "Coordinator Name",
			component: FormControl,
			type: "asyncSelect",
			loadOptions: (searchKey, callback) => loadUsers(getRoleID("Admin"), searchKey, callback),
			className: "form-control form-field",
			isEdit,
			onChange: (val) => {
				change("teamInformation.coordinatorTitle", val.title);
				saveValues([{ key: "teamInformation.coordinatorName", value: val }]);
			},
			isEditable,
		},
		{
			name: "teamInformation.coordinatorTitle",
			accessor: "teamInformation.coordinatorTitle",
			label: "Coordinator Title",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			isEdit,
			readOnly: true,
			isEditable: true,
		},
		{
			name: "teamInformation.officerName",
			label: "Officer Name",
			accessor: "teamInformation.officerName",
			component: FormControl,
			type: "asyncSelect",
			loadOptions: (searchKey, callback) => loadUsers(getRoleID("Admin"), searchKey, callback),
			onChange: (val) => {
				change("teamInformation.officerTitle", val.title);
				saveValues([{ key: "teamInformation.officerName", value: val }]);
			},
			className: "form-control form-field",
			isEdit,
			isEditable,
		},
		{
			name: "teamInformation.officerTitle",
			accessor: "teamInformation.officerTitle",
			label: "Officer Title",
			component: FormControl,
			type: "text",
			readOnly: true,
			className: "form-control form-field",
			isEdit,
			isEditable: true,
		},
		{
			name: "teamInformation.salesRepName",
			accessor: "teamInformation.salesRepName",
			label: "Sales  Rep Name",
			loadOptions: (searchKey, callback) => loadUsers(getRoleID("Sales Rep"), searchKey, callback),
			component: FormControl,
			className: "form-control form-field",
			type: "asyncSelect",
			isEdit,
			isEditable,
			onChange: (val) => {
				saveValues([{ key: "teamInformation.salesRepName", value: val }]);
			},
		},
		{
			name: "teamInformation.exchangeCompanyName",
			label: "Exchange Company",
			accessor: "teamInformation.exchangeCompanyName",
			component: FormControl,
			className: "form-control form-field",
			options: qiCompanies,
			type: "select",
			isEdit,
			isEditable,
			onChange: (val) => {
				saveValues([{ key: "teamInformation.exchangeCompanyName", value: val }]);
			},
		},
	];
};
