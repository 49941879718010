import React from "react";

import { Date, Currency } from "../../utils/formatter";
import { dateFormat } from "../../constant/common";

import FormControl from "../SharedComponts/FormControl";

export const columns = ({ history }) => [
	{
		name: "Property Address",
		accessor: "propertyAddress",
		className: "col-propertyAddress",
		render: (row) => <span className={row.propertyDeleted ? "ppty-deleted" : ""}>{row.propertyAddress}</span>,
	},
	{
		name: "Billed To",
		accessor: "toOrFrom",
		className: "col-toOrFrom",
	},
	{
		name: "Type",
		accessor: "type",
		className: "col-type",
	},
	{
		name: "Escrow #",
		accessor: "escrowNumber",
		className: "col-escrowNumber",
	},
	{
		name: "Exchange #",
		accessor: "exchangeNumber",
		className: "col-exchangeNumber",
		render: (row) => (
			<div
				className="text-highlighted"
				onClick={() => {
					history.push(`/exchanges/${row.guid}`);
				}}>
				{row.exchangeNumber}
			</div>
		),
	},
	{
		name: "Category",
		accessor: "category",
		className: "col-category",
	},
	{
		name: "Amount",
		accessor: "amount",
		className: "col-amount",
		render: (row) => (
			<span className={`fee-${row.category} fee-${row.status}`}>
				<Currency amount={row.amount} />
			</span>
		),
	},
	{
		name: "Status",
		accessor: "status",
		className: "col-status",
		render: (row) => <span className={`status-card status-${row.status}`}>{row.status}</span>,
	},
	{
		name: "Due Date",
		accessor: "dueDate",
		className: "col-dueDate",
		render: (row) => <Date utcTime={row.dueDate} format={dateFormat} noConvert={true} />,
	},
	{
		name: "Payment Date",
		accessor: "paidDate",
		className: "col-paidDate",
		render: (row) => <Date utcTime={row.paidDate} format={dateFormat} noConvert={true} />,
	},
];

export const advancedFilterFormFields = (exchangeNumberList, addressList, formValues, paymentStatus) => {
	return [
		{
			name: "property",
			label: "Property Address",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			options: addressList,
			onChange: (selected) => {},
		},
		{
			name: "exchangeNumber",
			label: "Exchange Number",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			options: exchangeNumberList,
			onChange: (selected) => {},
		},
		{
			name: "status",
			label: "Status",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			options: paymentStatus,
			onChange: (selected) => {},
		},
		{
			name: "fromDate",
			label: "Due From Date",
			type: "date",
			maxDate: formValues && formValues.toDate,
			component: FormControl,
			className: "form-control form-field",
		},
		{
			name: "toDate",
			label: "Due To Date",
			type: "date",
			component: FormControl,
			className: "form-control form-field",
			minDate: formValues && formValues.fromDate,
		},
	];
};
