import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import Slider from "../../SharedComponts/Slider";
import { formFields } from "./helper";
import SliderForm from "../../SharedComponts/SliderForm";
const AddEdit = (props) => {
	const { show, handleClose, handleSubmit, submitting, reset, exchangerList, isEdit, contactRoles } = props;

	return (
		<Slider
			show={show}
			title={isEdit ? "Edit Contact" : "Add Contact"}
			handleClose={() => {
				reset();
				handleClose();
			}}>
			<SliderForm handleSubmit={handleSubmit} submitting={submitting} handleClose={handleClose}>
				{formFields(exchangerList, contactRoles).map((item, index) => {
					return (
						<div key={index} className="form-group">
							<div className="form-lable">
								{item.label}
								{item.required ? <span className="required-label">*</span> : null}
							</div>
							<Field {...item} />
						</div>
					);
				})}
			</SliderForm>
		</Slider>
	);
};
export default connect(
	null,
	null
)(
	reduxForm({
		form: "ContactForm",
		enableReinitialize: true,
	})(AddEdit)
);
