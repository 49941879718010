import React from "react";
import { CurrencyK } from "../../../utils/formatter";
export const columnsTopEscrows = [
	{
		label: "REPRESENTATIVE",
		className: "col-representative",
		accessor: "representative",
		width: "36%",
		rowWidth: "36%",
	},
	{
		label: "EXCHANGE VALUE",
		className: "col-exchange-value",
		width: "32%",
		rowWidth: "32%",
		render: (row) => (
			<div className="value clr-green">{row.exchangeValue ? <CurrencyK amount={row.exchangeValue} /> : "-"}</div>
		),
	},
	{
		label: "NO. OF EXCHANGES",
		className: "col-exchanges-count-value",
		width: "32%",
		rowWidth: "32%",
		render: (row) => <div className="value clr-red">{row.exchangesCount ? row.exchangesCount : "-"}</div>,
	},
];
