import moment from "moment";
import FormControl from "../../SharedComponts/FormControl";
import {
	required,
	USZipCode,
	normalizeSSN,
	USPhoneNumber,
	email,
	max5,
	normalizePhone,
	percent,
	normalizeNumber,
	USTaxid,
	max250,
} from "../../../constant/formValidators";

export const formFields = (
	exchangeType,
	exchangerType,
	exchangeStatus,
	change,
	loadEntities,
	formValues,
	showAddEntityPopup
) => {
	return [
		{
			name: "exchangeType",
			label: "Exchange Type",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			validate: [required],
			required: true,
			options: exchangeType,
			onChange: (selected) => {
				change("exchangerType", exchangerType[0]);
				change("entityName", null);
			},
		},
		{
			name: "exchangerType",
			label: "Exchanger Type",
			component: FormControl,
			type: "select",
			value: {},
			className: "form-control form-field",
			options: exchangerType,
			onChange: (selected) => {
				change("entityName", null);
			},
			validate: [required],
			required: true,
		},
		{
			name: "entityName",
			label: "Entity Name",
			component: FormControl,
			type: "asyncSelect",
			loadOptions: loadEntities,
			className: "form-control form-field",
			addable: true,
			addLabel: "Add Entity",
			addAction: () => {
				showAddEntityPopup(1);
			},
			isDisabled: formValues && formValues.exchangerType && formValues.exchangerType.value ? false : true,
			validate: [required],
			required: true,
		},
		{
			name: "exchangeStatus",
			label: "Status",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			validate: [required],
			required: true,
			options: exchangeStatus,
		},
		{
			name: "rateOrShareQuoted",
			label: "Rate/Share Quoted (%)",
			component: FormControl,
			type: "text",
			normalize: normalizeNumber,
			className: "form-control form-field",
			validate: [percent, max5],
		},
	];
};

export const formFieldsDateSection = ({ change, formValues }) => {
	return [
		{
			name: "openDate",
			label: "Open Date",
			type: "date",
			maxDate: new Date(),
			component: FormControl,
			className: "form-control form-field",
		},
		{
			name: "rel1CloseDate",
			label: "Rel 1 Close Date",
			type: "date",
			component: FormControl,
			disabled: !(formValues && formValues.openDate),
			minDate: formValues && formValues.openDate,
			className: "form-control form-field",
			onChange: (selected) => {
				change("day45", moment(moment(selected).add(45, "days")).toDate());
				change("day180", moment(moment(selected).add(180, "days")).toDate());
			},
			onClear: () => {
				change("rel1CloseDate", null);
			},
		},
		{
			name: "day45",
			label: "45th Day",
			disabled: !(formValues && formValues.rel1CloseDate),
			onChange: (selected) => {
				change("actualDDate", null);
				change("finalCloseDate", null);
			},
			type: "date",
			component: FormControl,
			className: "form-control form-field",
			onClear: () => {
				change("day45", null);
			},
		},
		{
			name: "actualDDate",
			label: "Actual ID Date",
			type: "date",
			disabled: !(formValues && formValues.rel1CloseDate),
			maxDate: formValues && formValues.day45,
			minDate: formValues && formValues.rel1CloseDate,
			component: FormControl,
			className: "form-control form-field",
			onClear: () => {
				change("actualDDate", null);
			},
		},
		{
			name: "day180",
			label: "180th Day",
			type: "date",
			onChange: (selected) => {
				change("finalCloseDate", null);
			},
			disabled: !(formValues && formValues.rel1CloseDate),
			component: FormControl,
			className: "form-control form-field",
			onClear: () => {
				change("day180", null);
			},
		},
		{
			name: "finalCloseDate",
			label: "Final Close Date",
			type: "date",
			maxDate: formValues && formValues.day180,
			minDate: formValues && formValues.rel1CloseDate,
			component: FormControl,
			className: "form-control form-field",
			onClear: () => {
				change("finalCloseDate", null);
			},
		},
	];
};

export const formFieldsEntity = (states, cities, doGetCities, change, loadCounties) => {
	return [
		{
			name: "entityName",
			label: "Entity Name",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			validate: [required],
			required: true,
		},
		{
			name: "address.streetAddress",
			label: "Address",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
		},
		{
			name: "address.state",
			label: "State",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			options: states,
			onChange: (selected) => {
				doGetCities(selected.value);
				change("address.city", null);
			},
		},

		{
			name: "address.city",
			label: "City",
			component: FormControl,
			type: "select",
			options: cities,
			className: "form-control form-field",
		},
		{
			name: "address.county",
			label: "County",
			component: FormControl,
			type: "asyncSelect",
			loadOptions: loadCounties,
			className: "form-control form-field",
		},
		{
			name: "address.zipCode",
			label: "Zip Code",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			validate: [USZipCode],
		},
		{
			name: "taxId",
			label: "SSN/Tax ID",
			component: FormControl,
			validate: [USTaxid],
			type: "text",
			className: "form-control form-field",
		},
	];
};

export const formFieldsEntityIndividual = (states, cites, doGetCities, doShowLoader, change, loadCounties) => {
	return [
		{
			name: "name",
			label: "Exchanger Name",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			validate: [required],
			required: true,
		},
		{
			name: "jobTitle",
			label: "Title",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
		},
		{
			name: "emailAddress",
			label: "Email Address",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			validate: [required, email],
			required: true,
		},
		{
			name: "phoneNumber",
			label: "Phone 1",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			validate: [USPhoneNumber],
			normalize: normalizePhone,
		},
		{
			name: "phoneNumber2",
			label: "Phone 2",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			validate: [USPhoneNumber],
			normalize: normalizePhone,
		},
		{
			name: "ssn",
			label: "SSN",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			normalize: normalizeSSN,
		},
		{
			name: "fax",
			label: "Fax",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
		},
		{
			name: "address.streetAddress",
			label: "Address",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			validate: [max250],
		},
		{
			name: "address.state",
			label: "State",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			options: states,
			onChange: (selected) => {
				doShowLoader(true);
				change("address.city", null);
				doGetCities(
					selected.value,
					() => {
						doShowLoader(false);
					},
					() => {
						doShowLoader(false);
					}
				);
			},
		},
		{
			name: "address.city",
			label: "City",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			options: cites,
		},
		{
			name: "address.zipCode",
			label: "Zip Code",
			component: FormControl,
			type: "text",
			validate: [USZipCode],
			className: "form-control form-field",
		},
		{
			name: "address.county",
			label: "County",
			component: FormControl,
			type: "asyncSelect",
			loadOptions: loadCounties,
			className: "form-control form-field",
		},
	];
};
