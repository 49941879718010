import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Field, reduxForm, getFormValues, change, destroy } from "redux-form";
import { Button } from "react-bootstrap";
import FormControl from "../SharedComponts/FormControl";
import { required, email } from "../../constant/formValidators";

import { MoreOptions, AccordionSlider, NoteButton } from "../SharedComponts/FormElements";
import { Table, Pagination } from "../SharedComponts/FormElements/table";
import UseInfoTable from "../SharedComponts/FormElements/UserInfoTable";
import Timeline from "../SharedComponts/TimeLine";

import { columns as columnsFn, userDataColumn } from "./helper";
import Slider from "../SharedComponts/Slider";
import UserDetails from "./userDetails";

const userList = [
	{ fullName: "Abc", market: "xyzz", emailAddress: "yesss@yopmail.com" },
	{ fullName: "Abvdvc", market: "xyvdvzz", emailAddress: "yesvfvfss@yopmail.com" },
];
const userExchanges = [
	{
		fullName: "Abc",
		phone: "xyzz",
		emailAddress: "yesss@yopmail.com",
		exchanges: [{ no: "123", type: "Delayed" }, { no: "155" }],
	},
	{
		fullName: "Abvdvc",
		phone: "xyvdvzz",
		emailAddress: "yesvfvfss@yopmail.com",
		exchanges: [{ no: "253", type: "Delayed" }],
	},
];
const rowHeadItems = [
	{
		name: "Name",
		accessor: "fullName",
	},
	{
		name: "Phone",
		accessor: "phone",
	},
	{
		name: "email",
		accessor: "emailAddress",
	},
];

const options = [
	{ value: "chocolate", label: "Chocolate", extra: "aaaaa" },
	{ value: "strawberry", label: "Strawberry", extra: "aaaaa" },
	{ value: "vanilla", label: "Vanilla", extra: "aaaaa" },
];

const getaccordionData = () => {
	return <p>Move the mouse over the button to open the dropdown menu.</p>;
};
const accordionData = [
	{ title: "One", content: getaccordionData() },
	{ title: "Tow", content: getaccordionData() },
];

const Form = (props) => {
	const { handleSubmit, formValues } = props;
	const submitLogin = () => {};
	const onEdit = (id) => {
		//console.log(id);
	};

	const onSelectUser = (row) => {
		setCurrentRecord(row);
		setMode(1);
		handleShow(1);
	};
	const onEditUser = (row) => {
		setMode(0);
		setCurrentRecord(row);
		handleShow(1);
	};
	const onDeleteUser = (row) => {};

	const columns = columnsFn({
		onSelectUser,
		onEditUser,
		onDeleteUser,
	});

	const [show, setShow] = useState(false);

	const dispatch = useDispatch();

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const recordsTotal = 100;

	const [filters, setFilters] = useState({
		pageIndex: 1,
		pageSize: 10,
		sortColumn: columns[0].accessor,
		sortDirection: "asc",
		userId: null,
		userType: null,
		searchKey: "",
	});
	const editerMenu = {
		name: "Menu",
		menuList: [
			{
				type: "menuitem",
				text: "User",
				content: "{user}",
			},
			{
				type: "nestedmenuitem",
				text: "Exchange",
				submenus: [
					{
						type: "menuitem",
						text: "Exchange no",
						content: "{no}",
					},
					{
						type: "nestedmenuitem",
						text: "Exchange place",
						submenus: [
							{
								type: "menuitem",
								text: "Exchange no",
								content: "Hi { Recepient name },",
							},
						],
					},
				],
			},
		],
	};

	const [currentRecord, setCurrentRecord] = useState({});
	const [mode, setMode] = useState(0);

	return (
		<>
			<Timeline
				startLabel="Exchange Created"
				endLabel="Closed"
				now={60}
				points={[
					{ position: 10, active: true, label: "R1 Close" },
					{ position: 50, label: "45th Day", labelPosition: 20 },
					{ position: 30, label: "Actual ID Day", active: true, labelPosition: 20 },
					{ position: 90, label: "180th Day", labelPosition: 20 },
				]}
			/>
			<form className="exchanges-form m-4" onSubmit={handleSubmit(submitLogin)}>
				<div className="row w-100">
					<div className="col-12">
						<h2>Sample Form</h2>
						<hr className="heading-hr" />
					</div>

					<div className="row w-100">
						<div className="col-4">
							<div className="col-12">
								<div className="form-group">
									<div className="form-lable">Email Address</div>

									<Field
										name="email"
										component={FormControl}
										type="text"
										className="form-control form-field"
										placeholder="Email"
										validate={[required, email]}
									/>
								</div>
							</div>

							<div className="col-12">
								<div className="form-group">
									<div className="form-lable">Password</div>
									<Field
										name="password"
										label="Password"
										type="password"
										showPassword={true}
										component={FormControl}
										validate={[required]}
									/>
								</div>
							</div>
							<div className="col-12">
								<div className="form-group">
									<div className="form-lable">Match Password</div>
									<Field
										name="password1"
										label="Password"
										type="password"
										showPassword={true}
										component={FormControl}
										validate={[required]}
										matchWith={{ formValues, field: "password" }}
									/>
								</div>
							</div>

							<div className="col-12">
								<div className="form-group">
									<Field
										name="checkbox"
										label="Check Box"
										type="checkbox"
										component={FormControl}
										validate={[required]}
									/>
								</div>
							</div>

							{formValues && formValues.color_multi
								? formValues.color_multi.map((field, key) => (
										<div className="col-12" key={key}>
											<div className="form-group">
												<div className="form-lable">Dyamic Field</div>

												<Field
													name={`email_${key}`}
													component={FormControl}
													type="text"
													className="form-control form-field"
													placeholder="Email"
												/>
											</div>
										</div>
								  ))
								: null}

							<div className="col-12">
								<div className="form-group">
									<div className="form-lable">Select option</div>
									<Field
										name="color"
										label="Color"
										type="select"
										component={FormControl}
										validate={[required]}
										options={options}
										onChange={(value) => {
											dispatch(change("ExchangesForm", "color_multi", []));
										}}
									/>
								</div>
							</div>

							<div className="col-12">
								<div className="form-group">
									<div className="form-lable">Select option multi</div>
									<Field
										name="color_multi"
										label="Color"
										type="select"
										isMulti={true}
										component={FormControl}
										options={options}
										validate={[required]}
										onChange={(value) => {
											//console.log(value);
											dispatch(destroy("ExchangesForm", "email_1"));
										}}
									/>
								</div>
							</div>

							<div className="col-12">
								<div className="form-group">
									<div className="form-lable">Select Date</div>
									<Field
										name="date"
										label="Color"
										type="date"
										component={FormControl}
										validate={[required]}
									/>
								</div>
							</div>

							<div className="col-12">
								<div className="form-group">
									<Button className="form-button-primary w-100 inactive" type="submit">
										In Active Submit
									</Button>
								</div>
							</div>

							<div className="col-12">
								<div className="form-group">
									<Button className="form-button-secondary w-100" type="submit">
										+ Add Property
									</Button>
								</div>
							</div>
							<div className="col-12">
								<div className="form-group">
									<Button className="form-button-light w-100" type="submit">
										+ Add Property
									</Button>
								</div>
							</div>

							<div className="col-12">
								<div className="form-group">
									<Button className="form-button-secondary w-100 inactive" type="submit">
										+ Add Property Inactive
									</Button>
								</div>
							</div>

							<div className="col-12">
								<div className="form-group">
									<Button className="form-button-secondary w-100 small" type="submit">
										+ Add Property
									</Button>
								</div>
							</div>

							<div className="col-12">
								<Button className="form-button-primary w-100" onClick={handleShow}>
									Submit
								</Button>
							</div>
						</div>

						<div className="col-8">
							<div className="row">
								<div className="col-2">
									<NoteButton
										onClick={() => {
											//console.log("On click");
										}}
									/>
								</div>

								<div className="col-2">
									<div className="form-group">
										<MoreOptions
											options={[
												{ label: "Edit", onClick: () => onEdit(123) },
												{ label: "Delete", onClick: () => {} },
											]}
										/>
									</div>
								</div>

								<div className="col-2">
									<div className="form-group">
										<div className="link">Link</div>
									</div>
								</div>
							</div>

							<div className="row mt-4">
								<div className="col-12">
									{accordionData.map(({ title, content }, index) => (
										<AccordionSlider
											onClick={(index) => {
												//console.log(index);
											}}
											key={index}
											{...{ title, content, index }}
										/>
									))}
								</div>
							</div>

							<div className="row mt-4">
								{userList && userList.length ? (
									<div>
										<Table
											columns={columns}
											data={userList}
											onSort={(e) =>
												e.by !== filters.sortColumn || e.order !== filters.sortDirection
													? setFilters({
															...filters,
															sortColumn: e.by,
															sortDirection: e.order,
															pageIndex: 1,
													  })
													: null
											}
										/>
										<Pagination
											total={recordsTotal}
											count={filters.pageSize}
											page={filters.pageIndex}
											onChange={(e) => {
												setFilters({ ...filters, pageIndex: e });
											}}
										/>
									</div>
								) : null}
							</div>
							<div className="row mt-4" style={{ background: "white" }}>
								<UseInfoTable
									rowHeadItems={rowHeadItems}
									columns={userDataColumn}
									data={userExchanges}
									dataAccessor="exchanges"
								/>
							</div>
						</div>
					</div>
				</div>

				<Field
					onChange={(data) => {
						// console.log(data);
					}}
					name="doc"
					component={FormControl}
					type="editor"
					menu={editerMenu}
					className="form-control form-field"
				/>
			</form>

			<Slider show={show} handleClose={handleClose}>
				<UserDetails initialValues={currentRecord} mode={mode} currentRecord={currentRecord} />
			</Slider>
		</>
	);
};

const mapStateToProps = (state) => ({
	formValues: getFormValues("ExchangesForm")(state),
});

export default connect(
	mapStateToProps,
	null
)(
	reduxForm({
		form: "ExchangesForm",
		keepDirtyOnReinitialize: true,
		enableReinitialize: true,
		destroyOnUnmount: true,
		forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
	})(Form)
);
