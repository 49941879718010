import React, { useEffect, useState } from "react";
import SideMenu from "./SideMenu";
import "./layout.scss";
import { logout } from "../../../utils/helper";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import { useHistory } from "react-router-dom";
import avatar from "../../../assets/images/avatar-1.png";
import NotificationDropdown from "../NotificationDropdown";

import Select from "react-select/async";

import { exchangeNumbersSearch } from "../../../store/actions";

const Layout = (props) => {
	const { title, className, rightActionButtons, breadcrumbs, unreadNotifCount, doExchangeNumbersSearch } = props;
	const [show, setShow] = useState(false);
	const [showNotif, setShowNotif] = useState(false);
	const [notifCount, setNotifCount] = useState(0);

	const toggleSlide = () => {
		setShowNotif(false);
		setShow(!show);
	};

	const toggleNotif = () => {
		setShow(false);
		setShowNotif(!showNotif);
	};

	const history = useHistory();

	const homeLink = {
		label: "Home",
		onClick: () => {
			history.push("/");
		},
	};

	useEffect(() => {
		(unreadNotifCount || unreadNotifCount === 0) && setNotifCount(unreadNotifCount);
	}, [unreadNotifCount]);

	//const userName = useSelector((state) => state.Auth);
	const Auth = useSelector((state) => state.Auth);

	const loadOptions = (searchKey, callback) => {
		const successCB = ({ result }) => {
			callback(result);
		};
		const FailCB = (result) => {
			callback([]);
		};
		if (searchKey.length > 1) {
			doExchangeNumbersSearch(searchKey, successCB, FailCB);
		} else {
			callback([]);
		}
	};

	const navigateToExchange = (selected) => {
		history.push(`/exchanges/${selected.guid}`);
	};

	const PageHeader = () => (
		<div className="page-header">
			<div className="menu-head">
				<span className="icon-top menu-button">
					<i className="fas fa-bars"></i>
				</span>
				<span
					className={`right-items ${show ? "icon-up-arrow" : "icon-down-arrow"} user-down-arrow`}
					onClick={() => toggleSlide()}></span>
				<span onClick={() => toggleSlide()} className="user-info-wrapper right-items">
					<span className="user-info">
						<span className="user-name">{Auth.userDetails.name}</span>
						<span className="user-role">{Auth.userDetails.userRoleId.label}</span>
					</span>
					{show ? (
						<div className="user-dropdown-menu">
							<div className="slide-menu-item" onClick={() => history.push("/profile")}>
								Edit Profile
							</div>
							<div className="dropdown-divider" role="separator"></div>
							<div className="slide-menu-item" onClick={() => logout()}>
								Logout
							</div>
						</div>
					) : null}
				</span>

				<span className="icon-top user-icon right-items">
					{/* <i className="fas fa-user"></i> */}

					<div className="position-relative">
						<div onClick={() => toggleSlide()} className="logged-user-pic">
							<img
								className="thumbnail-image"
								src={`${
									Auth.userDetails.profileImageUrl && Auth.userDetails.profileImageUrl !== ""
										? Auth.userDetails.profileImageUrl
										: avatar
								}`}
								alt="user pic"
							/>
						</div>
					</div>
				</span>
				<span
					className="icon-top right-items notification-item"
					onClick={() => (notifCount ? toggleNotif() : history.push("/notifications"))}>
					<span className="icon-Notification" />
					{notifCount ? (
						<>
							<div className="notif-count-wrap">{notifCount}</div>
							{showNotif && <NotificationDropdown />}
						</>
					) : null}
				</span>

				<span className="exchange-search right-items">
					<div className="custom-select-box-wrap">
						<Select
							placeholder={"Type to search"}
							onChange={navigateToExchange}
							className="custom-select-box"
							onBlur={() => {}}
							onFocus={() => {
								setShowNotif(false);
							}}
							loadOptions={loadOptions}
							style={{
								indicatorSeparator: () => ({ display: "none" }),
								dropdownIndicator: () => ({ display: "none" }),
							}}
						/>
					</div>
				</span>
			</div>
			<div className="title-header">
				<div className="page-title">
					<div>
						<span className="breadcrumbs">
							{breadcrumbs &&
								[homeLink, ...breadcrumbs].map(({ label, onClick }, index) => {
									return (
										<span key={index}>
											<span
												onClick={() => {
													onClick && onClick();
												}}
												className={`breadcrumbs-item ${onClick ? "breadcrumbs-link" : null}`}
												key={`master-child-${index}`}>
												{label}
											</span>
											{breadcrumbs.length > index ? (
												<span className="breadcrumbs-item" key={`child-${index}`}>
													{">"}
												</span>
											) : null}
										</span>
									);
								})}
						</span>
						<h1>{title}</h1>
						<hr className="heading-hr" />
					</div>
					<div className="hearder-buttons">
						{rightActionButtons &&
							rightActionButtons.map(({ component: Component }, index) => {
								return <Component key={index} />;
							})}
					</div>
				</div>
			</div>
		</div>
	);

	return (
		<div className="d-flex layout-wrapper">
			<SideMenu />
			<div className={className ? `page-container ${className}` : `page-container`}>
				<Scrollbars className="scroll-class">
					<div className="d-flex flex-column mih-100">
						<PageHeader />
						<div className="page-content">{props.children}</div>
					</div>
				</Scrollbars>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	const {
		Notification: { unreadNotifCount },
	} = state;
	return {
		unreadNotifCount: unreadNotifCount,
	};
};
const mapDispatchToProps = {
	doExchangeNumbersSearch: exchangeNumbersSearch,
};
export default connect(mapStateToProps, mapDispatchToProps)(Layout);
