import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import Slider from "../../SharedComponts/Slider";
import { formFields } from "./helper";
import SliderForm from "../../SharedComponts/SliderForm";
const AddEdit = (props) => {
	const {
		show,
		companyTypes,
		states,
		cities,
		doGetCities,
		handleClose,
		change,
		handleSubmit,
		submitting,
		reset,
		isEdit,
		loadCounties,
	} = props;

	return (
		<Slider
			show={show}
			title={isEdit ? "Edit Entity" : "Add Entity"}
			handleClose={() => {
				reset();
				handleClose();
			}}>
			<SliderForm handleSubmit={handleSubmit} submitting={submitting} handleClose={handleClose}>
				{formFields({ states, cities, companyTypes, doGetCities, change, loadCounties, isEdit }).map((item, index) => {
					return item.hideField ? null : (
						<div key={index} className="form-group">
							<div className="form-lable">
								{item.label}
								{item.required ? <span className="required-label">*</span> : null}
							</div>
							<Field {...item} />
						</div>
					);
				})}
			</SliderForm>
		</Slider>
	);
};
const mapStateToProps = ({ Common: { states = [], counties = [], cites: cities = [] } }) => ({ states, counties, cities });

export default connect(
	mapStateToProps,
	null
)(
	reduxForm({
		form: "EnitityForm",
		enableReinitialize: true,
	})(AddEdit)
);
