import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import Slider from "../../../SharedComponts/Slider";
import SliderForm from "../../../SharedComponts/SliderForm";
import FormControl from "../../../SharedComponts/FormControl";
import { required } from "../../../../constant/formValidators";

const UploadDocumentSlider = (props) => {
	const {
		propertyTypeList = [],
		propertyAddressList = [],
		getPropertyAddress,
		show,
		handleClose,
		handleSubmit,
		submitting,
		reset,
		getRejectedFiles,
		change,
	} = props;

	const fields = [
		{
			name: "propertyType",
			label: "Property Type",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			options: propertyTypeList,
			onChange: (selected) => {
				getPropertyAddress(selected);
				change("propertyAddress", null);
			},
			validate: [required],
			required: true,
		},
		{
			name: "propertyAddress",
			label: "Property Address",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			options: propertyAddressList,
			validate: [required],
			required: true,
		},
		{
			name: "fileInput",
			label: "",
			component: FormControl,
			type: "file-drop",
			className: "form-control form-field",
			multiple: true,
			accept: ".pdf",
			maxSize: 10240000,
			getRejectedFiles: getRejectedFiles,
			validate: [required],
		},
	];

	return (
		<Slider
			show={show}
			title={"Upload Document"}
			handleClose={() => {
				reset();
				handleClose();
			}}>
			<SliderForm handleSubmit={handleSubmit} submitting={submitting} handleClose={handleClose}>
				{fields.map((item, index) => {
					return item.hideField ? null : (
						<div key={index} className="form-group">
							<div className="form-lable">
								{item.label}
								{item.required ? <span className="required-label">*</span> : null}
							</div>
							<Field {...item} />
						</div>
					);
				})}
			</SliderForm>
		</Slider>
	);
};

export default connect(
	null,
	null
)(
	reduxForm({
		form: "UploadDocxSliderForm",
		enableReinitialize: true,
	})(UploadDocumentSlider)
);
