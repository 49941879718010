export const required = (value) => {
	if (
		(typeof value === "number" && (value || value === 0)) ||
		(value && value instanceof Array && value.length) ||
		(value && typeof value === "object" && Object.keys(value).length) ||
		(value && typeof value === "string" && value && value !== "") ||
		(value && typeof value === "boolean")
	) {
		return undefined;
	}
	return "This is a required field";
};
export const validDate = (value) => {
	if (Object.prototype.toString.call(value) === "[object Date]") {
		// it is a date
		if (isNaN(value.getTime())) {
			// d.valueOf() could also work
			return "This is a required field";
		} else {
			return undefined;
		}
	} else {
		return "This is a required field";
	}
};

export const validateString = (value) => {
	const forbiddenChars = /[<>{}]/;
	const htmlTags = /<\/?[^>]+(>|$)/;
  
	if (value && (forbiddenChars.test(value) || htmlTags.test(value))) {
	  return "The input contains forbidden characters or HTML tags.";
	}

	return undefined;
  };
  

export const maxLength = (max) => (value) => value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const max5 = maxLength(5);
export const max10 = maxLength(10);
export const max20 = maxLength(20);
export const max25 = maxLength(25);
export const max64 = maxLength(64);
export const max100 = maxLength(100);
export const max160 = maxLength(160);
export const max200 = maxLength(200);
export const max250 = maxLength(250);

export const minLength = (min) => (value) => value && value.length < min ? `Must be ${min} characters or more` : undefined;

export const number = (value) => (value && isNaN(Number(value)) ? "Must be a number" : undefined);

export const percent = (value) =>
	(value && (value < 0 || value > 100)) || (value && isNaN(Number(value))) || (value && value.length > 5)
		? "Must be a valid percentage"
		: undefined;

export const minValue = (min) => (value) => value && value < min ? `Must be at least ${min}` : undefined;

export const email = (value) =>
	value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? "Please enter a valid email address" : undefined;

export const tooYoung = (value) => (value && value < 13 ? "You do not meet the minimum age requirement!" : undefined);

export const currency = (value) => (value && !/^[0-9]\d*(\.\d+)?$$/.test(value) ? "Please enter valid currency" : undefined);

//export const currency = (value) => (value && !/^[0-9]*$/.test(value) ? "Please enter valid currency" : undefined);

export const aol = (value) => (value && /.+@aol\.com/.test(value) ? "Really? You still use AOL for your email?" : undefined);

export const alphaNumeric = (value) => (value && /[^a-zA-Z0-9 ]/i.test(value) ? "Only alphanumeric characters" : undefined);

export const phoneNumber = (value) => (value && !/^(0|[1-9][0-9]{5,9})$/i.test(value) ? "Must be 6 to 10 digits" : undefined);

export const USPhoneNumber = (value) => {
	const pattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
	return value && !pattern.test(value) ? "Not a valid format" : undefined;
};
export const USZipCode = (value) => (value && !/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value) ? "Invalid ZIP Code" : undefined);

export const USTaxid = (value) => (value && !/^[\d -]+$/.test(value) ? "Invalid SSN/ Tax Id" : undefined);

export const passwordMatch = (pass1, pass2) => (pass1 && pass2 && pass1 !== pass2 ? "Password does not match" : undefined);

export const passwordStrength = (value) => {
	const pattern = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\\W_])(?=.{8,})");
	return value && !pattern.test(value) ? "The password does not meet the requirements." : undefined;
};

export const time = (value) =>
	value && !/^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$/.test(value) ? "Invalid time format" : undefined;

export const max =
	(max = 100) =>
	(value) =>
		value && value > max ? `Must be ${max} or less` : undefined;

export const maxInt = max(2147483647);

export const min = (min) => (value) => {
	const val = value ? (typeof value === "object" ? value.value : value) : undefined;
	return val && val < min ? `Must be ${min} or more` : undefined;
};

export const min0 = min(0);
export const min1 = min(1);

export const isInt = (value) => (value && !/^\d+$/.test(value) ? "Please provide a valid integer" : undefined);

export const array = (value) => (value && value.length > 0 ? undefined : "Required");

export const normalizePhone = (value, previousValue) => {
	if (!value) {
		return value;
	}
	const onlyNums = value.replace(/[^\d]/g, "");
	if (!previousValue || value.length > previousValue.length) {
		// typing forward
		if (onlyNums.length === 3) {
			return "(" + onlyNums + ") ";
		}
		if (onlyNums.length === 6) {
			return "(" + onlyNums.slice(0, 3) + ") " + onlyNums.slice(3) + "-";
		}
	}
	if (onlyNums.length <= 3) {
		return onlyNums;
	}
	if (onlyNums.length <= 6) {
		return "(" + onlyNums.slice(0, 3) + ") " + onlyNums.slice(3);
	}
	return "(" + onlyNums.slice(0, 3) + ") " + onlyNums.slice(3, 6) + "-" + onlyNums.slice(6, 10);
};
export const normalizeSSN = (value, previousValue) => {
	if (!value) {
		return value;
	}
	const onlyNums = value.replace(/[^\d]/g, "");
	if (!previousValue || value.length > previousValue.length) {
		// typing forward
		if (onlyNums.length === 3) {
			return onlyNums + "-";
		}
		if (onlyNums.length === 5) {
			return onlyNums.slice(0, 3) + "-" + onlyNums.slice(3) + "-";
		}
	}
	if (onlyNums.length <= 3) {
		return onlyNums;
	}
	if (onlyNums.length <= 5) {
		return onlyNums.slice(0, 3) + "-" + onlyNums.slice(3);
	}
	return onlyNums.slice(0, 3) + "-" + onlyNums.slice(3, 5) + "-" + onlyNums.slice(5, 9);
};

export const normalizeNumber = (value, previousValue) => {
	if (!value) {
		return value;
	}
	if (isNaN(value)) {
		return value.slice(0, value.length - 1);
	}
	return value;
};

export const isValidAmount = (value) => {
	// Convert to string and use regex to check pattern
	if (value) {
		const stringValue = value.toString();
		const regex = /^\d{1,10}(\.\d{1,2})?$/;

		return !regex.test(stringValue) ? "Must be 10 characters or less with maximum 2 decimal places" : undefined;
	}
};
