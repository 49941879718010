import { TEMPLATES } from "../types";

export default (
	state = {
		filters: {
			searchKey: "",
			pageSize: 10,
			pageIndex: 1,
			sortColumn: "lastModifiedTime",
			sortDirection: "desc",
		},
		formInputs: {},
	},
	action
) => {
	switch (action.type) {
		case TEMPLATES.SET_FILTER:
			return {
				...state,
				filters: action.payload ? action.payload : {},
			};
		case TEMPLATES.GET_TEMPLATE_LIST:
			return {
				...state,
				templateList: action.payload ? action.payload.result : [],
			};
		case TEMPLATES.SET_TEMPLATE_FORM_INPUT:
			return {
				...state,
				formInputs: action.payload ? action.payload : {},
			};

		default:
			return state;
	}
};
