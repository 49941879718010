import React, { useEffect, useRef } from "react";
import Chart from "chart.js";

const Barchart = (props) => {
	const piechartRef = useRef();
	const chartOptions = {
		responsive: false,
		legend: {
			position: "bottom",
			labels: {
				boxWidth: 12,
				fontColor: "black",
				fontStyle: "normal",
			},
		},
		title: {
			display: true,
			text: "Year ",
			fontStyle: "normal",
			fontColor: "black",
		},
		scales: {
			xAxes: [
				{
					gridLines: {
						display: false,
					},
				},
			],
			yAxes: [
				{
					gridLines: {
						lineWidth: 0.5,
					},
					ticks: {
						padding: 5,
						beginAtZero: true,
						callback: function (label, index, labels) {
							return label / 1000 + "k";
						},
				},					
				},
			],
		},
	};

	const barChartData = props.data;

	useEffect(() => {
		const { labels = [], datasets = [], titles = [] } = barChartData;

		for (const item of datasets) {
			item.barPercentage = 0.7;
			item.categoryPercentage = 0.3;
		}

		chartOptions.title.text = `Year - ${titles
			.sort()
			.filter((title, index) => index === titles.indexOf(title))
			.toString()}`;

		const { current } = piechartRef;

		Chart.defaults.global.defaultFontStyle = "Bold";
		new Chart(current, {
			type: "bar",
			data: { labels, datasets },
			options: chartOptions,
		});
	}, [barChartData, chartOptions]);

	return <canvas ref={piechartRef} />;
};

export default Barchart;
