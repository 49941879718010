import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, getFormValues } from "redux-form";
import Slider from "../SharedComponts/Slider";
import { formFields } from "./helper";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import SliderForm from "../SharedComponts/SliderForm";

const ResetPasswordSlider = (props) => {
	const { show, handleClose, handleSubmit, submitting, reset, formValues } = props;

	return (
		<Slider
			show={show}
			title={"Reset Password"}
			handleClose={() => {
				reset();
				handleClose();
			}}>
			<SliderForm handleSubmit={handleSubmit} submitting={submitting} handleClose={handleClose}>
				{formFields(formValues).map((item, index) => {
					return (
						<div key={index} className="form-group">
							<div className="form-lable">
								{item.label}
								{item.name === "newPassword" ? (
									<OverlayTrigger
										placement="right"
										overlay={
											<Tooltip
												style={{
													fontSize: "12px",
													fontWeight: "500",
												}}>
												Please enter a password with a minimum length of 8 characters and a combination
												of uppercase, lowercase, numeric and special characters.
											</Tooltip>
										}>
										<span className="newPassword-info">
											<i className="fa fa-info-circle" aria-hidden="true"></i>
										</span>
									</OverlayTrigger>
								) : null}
							</div>
							<Field {...item} />
						</div>
					);
				})}
				<p className="note">
					{" "}
					<i className="fa fa-info-circle" aria-hidden="true"></i>
					{` You will be logged out once you change the password
					to re-login with the new password`}
				</p>
			</SliderForm>
		</Slider>
	);
};
const mapStateToProps = (state) => ({
	formValues: getFormValues("ResetPasswordForm")(state),
});
export default connect(
	mapStateToProps,
	null
)(
	reduxForm({
		form: "ResetPasswordForm",
		enableReinitialize: true,
	})(ResetPasswordSlider)
);
