import React, { useState, useEffect } from "react";
import { Card, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import FormControl from "../../SharedComponts/FormControl";
import Layout from "../../SharedComponts/Layout";
import { Heading } from "../../SharedComponts/FormElements";
import { showLoader, getPDFConfig, updatePDFConfig } from "../../../store/actions";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";
import "./pdf.scss";

const PdfConfiguration = (props) => {
	const { history, handleSubmit, doShowLoader, doGetPDFConfig, doUpdatePDFConfig, initialize } = props;
	const [headerHTML, setHeaderHTML] = useState(null);
	const [footerHTML, setFooterHTML] = useState(null);
	const [headerHTMLOriginal, setHeaderHTMLOriginal] = useState(null);
	const [footerHTMLOriginal, setFooterHTMLOriginal] = useState(null);

	const handleSubmitForm = (values) => {
		if (headerHTML) {
			const count = (headerHTML.match(/<p>/g) || []).length;
			if (count > 4) {
				errorMessage(503);
				return;
			}
		}
		doShowLoader(true);

		const postData = {
			headerHTML: headerHTML ? headerHTML : "",
			footerHTML: footerHTML ? footerHTML : "",
			id: 0
		};

		const successCB = (e) => {
			doShowLoader(false);
			setHeaderHTMLOriginal(headerHTML);
			setFooterHTMLOriginal(footerHTML);
			successMessage(224);
		};
		const FailCB = (e) => {
			errorMessage(e);
			doShowLoader(false);
		};

		doUpdatePDFConfig(postData, successCB, FailCB);
	};

	useEffect(() => {
		doShowLoader(true);
		const successCB = (e) => {
			if (e && e.result) {
				const initialData = {};
				if (e.result.headerHTML) {
					initialData.headerHTML = e.result.headerHTML;
					setHeaderHTML(e.result.headerHTML);
					setHeaderHTMLOriginal(e.result.headerHTML);
				}
				if (e.result.footerHTML) {
					initialData.footerHTML = e.result.footerHTML;
					setFooterHTML(e.result.footerHTML);
					setFooterHTMLOriginal(e.result.footerHTML);
				}
				initialize(initialData);
			}
			doShowLoader(false);
		};
		const FailCB = (e) => {
			errorMessage(e);
			doShowLoader(false);
		};

		doGetPDFConfig(successCB, FailCB);
	}, [doShowLoader, doGetPDFConfig, initialize]);

	return (
		<Layout
			title="PDF Configuration"
			breadcrumbs={[
				{
					label: "Configurations",
					onClick: () => {
						const path = `/settings-master`;
						history.push(path);
					},
				},
				{ label: "PDF Configuration" },
			]}
			className="user-details">
			<form className="pdf-configuration-form" onSubmit={handleSubmit(handleSubmitForm)}>
				<Card className="pl-0 pr-0">
					<Card.Title className="info-card-title plr-25">
						<Heading title="Header" />
					</Card.Title>
					<Card.Body className="info-card-body d-flex flex-row">
						<Field
							onChange={(data) => setHeaderHTML(data)}
							name="headerHTML"
							component={FormControl}
							type="editor"
							textValue={headerHTML} // This should be handled by FormControl
							minToolBar={true}
							className="form-control form-field"
						/>
					</Card.Body>
				</Card>
				{/* <Card className="pl-0 pr-0 mt-3">
					<Card.Title className="info-card-title plr-25">
						<Heading title="Footer" />
					</Card.Title>
					<Card.Body className="info-card-body d-flex flex-row">
						<Field
							onChange={(data) => setFooterHTML(data)}
							name="footerHTML"
							component={FormControl}
							type="editor"
							textValue={footerHTML} // This should be handled by FormControl
							minToolBar={true}
							className="form-control form-field"
						/>
					</Card.Body>
				</Card> */}
				<div className="w-100 pb-5">
					<Button className="form-button-primary float-right" type="submit">
						SUBMIT
					</Button>
					<Button
						className="form-button-secondary float-right mr-3"
						onClick={() => {
							setHeaderHTML(headerHTMLOriginal);
							// setFooterHTML(footerHTMLOriginal);
						}}>
						RESET
					</Button>
				</div>
			</form>
		</Layout>
	);
};

const mapDispatchToProps = {
	doShowLoader: showLoader,
	doGetPDFConfig: getPDFConfig,
	doUpdatePDFConfig: updatePDFConfig,
};

export default connect(
	null,
	mapDispatchToProps
)(
	reduxForm({
		form: "PdfConfigurationForm",
		enableReinitialize: true,
	})(PdfConfiguration)
);
