import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { get } from "lodash";
import { getFormValues } from "redux-form";

import Layout from "../../SharedComponts/Layout";
import Confirm from "../../SharedComponts/FormElements/confirm";
import { Table, Pagination } from "../../SharedComponts/FormElements/table";
import { columns as columnsFn } from "./helper";
import {
	setEscrowFilter,
	getUsers,
	showLoader,
	createEscrow,
	getEscrow,
	updateEscrow,
	deleteUser,
	getEntityByType,
	getCompanyTypes,
} from "../../../store/actions";

import SearchField from "../../SharedComponts/FormElements/searchTable";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";
import { userTypes } from "../../../constant/common";
import { getRoleID, getStatus } from "../../../utils/helper";
import Details from "./Details";
import AddEdit from "./AddEdit";

import "../admin.scss";
import "../userdetail.scss";

const AdminList = ({
	doSetFilter,
	filters,
	userDetails,
	doGetUsers,
	recordsTotal,
	userList,
	doShowLoader,
	doCreateEscrow,
	doGetEscrow,
	doUpdateEscrow,
	doDeleteUser,
	doGetEntityByType,
	doGetCompanyTypes,
	cardTotalCount,
}) => {
	const [isList, setIsList] = useState(true);
	const [showPopup, setshowPopup] = useState(false);
	const [isEdit, setIsEdit] = useState(true);
	const [currentRecord, setCurrentRecord] = useState({});
	const [listReload, setListReload] = useState(false);
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [currentDeleteRecord, setCurrentDeleteRecord] = useState(null);
	const [settlementCompanies, setSettlementCompanies] = useState(null);

	const handleClose = () => setshowPopup(false);
	const handleShow = () => setshowPopup(true);

	const handleSubmit = async (value) => {
		doShowLoader(true);
		const successCB = (result) => {
			doShowLoader(false);
			successMessage(!isEdit ? 306 : 307);
			setListReload(!listReload);
			handleClose();
			!isEdit && setIsList(true);
			!isList && onSelectUser(currentRecord);
		};
		const failCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		const postData = {
			...value,
			status: !isEdit ? getStatus("Active") : value.status,
		};

		currentRecord.id && isEdit && doUpdateEscrow(postData, successCB, failCB);
		(!currentRecord.id || !isEdit) && doCreateEscrow(postData, successCB, failCB);
	};

	const confirmDeleteAction = () => {
		doShowLoader(true);
		const failCB = (result) => {
			doShowLoader(false);
			setCurrentDeleteRecord(null);
			setConfirmDelete(false);
			errorMessage(result);
		};
		const successCB = (result) => {
			doShowLoader(false);
			setCurrentDeleteRecord(null);
			setConfirmDelete(false);
			successMessage(220);
			setListReload(!listReload);
		};
		doDeleteUser(currentDeleteRecord, successCB, failCB);
	};

	useEffect(() => {
		doShowLoader(true);
		const backendFilter = { ...filters, "ExtraParams.RoleId": getRoleID("Escrow") };
		const successCB = (result) => {
			doShowLoader(false);
		};
		const failCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		userDetails.id && doGetUsers(backendFilter, successCB, failCB);
	}, [filters, userDetails, doGetUsers, doShowLoader, listReload]);

	useEffect(() => {
		const getCopanyType = (result, type) => {
			return result.filter((item) => item.label === type)[0]["value"];
		};
		const successCB = (e) => {
			setSettlementCompanies(e.result);
		};
		doGetCompanyTypes((e) => {
			doGetEntityByType({ typeId: getCopanyType(e.result, "Settlement"), isInactiveReq: "false" }, successCB);
		});
	}, [doGetCompanyTypes, doGetEntityByType]);

	const loggedInAs = (type) => {
		return userDetails.userType === userTypes[type];
	};

	const onSelectUser = (row) => {
		doShowLoader(true);
		const successCB = (e) => {
			setCurrentRecord(e.result);
			doShowLoader(false);
			setIsList(false);
		};
		const failCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		doGetEscrow(row.id, successCB, failCB);
	};
	const onEditUser = (row) => {
		doShowLoader(true);
		const successCB = (e) => {
			setIsEdit(true);
			setCurrentRecord(e.result);
			doShowLoader(false);
			handleShow();
		};
		const failCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		doGetEscrow(row.id, successCB, failCB);
	};
	const onDeleteUser = (row) => {
		setCurrentDeleteRecord(row.id);
		setConfirmDelete(true);
	};

	const addUser = () => {
		setIsEdit(false);
		setCurrentRecord({});
		handleShow();
	};

	const rightActionButtons = [
		{
			component: () => {
				return (
					<Button className="form-button-primary" onClick={addUser}>
						<span className="add-new-plus">+</span> New Escrow
					</Button>
				);
			},
		},
	];
	const columns = columnsFn({
		onSelectUser,
		onEditUser,
		onDeleteUser,
	});
	return loggedInAs("SA") ? (
		<>
			{isList ? (
				<Layout
					title="Escrow"
					breadcrumbs={[{ label: "Escrow Listing" }]}
					rightActionButtons={rightActionButtons}
					className="user-list user-listing-page">
					<>
						<SearchField
							initialValue={filters.searchKey}
							onSearch={(searchKey) =>
								doSetFilter({
									...filters,
									searchKey,
									pageIndex: 1,
								})
							}
							className={`flex-fill mb-0`}
							placeholder="Enter your query"
						/>
						<Table
							columns={columns}
							total={cardTotalCount}
							data={userList}
							onSort={(e) =>
								e.by !== filters.sortColumn || e.order !== filters.sortDirection
									? doSetFilter({
											...filters,
											sortColumn: e.by,
											sortDirection: e.order,
											pageIndex: 1,
									  })
									: null
							}
							sortBy={filters.sortColumn}
							sortDir={filters.sortDirection}
						/>
						<Pagination
							total={recordsTotal}
							count={filters.pageSize}
							page={filters.pageIndex}
							onChange={(e) => {
								doSetFilter({ ...filters, pageIndex: e });
							}}
							onPageCountChange={(e) => {
								doSetFilter({ ...filters, pageSize: e, pageIndex: 1 });
							}}
						/>
					</>
				</Layout>
			) : (
				<Details
					user={currentRecord}
					breadcrumbs={[
						{
							label: "Escrow Listing",
							onClick: () => {
								setIsList(true);
							},
						},
						{ label: "Escrow Details" },
					]}
					initialValues={currentRecord}
					setIsList={setIsList}
					showEdit={handleShow}
					setIsEdit={setIsEdit}
				/>
			)}
			{showPopup ? (
				<AddEdit
					show={showPopup}
					initialValues={currentRecord}
					isEdit={isEdit}
					handleClose={handleClose}
					onSubmit={handleSubmit}
					doShowLoader={doShowLoader}
					settlementCompanies={settlementCompanies}
				/>
			) : null}
			<Confirm
				onYes={confirmDeleteAction}
				onNo={(e) => {
					setConfirmDelete(false);
				}}
				show={confirmDelete ? true : false}
			/>
		</>
	) : null;
};

const mapStateToProps = (state) => {
	return {
		userList: get(state, "User.userList.data", null),
		filters: get(state, "User.escrowFilter", {}),
		userDetails: get(state, "Auth.userDetails", {}),
		recordsTotal: get(state, "User.userList.recordsTotal", 0),
		cardTotalCount: get(state, "User.userList.additionalParam.totalCount", 0),
		formValues: getFormValues("EscrowForm")(state),
	};
};
const mapDispatchToProps = {
	doSetFilter: setEscrowFilter,
	doGetUsers: getUsers,
	doShowLoader: showLoader,
	doCreateEscrow: createEscrow,
	doGetEscrow: getEscrow,
	doUpdateEscrow: updateEscrow,
	doDeleteUser: deleteUser,
	doGetEntityByType: getEntityByType,
	doGetCompanyTypes: getCompanyTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminList);
