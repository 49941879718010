import { COMMON, USER } from "../types";

export default (state = { cites: [] }, action) => {
	switch (action.type) {
		case COMMON.GET_COUNTIES_LIST:
			return {
				...state,
				counties: action.payload ? action.payload.result : [],
			};
		case COMMON.GET_STATE_LIST:
			return {
				...state,
				states: action.payload ? action.payload.result : [],
			};
		case COMMON.GET_CITY_LIST:
			return {
				...state,
				cites: action.payload ? action.payload.result : [],
			};
		case COMMON.GET_LOOKUP:
			return {
				...state,
				[action.extraParams.typeKey]: action.payload ? action.payload.result : [],
			};
		case COMMON.GET_LOOKUP_LIST:
			const list = {};
			action.payload.result.forEach((item) => {
				list[item.key] = item.result;
			});

			return {
				...state,
				...list,
			};
		case USER.GET_ADMIN_USERS:
			return {
				...state,
				AdminUsers: action.payload ? action.payload.result : [],
			};

		default:
			return state;
	}
};
