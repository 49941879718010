import { FEE } from "../types";
import fetchHandler from "../../utils/fetchHandler";
import { serialize } from "../../utils/helper";

export function setFeeFilter(filter, successHandler) {
	successHandler && successHandler();
	return {
		type: FEE.SET_FILTER,
		payload: filter,
	};
}
export const getFeesList = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `feestructures?${serialize(values)}`,
		method: "GET",
		secure: true,
		actionType: FEE.GET_FEE_LIST,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const createFee = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `feeStructure`,
		method: "POST",
		secure: true,
		actionType: FEE.CREATE_FEE,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const updateFee = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `feeStructure/${values.id}`,
		method: "PUT",
		secure: true,
		actionType: FEE.UPDATE_FEE,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const getFee = (id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `feeStructure/${id}`,
		method: "GET",
		secure: true,
		actionType: FEE.GET_FEE,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const deleteFee = (id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `feeStructure/${id}`,
		method: "DELETE",
		secure: true,
		actionType: FEE.DELETE_FEE,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
