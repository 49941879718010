import React, { useEffect, useState } from "react";
const RenderField = ({
	input,
	showPassword,
	label,
	readOnly,
	type,
	placeholder,
	matchWith,
	maxLength,
	meta: { touched, error, warning },
}) => {
	const [matching, setMatching] = useState(false);
	const [toggleShowPassword, setToggleShowPassword] = useState(false);
	const [inputType] = useState(type);

	useEffect(() => {
		if (matchWith && matchWith.formValues && matchWith.formValues[matchWith.field] && input.value) {
			const fieldValue = matchWith.formValues[matchWith.field];
			setMatching(fieldValue === input.value);
		} else {
			setMatching(false);
		}
	}, [matchWith, input]);

	return (
		<>
			<input
				className={`form-field ${touched && error && "form-error"} ${
					matchWith && matchWith.formValues && matchWith.formValues[matchWith.field] && input.value && !matching
						? "not-matching"
						: "matching"
				}`}
				{...input}
				readOnly={readOnly}
				autoComplete="new-password"
				maxLength={maxLength}
				type={type === "password" ? (toggleShowPassword ? "text" : "password") : inputType}
			/>
			{type === "password" && showPassword ? (
				<div className="view-password-btn" onClick={() => setToggleShowPassword(!toggleShowPassword)}>
					<i className={`password-eye icon-${toggleShowPassword ? "eye-hide" : "eye"}`} />
				</div>
			) : null}
			{touched && ((error && <span className="form-error-span">{error}</span>) || (warning && <span>{warning}</span>))}
		</>
	);
};
export default RenderField;
