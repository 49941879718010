import React from "react";

import { MoreOptions } from "../../SharedComponts/FormElements";
import { Date } from "../../../utils/formatter";
import { dateFormat, userStatus as statuses } from "../../../constant/common";

import FormControl from "../../SharedComponts/FormControl";
import { required, USZipCode, USTaxid } from "../../../constant/formValidators";

export const columns = ({ onSelectEntity, onEditEntity = null, onDeleteEntity = null }) => [
	{
		name: "Entity",
		accessor: "entityName",
		className: "col-entity-name",
		render: (row) => (
			<div className="text-highlighted" onClick={() => onSelectEntity(row)}>
				{row.entityName}
			</div>
		),
	},
	{
		name: "Entity Type",
		accessor: "companyType",
		className: "col-entity-type",
	},
	{
		name: "City",
		accessor: "cityName",
		className: "col-city",
	},
	{
		name: "State",
		accessor: "stateName",
		className: "col-state",
		headerClassName: "col-email-header",
	},
	{
		name: "Status",
		accessor: "companyStatus",
		className: "col-status",

		sortable: false,
	},
	{
		name: "Created Date",
		accessor: "creationTime",
		className: "col-date",
		headerClassName: "col-date-header",
		render: (row) => <Date utcTime={row.creationTime} format={dateFormat} />,
	},
	{
		name: "Actions",
		className: "col-actions text-right",
		headerClassName: "col-actions-header",
		sortable: false,
		render: (row) => {
			return (
				<MoreOptions
					options={[
						{ label: "Edit", onClick: () => onEditEntity(row) },
						{ label: "Delete", onClick: () => onDeleteEntity(row) },
					]}
				/>
			);
		},
	},
];

export const formFields = ({ states, cities, companyTypes, doGetCities, change, loadCounties, isEdit }) => {
	return [
		{
			name: "companyType",
			label: "Entity Type",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			validate: [required],
			required: true,
			options: companyTypes,
			isDisabled: isEdit,
		},
		{
			name: "entityName",
			label: "Entity Name",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			validate: [required],
			required: true,
		},
		{
			name: "address.streetAddress",
			label: "Address",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
		},
		{
			name: "address.state",
			label: "State",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			options: states,
			onChange: (selected) => {
				doGetCities(selected.value);
				change("address.city", null);
			},
		},

		{
			name: "address.city",
			label: "City",
			component: FormControl,
			type: "select",
			options: cities,
			className: "form-control form-field",
		},
		{
			name: "address.zipCode",
			label: "Zip Code",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			validate: [USZipCode],
		},
		{
			name: "taxId",
			label: "SSN/Tax ID",
			component: FormControl,
			type: "text",
			validate: [USTaxid],
			className: "form-control form-field",
		},
		{
			name: "status",
			label: "Status",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			options: statuses,
			hideField: !isEdit,
		},
	];
};
export const detailColumnFn = (history) => [
	{
		name: "Exchange Number",
		accessor: "exchangeNumber",
		render: (row) => (
			<div
				className="text-highlighted"
				onClick={() => {
					history.push(`/exchanges/${row.guid}`);
				}}>
				{row.exchangeNumber}
			</div>
		),
		className: "exchange-no",
		width: "14%",
	},
	{
		name: "Exchange Type",
		accessor: "exchangeType.label",
		width: "14%",
		render: (row) => <>{row.exchangeType.label}</>,
	},
	{
		name: "Status",
		accessor: "type",
		render: (row) => <span className={`status-card status-${row.exchangeStatus.label}`}>{row.exchangeStatus.label}</span>,
		width: "14%",
	},
	{
		name: "Open Date",
		accessor: "openDate",
		render: (row) => <Date utcTime={row.openDate} format={dateFormat} />,
		width: "14%",
	},
	{
		name: "45th Day",
		accessor: "day45",
		render: (row) => <Date utcTime={row.day45} format={dateFormat} />,
		width: "14%",
	},
	{
		name: "180th Day",
		accessor: "day180",
		render: (row) => <Date utcTime={row.day180} format={dateFormat} />,
		width: "14%",
	},
	{
		name: "Close date",
		accessor: "finalCloseDate",
		render: (row) => <Date utcTime={row.finalCloseDate} format={dateFormat} />,
	},
];
export const rowHeadItems = (entityType = "") => [
	{
		name: entityType === 227 ? "Agent Name" : "Contact Name",
		accessor: "contact",
	},
	{
		name: "Email",
		accessor: "emailAddress",
	},
	{
		name: "Phone",
		accessor: "phoneNumber",
	},
];
