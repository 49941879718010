import React from "react";
import { Heading } from "../../SharedComponts/FormElements";
import { columnsTopEscrows } from "./helper";
import noData from "../../../assets/images/no-data.png";
const renderRow = (row, columns) => {
	return columns.map(({ accessor, rowWidth, render, className }, index) => {
		const width = rowWidth;
		if (render) {
			return (
				<div key={index} className={`table-col ${className ? className : ""}`} style={width ? { width } : {}}>
					{render(row)}
				</div>
			);
		}
		return (
			<div key={index} className={`table-col ${className ? className : ""}`} style={width ? { width } : {}}>
				{row[accessor] ? row[accessor] : "-"}
			</div>
		);
	});
};
const TopSales = (props) => {
	const { topSalesData } = props;
	return (
		<>
			<Heading title="Top Sales Representatives" />

			{topSalesData && topSalesData.length ? (
				<div className="task-table">
					<div className="table-head">
						{columnsTopEscrows.length
							? columnsTopEscrows.map(({ label, className, width }, index) => (
									<div
										key={index}
										className={`head-item ${className ? className : ""}`}
										style={width ? { width } : {}}>
										{label}
									</div>
							  ))
							: null}
					</div>
					<div className="table-body">
						{topSalesData.map((row, index) => {
							return (
								<div key={index} className={`table-row w-100 ${index % 2 === 0 ? "row-even" : "row-odd"}`}>
									{renderRow(row, columnsTopEscrows)}
								</div>
							);
						})}
					</div>
				</div>
			) : (
				<img src={noData} className="dashboard-no-data-card" alt="noData" />
			)}
		</>
	);
};

export default TopSales;
