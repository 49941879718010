import React from "react";
import "./userinfotable.scss";

const UserInfoTable = (props) => {
	const { columns, data, dataAccessor, rowHeadItems } = props;
	const RowItem = ({ colData, index }) => (
		<div className={`row-data ${index % 2 === 0 ? "row-even" : "row-odd"}`}>
			{columns.map(({ accessor, width, render }, index) => {
				if (render) {
					return (
						<div key={index} className="user-table-col" style={width ? { width } : {}}>
							{render(colData)}
						</div>
					);
				}
				return (
					<div key={index} className="user-table-col" style={width ? { width } : {}}>
						{colData[accessor] ? colData[accessor] : "-"}
					</div>
				);
			})}
		</div>
	);
	const Row = ({ row, index }) => {
		return (
			<div className={`user-table-row`}>
				{rowHeadItems && rowHeadItems.length ? (
					<div className="row-head">
						{rowHeadItems.map(({ name, accessor }, index) => (
							<div key={index} className="head-Item">
								<span className="label">{`${name} : `}</span>
								<span className="spectrum-table-td sub-head pl-5px">
									{row[accessor] ? row[accessor] : "---"}
								</span>
							</div>
						))}
					</div>
				) : null}
				{row[dataAccessor].map((colData, index) => (
					<RowItem index={index} key={index} colData={colData} />
				))}
			</div>
		);
	};
	return (
		<div className="user-Info-table">
			<div className="table-head pb-3">
				{columns.map(({ name, className, width }, index) => (
					<div key={index} className={`col-head label ${className ? className : ""}`} style={width ? { width } : {}}>
						{name}
					</div>
				))}
			</div>

			{dataAccessor ? (
				data.map((row, index) => <Row row={row} key={index} index={index} />)
			) : (
				<div className={`user-table-row`}>
					{data.map((row, index) => (
						<RowItem index={index} key={index} colData={row} />
					))}
				</div>
			)}
		</div>
	);
};

export default UserInfoTable;
