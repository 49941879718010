import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { connect } from "react-redux";
import { createAdditionalSign, getSignatureMenu, showLoader, generateDocument } from "../../../../../store/actions";
import Editor from "../../../../SharedComponts/FormControl/Editer";
import AdditionalSignForm from "./AdditionalSignForm";
import Checkbox from "../../../../SharedComponts/FormControl/Checkbox";
import { errorMessage } from "../../../../../utils/errorMessage";

const SecondStage = (props) => {
	const {
		document,
		setDocumentDraftHtml,
		documentDraftHtml,
		doCreateAdditionalSign,
		doGetSignatureMenu,
		exchangeDetail,
		doShowLoader,
		isHeaderFooterReq,
		setHeaderFooterReq,
		menu,
		setMenu,
	} = props;
	const [refreshMenu, setRefreshMenu] = useState(false);
	useEffect(() => {
		setMenu(null);
		const successCB = (response) => {
			doShowLoader(false);

			setMenu({ name: "Signature Menu", menuList: response.result });
		};
		const FailCB = (result) => {
			doShowLoader(false);
		};
		if (document) {
			doShowLoader(true);
			doGetSignatureMenu(
				{
					exchangeId: exchangeDetail.id,
					propertyId: document.propertyId ? document.propertyId : 0,
					documentId: document.id,
				},
				successCB,
				FailCB
			);
		}

		if (document && document.documentHTML) {
			if (!documentDraftHtml) {
				setDocumentDraftHtml(document.documentHTML ? document.documentHTML : document.templateHTML);
			}
		}
		// eslint-disable-next-line
	}, [document, exchangeDetail, setMenu, refreshMenu, doShowLoader, setDocumentDraftHtml, doGetSignatureMenu]);

	const [showSlider, setshowSlider] = useState(false);
	const onSelectMenu = (item, editor) => {
		if (item.signatureTypes === 955 && item.userId === 0) {
			setshowSlider(true);
		}
	};

	const handleSubmitAdditionalSign = (values) => {
		setMenu(null);
		const successCB = (response) => {
			doShowLoader(false);
			setshowSlider(false);
			setRefreshMenu(!refreshMenu);
		};
		const FailCB = (result) => {
			errorMessage(result);
			setRefreshMenu(!refreshMenu);
			doShowLoader(false);
		};
		doShowLoader(true);
		doCreateAdditionalSign(
			{
				...values,
				exchangeId: exchangeDetail.id,
				templateId: document.templateId,
				exchangeDocumentId: document.id,
			},
			successCB,
			FailCB
		);
	};
	return (
		<>
			<Card>
				<div className="col-12 mb-2 d-flex">
					<Checkbox
						input={{
							id: "check",
							checked: isHeaderFooterReq,
							onChange: () => {
								setHeaderFooterReq(!isHeaderFooterReq);
							},
						}}
						meta={{}}
					/>
					<p>Check the box to include default header to the document.</p>
				</div>

				<div className="col-12 editor-head">
					<div className="head-item">
						<span className="label">Document Name :</span>
						<span className="value"> {document?.documentName}</span>
					</div>
					<div className="head-item">
						<span className="label">Exchange Type :</span>
						<span className="value"> {exchangeDetail?.exchangeType?.label}</span>
					</div>
				</div>
				{menu && document && document.id && (
					<Editor
						input={{
							onChange: (data) => {
								setDocumentDraftHtml(data);
							},
							value: documentDraftHtml
							? documentDraftHtml
							: document.documentHTML
							? document.documentHTML
							: document.templateHTML
						}}
						onSelectMenu={onSelectMenu}
						
						type="editor"
						menu={menu}
						className="form-control form-field"
					/>
				)}
			</Card>
			{showSlider && (
				<AdditionalSignForm
					show={showSlider}
					handleClose={() => setshowSlider(false)}
					onSubmit={handleSubmitAdditionalSign}
				/>
			)}
		</>
	);
};

const mapDispatchToProps = {
	doGetSignatureMenu: getSignatureMenu,
	doCreateAdditionalSign: createAdditionalSign,
	doShowLoader: showLoader,
	doGenerateDocument: generateDocument,
};
const mapStateToProps = (state) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SecondStage);
