import DatePicker from "react-datepicker";
import React, { forwardRef } from "react";

const CustomDateInput = forwardRef(({ onClick, value, onChange, id, name, onClear, isDisabled }, ref) => (
	<>
		<i className="icon icon-calendar date-picker-icon" onClick={onClick}></i>
		{onClear && <i className={`icon icon-close date-picker-icon_2 ${value && "has-value"}`} onClick={onClear}></i>}
		<input
			className={`form-control form-field react-datepicker-ignore-onclickoutside ${isDisabled && "form-disabled"}`}
			type="text"
			onClick={onClick}
			onChange={onChange}
			value={value}
			ref={ref}
			id={id}
			name={name}
			readOnly={true}
		/>
	</>
));

const renderField = ({ id, name, input, label, type, placeholder, onClear, meta: { touched, error, warning }, ...rest }) => {
	const { onChange, value, validate } = input;

	return (
		<>
			<DatePicker
				className={`form-field ${touched && error && "form-error"} `}
				validate={validate}
				onSelect={onChange}
				dateFormat="MM/dd/yyyy"
				selected={value || null}
				placeholder={placeholder}
				showYearDropdown
				customInput={<CustomDateInput onClear={onClear} isDisabled={rest.disabled} />}
				popperModifiers={{
					preventOverflow: {
						enabled: true,
					},
				}}
				{...rest}
			/>

			{touched && ((error && <span className="form-error-span">{error}</span>) || (warning && <span>{warning}</span>))}
		</>
	);
};
export default renderField;
