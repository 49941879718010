import React, { useEffect, useState } from "react";
import { Accordion, Button, Card } from "react-bootstrap";
import { errorMessage } from "../../../../utils/errorMessage";
import { AccordionSlider, Heading, InfoItem, SubSectionHeading } from "../../../SharedComponts/FormElements";
import { buyerBasicForm, buyerContactDetails, buyerAddressForm } from "./helper";

const renderBuyer = ({
	buyer,
	saveProperty,
	property,
	popupBuyerDetails,
	loadUsers,
	doGetCities,
	doShowLoader,
	cities,
	states,
	change,
	isLocked,
	dealerFormValues,
}) => {
	return (
		<>
			<Card.Title className="info-card-title">
				<SubSectionHeading title="Basic Details" />
			</Card.Title>
			<Card.Body className="info-card-body">
				{buyerBasicForm({ buyer, saveProperty, popupBuyerDetails, loadUsers, change, property, isLocked }).map(
					(item, index) => (
						<InfoItem key={index} formData={dealerFormValues} {...item} />
					)
				)}
			</Card.Body>
			<Card.Title className="info-card-title">
				<SubSectionHeading title="Contact Details" />
			</Card.Title>
			<Card.Body className="info-card-body">
				{buyerContactDetails({ buyer, saveProperty, property, isLocked }).map((item, index) => (
					<InfoItem formData={dealerFormValues} key={index} {...item} />
				))}
			</Card.Body>
			<Card.Title className="info-card-title">
				<SubSectionHeading title="Address Details" />
			</Card.Title>
			<Card.Body className="info-card-body">
				{buyerAddressForm({
					buyer,
					property,
					saveProperty,
					doGetCities,
					doShowLoader,
					cities,
					states,
					change,
					isLocked,
				}).map((item, index) => (
					<InfoItem formData={dealerFormValues} key={index} {...item} />
				))}
			</Card.Body>
		</>
	);
};
const DealerForm = ({
	relinquishedProperty,
	onDeleteBuyer,
	createBuyer,
	doShowLoader,
	saveProperty,
	fields,
	property,
	doGetUser,
	doGetCities,
	loadUsers,
	cities,
	states,
	change,
	isLocked,
	propertyFormValues,
}) => {
	const [actveKey, setActiveKey] = useState("buyer_0");
	useEffect(() => {
		if (relinquishedProperty && relinquishedProperty.dealerDtoList) {
			setActiveKey(`buyer_${relinquishedProperty.dealerDtoList.length - 1}`);
		}
	}, [relinquishedProperty, setActiveKey]);
	const popupBuyerDetails = (buyer, id) => {
		doShowLoader(true);
		const successCB = (response) => {
			doShowLoader(false);
			change(`${buyer}.phoneNumber`, response.result.phoneNumber);
			change(`${buyer}.fax`, response.result.fax ? response.result.fax : null);
			change(`${buyer}.emailAddress`, response.result.emailAddress);
			change(`${buyer}.ssnOrTaxId`, response.result.ssn ? response.result.ssn : null);
		};
		const failCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};
		doGetUser(id, successCB, failCB);
	};
	return (
		<>
			<Card.Title className="info-card-title align-items-baseline">
				{fields.length ? <Heading className="mt-20" title="Buyer Information" /> : null}
				{!isLocked && (
					<Button
						className="form-button-secondary"
						onClick={() => createBuyer(Number(property.split("[")[1].split("]")[0]))}>
						<span className="add-new-plus">+</span> Add Buyer
					</Button>
				)}
			</Card.Title>
			<Card.Body className="p-0">
				<Accordion activeKey={actveKey}>
					{fields.map((buyer, index) => (
						<AccordionSlider
							onClick={(key) => {
								if (key === actveKey) {
									setActiveKey("");
								} else {
									setActiveKey(key);
								}
							}}
							key={index}
							defaultKey={fields.length}
							index={index}
							eventKey={`buyer_${index}`}
							subTitle={fields.get(index)?.name}
							onDelete={
								!isLocked
									? () => {
											onDeleteBuyer(Number(property.split("[")[1].split("]")[0]), index);
									  }
									: null
							}
							title={`Buyer ${index + 1}`}
							content={renderBuyer({
								dealerFormValues: propertyFormValues?.dealerDtoList
									? propertyFormValues.dealerDtoList[index]
									: {},
								property,
								saveProperty,
								popupBuyerDetails,
								loadUsers,
								buyer,
								doGetCities,
								doShowLoader,
								cities,
								states,
								change,
								isLocked,
							})}
						/>
					))}
				</Accordion>
			</Card.Body>
		</>
	);
};
export default DealerForm;
