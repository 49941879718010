import React, { useState, useEffect } from "react";
import Layout from "../SharedComponts/Layout";
import { Table, Pagination } from "../SharedComponts/FormElements/table";
import {
	setNotificationFilter,
	fetchNotificationList,
	updateNotification,
	deleteNotification,
	showLoader,
	reloadNotification,
} from "../../store/actions";
import { errorMessage } from "../../utils/errorMessage";
import { successMessage } from "../../utils/successMessage";
import SearchField from "../SharedComponts/FormElements/searchTable";
import Confirm from "../SharedComponts/FormElements/confirm";
import { columns as columnsFn } from "./helper";
import { connect } from "react-redux";
import "./notification.scss";

const Notification = (props) => {
	const {
		filters,
		doSetFilter,
		doFetchNotificationList,
		doUpdateNotification,
		doDeleteNotification,
		recordsTotal,
		totalCount = 5,
		doShowLoader,
		doReloadNotification,
		reloadFlag,
		history,
	} = props;

	const [masterCheckbox, setMasterCheckbox] = useState(false);
	const [notificationList, setnotificationList] = useState([]);
	const [checkedNotificationList, setCheckedNotificationList] = useState([]);
	const [confirmDelete, setConfirmDelete] = useState(false);

	useEffect(() => {
		doShowLoader(true);
		const notifiFetchFailCB = (e) => {
			errorMessage(e);
			doShowLoader(false);
		};

		const notifiFetchSuccessCB = (e) => {
			doShowLoader(false);
			if (e && e.result && e.result.data) {
				const flaggedNotificationList = e.result.data.map((obj) => {
					return { ...obj, isSelected: false };
				});
				setnotificationList(flaggedNotificationList);
				setMasterCheckbox(false);
			}
		};
		const backendFilter = { ...filters };
		doFetchNotificationList(backendFilter, notifiFetchSuccessCB, notifiFetchFailCB);
	}, [filters, doShowLoader, doFetchNotificationList, reloadFlag]);

	const onNotificationCheck = (e) => {
		const { value, checked } = e.target;
		updateReadState(checked, value);
	};
	const onMasterCheckbox = (value) => {
		setMasterCheckbox(value);
		updateReadState(value);
	};

	const updateReadState = (value, id) => {
		const newCheckedNotifList = [];
		const updatedList = notificationList.map((object) => {
			const isSelected = id ? (object.id === parseInt(id) ? value : object.isSelected) : value;
			isSelected && newCheckedNotifList.push(object.id);
			return {
				...object,
				isSelected: isSelected,
			};
		});
		setCheckedNotificationList(newCheckedNotifList);
		masterCheckbox && newCheckedNotifList.length !== notificationList.length && setMasterCheckbox(!masterCheckbox);
		newCheckedNotifList.length === notificationList.length && !masterCheckbox && setMasterCheckbox(!masterCheckbox);
		setnotificationList(updatedList);
	};

	const columns = columnsFn({
		onNotificationCheck,
		onMasterCheckbox,
		masterCheckbox,
		history,
	});

	const deleteNotification = () => {
		setConfirmDelete(false);
		doShowLoader(true);
		const notifFailCB = (e) => {
			errorMessage(e);
			doShowLoader(false);
		};

		const notifSuccessCB = (e) => {
			doShowLoader(false);
			doReloadNotification();
			successMessage(602);
			setCheckedNotificationList([]);
		};

		doDeleteNotification(checkedNotificationList, notifSuccessCB, notifFailCB);
	};
	const updateNotification = () => {
		doShowLoader(true);
		const notifFailCB = (e) => {
			errorMessage(e);
			doShowLoader(false);
		};

		const notifSuccessCB = (e) => {
			doShowLoader(false);
			doReloadNotification();
			successMessage(601);
			setCheckedNotificationList([]);
		};

		doUpdateNotification({ id: checkedNotificationList, isClearAll: false }, notifSuccessCB, notifFailCB);
	};
	return (
		<Layout title="Notifications" breadcrumbs={[{ label: "Notifications" }]} className="">
			<div className="action-items">
				<div className="action-item-search">
					<SearchField
						initialValue={filters.searchKey}
						onSearch={(searchKey) =>
							doSetFilter({
								...filters,
								searchKey,
								pageIndex: 1,
							})
						}
						className={`flex-fill mb-0`}
						placeholder="Enter your query"
					/>
				</div>

				<div className={`action-item-read ${checkedNotificationList.length ? null : "action-disabled"}`}>
					<div className="info-button filter-option text-highlighted" onClick={() => updateNotification()}>
						<span className={`icon-excel`}></span> Mark as read
					</div>
				</div>
				<div className={`action-item-delete ${checkedNotificationList.length ? null : "action-disabled"}`}>
					<div className="info-button filter-option text-highlighted" onClick={() => setConfirmDelete(true)}>
						<span className={`icon-icon-delete`}></span> Delete
					</div>
				</div>
			</div>
			<Table
				columns={columns}
				total={totalCount}
				data={notificationList}
				rowClassName="row_is_read"
				rowDependencyfield="isRead"
			/>

			<Pagination
				total={recordsTotal}
				count={filters.pageSize}
				page={filters.pageIndex}
				onChange={(e) => {
					doSetFilter({ ...filters, pageIndex: e });
					setCheckedNotificationList([]);
					masterCheckbox && setMasterCheckbox(!masterCheckbox);
				}}
				onPageCountChange={(e) => {
					doSetFilter({ ...filters, pageSize: e, pageIndex: 1 });
					setCheckedNotificationList([]);
					masterCheckbox && setMasterCheckbox(!masterCheckbox);
				}}
			/>

			<Confirm
				onYes={deleteNotification}
				onNo={(e) => {
					setConfirmDelete(false);
				}}
				show={confirmDelete ? true : false}
			/>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	const {
		Notification: { filters, reloadFlag, recordsTotal, totalCount },
	} = state;
	return {
		filters,
		reloadFlag,
		recordsTotal,
		totalCount,
	};
};

const mapDispatchToProps = {
	doSetFilter: setNotificationFilter,
	doFetchNotificationList: fetchNotificationList,
	doUpdateNotification: updateNotification,
	doDeleteNotification: deleteNotification,
	doShowLoader: showLoader,
	doReloadNotification: reloadNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(Notification);
