import React, { useEffect, useState } from "react";
import moment from "moment";
import Layout from "../../SharedComponts/Layout";
import TimeLine from "../../SharedComponts/TimeLine";
import { Card, Tabs, Tab } from "react-bootstrap";
import BasicInfoForm from "./BasicInfoForm";
import { getCopy, getSufixOfNumber, getDateFromDatePicker, formatDateToISOString } from "../../../utils/helper";
import ExchangerInfoTab from "./ExchangerInfoTab";
import RelinquishPropertyTab from "./RelinquishPropertyTab";
import ReplacementPropertyTab from "./ReplacementPropertyTab";
import TransactionAndIncome from "./TransactionAndIncome";
import DocumentTab from "./DocumentTab";
import Tasks from "./TaskTab";
import "./exchangdetail.scss";
import { dateFormat } from "../../../constant/common";
import GenerateDocumentWizard from "./DocumentTab/GenerateDocumentWizard";
import { connect } from "react-redux";
import {
	getExchangeDetails,
	updateExchangerInfo,
	getEntityByType,
	showLoader,
	searchContacts,
	searchUsers,
	updateAddress,
	getStates,
	getCounties,
	updateExchange,
	createNote,
	deleteNote,
	getNotes,
	sendEmail,
	exchangeUnlock,
} from "../../../store/actions";
import { useParams } from "react-router-dom";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";
import { NoteButton } from "../../SharedComponts/FormElements";
import NoteSlider from "../NoteSlider";
import Tooltip from "../../SharedComponts/ToolTip";
import EmailSlider from "../EmailSlider";
import { Button } from "react-bootstrap";
import { Date } from "../../../utils/formatter";
const ExchangeView = (props) => {
	const {
		history,
		replacementProperties,
		doGetExchangeDetails,
		doShowLoader,
		doSearchContacts,
		doUpdateExchange,
		exchangeDetail,
		exchangerInformation,
		doSearchUsers,
		doGetEntityByType,
		CompanyType,
		doGetStates,
		doGetCounties,
		relinquishedProperties,
		isLocked,
		doGetNotes,
		doDeleteNote,
		doCreateNote,
		doSendEmail,
		userDetails,
		doExchangeUnlock,
	} = props;
	const { id } = useParams();
	const [showNotePopup, setNoteshowPopup] = useState(false);
	const [showNoteAdd, setShowNoteAdd] = useState(true);
	const [isReload, setReload] = useState(false);
	const [qiCompanies, setQiCompanies] = useState([]);
	const [isEdit, setIsEdit] = useState(false);
	const [shoWGenerateDocumnt, setShoWGenerateDocumnt] = useState(false);
	const [documentId, setDocumentId] = useState(null);
	const [defaultTab, setDefaultTab] = useState("exhanger");

	const [timeLineData, setTimelineData] = useState({
		now: 0,
		points: [
			{
				position: 10,
				active: false,
				label: "ID Period Starts",
				labelPosition: 40,
			},
			{
				position: 50,
				label: "45th Day",
				active: false,
				labelPosition: 20,
			},
			{
				position: 98,
				active: false,
				labelPosition: 20,
			},
		],
	});
	useEffect(() => {
		doShowLoader(true);
		const successCB = (response) => {
			if (response.result.exchangeDetail) {
				if (response.result.exchangeDetail.rel1CloseDate) {
					const rel1CloseDate = moment(response.result.exchangeDetail.rel1CloseDate);
					const day45 = moment(response.result.exchangeDetail.day45);
					const day180 = moment(response.result.exchangeDetail.day180);
					const finalCloseDate = response.result.exchangeDetail.finalCloseDate
						? moment(response.result.exchangeDetail.finalCloseDate)
						: null;
					let now = 0;
					let activei80 = false;
					let active45 = false;
					let activeDdate = false;
					let activeRel1 = false;
					let finlalCloseActive = false;
					let points = [
						{
							position: 10,
							active: false,
							label: "ID Period Starts",
							labelPosition: 40,
						},
						{
							position: 50,
							label: "45th Day",
							active: false,
							labelPosition: 20,
						},
						{
							position: 98,
							active: false,
						},
					];
					if (response.result.exchangeDetail.actualDDate) {
						try {
							const actualDDate = moment(response.result.exchangeDetail.actualDDate);
							const diffwith45 = day45.diff(rel1CloseDate, "days");
							const diffwithactual = actualDDate.diff(rel1CloseDate, "days");
							const idPosition = (diffwithactual / diffwith45) * 40 + 10;
							let diffwith180 = 0;
							let diffwithFinal = 0;
							let finalPosition = 98;
							if (finalCloseDate) {
								diffwith180 = day180.diff(day45, "days");
								diffwithFinal = finalCloseDate.diff(day45, "days");
								finalPosition = (diffwithFinal / diffwith180) * 48 + 50;
							}
							if (moment().isSameOrAfter(day180)) {
								now = 100;
								activei80 = true;
								active45 = true;
								activeDdate = true;
								activeRel1 = true;
								finlalCloseActive = true;
							} else if (finalCloseDate && moment().isSameOrAfter(finalCloseDate)) {
								now = finalPosition;
								active45 = true;
								activeDdate = true;
								activeRel1 = true;
								finlalCloseActive = true;
							} else if (moment().isSameOrAfter(day45)) {
								now = 50;
								active45 = true;
								activeDdate = true;
								activeRel1 = true;
							} else if (moment().isSameOrAfter(actualDDate)) {
								activeRel1 = true;
								activeDdate = true;
								now = idPosition;
							} else if (moment().isSameOrAfter(rel1CloseDate)) {
								now = 10;
								activeRel1 = true;
							}
							points = [
								{
									position: 10,
									active: activeRel1,
									tooltipText: `Id Period Starts : ${rel1CloseDate.format("MM/DD/YYYY")}`,
								},
								{
									position: idPosition,
									active: activeDdate,
									labelPosition: 40,
									tooltipText: `Identified on ${getSufixOfNumber(diffwithactual)} Day`,
								},
								{
									position: 50,
									label: "45th Day",
									active: active45,
									tooltipText: `${day45.format("MM/DD/YYYY")}`,
									labelPosition: 20,
								},
								{
									position: 98,
									active: activei80,
									tooltipText: `180th Day : ${day180.format("MM/DD/YYYY")}`,
									labelPosition: 20,
								},
							];
							if (finalCloseDate) {
								let tooltipText = `Closed on : ${finalCloseDate.format("MM/DD/YYYY")}`;
								if (finalCloseDate.diff(actualDDate, "days") === 0) {
									tooltipText = `Actual Id date and close date on :${finalCloseDate.format("MM/DD/YYYY")}`;
								}

								points.splice(3, 0, {
									position: finalPosition,
									active: finlalCloseActive,
									tooltipText: tooltipText,
									labelPosition: 42,
								});
							}
						} catch (err) {
							console.error(err);
						}
					} else {
						let diffwith180 = 0;
						let diffwithFinal = 0;
						let finalPosition = 0;
						if (finalCloseDate) {
							diffwith180 = day180.diff(day45, "days");
							diffwithFinal = finalCloseDate.diff(day45, "days");
							finalPosition = (diffwithFinal / diffwith180) * 48 + 50;
						}
						if (moment().isSameOrAfter(day180)) {
							now = 100;
							activei80 = true;
							active45 = true;
							activeDdate = true;
							activeRel1 = true;
							finlalCloseActive = true;
						} else if (moment().isSameOrAfter(finalCloseDate)) {
							finlalCloseActive = true;
							now = finalPosition;
							active45 = true;
							activeRel1 = true;
							finlalCloseActive = true;
						} else if (moment().isSameOrAfter(day45)) {
							now = 50;
							active45 = true;
							activeDdate = true;
							activeRel1 = true;
						} else if (moment().isSameOrAfter(rel1CloseDate)) {
							now = 10;
							activeRel1 = true;
						}
						points = [
							{
								position: 10,
								active: activeRel1,
								tooltipText: `Id Period Starts : ${rel1CloseDate.format("MM/DD/YYYY")}`,
							},
							{
								position: 50,
								label: "45th Day",
								active: active45,
								tooltipText: `${day45.format("MM/DD/YYYY")}`,
								labelPosition: 20,
							},
							{
								position: 98,
								active: activei80,
								tooltipText: `${day180.format("MM/DD/YYYY")}`,
							},
						];
						if (finalCloseDate) {
							points.splice(2, 0, {
								position: finalPosition,
								active: finlalCloseActive,
								tooltipText: `Closed on : ${finalCloseDate.format("MM/DD/YYYY")}`,
							});
						}
					}
					setTimelineData({
						now: now,
						points: points,
					});
				}
			}
			doShowLoader(false);
		};
		const failCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};
		doGetExchangeDetails(id, successCB, failCB);
	}, [doGetExchangeDetails, setTimelineData, doShowLoader, isReload, id]);
	useEffect(() => {
		doGetStates();
	}, [doGetStates]);
	useEffect(() => {
		const successCB = (response) => {
			// doShowLoader(false);
			setQiCompanies(response.result);
		};
		const failCB = (result) => {
			errorMessage(result);
			// doShowLoader(false);
		};
		if (CompanyType && CompanyType.length > 0) {
			const id = CompanyType.find((item) => {
				return item.label === "Qualified Intermediary";
			}).value;
			// doShowLoader(true);
			doGetEntityByType({ typeId: id, isInactiveReq: "false" }, successCB, failCB);
		}
	}, [doGetEntityByType, doShowLoader, CompanyType, setQiCompanies]);
	const onSubmitBasicInfo = (values) => {
		// console.log("submit values", values);
		const payload = getCopy(values);
		doShowLoader(true);
		const successCB = (result) => {
			successMessage(411);
			setIsEdit(false);
			doShowLoader(false);
			setReload(!isReload);
		};
		const failCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
			setReload(!isReload);
		};
		// console.log(values);
		// if (payload.day45) {
		// 	payload.day45 = new Date(payload.day45);
		// }
		// if (payload.openDate) {
		// 	payload.openDate = new Date(payload.openDate);
		// }
		// if (payload.rel1CloseDate) {
		// 	payload.rel1CloseDate = new Date(payload.rel1CloseDate);
		// }
		// if (payload.day180) {
		// 	payload.day180 = new Date(payload.day180);
		// }
		// if (payload.actualDDate) {
		// 	payload.actualDDate = new Date(payload.actualDDate);
		// }
		// if (payload.finalCloseDate) {
		// 	payload.finalCloseDate = new Date(payload.finalCloseDate);
		// }
		doUpdateExchange(
			{
				...payload,
				openDate: payload.openDate ? formatDateToISOString(payload.openDate) : null,
				rel1CloseDate: payload.rel1CloseDate ? formatDateToISOString(payload.rel1CloseDate) : null,
				day45: payload.day45 ? formatDateToISOString(payload.day45) : null,
				day180: payload.day180 ? formatDateToISOString(payload.day180) : null,
				actualDDate: payload.actualDDate ? formatDateToISOString(payload.actualDDate) : null,
				finalCloseDate: payload.finalCloseDate ? formatDateToISOString(payload.finalCloseDate) : null,
			},
			successCB,
			failCB
		);
	};
	const loadUsers = (roleId, searchKey, callback) => {
		const successCB = ({ result }) => {
			callback(result);
		};
		const FailCB = (result) => {
			callback([]);
		};
		if (searchKey.length > 1) {
			doSearchUsers({ roleId, searchKey }, successCB, FailCB);
		} else {
			callback([]);
		}
	};
	const loadContacts = (searchKey, callback) => {
		const id = exchangeDetail.entityName.value;
		const successCB = ({ result }) => {
			callback(result);
		};
		const FailCB = (result) => {
			callback([]);
		};
		if (searchKey.length > 1) {
			doSearchContacts({ id, searchKey }, successCB, FailCB);
		} else {
			callback([]);
		}
	};
	const loadCounties = (searchKey, callback) => {
		const successCB = ({ result }) => {
			callback(result);
		};
		const FailCB = (result) => {
			callback([]);
		};
		if (searchKey.length > 1) {
			doGetCounties(searchKey, successCB, FailCB);
		} else {
			callback([]);
		}
	};
	const addNote = (values) => {
		doShowLoader(true);
		const backendFilter = {
			objectTypeId: 152,
			objectId: exchangeDetail.id,
			message: values.message,
			TypeId: 152,
		};
		const successCB = (result) => {
			doShowLoader(false);
			successMessage(405);
			doGetNotes({ ...backendFilter, Id: exchangeDetail.id });
			setShowNoteAdd(true);
		};
		const FailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};
		doCreateNote(backendFilter, successCB, FailCB);
	};
	const deleteNote = (id) => {
		const backendFilter = {
			Id: exchangeDetail.id,
			TypeId: 152,
		};
		doShowLoader(true);
		const successCB = (result) => {
			doShowLoader(false);
			successMessage(406);
			doGetNotes(backendFilter);
		};
		const FailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};
		doDeleteNote(id, successCB, FailCB);
	};
	const handleClose = () => {
		setNoteshowPopup(false);
	};
	const [showEmailPopup, setEmailshowPopup] = useState(false);
	const handleEmailClose = () => {
		setEmailshowPopup(false);
	};
	const sendEmail = (values) => {
		doShowLoader(true);
		const successCB = (result) => {
			setEmailshowPopup(false);
			doShowLoader(false);
			successMessage(401);
		};
		const FailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};
		doSendEmail({ ...values, objectId: exchangeDetail.id }, successCB, FailCB);
	};
	const changeLock = (row) => {
		doShowLoader(true);
		const successCB = (result) => {
			doShowLoader(false);
			successMessage(402);
		};
		const FailCB = (result) => {
			doShowLoader(false);
			errorMessage(result);
		};
		doExchangeUnlock(exchangeDetail.guid, successCB, FailCB);
	};

	const editGeneratedDocument = (id) => {
		setShoWGenerateDocumnt(true);
		setDocumentId(id);
	};
	const exchagerInitialInfo = { ...exchangerInformation };
	if (
		exchangerInformation &&
		exchangerInformation.teamInformation &&
		!exchangerInformation.teamInformation.exchangeCompanyName
	) {
		const defaultExchanger = qiCompanies.find((item) => item.isDefault);
		if (defaultExchanger) {
			exchangerInformation.teamInformation.exchangeCompanyName = { ...defaultExchanger, isInactiveReq: "false" };
		}
	}
	let breadcrumbs = [
		{
			label: "Exchanges",
			onClick: () => {
				const path = `/exchanges`;
				history.push(path);
			},
		},
		{ label: "Exchange Details" },
	];
	if (shoWGenerateDocumnt) {
		breadcrumbs = [
			{
				label: "Exchanges",
				onClick: () => {
					const path = `/exchanges`;
					history.push(path);
				},
			},
			{
				label: "Exchange Details",
				onClick: () => {
					setShoWGenerateDocumnt(false);
					setDocumentId(null);
					setDefaultTab("document");
				},
			},
			{ label: "Generate Document" },
		];
	}
	return (
		<Layout breadcrumbs={breadcrumbs} title="Exchanges" className="exchange-detail-page">
			{!shoWGenerateDocumnt && (
				<>
					<Card className="page-card">
						<TimeLine
							startLabel="Exchange Created"
							endLabel="Closed"
							now={timeLineData.now}
							points={timeLineData.points}
						/>
					</Card>
					<NoteButton
						onClick={() => {
							setNoteshowPopup(true);
						}}
					/>
					<div className="info-section-edit d-flex justify-content-between">
						<div className="exchange-top-info">
							<span className="label modifiedTimeLabel"> Last modified on : </span>
							<span className="modifiedTime">
								{exchangeDetail.lastModificationTime ? (
									<Date utcTime={exchangeDetail.lastModificationTime} format={dateFormat} />
								) : (
									<Date utcTime={null} format={dateFormat} />
								)}
							</span>
							<span className="label modifiedTimeLabel">By : </span>
							<span className="modifiedTime capitalize">{exchangeDetail.lastModifierUser}</span>
						</div>
						<div className="d-flex mt-0">
							{isLocked && userDetails && userDetails.unlockPermission && (
								<div className="exchange-top-icons mt-2">
									<Tooltip
										message={"Click To Unlock"}
										component={
											<span
												className="user-action-icon mr-0 unlock-main-icon"
												onClick={() => changeLock()}>
												<span className="icon-lock" />
											</span>
										}
									/>
								</div>
							)}
							<div className="exchange-top-icons ml-2">
								<Button className="form-button-primary" onClick={() => setEmailshowPopup(true)}>
									Send Mail
								</Button>
							</div>
						</div>
					</div>
					<BasicInfoForm
						isEdit={isEdit}
						setIsEdit={setIsEdit}
						initialValues={exchangeDetail}
						exchangeDetail={exchangeDetail}
						onSubmit={onSubmitBasicInfo}
						isLocked={isLocked}
					/>
					<Card>
						<Tabs className="mr-0" defaultActiveKey={defaultTab} id="uncontrolled-tab-example" mountOnEnter={true}>
							<Tab eventKey="exhanger" title="Exchanger Information">
								<ExchangerInfoTab
									loadUsers={loadUsers}
									setReload={setReload}
									isReload={isReload}
									loadCounties={loadCounties}
									qiCompanies={qiCompanies}
									initialValues={exchagerInitialInfo}
									exchangerType={exchangeDetail.exchangerType}
									exchangeDetail={exchangeDetail}
									exchangerInformation={exchangerInformation}
									loadContacts={loadContacts}
									isLocked={isLocked}
								/>
							</Tab>
							<Tab eventKey="relinquish" title="Relinquished Property Information">
								<RelinquishPropertyTab
									loadUsers={loadUsers}
									setReload={setReload}
									isReload={isReload}
									loadCounties={loadCounties}
									qiCompanies={qiCompanies}
									initialValues={relinquishedProperties}
									relinquishedProperties={relinquishedProperties}
									exchangerType={exchangeDetail.exchangerType}
									exchangeDetail={exchangeDetail}
									exchangerInformation={exchangerInformation}
									loadContacts={loadContacts}
									isLocked={isLocked}
								/>
							</Tab>
							<Tab eventKey="replacement" title="Replacement Property Information">
								<ReplacementPropertyTab
									loadUsers={loadUsers}
									setReload={setReload}
									isReload={isReload}
									loadCounties={loadCounties}
									qiCompanies={qiCompanies}
									initialValues={replacementProperties}
									replacementProperties={replacementProperties}
									exchangerType={exchangeDetail.exchangerType}
									exchangeDetail={exchangeDetail}
									exchangerInformation={exchangerInformation}
									loadContacts={loadContacts}
									isLocked={isLocked}
								/>
							</Tab>
							<Tab eventKey="bank" title="Bank Transaction & Income">
								<TransactionAndIncome isLocked={isLocked} />
							</Tab>
							<Tab eventKey="document" title="Documents">
								<DocumentTab
									exchangeDetail={exchangeDetail}
									setShoWGenerateDocumnt={setShoWGenerateDocumnt}
									editGeneratedDocument={editGeneratedDocument}
									isLocked={isLocked}
								/>
							</Tab>
							<Tab eventKey="task" title="Tasks">
								<Tasks isLocked={isLocked} />
							</Tab>
						</Tabs>
					</Card>
				</>
			)}
			{shoWGenerateDocumnt && (
				<GenerateDocumentWizard
					setShoWGenerateDocumnt={setShoWGenerateDocumnt}
					exchangeDetail={exchangeDetail}
					documentId={documentId}
					setDefaultTab={setDefaultTab}
				/>
			)}
			{showNotePopup ? (
				<NoteSlider
					onSubmit={addNote}
					currentExchange={exchangeDetail}
					show={showNotePopup}
					handleClose={handleClose}
					showAdd={showNoteAdd}
					deleteNote={deleteNote}
					setShowAdd={setShowNoteAdd}
				/>
			) : null}
			{showEmailPopup ? (
				<EmailSlider
					currentExchange={exchangeDetail}
					onSubmit={sendEmail}
					show={showEmailPopup}
					handleClose={handleEmailClose}
					initialValues={{ subject: `Exchange - ${exchangeDetail.exchangeNumber}` }}
				/>
			) : null}
		</Layout>
	);
};
const mapStateToProps = (state) => {
	const {
		Common: { CompanyType, Modules },
		Exchange: {
			exchangeDetail = {},
			exchangerInformation,
			replacementProperties,
			relinquishedProperties,
			exchangeInformation,
			isLocked,
		},
		Auth: { userDetails = {} },
	} = state;
	return {
		exchangeDetail,
		Modules,
		CompanyType,
		relinquishedProperties,
		replacementProperties,
		exchangerInformation: exchangerInformation ? exchangerInformation : exchangeInformation,
		isLocked,
		userDetails,
	};
};
const mapDispatchToProps = {
	doShowLoader: showLoader,
	doGetExchangeDetails: getExchangeDetails,
	doUpdateExchangerInfo: updateExchangerInfo,
	doSearchContacts: searchContacts,
	doSearchUsers: searchUsers,
	doGetEntityByType: getEntityByType,
	doUpdateAddress: updateAddress,
	doGetStates: getStates,
	doGetCounties: getCounties,
	doUpdateExchange: updateExchange,
	doCreateNote: createNote,
	doDeleteNote: deleteNote,
	doGetNotes: getNotes,
	doSendEmail: sendEmail,
	doExchangeUnlock: exchangeUnlock,
};
export default connect(mapStateToProps, mapDispatchToProps)(ExchangeView);
