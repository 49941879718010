import React from "react";

import { MoreOptions } from "../../SharedComponts/FormElements";
import { Date } from "../../../utils/formatter";
import { dateFormat, userStatus } from "../../../constant/common";

import FormControl from "../../SharedComponts/FormControl";
import { required, email, USPhoneNumber, normalizePhone, max5, normalizeSSN } from "../../../constant/formValidators";

export const columns = ({ onSelectUser, onEditUser = null, onDeleteUser = null }) => [
	{
		name: "Name",
		accessor: "displayName",
		className: "col-exchangerName",
		headerClassName: "col-exchangerName-header",
		render: (row) => (
			<div className="text-highlighted" onClick={() => onSelectUser(row)}>
				{row.displayName}
			</div>
		),
	},
	{
		name: "Type",
		accessor: "exchangerType",
		className: "col-exchangerType",
		sortable: false,
		headerClassName: "col-exchangerType-header",
	},
	{
		name: "Status",
		accessor: "status",
		className: "col-status",
		headerClassName: "col-status-header",
	},
	{
		name: "Phone",
		accessor: "phoneNumber",
		className: "col-exchangerPhone",
		headerClassName: "col-exchangerPhone-header",
		sortable: false,
	},
	{
		name: "Created Date",
		accessor: "creationTime",
		className: "col-exchangerCrDate",
		headerClassName: "col-exchangerCrDate-header",
		render: (row) => <Date utcTime={row.creationTime} format={dateFormat} />,
	},
	{
		name: "Actions",
		className: "col-actions text-right",
		headerClassName: "col-actions-header",
		sortable: false,
		render: (row) => {
			if (row.exchangerType === "Individual") {
				return (
					<MoreOptions
						options={[
							{ label: "Edit", onClick: () => onEditUser(row) },
							{ label: "Delete", onClick: () => onDeleteUser(row) },
						]}
					/>
				);
			} else {
				return null;
			}
		},
	},
];

export const formFields = (states, cites, counties, doGetCities, doShowLoader, change, loadCounties, isEdit) => {
	return [
		{
			name: "name",
			label: "Exchanger Name",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			validate: [required],
			required: true,
		},
		{
			name: "jobTitle",
			label: "Title",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
		},
		{
			name: "emailAddress",
			label: "Email Address",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			validate: [required, email],
			required: true,
		},
		{
			name: "phoneNumber",
			label: "Phone",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			validate: [USPhoneNumber],
			normalize: normalizePhone,
		},
		{
			name: "phoneNumber2",
			label: "Phone 2",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			validate: [USPhoneNumber],
			normalize: normalizePhone,
		},
		{
			name: "fax",
			label: "Fax",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
		},
		{
			name: "ssn",
			label: "SSN",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			normalize: normalizeSSN,
		},
		{
			name: "address.streetAddress",
			label: "Address",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
		},
		{
			name: "address.state",
			label: "State",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			options: states,
			onChange: (selected) => {
				doShowLoader(true);
				change("address.city", null);
				doGetCities(
					selected.value,
					() => {
						doShowLoader(false);
					},
					() => {
						doShowLoader(false);
					}
				);
			},
		},
		{
			name: "address.city",
			label: "City",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			options: cites,
		},
		{
			name: "address.zipCode",
			label: "Zip Code",
			component: FormControl,
			type: "text",
			validate: [max5],
			className: "form-control form-field",
		},
		{
			name: "status",
			label: "Status",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			options: userStatus,
			hideField: !isEdit,
		},
	];
};
