import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { get } from "lodash";
import { Table, Pagination } from "../SharedComponts/FormElements/table";
import Layout from "../SharedComponts/Layout";
import SummaryCards from "../SharedComponts/SummaryCards";
import SearchField from "../SharedComponts/FormElements/searchTable";
import { errorMessage } from "../../utils/errorMessage";
import { columns as columnsFn, advancedFilterFormFields } from "./helper";
import { downloadFile } from "../../utils/helper";
import { getFormValues } from "redux-form";

import dhand from "../../assets/images/dhand.png";
import invoice from "../../assets/images/invoice.png";
import { useHistory } from "react-router-dom";

import "./income.scss";
import { getDateFromDatePicker } from "../../utils/helper";
import AdvancedFilter from "../SharedComponts/AdvancedFilter/advancedFilter";
import AppliedFilter from "../SharedComponts/AdvancedFilter/appliedFilter";

import { Currency } from "../../utils/formatter";

import {
	showLoader,
	setIncomeFilter,
	getFullIncomeList,
	incomeExport,
	getExchangeNumbers,
	getAllPropertyAddress,
} from "../../store/actions";

const Exchanges = ({
	filters,
	additionalParam,
	recordsTotal,
	incomeList,
	addressList,
	paymentStatus,
	formValues,
	doGetExchangeNumbers,
	doIncomeExport,
	doGetFullIncomeList,
	doSetFilter,
	doShowLoader,
	doGetAllPropertyAddress,
}) => {
	const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);
	const [exchangeNumberList, setExchangeNumberList] = useState([]);

	const history = useHistory();

	useEffect(() => {
		doShowLoader(true);
		const extraParams = {
			...filters.extraParams,
			dueDateFrom:
				filters.extraParams && filters.extraParams.fromDate
					? getDateFromDatePicker(filters.extraParams.fromDate)
					: null,
			dueDateTo:
				filters.extraParams && filters.extraParams.toDate ? getDateFromDatePicker(filters.extraParams.toDate) : null,
		};
		const backendFilter = { ...filters, ...extraParams, extraParams };
		const successCB = (result) => {
			doShowLoader(false);
		};
		const FailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		doGetFullIncomeList(backendFilter, successCB, FailCB);
	}, [filters, doGetFullIncomeList, doShowLoader]);

	const columns = columnsFn({ history });

	useEffect(() => {
		const successCBSub = (result) => {
			setExchangeNumberList(result.result);
		};
		const FailCBSub = (result) => {
			errorMessage(result);
		};
		doGetExchangeNumbers(null, successCBSub, FailCBSub);
	}, [doGetExchangeNumbers]);
	useEffect(() => {
		const FailCBSub = (result) => {
			errorMessage(result);
		};
		doGetAllPropertyAddress(null, FailCBSub);
	}, [doGetAllPropertyAddress]);

	const exportIncome = () => {
		doShowLoader(true);
		const extraParams = {
			...filters.extraParams,
			dueDateFrom:
				filters.extraParams && filters.extraParams.fromDate
					? getDateFromDatePicker(filters.extraParams.fromDate)
					: null,
			dueDateTo:
				filters.extraParams && filters.extraParams.toDate ? getDateFromDatePicker(filters.extraParams.toDate) : null,
		};
		const backendFilter = { ...filters, ...extraParams, extraParams };
		const successCB = (result) => {
			doShowLoader(false);
			downloadFile(result.result, "Income List");
		};
		const FailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		doIncomeExport(backendFilter, successCB, FailCB);
	};

	const advancedFilters = advancedFilterFormFields(exchangeNumberList, addressList, formValues, paymentStatus);
	const handleCloseFilter = () => {
		setShowAdvancedFilter(false);
	};
	const handleCancelFilter = (key) => {
		const extraParams = { ...filters.extraParams };
		delete extraParams[key];

		doSetFilter({
			...filters,
			extraParams: extraParams,
			pageIndex: 1,
		});
	};
	const clearAllFilter = () => {
		doSetFilter({
			...filters,
			extraParams: {},
			pageIndex: 1,
		});
	};
	const handleSubmitFilter = (values) => {
		doSetFilter({
			...filters,
			extraParams: values,
			pageIndex: 1,
		});
	};
	const ifFilterExist = () => {
		return (
			filters.extraParams &&
			(filters.extraParams.fromDate ||
				filters.extraParams.toDate ||
				filters.extraParams.property ||
				filters.extraParams.exchangeNumber ||
				filters.extraParams.status ||
				filters.extraParams.fromDate ||
				filters.extraParams.toDate)
		);
	};
	return (
		<Layout
			breadcrumbs={[{ label: "Income" }]}
			title="Income"
			rightActionButtons={[]}
			className="exchange-listing-page income-page">
			{incomeList ? (
				<>
					<SummaryCards
						cards={[
							{
								label: "Amount Received",
								count: additionalParam && <Currency amount={additionalParam.paidAmount} />,
								countColor: "#4899f2",
								icon: dhand,
								className: "col-lg-4 col-md-6 col-sm-12 mb-sm-2 mb-xs-2",
							},
							{
								label: "Amount Billed",
								count: additionalParam && <Currency amount={additionalParam.billedAmount} />,
								icon: invoice,
								countColor: "#00aa63",
								className: "col-lg-4 col-md-6 col-sm-12 mb-sm-2 mb-xs-2",
							},
						]}
					/>
					<div className="info-button filter-option text-highlighted" onClick={exportIncome}>
						<span className={`icon-excel`}></span>Export
					</div>
					<div
						onClick={() => {
							setShowAdvancedFilter(true);
						}}
						className={`info-button filter-option text-highlighted ${ifFilterExist() ? "filter-applied" : null}`}>
						<span className={`icon-filter`}></span>Filter
					</div>

					<SearchField
						initialValue={filters.searchKey}
						onSearch={(searchKey) =>
							doSetFilter({
								...filters,
								searchKey,
								pageIndex: 1,
							})
						}
						className={`flex-fill mb-0`}
						placeholder="Enter your query"
					/>

					{showAdvancedFilter ? (
						<AdvancedFilter
							onSubmit={handleSubmitFilter}
							handleClose={handleCloseFilter}
							formFields={advancedFilters}
							initialValues={filters.extraParams}
							clearAllFilter={clearAllFilter}
						/>
					) : ifFilterExist() ? (
						<AppliedFilter
							handleClose={handleCancelFilter}
							advancedFilters={advancedFilters}
							values={filters.extraParams}
						/>
					) : null}

					<Table
						columns={columns}
						total={<Currency amount={additionalParam.totalIncome} />}
						totalLabel="Total Income"
						data={incomeList}
						onSort={(e) =>
							e.by !== filters.sortColumn || e.order !== filters.sortDirection
								? doSetFilter({
										...filters,
										sortColumn: e.by,
										sortDirection: e.order,
										pageIndex: 1,
								  })
								: null
						}
						sortBy={filters.sortColumn}
						sortDir={filters.sortDirection}
					/>
					<Pagination
						total={recordsTotal}
						count={filters.pageSize}
						page={filters.pageIndex}
						onChange={(e) => {
							doSetFilter({ ...filters, pageIndex: e });
						}}
						onPageCountChange={(e) => {
							doSetFilter({ ...filters, pageSize: e, pageIndex: 1 });
						}}
					/>
				</>
			) : null}
		</Layout>
	);
};

const mapStateToProps = (state) => {
	return {
		addressList: get(state, "Accounting.addressList", []),
		recordsTotal: get(state, "Accounting.incomeList.recordsTotal", 0),
		additionalParam: get(state, "Accounting.incomeList.additionalParam", {}),
		incomeList: get(state, "Accounting.incomeList.data", []),
		filters: get(state, "Accounting.incomeFilters", []),
		formValues: getFormValues("advancedFilterForm")(state),
		paymentStatus: get(state, "Common.paymentStatus", []),
	};
};

const mapDispatchToProps = {
	doSetFilter: setIncomeFilter,
	doGetFullIncomeList: getFullIncomeList,
	doShowLoader: showLoader,
	doIncomeExport: incomeExport,
	doGetExchangeNumbers: getExchangeNumbers,
	doGetAllPropertyAddress: getAllPropertyAddress,
};

export default connect(mapStateToProps, mapDispatchToProps)(Exchanges);
