import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { Card } from "react-bootstrap";
import Layout from "../../SharedComponts/Layout";
import { Heading } from "../../SharedComponts/FormElements";
import { Field, reduxForm, getFormValues } from "redux-form";
import { formFields, formFieldsDateSection } from "./helper";
import { Button } from "react-bootstrap";

import {
	getEntityList,
	getCounties,
	getCities,
	getStates,
	showLoader,
	createEntity,
	createExchanger,
	createExchange,
} from "../../../store/actions";

import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";
import { getStatus, getRole, getExchangerTypeId, getDateFromDatePicker,formatDateToISOString} from "../../../utils/helper";

import AddEntity from "./AddEntity";
import AddEntityIndividual from "./AddEntityIndividual";
const InfoItem = ({ label, required, ...model }) => {
	return (
		<div className="info-item w-25 pr-4">
			<div className="label">
				{label} {required ? <span className="required-label">*</span> : null}
			</div>
			<div className="value">
				<Field {...model} />
			</div>
		</div>
	);
};
const CreateExchange = ({
	history,
	exchangeType,
	exchangerType,
	exchangeStatus,
	submitting,
	change,
	reset,
	formValues,
	doGetEntityList,
	doGetCounties,
	doGetStates,
	doGetCities,
	doShowLoader,
	doCreateEntity,
	companyType,
	doCreateExchanger,
	handleSubmit,
	doCreateExchange,
}) => {
	const [showPopup, setshowPopup] = useState(false);

	const handleSubmitExchange = (value) => {
		doShowLoader(true);
	
		const successCB = (result) => {
			doShowLoader(false);
			// successMessage(203);
			result && result.result && result.result.guid && history.push(`/exchanges/${result.result.guid}`);
		};
	
		const failCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};
	
		const postData = {
			...value,
			openDate: value.openDate ? formatDateToISOString(value.openDate) : null,
			rel1CloseDate: value.rel1CloseDate ? formatDateToISOString(value.rel1CloseDate) : null,
			day45: value.day45 ? formatDateToISOString(value.day45) : null,
			day180: value.day180 ? formatDateToISOString(value.day180) : null,
			actualDDate: value.actualDDate ? formatDateToISOString(value.actualDDate) : null,
			finalCloseDate: value.finalCloseDate ? formatDateToISOString(value.finalCloseDate) : null,
		};
	
		doCreateExchange(postData, successCB, failCB);
	};
	

	const handleSubmitEntity = async (value) => {
		doShowLoader(true);
		const successCB = (result) => {
			doShowLoader(false);
			//showPopup = 1 entity , 2 individual exchanger
			successMessage(showPopup === 1 ? 206 : 203);
			const setObject = {
				label: showPopup === 1 ? result.result.entityName : result.result.name,
				value: showPopup === 1 ? result.result.id : result.result.userId,
			};
			change("entityName", setObject);
			setshowPopup(false);
		};
		const FailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};
		if (showPopup === 1) {
			const companyTypeObject = formValues
				? formValues.exchangerType
					? formValues.exchangerType.value === 203
						? { value: 228, label: "Trust or Other" }
						: { value: 226, label: "Exchanger" }
					: null
				: null;

			const postData = {
				...value,
				companyType: companyTypeObject,
				status: getStatus("Active"),
			};

			doCreateEntity({ ...postData }, successCB, FailCB);
		} else if (showPopup === 2) {
			const postData = {
				...value,
				userRoleId: getRole("Exchanger"),
				exchangerType: { value: getExchangerTypeId("Individual"), label: "Individual" },
				status: getStatus("Active"),
			};
			doCreateExchanger(postData, successCB, FailCB);
		}
	};

	const loadEntities = (searchKey, callback) => {
		const successCB = ({ result }) => {
			callback(result);
		};
		const FailCB = (result) => {
			callback([]);
		};
		if (searchKey.length > 1) {
			doGetEntityList({ searchKey, id: formValues.exchangerType.value }, successCB, FailCB);
		} else {
			callback([]);
		}
	};

	const showAddEntityPopup = (value) => {
		//2 Individual exchanger , 1 - company type entity
		const popupType =
			formValues && formValues.exchangerType && formValues.exchangerType.value === getExchangerTypeId("Individual")
				? 2
				: 1;
		setshowPopup(popupType);
	};
	const handleClose = () => {
		//reset();
		setshowPopup(false);
	};

	const loadCounties = (searchKey, callback) => {
		const successCB = ({ result }) => {
			callback(result);
		};
		const FailCB = (result) => {
			callback([]);
		};
		if (searchKey.length > 1) {
			doGetCounties(searchKey, successCB, FailCB);
		} else {
			callback([]);
		}
	};

	useEffect(() => {
		doGetStates();
		const setObject = { label: "Delayed", value: 351 };
		change("exchangeType", setObject);
		change("openDate", new Date());
		// eslint-disable-next-line
	}, [doGetStates]);

	return (
		<Layout
			breadcrumbs={[
				{
					label: "Exchanges",
					onClick: () => {
						const path = `/exchanges`;
						history.push(path);
					},
				},
				{ label: "Create Exchange" },
			]}
			className="user-details admin-details"
			title={`Create Exchange`}>
			<form className="inline-form" onSubmit={handleSubmit(handleSubmitExchange)}>
				<Card>
					<Card.Title className="info-card-title">
						<Heading title="Basic Information" />
					</Card.Title>
					<Card.Body className="info-card-body d-flex flex-row">
						{formFields(
							exchangeType,
							exchangerType,
							exchangeStatus.filter((item) => {
								return item.label && (item.label === "Active" || item.label === "New");
							}),
							change,
							loadEntities,
							formValues,
							showAddEntityPopup
						).map((item, index) => {
							return <InfoItem key={index} {...item} />;
						})}
					</Card.Body>

					<Card.Title className="info-card-title">
						<Heading title="Important Dates" />
					</Card.Title>
					<Card.Body className="info-card-body d-flex flex-row">
						{formFieldsDateSection({ change, formValues }).map((item, index) => {
							return <InfoItem key={index} {...item} />;
						})}
					</Card.Body>
				</Card>
				<div className="float-buttons">
					<Button className="form-button-light mr-2" onClick={() => history.push(`/exchanges`)} disabled={submitting}>
						Cancel
					</Button>
					<Button className="form-button-primary" type="submit" disabled={submitting}>
						Create
					</Button>
				</div>
			</form>
			{showPopup === 1 ? (
				<AddEntity
					loadCounties={loadCounties}
					doGetCities={doGetCities}
					show={showPopup >= 1}
					handleClose={handleClose}
					onSubmit={handleSubmitEntity}
				/>
			) : null}

			{showPopup === 2 ? (
				<AddEntityIndividual
					loadCounties={loadCounties}
					doGetCities={doGetCities}
					show={showPopup >= 1}
					handleClose={handleClose}
					onSubmit={handleSubmitEntity}
					doShowLoader={doShowLoader}
				/>
			) : null}
		</Layout>
	);
};

const mapStateToProps = (state) => {
	const {
		Common: { ExchangeType = [], ExchangerType = [], ExchangeStatus = [], CompanyType = [] },
	} = state;
	return {
		exchangeType: ExchangeType,
		exchangerType: ExchangerType,
		exchangeStatus: ExchangeStatus,
		companyType: CompanyType,
		formValues: getFormValues("CreateExchangeForm")(state),
	};
};
const mapDispatchToProps = {
	doGetEntityList: getEntityList,
	doGetCounties: getCounties,
	doGetStates: getStates,
	doGetCities: getCities,
	doShowLoader: showLoader,
	doCreateEntity: createEntity,
	doCreateExchanger: createExchanger,
	doCreateExchange: createExchange,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "CreateExchangeForm",
		enableReinitialize: true,
	})(CreateExchange)
);
