import React from "react";
import { Button } from "react-bootstrap";
import CustomScroll from "react-custom-scrollbars";
import { connect } from "react-redux";
import { updateUser, showLoader } from "../../store/actions";
import { errorMessage } from "../../utils/errorMessage";
import { successMessage } from "../../utils/successMessage";

const OtpForm = (props) => {
    const { submitting, handleClosed, children, hideSubmit, doShowLoader, otpCompleted, submitData, otp, doUpdateUser, } = props;

    const handleSubmit = (e) => {
        e.preventDefault();
        doShowLoader(true);

        const successCB = (response) => {
            doShowLoader(false);
            successMessage(202)
            setTimeout(function () {
                window.location.reload();
            }, 2000);
        };

        const FailCB = (result) => {
            errorMessage(10528);
            doShowLoader(false);
        };

        const postData = { ...submitData, otp: otp }
        doUpdateUser(postData, successCB, FailCB);
    }
    return (
        <form className="slider-form" onSubmit={(e) => handleSubmit(e)}>
            <CustomScroll style={{ height: "calc(100vh - 125px)" }}>
                <div className="slider-form-fields">{children}</div>
            </CustomScroll>

            {!hideSubmit ? (
                <div className="form-button">
                    <Button className="form-button-primary" type="submit" disabled={submitting || !otpCompleted}>
                        Done
                    </Button>

                    <Button className="form-button-light mr-2" onClick={handleClosed} disabled={submitting}>
                        Back
                    </Button>
                </div>
            ) : null}
        </form>
    );
};
const mapStateToProps = (state) => {
    const {
        Settings: { companyList = [] },
        Auth: { userDetails = {} },
    } = state;
    return {
        companyList,
        userDetails,
    };
};
const mapDispatchToProps = {
    doUpdateUser: updateUser,
    doShowLoader: showLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(OtpForm);
