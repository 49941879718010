import React from "react";
const renderField = ({ id, input, label, type, maxLength, placeholder, rows, cols, meta: { touched, error, warning } }) => {
	return (
		<div className="input-textarea-container">
			<textarea
				rows={rows}
				cols={cols}
				{...input}
				placeholder={placeholder}
				maxLength={maxLength ? maxLength : null}
				className="form-control"
			/>
			{touched && ((error && <span className="form-error-span">{error}</span>) || (warning && <span>{warning}</span>))}
		</div>
	);
};
export default renderField;
