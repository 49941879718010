import { COMMON } from "../types";

export default (state = { showLoader: 0 }, action) => {
	switch (action.type) {
		case COMMON.SHOW_LOADER:
			return {
				...state,
				showLoader: action.payload ? state.showLoader + 1 : state.showLoader - 1,
			};
		default:
			return state;
	}
};
