
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, getFormValues } from "redux-form";
import FormControl from "../../SharedComponts/FormControl";
import { Button, Card } from "react-bootstrap";
import { showLoader, createTemplate, updateTemplate, getTeplatePlaceholderMenu } from "../../../store/actions";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";
import Layout from "../../SharedComponts/Layout";

const TemplateEdit = (props) => {
	const {
		history,
		currentRecord,
		reload,
		setIsList,
		isEdit,
		doUpdateTemplate,
		doGetTeplatePlaceholderMenu,
		formValues,
		doCreateTemplate,
		doShowLoader,
		handleSubmit,
	} = props;

	const [editorMenu, setEditorMenu] = useState(null);
	const [templateHTML, setTemplateHTML] = useState("");

	const setDocumentDraftHtml = (data) => {
		setTemplateHTML(data);
	};
	useEffect(() => {
		doShowLoader(true);

		const successCB = (response) => {
			setTemplateHTML(currentRecord?.templateHTML);
			doShowLoader(false);
			if (!editorMenu) {
				setEditorMenu({
					name: "Menu",
					menuList: Array.isArray(response.result)
						? response.result
						: [response.result.toString()],
				});
			}
		};

		const failCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		if (currentRecord.templateType) {
			doGetTeplatePlaceholderMenu(currentRecord.templateType.value, successCB, failCB);
		}
	}, [currentRecord.templateType, currentRecord.templateHTML, doShowLoader, doGetTeplatePlaceholderMenu]);

	const saveAsDraft = () => {
		doShowLoader(true);
		const successCB = () => {
			doShowLoader(false);
			successMessage(511);
			reload();
			setIsList(true);
		};
		const failCB = (result) => {
			doShowLoader(false);
			errorMessage(result);
		};

		if (isEdit) {
			doUpdateTemplate(
				{
					...formValues,
					templateHTML,
					templateStatus: { value: 702, label: "Saved as Draft" },
				},
				successCB,
				failCB
			);
		} else {
			doCreateTemplate(
				{ ...formValues, templateHTML, templateStatus: { value: 702, label: "Saved as Draft" } },
				successCB,
				failCB
			);
		}
	};

	const submitTemplate = (values) => {
		doShowLoader(true);

		const successCB = () => {
			doShowLoader(false);
			successMessage(510);
			reload();
			setIsList(true);
		};
		const failCB = (result) => {
			doShowLoader(false);
			errorMessage(result);
		};

		if (isEdit) {
			delete values.lastModifiedTime;
			delete values.lastModifiedUser;
			delete values.templateUrl;

			doUpdateTemplate(
				{
					...values,
					templateHTML,
					templateStatus: {
						value: 701,
						label: "Published",
					},
				},
				successCB,
				failCB
			);
		} else {
			doCreateTemplate(
				{
					...values,
					templateHTML,
					templateStatus: {
						value: 701,
						label: "Published",
					},
				},
				successCB,
				failCB
			);
		}
	};

	return (
		<>
			<Layout
				title="Templates"
				breadcrumbs={[
					{
						label: "Configurations",
						onClick: () => {
							const path = `/settings-master`;
							history.push(path);
						},
					},
					{
						label: "Templates",
						onClick: () => {
							reload();
							setIsList(true);
						},
					},
					{ label: "Edit Template" },
				]}
				className="bank-page"
			>
				<Card>
					<div className="col-12 editor-head">
						<div className="head-item">
							<span className="label">Template Name :</span>
							<span className="value"> {currentRecord?.templateName}</span>
						</div>
						<div className="head-item">
							<span className="label">Exchange Type :</span>
							<span className="value">{currentRecord?.exchangeType?.label}</span>
						</div>
					</div>
					<form onSubmit={handleSubmit(submitTemplate)}>
						{!!editorMenu && (
							<Field
								onChange={(event, newValue, previousValue, name) => {
								}}
								textValue={currentRecord.templateHTML}
								name="templateHTML"
								component={FormControl}
								type="editor"
								menu={editorMenu}
								className="form-control form-field"
							/>
						)}
						<div className="mb-4 mt-25 d-flex justify-content-end">
							<Button className="form-button-primary mr-1" onClick={saveAsDraft}>
								Save as Draft
							</Button>
							<Button type="submit" className="form-button-primary">
								Publish
							</Button>
						</div>
					</form>
				</Card>
			</Layout>
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		Template: { formInputs = {} },
	} = state;
	return { formInputs, formValues: getFormValues("templateMasterForm")(state) };
};

const mapDispatchToProps = {
	doShowLoader: showLoader,
	doCreateTemplate: createTemplate,
	doGetTeplatePlaceholderMenu: getTeplatePlaceholderMenu,
	doUpdateTemplate: updateTemplate,
};

export default connect(mapStateToProps, mapDispatchToProps)(
	reduxForm({
		form: "templateMasterForm",
		enableReinitialize: true,
	})(TemplateEdit)
);
