import React from "react";

import { MoreOptions } from "../../SharedComponts/FormElements";
import { Date } from "../../../utils/formatter";
import { dateFormat, userStatus } from "../../../constant/common";

import FormControl from "../../SharedComponts/FormControl";

import { required, validateString, email, USPhoneNumber, normalizePhone } from "../../../constant/formValidators";

export const columns = ({ onSelectUser, onEditUser = null, sendActivationLink, onDeleteUser = null }) => [
	{
		name: "Name",
		accessor: "displayName",
		className: "col-user-name",
		headerClassName: "col-user-name-header",
		render: (row) => (
			<div className="text-highlighted" onClick={() => onSelectUser(row)}>
				{row.displayName}
			</div>
		),
	},
	{
		name: "Phone",
		accessor: "phoneNumber",
		className: "col-phone",
		headerClassName: "col-phone-header",
	},
	{
		name: "Role",
		accessor: "roleName",
		className: "col-jobTitle",
		headerClassName: "col-jobTitle-header",
	},
	{
		name: "Email",
		accessor: "emailAddress",
		className: "col-email",
		headerClassName: "col-email-header",
	},
	{
		name: "Created Date",
		accessor: "creationTime",
		className: "col-date",
		headerClassName: "col-date-header",
		render: (row) => <Date utcTime={row.creationTime} format={dateFormat} />,
	},
	{
		name: "Status",
		accessor: "status",
		className: "col-status",
		headerClassName: "col-status-header",
	},
	{
		name: "Actions",
		className: "col-actions text-right",
		headerClassName: "col-actions-header",
		sortable: false,
		render: (row) => {
			return (
				<MoreOptions
					options={[
						{ label: "Edit", onClick: () => onEditUser(row) },
						{ label: "Reset Password", onClick: () => sendActivationLink(row) },
						{ label: "Delete", onClick: () => onDeleteUser(row) },
					]}
				/>
			);
		},
	},
];

export const columnsActivityLog = (getExchangeModuleID, history) => [
	{
		name: "Description",
		accessor: "description",
		className: "col-description",
		sortable: false,
		headerClassName: "col-description",
		hideToolTip: true,
		render: (row) => (
			<div>
				{row.description}
				{row.moduleTypeId === getExchangeModuleID() ? (
					<span
						onClick={() => {
							history.push(`/exchanges/${row.guid}`);
						}}
						className="text-highlighted">
						{" "}
						{row.exchangeNumber}
					</span>
				) : null}
			</div>
		),
	},
	{
		name: "Created On",
		accessor: "createdOn",
		className: "col-createdOn",
		headerClassName: "col-createdOn",
		render: (row) => <Date utcTime={row.createdOn} format={dateFormat} />,
	},
];

export const formFields = (companyList, isProfile) => {
	return [
		{
			name: "name",
			label: "Name",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			validate: [required, validateString],
			required: true,
		},
		{
			name: "emailAddress",
			label: "Email Address",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			validate: [required, email],
			required: true,
		},
		{
			name: "phoneNumber",
			label: "Phone",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			validate: [USPhoneNumber],
			normalize: normalizePhone,
		},
		{
			name: "jobTitle",
			label: "Job Title",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
		},
		{
			name: "companyInput",
			label: "Company",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			options: companyList,
		},
		{
			name: "status",
			label: "Status",
			hideField: isProfile,
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			options: userStatus,
		},
	];
};
