import React from "react";
import "./summarycards.scss";

const SummaryCards = (props) => {
	const { cards = [] } = props;
	return (
		<div className="summary-card-wrap">
			{cards.map(({ label, count, countColor, icon, className }, index) => (
				<div key={index} className={`summary-card p-0 ${className ? className : "col-md-2 col-sm-2 mb-sm-2 mb-xs-2"}`}>
					<div className="item">
						<div className="info">
							<div className="label">{label}</div>
							<div className="count" style={{ color: countColor }}>
								{count}
							</div>
						</div>

						<img className="icon" alt="icon" src={icon} />
					</div>
				</div>
			))}
		</div>
	);
};
export default SummaryCards;
