import { NOTIFICATION } from "../types";

export default (
	state = {
		filters: {
			pageIndex: 1,
			pageSize: 10,
			searchKey: "",
			extraParams: {},
		},
		notificationList: [],
		reloadFlag: false,
		recordsTotal: 0,
	},
	action
) => {
	switch (action.type) {
		case NOTIFICATION.SET_FILTER:
			return {
				...state,
				filters: action.payload ? action.payload : {},
			};
		case NOTIFICATION.SET_FLAG:
			const prevFlag = state.reloadFlag;
			return {
				...state,
				reloadFlag: !prevFlag,
			};
		case NOTIFICATION.GET_UNREAD_NOTIF_COUNT:
			return {
				...state,
				unreadNotifCount: action.payload && action.payload.result ? action.payload.result : 0,
			};
		case NOTIFICATION.FETCH_NOTIFICATION:
			return {
				...state,
				notifications:
					action.payload && action.payload.result && action.payload.result.data ? action.payload.result.data : [],
			};
		case NOTIFICATION.FETCH_NOTIFICATION_LIST:
			return {
				...state,
				notificationList:
					action.payload && action.payload.result && action.payload.result.data ? action.payload.result.data : [],
				recordsTotal: action.payload && action.payload.result && action.payload.result.recordsTotal,
				totalCount: action.payload && action.payload.result && action.payload.result.totalCount,
			};
		default:
			return state;
	}
};
