import React, { useEffect, useState } from "react";

import { connect } from "react-redux";

import { Table, Pagination } from "../SharedComponts/FormElements/table";
import Layout from "../SharedComponts/Layout";
import { Button } from "react-bootstrap";
import SummaryCards from "../SharedComponts/SummaryCards";
import SearchField from "../SharedComponts/FormElements/searchTable";
import { errorMessage } from "../../utils/errorMessage";
import { successMessage } from "../../utils/successMessage";
import { columns as columnsFn, advancedFilterFormFields } from "./helper";
import { downloadFile } from "../../utils/helper";
import { getFormValues } from "redux-form";

import newIcon from "../../assets/images/New.png";
import activeIcon from "../../assets/images/Active.png";
import canceledIcon from "../../assets/images/Canceled.png";
import closedIcon from "../../assets/images/Closed.png";
import "./exchanges.scss";
import NoteSlider from "./NoteSlider";
import EmailSlider from "./EmailSlider";
import { getDateFromDatePicker } from "../../utils/helper";
import AdvancedFilter from "../SharedComponts/AdvancedFilter/advancedFilter";
import AppliedFilter from "../SharedComponts/AdvancedFilter/appliedFilter";
import Confirm from "../SharedComponts/FormElements/confirm";

import {
	showLoader,
	setExchangeFilter,
	getExchangeList,
	exchangeExport,
	getExchangers,
	getEntityByType,
	getSalesRepoList,
	sendEmail,
	exchangeUnlock,
	deleteExchange,
	createNote,
	deleteNote,
	getNotes,
} from "../../store/actions";

const Exchanges = ({
	history,
	doSetFilter,
	filters,
	additionalParam,
	doGetExchangeList,
	recordsTotal,
	doShowLoader,
	exchangeList,
	doExchangeExport,
	exchangeType,
	formValues,
	doGetExchangers,
	doGetEntityByType,
	doGetSalesRepoList,
	doSendEmail,
	exchangeDateOptions,
	doExchangeUnlock,
	doDeleteExchange,
	doCreateNote,
	doDeleteNote,
	doGetNotes,
	userDetails,
	change,
}) => {
	const [showNotePopup, setNoteshowPopup] = useState(false);
	const [reloadList, setReloadList] = useState(false);
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [currentExchange, setCurrentExchange] = useState(false);
	const [showEmailPopup, setEmailshowPopup] = useState(false);
	const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);
	const [exchangers, setExchangers] = useState([]);
	const [settlementCompanies, setSettlementCompanies] = useState([]);
	const [salesRepoList, setSalesRepoList] = useState([]);
	const [showNoteAdd, setShowNoteAdd] = useState(true);

	const addExchange = () => {
		const path = `/add-exchange`;
		history.push(path);
	};
	const onSelect = (row) => {
		history.push(`/exchanges/${row.guid}`);
	};
	const onEdit = (row) => {
		history.push(`/exchanges/${row.guid}`);
	};
	const handleClose = () => {
		setNoteshowPopup(false);
	};
	const handleEmailClose = () => {
		setEmailshowPopup(false);
	};
	const showNote = (row) => {
		setCurrentExchange(row);
		setNoteshowPopup(true);
	};
	const showEmail = (row) => {
		setCurrentExchange(row);
		setEmailshowPopup(true);
	};

	const sendEmail = (values) => {
		doShowLoader(true);
		const successCB = (result) => {
			setEmailshowPopup(false);
			doShowLoader(false);
			successMessage(401);
		};
		const FailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};
		doSendEmail({ ...values, objectId: currentExchange.id }, successCB, FailCB);
	};

	const onDelete = (row) => {
		setCurrentExchange(row);
		setConfirmDelete(true);
	};

	const confirmDeleteAction = () => {
		doShowLoader(true);
		const failCB = (result) => {
			doShowLoader(false);
			setCurrentExchange(null);
			setConfirmDelete(false);
			errorMessage(result);
		};
		const successCB = (result) => {
			doShowLoader(false);
			setCurrentExchange(null);
			setConfirmDelete(false);
			successMessage(403);
			setReloadList(!reloadList);
		};
		doDeleteExchange(currentExchange.guid, successCB, failCB);
	};

	const changeLock = (row) => {
		doShowLoader(true);
		const successCB = (result) => {
			doShowLoader(false);
			setReloadList(!reloadList);
			successMessage(402);
		};
		const FailCB = (result) => {
			doShowLoader(false);
			errorMessage(result);
		};
		doExchangeUnlock(row.guid, successCB, FailCB);
	};

	const addNote = (values) => {
		doShowLoader(true);
		const backendFilter = {
			objectTypeId: 152,
			objectId: currentExchange.id,
			message: values.message,
			TypeId: 152,
		};
		const successCB = (result) => {
			doShowLoader(false);
			successMessage(405);
			doGetNotes({ ...backendFilter, Id: currentExchange.id });
			setShowNoteAdd(true);
		};
		const FailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		doCreateNote(backendFilter, successCB, FailCB);
	};

	const deleteNote = (id) => {
		const backendFilter = {
			Id: currentExchange.id,
			TypeId: 152,
		};
		doShowLoader(true);
		const successCB = (result) => {
			doShowLoader(false);
			successMessage(406);
			doGetNotes(backendFilter);
		};
		const FailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		doDeleteNote(id, successCB, FailCB);
	};

	const rightActionButtons = [
		{
			component: () => {
				return (
					<Button className="form-button-primary" onClick={addExchange}>
						<span className="add-new-plus">+</span> New Exchange
					</Button>
				);
			},
		},
	];

	useEffect(() => {
		doShowLoader(true);
		const extraParams = {
			...filters.extraParams,
			fromDate:
				filters.extraParams && filters.extraParams.fromDate
					? getDateFromDatePicker(filters.extraParams.fromDate)
					: null,
			toDate:
				filters.extraParams && filters.extraParams.toDate ? getDateFromDatePicker(filters.extraParams.toDate) : null,
		};
		const backendFilter = { ...filters, ...extraParams, extraParams };
		const successCB = (result) => {
			doShowLoader(false);
		};
		const FailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		doGetExchangeList(backendFilter, successCB, FailCB);
	}, [filters, doGetExchangeList, doShowLoader, reloadList]);

	const columns = columnsFn({
		onSelect,
		onEdit,
		onDelete,
		showNote,
		showEmail,
		changeLock,
		userDetails,
	});
	useEffect(() => {
		const successCB = (result) => {
			setExchangers(result.result);
		};
		const FailCB = (result) => {
			errorMessage(result);
		};
		doGetExchangers(null, successCB, FailCB);
		const successCBSub = (result) => {
			setSettlementCompanies(result.result);
		};
		const FailCBSub = (result) => {
			errorMessage(result);
		};
		doGetEntityByType({ typeId: 227 }, successCBSub, FailCBSub);

		const successCBSalesRepo = (result) => {
			setSalesRepoList(result.result);
		};
		const failCBSalesRepo = (result) => {
			errorMessage(result);
		};
		doGetSalesRepoList(successCBSalesRepo, failCBSalesRepo);
	}, [doGetExchangers, doGetEntityByType,doGetSalesRepoList]);
	const exportExchanges = () => {
		doShowLoader(true);

		const extraParams = {
			...filters.extraParams,
			fromDate:
				filters.extraParams && filters.extraParams.fromDate
					? getDateFromDatePicker(filters.extraParams.fromDate)
					: null,
			toDate:
				filters.extraParams && filters.extraParams.toDate ? getDateFromDatePicker(filters.extraParams.toDate) : null,
		};
		const backendFilter = { ...filters, extraParams };
		const successCB = (result) => {
			doShowLoader(false);
			downloadFile(result.result, "Exchanges");
		};
		const FailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		doExchangeExport(backendFilter, successCB, FailCB);
	};

	const advancedFilters = advancedFilterFormFields(
		exchangeType,
		exchangers,
		settlementCompanies,
		formValues,
		exchangeDateOptions,
		change,
		salesRepoList
	);
	const handleCloseFilter = () => {
		setShowAdvancedFilter(false);
	};
	const handleCancelFilter = (key) => {
		const extraParams = { ...filters.extraParams };
		delete extraParams[key];
		if (key === "filterBy") {
			delete extraParams["fromDate"];
			delete extraParams["toDate"];
		}
		doSetFilter({
			...filters,
			extraParams: extraParams,
			pageIndex: 1,
		});
	};
	const clearAllFilter = () => {
		doSetFilter({
			...filters,
			extraParams: {},
			pageIndex: 1,
		});
	};
	const handleSubmitFilter = (values) => {
		if (values.filterBy && !values.fromDate && !values.toDate) {
			errorMessage(105);
			return false;
		}
		doSetFilter({
			...filters,
			extraParams: values,
			pageIndex: 1,
		});
	};
	const ifFilterExist = () => {
		return (
			filters.extraParams &&
			(filters.extraParams.fromDate ||
				filters.extraParams.toDate ||
				filters.extraParams.exchangeType ||
				filters.extraParams.exchangerIds ||
				filters.extraParams.settlementCompanyIds||
				filters.extraParams.salesRepIds)
		);
	};
	return (
		<Layout
			breadcrumbs={[{ label: "Exchanges" }]}
			title="Exchanges"
			rightActionButtons={rightActionButtons}
			className="exchange-listing-page">
			{exchangeList ? (
				<>
					<SummaryCards
						cards={[
							{
								label: "New Exchanges",
								count: additionalParam && additionalParam.newExchanges,
								countColor: "#4899f2",
								icon: newIcon,
								className: "col-xl-3 col-lg-6 col-md-6  col-sm-12 mb-sm-3 mb-xs-2",
							},
							{
								label: "Active Exchanges",
								count: additionalParam && additionalParam.activeExchanges,
								icon: activeIcon,
								countColor: "#00aa63",
								className: "col-xl-3 col-lg-6 col-md-6  col-sm-12  mb-sm-3 mb-xs-2",
							},
							{
								label: "Cancelled Exchanges",
								count: additionalParam && additionalParam.cancelledExchanges,
								icon: canceledIcon,
								className: "col-xl-3 col-lg-6 col-md-6  col-sm-12 mb-sm-3 mb-xs-2",
							},
							{
								label: "Closed Exchanges",
								count: additionalParam && additionalParam.closedExchanges,
								icon: closedIcon,
								countColor: "#ccc",
								className: "col-xl-3 col-lg-6 col-md-6  col-sm-12  mb-sm-3 mb-xs-2",
							},
						]}
					/>
					<div className="info-button filter-option text-highlighted" onClick={exportExchanges}>
						<span className={`icon-excel`}></span>Export
					</div>
					<div
						onClick={() => {
							setShowAdvancedFilter(true);
						}}
						className={`info-button filter-option text-highlighted ${ifFilterExist() ? "filter-applied" : null}`}>
						<span className={`icon-filter`}></span>Filter
					</div>

					<SearchField
						initialValue={filters.searchKey}
						onSearch={(searchKey) =>
							doSetFilter({
								...filters,
								searchKey,
								pageIndex: 1,
							})
						}
						className={`flex-fill mb-0`}
						placeholder="Enter your query"
					/>

					{showAdvancedFilter ? (
						<AdvancedFilter
							onSubmit={handleSubmitFilter}
							handleClose={handleCloseFilter}
							formFields={advancedFilters}
							initialValues={filters.extraParams}
							clearAllFilter={clearAllFilter}
						/>
					) : ifFilterExist() ? (
						<AppliedFilter
							handleClose={handleCancelFilter}
							advancedFilters={advancedFilters}
							values={filters.extraParams}
						/>
					) : null}

					<Table
						columns={columns}
						total={additionalParam && additionalParam.totalCount}
						data={exchangeList}
						onSort={(e) =>
							e.by !== filters.sortColumn || e.order !== filters.sortDirection
								? doSetFilter({
										...filters,
										sortColumn: e.by,
										sortDirection: e.order,
										pageIndex: 1,
								  })
								: null
						}
						sortBy={filters.sortColumn}
						sortDir={filters.sortDirection}
					/>
					<Pagination
						total={recordsTotal}
						count={filters.pageSize}
						page={filters.pageIndex}
						onChange={(e) => {
							doSetFilter({ ...filters, pageIndex: e });
						}}
						onPageCountChange={(e) => {
							doSetFilter({ ...filters, pageSize: e, pageIndex: 1 });
						}}
					/>
				</>
			) : null}

			{showNotePopup ? (
				<NoteSlider
					onSubmit={addNote}
					currentExchange={currentExchange}
					show={showNotePopup}
					handleClose={handleClose}
					showAdd={showNoteAdd}
					deleteNote={deleteNote}
					setShowAdd={setShowNoteAdd}
				/>
			) : null}
			{showEmailPopup ? (
				<EmailSlider
					currentExchange={currentExchange}
					onSubmit={sendEmail}
					show={showEmailPopup}
					handleClose={handleEmailClose}
					initialValues={{ subject: `Exchange - ${currentExchange.exchangeNumber}` }}
				/>
			) : null}
			<Confirm
				onYes={confirmDeleteAction}
				onNo={(e) => {
					setConfirmDelete(false);
				}}
				show={confirmDelete ? true : false}
			/>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	const {
		Exchange: { exchangeList: { data: exchangeList = [], recordsTotal = 0, additionalParam } = { data: [] }, filters = {} },
		Common: { ExchangeType = [], ExchangeDateOptions = [] },
		Auth: { userDetails = {} },
	} = state;
	return {
		exchangeList,
		filters,
		recordsTotal,
		additionalParam,
		exchangeType: ExchangeType,
		exchangeDateOptions: ExchangeDateOptions,
		formValues: getFormValues("advancedFilterForm")(state),
		userDetails,
	};
};
const mapDispatchToProps = {
	doSetFilter: setExchangeFilter,
	doGetExchangeList: getExchangeList,
	doShowLoader: showLoader,
	doExchangeExport: exchangeExport,
	doGetExchangers: getExchangers,
	doGetEntityByType: getEntityByType,
	doGetSalesRepoList:getSalesRepoList,
	doSendEmail: sendEmail,
	doExchangeUnlock: exchangeUnlock,
	doDeleteExchange: deleteExchange,
	doCreateNote: createNote,
	doDeleteNote: deleteNote,
	doGetNotes: getNotes,
};

export default connect(mapStateToProps, mapDispatchToProps)(Exchanges);
