import { USER } from "../types";
const defaultFilter = {
	sortColumn: "creationTime",
	sortDirection: "desc",
	pageIndex: 1,
	pageSize: 10,
	searchKey: "",
};
export default (
	state = {
		adminFilter: defaultFilter,
		exchangerFilter: defaultFilter,
		escrowFilter: defaultFilter,
		contactFilter: defaultFilter,
	},
	action
) => {
	switch (action.type) {
		case USER.SET_ADMIN_FILTER:
			return {
				...state,
				adminFilter: action.payload ? action.payload : {},
			};
		case USER.SET_EXCHANGER_FILTER:
			return {
				...state,
				exchangerFilter: action.payload ? action.payload : {},
			};
		case USER.SET_ESCROW_FILTER:
			return {
				...state,
				escrowFilter: action.payload ? action.payload : {},
			};
		case USER.SET_CONTACT_FILTER:
			return {
				...state,
				contactFilter: action.payload ? action.payload : {},
			};
		case USER.GET_USER_LIST:
			return {
				...state,
				userList: action.payload ? action.payload.result : [],
			};
		case USER.GET_ACTIVITY_LOG:
			return {
				...state,
				activityLog: action.payload ? action.payload.result : [],
			};

		default:
			return state;
	}
};
