import React from "react";
import { Button, Card } from "react-bootstrap";
import { Heading, InfoItem } from "../../SharedComponts/FormElements";
import { reduxForm, getFormValues } from "redux-form";
import { connect } from "react-redux";
import { basicInfoFormFields, dateFormFields } from "./helper";
import Tooltip from "../../SharedComponts/ToolTip";
import { getEntityList } from "../../../store/actions";

// const InfoItem = ({ label, isEditable, formData, accessor, isEdit, ...model }) => {
// 	let value = "-";
// 	if (accessor) {
// 		value = lodash.get(formData, accessor, "-");
// 		if (model.type === "date") {
// 			value = value ? moment(value).format("MM/DD/yyyy") : "-";
// 		} else {
// 			value = value ? value : "-";
// 		}
// 	}

// 	return (
// 		<div className="info-item col-3">
// 			<div className="label">{label} </div>
// 			<div className="value">
// 				{isEdit ? (
// 					isEditable ? (
// 						<Field {...model} />
// 					) : (
// 						<input className="form-field" type="text" readOnly value={value} />
// 					)
// 				) : (
// 					value
// 				)}
// 			</div>
// 		</div>
// 	);
// };

const BasicInfoForm = (props) => {
	const {
		handleSubmit,
		submitting,
		change,
		exchangeType,
		exchangerType,
		exchangeStatus,
		exchangeDetail,
		isEdit,
		setIsEdit,
		doGetEntityList,
		reset,
		formValues,
		isLocked,
	} = props;
	const loadEntities = (searchKey, callback) => {
		const successCB = ({ result }) => {
			callback(result);
		};
		const FailCB = (result) => {
			callback([]);
		};
		if (searchKey.length > 1) {
			doGetEntityList({ searchKey, id: formValues.exchangerType.value }, successCB, FailCB);
		} else {
			callback([]);
		}
	};

	return (
		<>
			<form onSubmit={handleSubmit}>
				<Card>
					<Card.Title className="info-card-title">
						<Heading title="Basic Information" />
						<div className="user-action-icons">
							{!isEdit && !isLocked && (
								<Tooltip
									message={"Click To Edit"}
									component={
										<span className="user-action-icon" onClick={() => setIsEdit(true)}>
											<span className="icon-icon-edit" />
										</span>
									}
								/>
							)}
						</div>
					</Card.Title>

					<Card.Body className="info-card-body">
						{basicInfoFormFields({ exchangeType, exchangeStatus, loadEntities, change, exchangerType, isEdit }).map(
							(item, index) => (
								<InfoItem key={index} formData={exchangeDetail} {...item} />
							)
						)}
					</Card.Body>
					<Card.Title className="info-card-title">
						<Heading title="Important Dates" />
					</Card.Title>
					<Card.Body className="info-card-body">
						{dateFormFields({ isEdit, change, formValues }).map((item, index) => (
							<InfoItem key={index} formData={exchangeDetail} {...item} />
						))}
					</Card.Body>
					{isEdit && !isLocked && (
						<div className="d-flex justify-content-end mb-4">
							<Button
								className="form-button-light"
								onClick={() => {
									setIsEdit(false);
									reset();
								}}>
								Cancel
							</Button>
							<Button type="submit" disabled={submitting} className="form-button-primary">
								Submit
							</Button>
						</div>
					)}
				</Card>
			</form>
		</>
	);
};
const mapStateToProps = (state) => {
	const {
		Common: { ExchangeType = [], ExchangerType = [], ExchangeStatus = [], CompanyType = [] },
	} = state;
	return {
		exchangeType: ExchangeType,
		exchangerType: ExchangerType,
		exchangeStatus: ExchangeStatus,
		companyType: CompanyType,
		formValues: getFormValues("exchageBasicInfoForm")(state),
	};
};
const mapDispatchToProps = {
	doGetEntityList: getEntityList,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "exchageBasicInfoForm",
		enableReinitialize: true,
	})(BasicInfoForm)
);
