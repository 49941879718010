export const userTypes = { SA: "Admin", EX: "Exchanger", ES: "Escrow" };

export const userRoles = [
	{
		value: 2,
		label: "Admin",
	},
	{
		value: 7,
		label: "Attorney",
	},
	{
		value: 11,
		label: "Buyer",
	},
	{
		value: 6,
		label: "Co-Exchanger",
	},
	{
		value: 3,
		label: "Employee",
	},
	{
		value: 5,
		label: "Escrow",
	},
	{
		value: 4,
		label: "Exchanger",
	},
	{
		value: 8,
		label: "Legal Advisor",
	},
	{
		value: 9,
		label: "Sales Rep",
	},
	{
		value: 10,
		label: "Seller",
	},
	{
		value: 12,
		label: "Tax Consultant",
	},
];
export const exchangerTypes = [
	{
		value: 204,
		label: "Other",
	},
	{
		value: 203,
		label: "Trust or other",
	},
	{
		value: 202,
		label: "Company",
	},
	{
		value: 201,
		label: "Individual",
	},
];

export const userStatus = [
	{ value: 100, label: "Active" },
	{ value: 101, label: "Inactive" },
];

export const dateFormat = "MM/DD/YYYY";
export const datePickerDateFormat = "MM/dd/yyyy";

export const lookupTypes = [
	{ key: "GeneralStatus", value: 1 },
	{ key: "EntityType", value: 2 },
	{ key: "Modules", value: 3 },
	{ key: "ExchangeStatus", value: 4 },
	{ key: "ExchangerType", value: 5 },
	{ key: "CompanyType", value: 6 },
	{ key: "DealType", value: 7 },
	{ key: "CompanyStatus", value: 8 },
	{ key: "ExchangeType", value: 9 },
	{ key: "DealerType", value: 10 },
	{ key: "ExchangeDateOptions", value: 11 },
	{ key: "WeekRange", value: 18 },
	{ key: "TaskStatus", value: 14 },
	{ key: "incomeCategory", value: 12 },
	{ key: "paymentStatus", value: 13 },
	{ key: "barchartFilter", value: 25 },
	{ key: "templateTypes", value: 19 },
	{ key: "templateStatus", value: 20 },
];
