import React from "react";

import { connect } from "react-redux";

import Layout from "../SharedComponts/Layout";

import Form from "./form";

const Exchanges = ({ handleSubmit, history }) => {
	return (
		<Layout breadcrumbs={[{ label: "Exchanges" }]} title="Exchanges" className="exchange-listing-page">
			<Form
				initialValues={{
					color_multi: [
						{ value: "strawberry", label: "Strawberry" },
						{ value: "vanilla", label: "Vanilla" },
					],
					checkbox: true,
					email: "yessssatgmil.com",
					color: { value: "strawberry", label: "Strawberry" },
				}}
			/>
		</Layout>
	);
};
export default connect(null, null)(Exchanges);
