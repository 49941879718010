import { REPORTS } from "../types";
import fetchHandler from "../../utils/fetchHandler";

export const getExchangerNames = (value, successHandler, errorHandler) => {
	const url = value ? `reports/exchangers?id=${value}` : `reports/exchangers`;
	const fetchOptions = {
		url: url,
		method: "GET",
		secure: true,
		actionType: REPORTS.GET_EXCHANGER_NAMES,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getExchangeNumbers = (value, successHandler, errorHandler) => {
	const url = value ? `reports/exchange?exchangerId=${value}` : `reports/exchange`;
	const fetchOptions = {
		url: url,
		method: "GET",
		secure: true,
		actionType: REPORTS.GET_EXCHANGE_NBRS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getExchangeReport = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `reports/exchangeReport`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: REPORTS.GET_EXCHANGE_REPORT,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const getTransactionReport = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `reports/transactionReport`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: REPORTS.GET_EXCHANGE_REPORT,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

// export const sendEmail = (values, successHandler, errorHandler) => {
// 	const fetchOptions = {
// 		url: `general/email`,
// 		method: "POST",
// 		secure: true,
// 		actionType: REPORTS.SEND_MAIL,
// 		body: JSON.stringify(values),
// 	};

// 	return fetchHandler(fetchOptions, successHandler, errorHandler);
// };
