import React, { useEffect, useState } from "react";
import { Heading, InfoItem } from "../../../SharedComponts/FormElements";
import { Card, Button, Accordion } from "react-bootstrap";
import { reduxForm, FieldArray } from "redux-form";
import { connect } from "react-redux";
import lodash from "lodash";

import {
	getCities,
	showLoader,
	updateAddress,
	relatedExchangesExists,
	createExchangeContact,
	updateExchangerInfo,
	relatedExchangeExistsForCompany,
	createCoexchanger,
	deleteCoexchanger,
	getUser,
} from "../../../../store/actions";

import { basicInfoFormFields, addressInfoForm, contactInfoForm, teamInfoForm, coExchangerFormFields } from "./helper";
import AddContact from "./AddContact";
import { errorMessage } from "../../../../utils/errorMessage";
import Confirm from "../../../SharedComponts/FormElements/confirm";
import { AccordionSlider } from "../../../SharedComponts/FormElements";

import { getCopy } from "../../../../utils/helper";
import { successMessage } from "../../../../utils/successMessage";

const renderCoExcahnger = ({
	saveValues,
	change,
	populateCoexchanger,
	coExchangerFormData,
	loadUsers,
	coExchanger,
	isLocked,
}) => {
	return (
		<Card.Body className="info-card-body">
			{coExchangerFormFields({ coExchanger, change, populateCoexchanger, saveValues, loadUsers, isLocked }).map(
				(item, index) => (
					<InfoItem key={index} formData={coExchangerFormData} {...item} />
				)
			)}
		</Card.Body>
	);
};
const CoExchangerForm = ({
	change,
	addCoechanger,
	populateCoexchanger,
	exchangerInformation,
	onDeleteCoexchanger,
	saveValues,
	loadUsers,
	fields,
	meta: { error },
	isLocked,
}) => {
	const [actveKey, setActiveKey] = useState("0");
	useEffect(() => {
		if (exchangerInformation && exchangerInformation.coexchangerInformation) {
			setActiveKey(`${exchangerInformation.coexchangerInformation.length - 1}`);
		}
	}, [exchangerInformation, setActiveKey]);
	return (
		<>
			<Card.Title className="info-card-title align-items-baseline">
				<Heading className="mt-20" title="Co-Exchanger Information" />
				{!isLocked && (
					<Button className="form-button-secondary" onClick={addCoechanger}>
						<span className="add-new-plus">+</span> Add Co-Exchanger
					</Button>
				)}
			</Card.Title>
			<Card.Body className="p-0">
				<Accordion activeKey={actveKey}>
					{fields.map((coExchanger, index) => (
						<AccordionSlider
							onClick={(key) => {
								if (key === actveKey) {
									setActiveKey("");
								} else {
									setActiveKey(key);
								}
							}}
							key={index}
							index={index}
							defaultKey={fields.length}
							eventKey={`${index}`}
							isActive={actveKey === `${index}`}
							subTitle={fields.get(index)?.name?.label}
							onDelete={
								!isLocked
									? () => {
											onDeleteCoexchanger(index);
									  }
									: null
							}
							title={`Co Exchanger ${index + 1}`}
							content={renderCoExcahnger({
								coExchanger,
								populateCoexchanger,
								loadUsers,
								change,
								coExchangerFormData: exchangerInformation.coexchangerInformation
									? exchangerInformation.coexchangerInformation[index]
									: {},
								saveValues,
								isLocked,
							})}
						/>
					))}
				</Accordion>
			</Card.Body>
		</>
	);
};

const ExchangerInfo = (props) => {
	const {
		doGetUser,
		doRelatedExchangesExists,
		doUpdateAddress,
		handleSubmit,
		doCreateExchangeContact,
		doCreateCoexchanger,
		doDeleteCoexchanger,
		reset,
		qiCompanies,
		doUpdateExchangerInfo,
		exchangerInformation,
		loadUsers,
		loadContacts,
		states,
		cities,
		change,
		isReload,
		doGetCities,
		loadCounties,
		exchangerType,
		setReload,
		doShowLoader,
		exchangeDetail,
		formValues,
		doRelatedExchangeExistsForCompany,
		isLocked,
	} = props;
	const [showPopup, setshowPopup] = useState(false);
	const [confirmAddressEdit, setConfirmAddressEdit] = useState(false);
	const [isAddressEdit, setAddressEdit] = useState(false);
	const [addressInformation, setAddressInformation] = useState({});
	const [confirmDeleteCoexchanger, setConfirmDeleteCoexchanger] = useState({ show: false, id: null });

	const showContactPopup = () => {
		setshowPopup(true);
	};
	const handleClose = () => {
		setshowPopup(false);
	};
	const handleSubmitContact = (values) => {
		doShowLoader(true);
		const successCB = (result) => {
			doShowLoader(false);
			setReload(!isReload);
			handleClose();
		};
		const failCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
			setReload(!isReload);
		};

		const exchangeId = exchangeDetail.id;

		const exchangerTypeId = exchangerType.value;
		const companyId = exchangeDetail.entityName.value;

		doCreateExchangeContact({ exchangerTypeId, exchangeId, companyId, ...values }, successCB, failCB);
	};
	const confirmAddressEditAction = () => {
		upDateExchangeAddress(addressInformation, true);
		setConfirmAddressEdit(false);
	};
	const noConfirmAddressEditAction = () => {
		setConfirmAddressEdit(false);
		setAddressEdit(false);
		setReload(!isReload);
		reset();
	};
	const checkExchangeExists = (address) => {
		const successCB = (result) => {
			doShowLoader(false);
			if (result.result === true) {
				setConfirmAddressEdit(true);
			} else {
				upDateExchangeAddress(address, false);
			}
		};
		const failCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};
		doShowLoader(true);
		if (exchangeDetail.exchangerType.label === "Individual") {
			doRelatedExchangesExists({ id: exchangeDetail.entityName.value, exchangeId: exchangeDetail.id }, successCB, failCB);
		} else {
			doRelatedExchangeExistsForCompany(
				{ id: exchangeDetail.entityName.value, exchangeId: exchangeDetail.id },
				successCB,
				failCB
			);
		}
	};
	const addCoechanger = () => {
		doShowLoader(true);
		const successCB = (result) => {
			doShowLoader(false);
			setReload(!isReload);
			handleClose();
		};
		const failCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
			// setReload(!isReload);
		};
		doCreateCoexchanger({ id: 0, exchangeId: exchangeDetail.id }, successCB, failCB);
	};

	const onDeleteCoexchanger = (index) => {
		setConfirmDeleteCoexchanger({ show: true, id: formValues.coexchangerInformation[index].id });
	};
	const confirmDeleteCoexchangerAction = () => {
		doShowLoader(true);
		const successCB = (result) => {
			doShowLoader(false);
			setReload(!isReload);
			handleClose();
		};
		const failCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
			// setReload(!isReload);
		};

		doDeleteCoexchanger(confirmDeleteCoexchanger.id, successCB, failCB);
		setConfirmDeleteCoexchanger({ show: false, id: null });
	};
	const upDateExchangeAddress = (values, isUpdate) => {
		doShowLoader(true);
		const successCB = (response) => {
			doShowLoader(false);
			saveValues([{ key: "addressInformation", value: response.result }], true, true);
			setAddressEdit(false);
		};
		const failCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
			setReload(!isReload);
			// setAddressInformation({});
		};

		const objectTypeId = exchangeDetail.exchangerType.label === "Individual" ? 127 : 126;
		const objectId = exchangeDetail.entityName.value;
		doUpdateAddress({ ...values, objectId, objectTypeId, isUpdateRelatedAddress: isUpdate }, successCB, failCB);
	};
	const popupUserDetails = (id) => {
		doShowLoader(true);
		const successCB = (response) => {
			doShowLoader(false);
			change("contactInformation.primaryPhoneNumber", response.result.phoneNumber);
			change("contactInformation.title", response.result.jobTitle);
			change("contactInformation.fax", response.result.fax ? response.result.fax : null);
			change("contactInformation.emailAddress", response.result.emailAddress);
			change("contactInformation.additionalEmail", response.result.additionalEmail);
			change(
				"contactInformation.secondaryPhoneNumber",
				response.result.phoneNumber2 ? response.result.phoneNumber2 : null
			);
		};
		const failCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};
		doGetUser(id, successCB, failCB);
	};
	const addressSubmit = () => {
		setAddressInformation(JSON.parse(JSON.stringify(formValues.addressInformation)));
		checkExchangeExists(JSON.parse(JSON.stringify(formValues.addressInformation)));
	};
	const onSubmitExchangerInfo = (values) => {
		const id = exchangeDetail.id;
		if (isAddressEdit) {
			setAddressInformation(JSON.parse(JSON.stringify(values.addressInformation)));
			checkExchangeExists(JSON.parse(JSON.stringify(values.addressInformation)));
		} else {
			if (values.coexchangerInformation) {
				let isDuplicateCoExchanger = false;
				let checkName = { label: null, value: null };
				let coexchangerInformation = [];
				coexchangerInformation = formValues.coexchangerInformation.filter((item) => {
					return !!item.name;
				});
				for (let item of coexchangerInformation) {
					const name = {
						label: item.name.label,
						value: item.name.label === item.name.value ? 0 : item.name.value,
					};
					item = { ...item, name };

					if (JSON.stringify(checkName) === JSON.stringify(item.name)) {
						isDuplicateCoExchanger = true;

						break;
					}
					checkName = item.name;
				}
				if (isDuplicateCoExchanger) {
					errorMessage(508);
					return;
				}
				values.coexchangerInformation = coexchangerInformation;
			}
			doShowLoader(true);
			const successCB = (result) => {
				successMessage(507);
				doShowLoader(false);
				setReload(!isReload);
			};
			const failCB = (result) => {
				errorMessage(result);
				doShowLoader(false);
				setReload(!isReload);
			};
			doUpdateExchangerInfo(
				{ id, guid: exchangeDetail.guid, exchangerType: exchangeDetail.exchangerType, ...values, isSubmit: true },
				successCB,
				failCB
			);
		}
	};
	const populateCoexchanger = (coExchanger, selected) => {
		if (selected.label !== selected.value) {
			doShowLoader(true);
			const successCB = (response) => {
				doShowLoader(false);
				change(`${coExchanger}.phoneNumber`, response.result.phoneNumber);
				change(`${coExchanger}.emailAddress`, response.result.emailAddress);
				change(`${coExchanger}.ssnOrTaxId`, response.result.ssn ? response.result.ssn : null);
				const keyValues = [
					{ key: `${coExchanger}.name`, value: selected },
					{ key: `${coExchanger}.phoneNumber`, value: response.result.phoneNumber },
					{ key: `${coExchanger}.emailAddress`, value: response.result.emailAddress },
					{ key: `${coExchanger}.ssnOrTaxId`, value: response.result.ssn ? response.result.ssn : null },
				];
				saveValues(keyValues);
			};
			const failCB = (result) => {
				errorMessage(result);
				doShowLoader(false);
			};
			doGetUser(selected.value, successCB, failCB);
		}
	};

	const saveValues = (keyValues, isLoader, reload) => {
		const id = exchangeDetail.id;
		const values = getCopy(formValues);
		if (keyValues) {
			if (keyValues.length > 0) {
				for (const item of keyValues) {
					lodash.set(values, item.key, item.value);
				}
			}
		}
		if (values.coexchangerInformation) {
			values.coexchangerInformation = values.coexchangerInformation
				.filter((item) => {
					return !!item.name;
				})
				.map((item) => {
					const name = { label: item.name.label, value: item.name.label === item.name.value ? 0 : item.name.value };
					return { ...item, name };
				});
		}

		if (isLoader) {
			doShowLoader(true);
		}
		const successCB = () => {
			if (isLoader) {
				doShowLoader(false);
			}
			if (reload) {
				setReload(!isReload);
			}
		};
		const failCB = () => {
			if (isLoader) {
				doShowLoader(false);
			}
		};
		doUpdateExchangerInfo(
			{ id, guid: exchangeDetail.guid, exchangerType: exchangeDetail.exchangerType, ...values, isSubmit: false },
			successCB,
			failCB
		);
	};

	return (
		<>
			{showPopup && (
				<AddContact
					loadCounties={loadCounties}
					doGetCities={doGetCities}
					show={showPopup}
					handleClose={handleClose}
					onSubmit={handleSubmitContact}
				/>
			)}
			{confirmAddressEdit && (
				<Confirm
					onYes={confirmAddressEditAction}
					onNo={(e) => {
						noConfirmAddressEditAction();
					}}
					title="Confirm Update"
					message="There are active or new exchanges related to this address, Do you want to continue?"
					show={confirmAddressEdit ? true : false}
				/>
			)}
			{confirmDeleteCoexchanger.show && (
				<Confirm
					onYes={confirmDeleteCoexchangerAction}
					onNo={(e) => {
						setConfirmDeleteCoexchanger({ show: false, id: null });
					}}
					show={confirmDeleteCoexchanger.show}
				/>
			)}
			<form onSubmit={handleSubmit(onSubmitExchangerInfo)}>
				<Card.Title className="info-card-title">
					<Heading title="Basic Information" />
				</Card.Title>

				<Card.Body className="info-card-body">
					{basicInfoFormFields({
						loadContacts,
						exchangerType,
						showContactPopup,
						popupUserDetails,
						saveValues,
						isLocked,
					}).map((item, index) => (
						<InfoItem key={index} formData={formValues} value={"initialValues"} {...item} />
					))}
				</Card.Body>
				<Card.Title className="info-card-title">
					<Heading title="Contact Information" />
				</Card.Title>
				<Card.Body className="info-card-body">
					{contactInfoForm({ exchangerType, formValues, saveValues, isLocked }).map((item, index) => (
						<InfoItem key={index} formData={formValues} {...item} />
					))}
				</Card.Body>
				<Card className="address-card">
					<Card.Title className="info-card-title">
						<Heading title="Address Information" />
						{!isAddressEdit && !isLocked && (
							<div className="user-action-icons">
								<span className="user-action-icon" onClick={() => setAddressEdit(true)}>
									<span className="icon-icon-edit" />
								</span>
							</div>
						)}
					</Card.Title>
					<Card.Body className="info-card-body">
						{addressInfoForm(isAddressEdit, states, cities, doGetCities, change, loadCounties).map(
							(item, index) => (
								<InfoItem key={index} formData={exchangerInformation} value={"initialValues"} {...item} />
							)
						)}
						{isAddressEdit && (
							<div className="mb-4 d-flex w-100 justify-content-end">
								<Button
									className="button-light"
									onClick={() => {
										setAddressEdit(false);
										reset();
									}}>
									Cancel
								</Button>
								<Button onClick={addressSubmit} className="button-primary">
									Submit
								</Button>
							</div>
						)}
					</Card.Body>
				</Card>

				<FieldArray
					name="coexchangerInformation"
					props={{
						addCoechanger,
						saveValues,
						populateCoexchanger,
						loadUsers,
						change,
						exchangerInformation,
						onDeleteCoexchanger,
						isLocked,
					}}
					component={CoExchangerForm}
				/>
				<Card.Title className="info-card-title">
					<Heading className="mt-20" title="Team Information" />
				</Card.Title>
				<Card.Body className="info-card-body">
					{teamInfoForm({ loadUsers, saveValues, qiCompanies, change, isLocked }).map((item, index) => (
						<InfoItem key={index} formData={formValues} {...item} />
					))}
				</Card.Body>
				{!isLocked && (
					<div className="mb-4 d-flex justify-content-end">
						{/* <Button className="form-button-light" onClick={() => reset()}>
							Cancel
						</Button> */}
						<Button type="submit" className="form-button-primary">
							Submit
						</Button>
					</div>
				)}
			</form>
		</>
	);
};
const mapStateToProps = ({
	form: { exchagerInfoForm },
	Common: { states = [], Modules, counties = [], cites: cities = [] },
}) => {
	const formValues = (exchagerInfoForm && exchagerInfoForm.values) || {};
	return {
		states,
		counties,
		cities,
		Modules,
		formValues,
	};
};
const mapDispatchToProps = {
	doShowLoader: showLoader,
	doGetCities: getCities,
	doCreateExchangeContact: createExchangeContact,
	doUpdateExchangerInfo: updateExchangerInfo,
	doUpdateAddress: updateAddress,
	doRelatedExchangesExists: relatedExchangesExists,
	doGetUser: getUser,
	doRelatedExchangeExistsForCompany: relatedExchangeExistsForCompany,
	doCreateCoexchanger: createCoexchanger,
	doDeleteCoexchanger: deleteCoexchanger,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "exchagerInfoForm",
		enableReinitialize: true,
	})(ExchangerInfo)
);
