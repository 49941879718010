import React from "react";
import { OverlayTrigger, Tooltip as BSTooltip } from "react-bootstrap";

const Tooltip = ({ message, component, placement = "top" }) => {
	return message ? (
		<OverlayTrigger placement={placement} overlay={<BSTooltip>{message}</BSTooltip>}>
			{component}
		</OverlayTrigger>
	) : (
		component
	);
};

export default Tooltip;
