import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";

import Layout from "../../SharedComponts/Layout";
import { Table, Pagination } from "../../SharedComponts/FormElements/table";
import { columns as columnsFn } from "./helper";
import { getCopy, getStatus } from "../../../utils/helper";

import {
	setEntityFilter,
	deleteEntity,
	getEntities,
	showLoader,
	getCompanyList,
	createEntity,
	getEntity,
	updateEntity,
	getCounties,
	getCities,
	getStates,
	getCompanyTypes,
	relatedExchangeExistsForCompany,
} from "../../../store/actions";

import SearchField from "../../SharedComponts/FormElements/searchTable";
import Confirm from "../../SharedComponts/FormElements/confirm";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";
import EntityDetails from "./EntityDetails";
import AddEdit from "./AddEdit";
import SummaryCards from "../../SharedComponts/SummaryCards";

import "./entities.scss";

import exchangerIcon from "../../../assets/images/check-out.png";
import settlementIcon from "../../../assets/images/contract.png";
import trustIcon from "../../../assets/images/trust.png";
import { getFormValues } from "redux-form";

const EntityList = ({
	doSetFilter,
	filters,
	history,
	additionalParam,
	doGetEntities,
	recordsTotal,
	entities,
	doShowLoader,
	companyTypes,
	doCreateEntity,
	doGetEntity,
	doUpdateEntity,
	doGetCounties,
	doGetStates,
	doGetCities,
	doGetCompanyTypes,
	doDeleteEntity,
	doRelatedExchangeExistsForCompany,
	formValues,
}) => {
	const [isList, setIsList] = useState(true);
	const [showPopup, setshowPopup] = useState(false);
	const [isEdit, setIsEdit] = useState(true);
	const [confirmAddressEdit, setConfirmAddressEdit] = useState(false);
	const [currentRecord, setCurrentRecord] = useState({});
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [currentDeleteRecord, setCurrentDeleteRecord] = useState(null);
	const [listReload, setListReload] = useState(false);
	const handleClose = () => setshowPopup(false);
	const handleShow = () => setshowPopup(true);
	const handleSubmit = async (value) => {
		const successCB = (result) => {
			doShowLoader(false);
			successMessage(!isEdit ? 206 : 207);
			doSetFilter({
				...filters,
				pageIndex: 1,
			});
			handleClose();
			if (!isList) {
				onSelectEntity(currentRecord);
			}
		};
		const FailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		const postData = {
			...value,
			status: !isEdit ? getStatus("Active") : value.status,
		};

		if (!currentRecord.id || !isEdit) {
			doShowLoader(true);
			doCreateEntity({ ...postData }, successCB, FailCB);
		} else {
			if (currentRecord.companyType.value !== 229) {
				const addressExists = await checkExchangeExists(currentRecord.id);
				if (addressExists) {
					setConfirmAddressEdit(true);
				} else {
					doShowLoader(true);
					currentRecord.id && isEdit && doUpdateEntity(postData, successCB, FailCB);
				}
			} else {
				doShowLoader(true);
				currentRecord.id && isEdit && doUpdateEntity(postData, successCB, FailCB);
			}
		}
	};

	useEffect(() => {
		doShowLoader(true);
		const backendFilter = { ...filters };
		const successCB = (result) => {
			doShowLoader(false);
		};
		const FailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		doGetEntities(backendFilter, successCB, FailCB);
	}, [filters, listReload, doGetEntities, doShowLoader]);

	useEffect(() => {
		doGetStates();
		doGetCompanyTypes();
	}, [doGetStates, doGetCompanyTypes]);

	const onSelectEntity = (row) => {
		doShowLoader(true);
		const successCB = (e) => {
			setCurrentRecord(e.result);
			doShowLoader(false);
			setIsList(false);
		};
		const FailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		doGetEntity(row.id, successCB, FailCB);
	};
	const checkExchangeExists = (id) => {
		doShowLoader(true);
		return new Promise((resolve, reject) => {
			const successCB = (result) => {
				doShowLoader(false);
				if (result.result === true) {
					resolve(true);
				} else {
					resolve(false);
				}
			};
			const failCB = (result) => {
				errorMessage(result);
				doShowLoader(false);
				resolve(false);
			};
			doRelatedExchangeExistsForCompany({ id }, successCB, failCB);
		});
	};
	const confirmAddressEditAction = () => {
		setConfirmAddressEdit(false);
		const successCB = (result) => {
			doShowLoader(false);
			successMessage(!isEdit ? 206 : 207);
			doSetFilter({
				...filters,
				pageIndex: 1,
			});
			handleClose();
			if (!isList) {
				onSelectEntity(currentRecord);
			}
		};
		const FailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};
		doShowLoader(true);
		const payload = getCopy(formValues);
		payload.address.isUpdateRelatedAddress = true;
		doUpdateEntity({ ...payload }, successCB, FailCB);
	};
	const noConfirmAddressEditAction = () => {
		setConfirmAddressEdit(false);
		handleClose();
		if (!isList) {
			onSelectEntity(currentRecord);
		}
	};
	const onEditEntity = (row) => {
		doShowLoader(true);
		const successCB = (e) => {
			setIsEdit(true);
			setCurrentRecord(e.result);
			doShowLoader(false);
			handleShow();
		};
		const FailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		doGetEntity(row.id, successCB, FailCB);
	};
	const onDeleteEntity = (row) => {
		setCurrentDeleteRecord(row.id);
		setConfirmDelete(true);
	};
	const confirmDeleteAction = () => {
		doShowLoader(true);
		const failCB = (result) => {
			doShowLoader(false);
			setCurrentDeleteRecord(null);
			setConfirmDelete(false);
			errorMessage(result);
		};
		const successCB = (result) => {
			doShowLoader(false);
			setCurrentDeleteRecord(null);
			setConfirmDelete(false);
			successMessage(208);
			setListReload(!listReload);
		};
		doDeleteEntity(currentDeleteRecord, successCB, failCB);
	};

	const addEntity = () => {
		setIsEdit(false);
		setCurrentRecord({});
		handleShow();
	};
	const loadCounties = (searchKey, callback) => {
		const successCB = ({ result }) => {
			callback(result);
		};
		const FailCB = (result) => {
			callback([]);
		};
		if (searchKey.length > 1) {
			doGetCounties(searchKey, successCB, FailCB);
		} else {
			callback([]);
		}
	};

	const rightActionButtons = [
		{
			component: () => {
				return (
					<Button className="form-button-primary" onClick={addEntity}>
						<span className="add-new-plus">+</span> New Entity
					</Button>
				);
			},
		},
	];

	const columns = columnsFn({
		onSelectEntity,
		onEditEntity,
		onDeleteEntity,
	});
	return (
		<>
			{isList ? (
				<Layout
					title="Entities"
					breadcrumbs={[
						{
							label: "Configurations",
							onClick: () => {
								const path = `/settings-master`;
								history.push(path);
							},
						},
						{ label: "Entities" },
					]}
					rightActionButtons={rightActionButtons}
					className="entity-list-page">
					{entities ? (
						<>
							<SummaryCards
								cards={[
									{
										label: "Exchanger Companies",
										count: additionalParam && additionalParam.exchangerCount,
										countColor: "#4899f2",
										icon: exchangerIcon,
										className: "col-lg-4 col-md-4 col-sm-12 mb-sm-2 mb-xs-2",
									},
									{
										label: "Settlement Companies",
										count: additionalParam && additionalParam.settlementCount,
										icon: settlementIcon,
										className: "col-lg-4 col-md-4 col-sm-12 mb-sm-2 mb-xs-2",
									},
									{
										label: "Trust or Other",
										count: additionalParam && additionalParam.trustCount,
										icon: trustIcon,
										countColor: "#00aa63",
										className: "col-lg-4 col-md-4 col-sm-12 mb-sm-2 mb-xs-2",
									},
								]}
							/>
							<SearchField
								initialValue={filters.searchKey}
								onSearch={(searchKey) =>
									doSetFilter({
										...filters,
										searchKey,
										pageIndex: 1,
									})
								}
								className={`flex-fill mb-0`}
								placeholder="Enter your query"
							/>

							<Table
								columns={columns}
								total={additionalParam && additionalParam.totalCount}
								data={entities}
								onSort={(e) =>
									e.by !== filters.sortColumn || e.order !== filters.sortDirection
										? doSetFilter({
												...filters,
												sortColumn: e.by,
												sortDirection: e.order,
												pageIndex: 1,
										  })
										: null
								}
								sortBy={filters.sortColumn}
								sortDir={filters.sortDirection}
							/>
							<Pagination
								total={recordsTotal}
								count={filters.pageSize}
								page={filters.pageIndex}
								onChange={(e) => {
									doSetFilter({ ...filters, pageIndex: e });
								}}
								onPageCountChange={(e) => {
									doSetFilter({ ...filters, pageSize: e, pageIndex: 1 });
								}}
							/>
						</>
					) : null}

					<Confirm
						onYes={confirmDeleteAction}
						onNo={(e) => {
							setConfirmDelete(false);
						}}
						show={confirmDelete ? true : false}
					/>
				</Layout>
			) : (
				<EntityDetails
					entity={currentRecord}
					breadcrumbs={[
						{
							label: "Configurations",
							onClick: () => {
								const path = `/settings-master`;
								history.push(path);
							},
						},
						{
							label: "Entities",
							onClick: () => {
								setIsList(true);
							},
						},
						{ label: "Entity Details" },
					]}
					initialValues={currentRecord}
					setIsList={setIsList}
					showEdit={handleShow}
					setIsEdit={setIsEdit}
				/>
			)}
			{showPopup ? (
				<AddEdit
					loadCounties={loadCounties}
					doGetCities={doGetCities}
					show={showPopup}
					initialValues={currentRecord}
					isEdit={isEdit}
					handleClose={handleClose}
					onSubmit={handleSubmit}
					companyTypes={companyTypes}
				/>
			) : null}
			{confirmAddressEdit && (
				<Confirm
					onYes={confirmAddressEditAction}
					onNo={(e) => {
						noConfirmAddressEditAction();
					}}
					title="Confirm Update"
					message="There are active or new exchanges related to this address, Do you want to continue?"
					show={confirmAddressEdit ? true : false}
				/>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		Entity: {
			entities: { data: entities = [], recordsTotal = 0, additionalParam } = { data: [] },
			filters = {},
			entityDetails = {},
		},
		Settings: { companyTypes = [] },
	} = state;
	const formValues = getFormValues("EnitityForm")(state);
	return {
		entities,
		entityDetails,
		filters,
		companyTypes,
		recordsTotal,
		additionalParam,
		formValues,
	};
};
const mapDispatchToProps = {
	doSetFilter: setEntityFilter,
	doGetEntities: getEntities,
	doShowLoader: showLoader,
	doGetCompanyList: getCompanyList,
	doCreateEntity: createEntity,
	doGetEntity: getEntity,
	doUpdateEntity: updateEntity,
	doGetCounties: getCounties,
	doGetStates: getStates,
	doGetCities: getCities,
	doGetCompanyTypes: getCompanyTypes,
	doDeleteEntity: deleteEntity,
	doRelatedExchangeExistsForCompany: relatedExchangeExistsForCompany,
};

export default connect(mapStateToProps, mapDispatchToProps)(EntityList);
