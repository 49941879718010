import React from "react";

import { Date, Currency } from "../../utils/formatter";
import { dateFormat } from "../../constant/common";

import FormControl from "../SharedComponts/FormControl";
import Tooltip from "../SharedComponts/ToolTip";

export const columns = ({ onSelect, showNote = null, history }) => [
	{
		name: "Property Address",
		accessor: "propertyAddress",
		className: "col-propertyAddress",
		render: (row) => <span className={row.propertyDeleted ? "ppty-deleted" : ""}>{row.propertyAddress}</span>,
	},
	{
		name: "From/To",
		accessor: "toOrFrom",
		className: "col-escrowCompany",
	},
	{
		name: "Account #",
		accessor: "accountNumber",
		className: "col-accountNumber",
	},
	{
		name: "Exchange Info",
		accessor: "exchangeNumber",
		className: "col-exchangeInfo",
		render: (row) => (
			<>
				<span
					className={`exchange-number d-flex text-highlighted`}
					onClick={() => {
						history.push(`/exchanges/${row.guid}`);
					}}>
					{row.exchangeNumber}
				</span>
				<span className={`exchanger-name`}>{row.exchanger}</span>
			</>
		),
	},
	{
		name: "Dollars In",
		accessor: "dollarsIn",
		className: "col-dollarsIn",
		render: (row) => (
			<span className={`dollarsIn`}>
				<Currency amount={row.dollarsIn} />
			</span>
		),
	},
	{
		name: "Dollars Out",
		accessor: "dollarsOut",
		className: "col-dollarsOut",
		render: (row) => (
			<span className={`dollarsOut`}>
				<Currency amount={row.dollarsOut} />
			</span>
		),
	},
	{
		name: "Transaction Date",
		accessor: "transactionDate",
		className: "col-transactionDate",
		render: (row) => <Date utcTime={row.transactionDate} format={dateFormat} noConvert={true} />,
	},
	{
		name: "Notes",
		className: "col-actions text-right",
		headerClassName: "col-actions-header",
		sortable: false,
		render: (row) => {
			return (
				<Tooltip
					message={"Click to view note"}
					component={
						<span className="note-icon-span" onClick={() => showNote(row.message)}>
							<i className="icon icon-notepad"></i>
						</span>
					}
				/>
			);
		},
	},
];

export const advancedFilterFormFields = (exchangerNameList, exchangeNumberList, addressList, formValues, weekRange) => {
	return [
		{
			name: "property",
			label: "Property Address",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			options: addressList,
			onChange: (selected) => {},
		},
		{
			name: "exchanger",
			label: "Exchanger",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			options: exchangerNameList,
			onChange: (selected) => {},
		},
		{
			name: "exchangeNumber",
			label: "Exchange Number",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			options: exchangeNumberList,
			onChange: (selected) => {},
		},
		{
			name: "transactionPeriod",
			label: "Transaction Period",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			options: weekRange,
			isDisabled: formValues && (formValues.toDate || formValues.fromDate),
			onChange: (selected) => {},
		},
		{
			name: "fromDate",
			label: "From Date",
			type: "date",
			maxDate: formValues && formValues.toDate,
			component: FormControl,
			className: "form-control form-field",
			disabled: formValues && formValues.transactionPeriod && formValues.transactionPeriod.value,
		},
		{
			name: "toDate",
			label: "To Date",
			type: "date",
			component: FormControl,
			className: "form-control form-field",
			minDate: formValues && formValues.fromDate,
			disabled: formValues && formValues.transactionPeriod && formValues.transactionPeriod.value,
		},
	];
};
