import { EXCHANGE, ACCOUNTING } from "../types";
import fetchHandler from "../../utils/fetchHandler";

export const getExchangeTransactionList = (id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `exchangetransactions/${id}`,
		method: "GET",
		secure: true,
		actionType: EXCHANGE.GET_EXCHANGE_TRANSACTION_LIST,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const createExchangeTransaction = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `transaction`,
		method: "POST",
		secure: true,
		actionType: EXCHANGE.CREATE_EXCHANGE_TRANSACTION,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const updateExchangeTransaction = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `transaction/${values.id}`,
		method: "PUT",
		secure: true,
		actionType: EXCHANGE.UPDATE_EXCHANGE_TRANSACTION,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const deleteExchangeTransaction = (id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `exchangetransaction/${id}`,
		method: "DELETE",
		secure: true,
		actionType: EXCHANGE.DELETE_EXCHANGE_TRANSACTION,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const getExchangeTransaction = (id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `exchangeTransaction/${id}`,
		method: "GET",
		secure: true,
		actionType: EXCHANGE.GET_EXCHANGE_TRANSACTION,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const getExchangeIncomeList = (id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `exchangeIncomes/${id}`,
		method: "GET",
		secure: true,
		actionType: EXCHANGE.GET_EXCHANGE_INCOME_LIST,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const createExchangeIncome = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `exchangeIncome`,
		method: "POST",
		secure: true,
		actionType: EXCHANGE.CREATE_EXCHANGE_INCOME,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const updateExchangeIncome = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `exchangeIncome/${values.id}`,
		method: "PUT",
		secure: true,
		actionType: EXCHANGE.UPDATE_EXCHANGE_INCOME,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const deleteExchangeIncome = (id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `exchangeIncome/${id}`,
		method: "DELETE",
		secure: true,
		actionType: EXCHANGE.DELETE_EXCHANGE_INCOME,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const getExchangeIncome = (id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `exchangeIncome/${id}`,
		method: "GET",
		secure: true,
		actionType: EXCHANGE.GET_EXCHANGE_INCOME,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const getAllPropertyAddress = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `propertyAddress`,
		method: "GET",
		secure: true,
		actionType: ACCOUNTING.GET_ALL_ADDRESS_LIST,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getFullTransactionList = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `exchangetransactionslist`,
		method: "POST",
		secure: true,
		actionType: ACCOUNTING.GET_ALL_TRANSACTION_LIST,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const getFullIncomeList = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `exchangeIncomes`,
		method: "POST",
		secure: true,
		actionType: ACCOUNTING.GET_ALL_INCOME_LIST,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export function setTransactionFilter(filter, successHandler) {
	successHandler && successHandler();
	return {
		type: ACCOUNTING.SET_TRANSACTION_FILTER,
		payload: filter,
	};
}
export function setIncomeFilter(filter, successHandler) {
	successHandler && successHandler();
	return {
		type: ACCOUNTING.SET_INCOME_FILTER,
		payload: filter,
	};
}

export const transactionExport = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `exchangeTransactionsExport`,
		method: "POST",
		secure: true,
		actionType: ACCOUNTING.TRANSACTION_EXPORT,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const incomeExport = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `exchangeIncomesExport`,
		method: "POST",
		secure: true,
		actionType: ACCOUNTING.INCOME_EXPORT,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
