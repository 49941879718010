import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { NotificationContainer } from "react-notifications";

import {
	resetField,
	getLoggedUser,
	showLoader,
	getLookupValues,
	getLookupValuesList,
	getAdminusers,
	getUnreadCount,
	fetchNotification,
} from "./store/actions";
import { MainRouter, SessionRouter } from "./routers";
import { errorMessage } from "./utils/errorMessage";
import PreloaderInner from "./utils/preloaderinner";
import { lookupTypes } from "./constant/common";
import { getLsWithExpiry } from "./utils/helper";

import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "react-datepicker/dist/react-datepicker.css";

import "./icomoon/style.css";
import "./App.scss";
import "./Appl.scss";
import "./Responsive.scss";
import "loaders.css/src/animations/ball-clip-rotate-multiple.scss";

const LSVariable = process.env.REACT_APP_LS_VAR;

function App({
	history,
	location,
	accessToken,
	doResetField,
	doGetLoggedUser,
	userDetails,
	doShowLoader,
	doGetLookupValues,
	doGetAdminusers,
	doGetUnreadCount,
	doFetchNotification,
	notifReloadFlag,
	doGetLookupValuesList,
}) {
	const ifNotAuthorized = useCallback(() => {
		localStorage.removeItem(LSVariable);
		history.push("/");
	}, [history]);

	useEffect(() => {
		const getLSVariable = getLsWithExpiry(LSVariable);

		const userData = getLSVariable ? JSON.parse(getLSVariable) : null;

		const getUserDetails = (userData) => {
			doShowLoader(true);
			const successHandler = (event) => {
				const userDetails = { userType: event.result.userRoleId.label, ...event.result };
				userDetails && doResetField("Auth.userDetails", userDetails);
				doShowLoader(false);

				doGetLookupValuesList(lookupTypes);
				doGetAdminusers();
			};
			const errorHandler = (event) => {
				errorMessage(event);
				ifNotAuthorized();
				doShowLoader(false);
			};
			userData.userId && doGetLoggedUser(userData.userId, successHandler, errorHandler, true);
		};

		if (getLSVariable && accessToken) {
			userData && getUserDetails(accessToken ? accessToken : userData);
		}
		if (getLSVariable && !accessToken) {
			//Remove first line after user details api hook
			userData && doResetField("Auth.accessToken", userData);
		}
	}, [
		doResetField,
		doGetLoggedUser,
		accessToken,
		ifNotAuthorized,
		doShowLoader,
		doGetLookupValues,
		doGetAdminusers,
		doGetLookupValuesList,
	]);

	useEffect(() => {
		const getLSVariable = getLsWithExpiry(LSVariable);
		const notificationCountSuccessCB = (e) => {
			e && e.result && doResetField("Notification.unreadNotifCount", e.result);
		};
		const notificationCountFailCB = (e) => {};

		const notificationSuccessCB = (e) => {
			e && e.result && e.result.data && doResetField("Notification.notifications", e.result.data);
		};
		const notificationFailCB = (e) => {};

		const postData = {
			sortColumn: "",
			sortDirection: "",
			pageIndex: 1,
			pageSize: 5,
			extraParams: { isRead: false },
		};
		if (getLSVariable && accessToken) {
			doGetUnreadCount(notificationCountSuccessCB, notificationCountFailCB);
			doFetchNotification(postData, notificationSuccessCB, notificationFailCB);
		}
	}, [doResetField, doGetUnreadCount, doFetchNotification, notifReloadFlag, accessToken]);

	const desideRouter = () => {
		const getLSVariable = getLsWithExpiry(LSVariable);
		const userData = getLSVariable ? JSON.parse(getLSVariable) : null;
		if (userData) {
			return <>{userDetails && userDetails.id ? <MainRouter userData={userData} location={location} /> : null}</>;
		}
		return (
			<div className="login-wrap">
				<SessionRouter location={location} />
			</div>
		);
	};

	return (
		<>
			{desideRouter()}
			<NotificationContainer />

			<PreloaderInner />
		</>
	);
}
const mapStateToProps = (state) => {
	return {
		accessToken: state.Auth.accessToken,
		userDetails: state.Auth.userDetails,
		notifReloadFlag: state.Notification.reloadFlag,
	};
};

const mapDispatchToProps = {
	doResetField: resetField,
	doGetLoggedUser: getLoggedUser,
	doShowLoader: showLoader,
	doGetLookupValues: getLookupValues,
	doGetAdminusers: getAdminusers,
	doGetUnreadCount: getUnreadCount,
	doFetchNotification: fetchNotification,
	doGetLookupValuesList: getLookupValuesList,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
