import React from "react";

import Slider from "../SharedComponts/Slider";
import SliderForm from "../SharedComponts/SliderForm";

const NoteSlider = (props) => {
	const { show, handleClose, note } = props;
	return (
		<Slider
			show={show}
			title={`Notes`}
			closeButton={true}
			handleClose={() => {
				handleClose();
			}}>
			<SliderForm handleClose={handleClose} hideSubmit={true}>
				{note ? (
					<div className="form-group note-text">
						<div className="note-block">
							<div className="content">{note}</div>
						</div>
					</div>
				) : (
					<div className="note-block">
						<div className="content">No notes added</div>
					</div>
				)}
			</SliderForm>
		</Slider>
	);
};

export default NoteSlider;
