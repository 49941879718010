import { BANK } from "../types";
import { serialize } from "../../utils/helper";
import fetchHandler from "../../utils/fetchHandler";
export function setBankFilter(filter, successHandler) {
	successHandler && successHandler();
	return {
		type: BANK.SET_FILTER,
		payload: filter,
	};
}
export const getBankList = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `banks?${serialize(values)}`,
		method: "GET",
		secure: true,
		actionType: BANK.GET_BANK_LIST,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const getBank = (id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `bank/${id}`,
		method: "GET",
		secure: true,
		actionType: BANK.GET_BANK,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const createBank = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `bank`,
		method: "POST",
		secure: true,
		actionType: BANK.CREATE_BANK,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const updateBank = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `bank/${values.id}`,
		method: "PUT",
		secure: true,
		actionType: BANK.UPDATE_BANK,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const setDefaultBank = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `/setdefaultbank/${values.id}`,
		method: "PUT",
		secure: true,
		actionType: BANK.SET_DEFAULT,
		body: "true",
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const deleteBank = (id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `bank/${id}`,
		method: "DELETE",
		secure: true,
		actionType: BANK.DELETE_BANK,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
