import React, { useState } from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

const MenuItem = (props) => {
	const {
		menu: { label, iconClass, subMenu, link, key },
	} = props;
	const [show, setShow] = useState(false);

	// const overlayRef = useRef(null);

	const location = useLocation();
	const activeMenu = link ? location.pathname.startsWith(link) : key ? location.pathname.startsWith(key) : false;
	if (subMenu) {
		return (
			<OverlayTrigger
				trigger="click"
				placement="right"
				className="sidemenu-dropdown"
				rootClose={true}
				show={show}
				onToggle={() => setShow(!show)}
				overlay={
					<Popover id="popover-basic" className={`sidemenu-popover`}>
						<Popover.Content>
							<div className="submenu-list">
								{subMenu.map(({ label, link }, index) => (
									<Link
										to={link}
										key={index}
										className={location.pathname.startsWith(link) ? "submenu-item active" : "submenu-item"}>
										<span className="bullet"></span>
										{label}
									</Link>
								))}
							</div>
						</Popover.Content>
					</Popover>
				}>
				<div className={`menu-item ${activeMenu ? "active-menu-item" : ""}`}>
					<span className="menu-link">
						<span className="menu-icon-wrap">
							<span className={`${iconClass} menu-icon`}></span>
						</span>
						<span className="menu-text">{label}</span>
						<span className={`nav-icon ${show ? "icon-Right-Arrow" : "icon-down-arrow"}`}></span>
					</span>
				</div>
			</OverlayTrigger>
		);
	} else {
		return (
			<Link to={link}>
				<div className={`menu-item ${activeMenu ? "active-menu-item" : ""}`}>
					<span className="menu-link">
						<div className="menu-icon-wrap">
							<span className={`${iconClass} menu-icon`}></span>
						</div>
						<span className="menu-text">{label}</span>
					</span>
				</div>
			</Link>
		);
	}
};
export default MenuItem;
