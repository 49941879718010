import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, getFormValues } from "redux-form";
import { get } from "lodash";
import Slider from "../../../SharedComponts/Slider";
import { formFields } from "./helper";
import SliderForm from "../../../SharedComponts/SliderForm";
const AddTask = (props) => {
	const { show, handleClose, handleSubmit, submitting, reset, adminUsers, taskStatusList, formValues, change } = props;

	return (
		<Slider
			show={show}
			title={"Add Task"}
			handleClose={() => {
				reset();
				handleClose();
			}}>
			<SliderForm handleSubmit={handleSubmit} submitting={submitting} handleClose={handleClose}>
				{formFields({ adminUsers, taskStatusList, formValues, change }).map((item, index) => {
					return item.hideField ? null : (
						<div key={index} className="form-group">
							<div className="form-lable">
								{item.label}
								{item.required ? <span className="required-label">*</span> : null}
							</div>
							{item.type === "date" ? (
								<Field
									onClear={() => {
										change(item.name, null);
									}}
									{...item}
								/>
							) : (
								<Field {...item} />
							)}
						</div>
					);
				})}
			</SliderForm>
		</Slider>
	);
};
const mapStateToProps = (state) => {
	return {
		adminUsers: get(state, "Common.AdminUsers", null),
		taskStatusList: get(state, "Common.TaskStatus", null),
		formValues: getFormValues("TaskAddForm")(state),
	};
};

export default connect(
	mapStateToProps,
	null
)(
	reduxForm({
		form: "TaskAddForm",
		enableReinitialize: true,
	})(AddTask)
);
