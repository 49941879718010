import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import FormControl from "../SharedComponts/FormControl";

import { required } from "../../constant/formValidators";
const UserDetails = ({ handleSubmit, history, initialValues, mode, currentRecord }) => {
	const submitLogin = () => {};
	return initialValues.fullName ? (
		mode && mode === 1 ? (
			<div className="row w-100 ml-5">
				<div className="col-12">
					<h2>View User Deatils Form</h2>
					<hr className="heading-hr" />
				</div>

				<div className="row w-100">
					<div className="col-12">
						<div className="row w-100">
							<div className="col-6">
								<div className="form-group">
									<div className="form-lable">Full Name</div>
									{currentRecord.fullName}
								</div>
							</div>
							<div className="col-6">
								<div className="form-group">
									<div className="form-lable">Email</div>
									{currentRecord.emailAddress}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		) : (
			<form className="exchanges-form m-4" onSubmit={handleSubmit(submitLogin)}>
				<div className="row w-100">
					<div className="col-12">
						<h2>Edit User Deatils Form</h2>
						<hr className="heading-hr" />
					</div>

					<div className="row w-100">
						<div className="col-12">
							<div className="row w-100">
								<div className="col-12">
									<div className="form-group">
										<div className="form-lable">Full Name</div>

										<Field
											name="fullName"
											component={FormControl}
											type="text"
											className="form-control form-field"
											placeholder="Full Name"
											validate={[required]}
										/>
									</div>
								</div>
								<div className="col-12">
									<div className="form-group">
										<div className="form-lable">Email</div>

										<Field
											name="emailAddress"
											component={FormControl}
											type="text"
											className="form-control form-field"
											placeholder="Email"
											validate={[required]}
										/>
									</div>
								</div>
								<div className="col-12">
									<Field
										name="uploads"
										component={FormControl}
										accept="image/*"
										multiple
										type="file-drop"
										className="form-control form-field"
										placeholder="Email"
										validate={[]}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		)
	) : null;
};

export default connect(
	null,
	null
)(
	reduxForm({
		form: "UserDetailsForm",
		enableReinitialize: true,
	})(UserDetails)
);
