import React from "react";
import { Date as DateWrap } from "../../../../utils/formatter";
import { dateFormat } from "../../../../constant/common";
import { MoreOptions } from "../../../SharedComponts/FormElements";
export const columns = ({ onEdit = null, onDelete = null, onDownload = null, onView = null }) => [
	{
		label: "Document",
		accessor: "documentName",
		className: "col-documentName",
		headerClassName: "col-documentName-header",
		render: (row) => (
			<div className="text-highlighted" onClick={() => onView(row)}>
				{row.documentName}
			</div>
		),
		width: "26%",
	},
	{
		label: "Property ID",
		accessor: "exchangeProperty",
		className: "col-propertyId",
		headerClassName: "col-propertyId-header",
		width: "20%",
		render: (row) => {
			if (row.exchangeProperty) {
				return row.exchangeProperty.label ? (
					<span className={row.propertyDeleted ? "ppty-deletd" : ""}>{row.exchangeProperty.label}</span>
				) : (
					"---"
				);
			} else {
				return "---";
			}
		},
	},
	{
		label: "Last Modified By",
		accessor: "lastModifierUser",
		className: "col-generatedBy",
		headerClassName: "col-generatedBy-header",
		width: "20%",
		render: (row) => row.lastModifierUser.label,
	},

	{
		label: "Last Modified Date",
		accessor: "lastModificationTime",
		className: "col-generatedDate",
		headerClassName: "col-generatedDate-header",
		render: (row) => <DateWrap utcTime={row.lastModificationTime} format={dateFormat} noConvert={true} />,
		width: "15%",
	},
	{
		label: "Status",
		accessor: "status",
		className: "col-status",
		headerClassName: "col-status-header",
		width: "11%",
		render: (row) => <span className={`status-card status-${row.documentStatus.value}`}> {row.documentStatus.label}</span>,
	},
	{
		label: "Action",
		className: "col-actions  text-right action-cols",
		headerClassName: "col-actions-header",
		width: "8%",
		sortable: false,
		render: (row) => {
			const optionList = [
				{
					label: "Download",
					onClick: () => onDownload(row),
				},
			];
			if (row.deletable) {
				optionList.push({
					label: "Delete",
					onClick: () => onDelete(row),
				});
			}
			if (row.documentStatus.value === 1001) {
				optionList.push({
					label: "Edit",
					onClick: () => onEdit(row),
				});
			}
			return <MoreOptions options={optionList} />;
		},
	},
];
