import React from "react";
import { dateFormat } from "../../../../../constant/common";
import { email, required } from "../../../../../constant/formValidators";
import { Date } from "../../../../../utils/formatter";
import FormControl from "../../../../SharedComponts/FormControl";

export const columns = ({ onChooseTemplate, selectedTemplate }) => [
	{
		name: "Choose Template",
		className: "col-template-choose",
		sortable: false,
		render: (row) => (
			<FormControl
				type="radio"
				input={{
					name: "default",
					id: row.id,
					checked: selectedTemplate.id === row.id,
					value: row.bankName,
					onChange: (val) => {
						onChooseTemplate(row);
					},
				}}
				meta={{ touched: false, error: false, warning: false }}
			/>
		),
	},
	{
		name: "Template Name",
		accessor: "templateName",
		className: "col-template-name",
	},

	{
		name: "Last Modified By",
		accessor: "lastModifiedUser",
		sortable: true,
		className: "col-template-modified",
		render: (row) => <>{row.lastModifiedUser?.label}</>,
	},
	{
		name: "Last Modified Date",
		accessor: "lastModifiedTime",
		sortable: true,
		className: "col-zip",

		render: (row) => <Date utcTime={row.lastModifiedTime} format={dateFormat} />,
	},
];
export const signFormFields = () => {
	return [
		{
			label: "Name",
			name: "displayName",
			component: FormControl,
			type: "text",
			validate: [required],
			required: true,
			className: "form-control form-field",
		},

		{
			label: "Email Address",
			name: "emailAddress",
			component: FormControl,
			validate: [required, email],
			required: true,
			type: "text",
			className: "form-control form-field",
		},
	];
};
