import React from "react";
import Input from "./Input";
import RadioInput from "./RadioInput";
import CheckBox from "./Checkbox";
import Select from "./Select";
import Datepicker from "./DatePicker";
import Dropzone from "./DropZone";
import AsyncSelect from "./AsyncSelect";
import AsyncCreatableSelect from "./AsyncCreatableSelect";
import SpectrumEditer from "./Editer";
import TextArea from "./TextArea";
import ToggleButton from "./ToggleButton";
import Cselect from "./Cselect";
const FormControl = (props) => {
	const { type } = props;

	switch (type) {
		case "email":
		case "password":
		case "text":
			return <Input {...props} />;
		case "radio":
			return <RadioInput {...props} />;
		case "checkbox":
			return <CheckBox {...props} />;
		case "select":
			return <Select {...props} />;
		case "cselect":
			return <Cselect {...props} />;
		case "asyncSelect":
			return <AsyncSelect {...props} />;
		case "asyncCreatableSelect":
			return <AsyncCreatableSelect {...props} />;
		case "date":
			return <Datepicker {...props} />;
		case "file-drop":
			return <Dropzone {...props} />;
		case "editor":
			return <SpectrumEditer {...props} />;
		case "textarea":
			return <TextArea {...props} />;
		case "boolean":
			return (
				<div className="input-container">
					<ToggleButton {...props} />
				</div>
			);
		default: {
			return <></>;
		}
	}
};
export default FormControl;
