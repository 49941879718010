import React from "react";

import { MoreOptions } from "../../SharedComponts/FormElements";
import FormControl from "../../SharedComponts/FormControl";
import { required } from "../../../constant/formValidators";
import { Date } from "../../../utils/formatter";
import { dateFormat } from "../../../constant/common";

export const columns = ({
	onDeleteTemplate = () => {},
	onClickCopy = () => {},
	onDownloadTemplate,
	editTemplate,
	onClickView,
}) => [
	{
		name: "Template Name",
		accessor: "templateName",
		className: "col-bank-name",
		render: (row) => {
			return (
				<div
					className="text-highlighted"
					onClick={() => {
						onClickView(row);
					}}>
					{row.templateName}
				</div>
			);
		},
	},

	{
		name: "Exchange Type",
		accessor: "exchangeType.label",
		sortable: false,
		className: "col-city",
	},
	{
		name: "Template Type",
		accessor: "templateType.label",
		sortable: false,
		className: "col-type",
	},
	{
		name: "Last Modified By",
		accessor: "lastModifiedUser",
		sortable: true,
		className: "col-state",
		render: (row) => <>{row.lastModifiedUser ? row.lastModifiedUser.label : "--"}</>,
	},
	{
		name: "Last Modified Date",
		accessor: "lastModifiedTime",
		sortable: true,
		className: "col-zip",
		render: (row) => <Date utcTime={row.lastModifiedTime} format={dateFormat} />,
	},
	{
		name: "Status",
		accessor: "templateStatus",
		className: "col-status",
		sortable: false,
		render: (row) => (
			<span className={`status-card  status-${row.templateStatus?.value}`}>{row.templateStatus?.label}</span>
		),
	},
	{
		name: "Actions",
		className: "col-actions text-right",
		headerClassName: "col-actions-header",
		sortable: false,
		render: (row) => {
			return (
				<>
					<MoreOptions
						options={[
							{ label: "Download", onClick: () => onDownloadTemplate(row) },
							{ label: "Delete", onClick: () => onDeleteTemplate(row) },
							{ label: "Edit", onClick: () => editTemplate(row) },
							{ label: "Copy", onClick: () => onClickCopy(row) },
						]}
					/>
				</>
			);
		},
	},
];

export const formFields = ({ exchangeTypes, templateTypes }) => {
	return [
		{
			label: "Select the type of Exchange",
			name: "exchangeType",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			validate: [required],
			options: exchangeTypes,
			required: true,
		},

		{
			label: "Select the type of Template",
			name: "templateType",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			options: templateTypes,
		},

		{
			label: "What should the template be named ?",
			name: "templateName",
			component: FormControl,
			validate: [required],
			required: true,
			type: "text",
			className: "form-control form-field",
		},
	];
};
export const templateCopyFormFields = () => {
	return [
		{
			label: "Template Name",
			name: "newTemplateName",
			component: FormControl,
			validate: [required],
			required: true,
			type: "text",
			className: "form-control form-field",
		},
	];
};
