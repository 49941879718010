import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { reduxForm, FieldArray } from "redux-form";
import { connect } from "react-redux";
import PropertyForm from "./PropertyForm";
import { getStatus, getDateFromDatePicker, getCopy, formatDateToISOString } from "../../../../utils/helper";
import AddSettlementForm from "./AddSettlementForm";
import AddExcrowForm from "./AddExcrowForm";
import lodash from "lodash";
import { useDispatch } from 'react-redux';
import {
	getCities,
	showLoader,
	updateAddress,
	getUser,
	getReplacementProperty,
	createProperty,
	updateProperty,
	submitProperty,
	getEntityByType,
	searchExcrowByCompany,
	getCounties,
	createEntity,
	createEscrow,
	deleteProperty,
	createSeller,
	deleteDealer,
	getEntity,
	relatedExchangeExistsForCompany,
	createAddress,
	getEscrowAgent,
	excrowByCompany,
} from "../../../../store/actions";

import { errorMessage } from "../../../../utils/errorMessage";
import Confirm from "../../../SharedComponts/FormElements/confirm";
import { successMessage } from "../../../../utils/successMessage";
// import { successMessage } from "../../../../utils/successMessage";

const ReplacementProperyInfo = (props) => {
	const {
		formValues,
		CompanyType = [],
		replacementProperties,
		doGetEntityByType,
		doGetEntity,
		doGetUser,
		doRelatedExchangeExistsForCompany,
		doUpdateAddress,
		doCreateAddress,
		handleSubmit,
		doCreateEscrow,
		doGetEscrowAgent,
		// doCreateExchangeContact,
		doCreateEntity,
		doCreateProperty,
		doUpdateProperty,
		doSubmitProperty,
		reset,
		doGetReplacementProperty,
		doSearchExcrowByCompany,
		doGetCounties,
		doDeleteProperty,
		// qiCompanies,
		// exchangerInformation,
		loadUsers,

		states,
		cities,
		change,
		doDeleteDealer,
		// isReload,
		doGetCities,
		doCreateSeller,
		// loadCounties,
		// exchangerType,
		// setReload,
		doShowLoader,
		exchangeDetail,
		// Modules,
		isLocked,
		doExcrowByCompany,
	} = props;
	const dispatch = useDispatch();
	const [isAddressEdit, setAddressEdit] = useState(null);
	const [confirmAddressEdit, setConfirmAddressEdit] = useState(false);
	const [settlemntCompanies, setSettlemntCompanies] = useState([]);
	const [showSettleForm, setShowSettleForm] = useState({ show: false, property: null });
	const [reloadProperty, setReloadProperty] = useState(false);
	const [reloadCompany, setReloadCompany] = useState(false);
	const [excroForm, setExcrowForm] = useState({ show: false, company: null, property: null });
	const [confirmDeleteProperty, setConfirmDeleteProperty] = useState({ show: false, id: null });
	const [confirmDeleteSeller, setConfirmDeleteSeller] = useState({ show: false, id: null });
	const [autoSaveLoadingCount, setAutoSaveLoadingCount] = useState(0);
	const [escrowAgent, setEscrowAgent] = useState([]);
	useEffect(() => {
		if (exchangeDetail.id) {
			doShowLoader(true);
			const successCB = (response) => {
				doShowLoader(false);
				reset();
			};
			const failCB = (result) => {
				doShowLoader(false);
			};
			doGetReplacementProperty(exchangeDetail.guid, successCB, failCB);
		}
		// eslint-disable-next-line
	}, [doGetReplacementProperty, doShowLoader, reloadProperty, exchangeDetail]);
	useEffect(() => {
		const successCB = (response) => {
			setSettlemntCompanies(response.result);
		};
		const failCB = (result) => {
			errorMessage(result);
		};
		if (CompanyType && CompanyType.length > 0) {
			const id = CompanyType.find((item) => {
				return item.label === "Settlement";
			}).value;
			doGetEntityByType({ typeId: id, isInactiveReq: "false" }, successCB, failCB);
		}
	}, [doGetEntityByType, doShowLoader, CompanyType, reloadCompany, setSettlemntCompanies]);
	const getEscrowAgentData = async () => {
		const successCB = (response) => {
			setEscrowAgent(response.result);
		};
		const failCB = (result) => {
			errorMessage(result);
		};
		await doGetEscrowAgent(successCB, failCB);
	};


	useEffect(() => {
		getEscrowAgentData();
	}, [doGetEscrowAgent, setEscrowAgent]);
	const loadCounties = (searchKey, callback) => {
		const successCB = ({ result }) => {
			callback(result);
		};
		const FailCB = (result) => {
			callback([]);
		};
		if (searchKey.length > 1) {
			doGetCounties(searchKey, successCB, FailCB);
		} else {
			callback([]);
		}
	};
	const createRelinquishProperty = (cb) => {
		doShowLoader(true);

		const successCB = (result) => {
			doShowLoader(false);
			cb();
			setReloadProperty(!reloadProperty);
		};
		const failCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		doCreateProperty(
			{
				exchangeId: exchangeDetail.id,
				dealType: {
					value: 252,
					label: "Replacemnet",
				},
				id: 0,
			},
			successCB,
			failCB
		);
	};
	const showExcrowForm = (property) => {
		const properties = lodash.get({ ...formValues }, property);

		if (properties.settlementInformation && properties.settlementInformation.settlementCompany) {
			setExcrowForm({ show: true, company: properties.settlementInformation.settlementCompany, property });
		} else {
			setExcrowForm({ show: true, company: null });
		}
	};
	const saveProperty = (index, isUpdate, isshowLoader, isRefresh, keyValArray) => {
		const properties = JSON.parse(JSON.stringify({ ...formValues.properties[index] }));
		properties.dealerDtoList = properties.dealerDtoList.map((item) => {
			if (item.dealer && item.dealer.__isNew__) {
				item.dealer = { label: item.dealer.label, value: 0 };
			}
			return item;
		});

		if (properties.settlementInformation && properties.settlementInformation.contractDate) {
			properties.settlementInformation.contractDate = formatDateToISOString(
				properties.settlementInformation.contractDate
			);
		}
		if (properties.closeDate) {
			properties.closeDate = formatDateToISOString(properties.closeDate);
		}
		if (keyValArray && keyValArray.length > 0) {
			for (const item of keyValArray) {
				lodash.set(properties, item.key, item.value);
			}
		}

		const successCB = (result) => {
			let newcount = autoSaveLoadingCount;
			setAutoSaveLoadingCount(newcount--);
			if (isshowLoader) {
				doShowLoader(false);
			}
			if (isRefresh) {
				setReloadProperty(!reloadProperty);
			}
			if (isUpdate) {
				doGetReplacementProperty(exchangeDetail.guid);
			}
		};
		const failCB = (result) => {
			let newcount = autoSaveLoadingCount;
			setAutoSaveLoadingCount(newcount--);
			if (isshowLoader) {
				doShowLoader(false);
			}
		};
		if (isshowLoader) {
			doShowLoader(true);
		}
		setAutoSaveLoadingCount(autoSaveLoadingCount + 1);
		doUpdateProperty(
			{
				...properties,
				exchangeId: exchangeDetail.id,
				dealType: {
					value: 252,
					label: "Replacement",
				},
			},
			successCB,
			failCB
		);
	};

	const populateSettlementAddress = (property, entityid) => {
		const companyId = entityid
		let escrowByCompanyId = []
		const index = Number(property.split("[")[1].split("]")[0]);
		doShowLoader(true);
		const successCBcompany = (response) => {
			doShowLoader(false);
			escrowByCompanyId = response.result;
			setEscrowAgent(response?.result)
		};

		const failCBcompany = (result) => {
			doShowLoader(false);
		}
		const successCB = (response) => {
			doShowLoader(false);
			const keyValArray = [];
			keyValArray.push(
				{
					key: `settlementInformation.settlementCompany`,
					value: { label: response.result.entityName, value: response.result.id },
				},
				{
					key: `settlementInformation.escrowAgent`,
					value: null,
				},
				{
					key: `settlementInformation.phoneNumber`,
					value: null,
				},
				{
					key: `settlementInformation.emailAddress`,
					value: null,
				},
				{
					key: `settlementInformation.fax`,
					value: null,
				}
			);
			if (response.result.address) {
				for (const key in response.result.address) {
					change(`${property}.settlementInformation.address.${key}`, response.result.address[key]);
					keyValArray.push({ key: `settlementInformation.address.${key}`, value: response.result.address[key] });
				}
			}

			saveProperty(index, false, false, false, keyValArray);
		};
		const failCB = (result) => {
			doShowLoader(false);
		};
		doExcrowByCompany(companyId, successCBcompany, failCBcompany);
		doGetEntity(entityid, successCB, failCB);
	};

	const submitAddress = (index) => {
		checkExchangeExists(index);
	};
	const confirmAddressEditAction = () => {
		updateSettlemnetAddress(true);
		setConfirmAddressEdit(false);
	};
	const noConfirmAddressEditAction = () => {
		// updateSettlemnetAddress(false);
		saveProperty(isAddressEdit, false, true, true);
		setAddressEdit(null);
		setConfirmAddressEdit(false);
	};
	const checkExchangeExists = () => {
		doShowLoader(true);
		const successCB = (result) => {
			doShowLoader(false);
			if (result.result === true) {
				setConfirmAddressEdit(true);
			} else {
				updateSettlemnetAddress(false);
			}
		};
		const failCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};
		const id = formValues.properties[isAddressEdit].settlementInformation.settlementCompany.value;

		doRelatedExchangeExistsForCompany({ id, exchangeId: exchangeDetail.id }, successCB, failCB);
	};
	const updateSettlemnetAddress = (isUpdate) => {
		const values = formValues.properties[isAddressEdit].settlementInformation.address;
		doShowLoader(true);
		const successCB = (response, isCreate) => {
			doShowLoader(false);
			// setReload(!isReload);
			setAddressEdit(null);
			// setAddressInformation({});
			if (isCreate) {
				saveProperty(isAddressEdit, false, true, true, [
					{ key: "settlementInformation.address", value: response.result },
				]);
			}
		};
		const failCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
			// setReload(!isReload);
			// setAddressInformation({});
		};

		const objectTypeId = 126;
		const objectId = formValues.properties[isAddressEdit].settlementInformation.settlementCompany.value;

		if (values.id) {
			doUpdateAddress({ objectId, objectTypeId, ...values, isUpdateRelatedAddress: isUpdate }, successCB, failCB);
		} else {
			doCreateAddress(
				{ objectId, objectTypeId, ...values, isUpdateRelatedAddress: isUpdate },
				(result) => successCB(result, true),
				failCB
			);
		}
	};
	const popupExcrowContacts = (property, id) => {
		doShowLoader(true);
		const successCB = (response) => {
			doShowLoader(false);
			change(`${property}.settlementInformation.settlementCompany`, response.result.companyInput);
			change(`${property}.settlementInformation.phoneNumber`, response.result.phoneNumber);
			change(`${property}.settlementInformation.fax`, response.result.fax ? response.result.fax : null);
			change(`${property}.settlementInformation.emailAddress`, response.result.emailAddress);
			const keyValueArray = [
				{ key: "settlementInformation.phoneNumber", value: response.result.phoneNumber },
				{ key: "settlementInformation.fax`", value: response.result.emailAddress },
				{ key: "settlementInformation.emailAddress", value: response.result.fax ? response.result.fax : null },
				{ key: "settlementInformation.escrowAgent", value: { label: response.result.name, value: response.id } },
			];

			saveProperty(Number(property.split("[")[1].split("]")[0]), false, false, false, keyValueArray);
		};
		const failCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};
		doGetUser(id, successCB, failCB);
	};
	const onDeleteProperty = (index, cb) => {
		setConfirmDeleteProperty({ show: true, id: formValues.properties[index].id, cb: cb });
	};

	const onSubmitPropertyInfo = (values) => {
		const payload = getCopy(values);
		let isDuplicate = false;

		for (const property of payload.properties) {
			if (property.settlementInformation && property.settlementInformation.contractDate) {
				property.settlementInformation.contractDate = formatDateToISOString(
					property.settlementInformation.contractDate
				);
			}
			if (property.closeDate) {
				property.closeDate = formatDateToISOString(property.closeDate);
			}

			const dealerDtoList = [];
			let chekName = { label: null, value: null };
			for (const item of property.dealerDtoList) {
				if (item.dealer && item.dealer.__isNew__) {
					item.dealer = { label: item.dealer.label, value: 0 };
				}
				if (JSON.stringify(chekName) === JSON.stringify(item.dealer)) {
					isDuplicate = true;
					break;
				}
				chekName = item.dealer;
				dealerDtoList.push(item);
			}
			if (isDuplicate) {
				break;
			}
			property.dealerDtoList = dealerDtoList;
		}
		if (isDuplicate) {
			errorMessage(509);
			return;
		}
		doShowLoader(true);
		const successCB = (result) => {
			doShowLoader(false);
			// setReload(!isReload);
			successMessage(509);
		};
		const failCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
			// setReload(!isReload);
		};

		doSubmitProperty(payload, successCB, failCB);
	};
	const showSettlementPopup = (property) => {
		setShowSettleForm({ show: true, property });
	};
	const handleSubmitExcrow = async ({ property, ...value }) => {
		doShowLoader(true);
		const successCB = (response) => {
			doShowLoader(false);
			change(`${property}.settlementInformation.escrowAgent`, { label: response.result.name, value: response.result.id });
			change(`${property}.settlementInformation.phoneNumber`, response.result.phoneNumber);
			change(`${property}.settlementInformation.fax`, response.result.fax ? response.result.fax : null);
			change(`${property}.settlementInformation.emailAddress`, response.result.emailAddress);
			setExcrowForm({ show: false, company: null });
		};
		const failCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		const postData = {
			...value,
			status: getStatus("Active"),
		};

		doCreateEscrow(postData, successCB, failCB);
	};

	const onCreateSeller = (index) => {
		// Propertyid and  dealerRole is required role id = 10
		doShowLoader(true);
		const successCB = (result) => {
			doShowLoader(false);
			setReloadProperty(!reloadProperty);
		};
		const failCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};
		doCreateSeller(
			{
				propertyId: formValues.properties[index].id,
				dealerRole: {
					label: "Seller",
					value: 10,
				},
			},
			successCB,
			failCB
		);
	};
	const handleSubmitSettlement = ({ property, ...values }) => {
		doShowLoader(true);
		const { emailAddress, phoneNumber, name, ...postData } = values;
		const successCB = (response) => {
			doShowLoader(false);
			// successMessage(206);
			if (response.result) {
				const company = {
					value: response.result.id,
					label: response.result.entityName,
				};
				change(`${property}.settlementInformation.settlementCompany`, company);
				if (response.result.address) {
					for (const key in response.result.address) {
						change(`${property}.settlementInformation.address.${key}`, response.result.address[key]);
					}
				}
				setReloadCompany(!reloadCompany);
				handleSubmitExcrow({ emailAddress, phoneNumber, name, company, property });
			}

			setShowSettleForm({ show: false, property: null });
		};
		const FailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		doCreateEntity({ ...postData, status: getStatus("Active") }, successCB, FailCB);
	};
	const confirmDeletePropertyAction = () => {
		doShowLoader(true);
		const successCB = (response) => {
			doShowLoader(false);
			setConfirmDeleteProperty({ show: false, id: null });
			confirmDeleteProperty.cb();
			setReloadProperty(!reloadProperty);
		};
		const FailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};
		doDeleteProperty(confirmDeleteProperty.id, successCB, FailCB);
	};
	const onDeleteSeller = (propertyIndex, sellerIndex) => {
		setConfirmDeleteSeller({ show: true, id: formValues.properties[propertyIndex].dealerDtoList[sellerIndex].id });
	};
	const confirmDeleteSellerAction = () => {
		doShowLoader(true);
		const successCB = (response) => {
			doShowLoader(false);
			setConfirmDeleteSeller({ show: false, id: null });
			setReloadProperty(!reloadProperty);
		};
		const FailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};
		doDeleteDealer(confirmDeleteSeller.id, successCB, FailCB);
	};
	const resetFormField = (property) => {
		change(`${property}.settlementInformation.phoneNumber`, null);
		change(`${property}.settlementInformation.escrowAgent`, null);
		change(`${property}.settlementInformation.settlementCompany`, null);
		change(`${property}.settlementInformation.emailAddress`, null);
		change(`${property}.settlementInformation.fax`, null);
		change(`${property}.settlementInformation.address.streetAddress`, null);
		change(`${property}.settlementInformation.address.state`, null);
		change(`${property}.settlementInformation.address.city`, null);
		change(`${property}.settlementInformation.address.zipCode`, null);
		getEscrowAgentData();
	}
	return (
		<>
			{excroForm.show && (
				<AddExcrowForm
					show={excroForm.show}
					initialValues={{ company: excroForm.company, property: excroForm.property }}
					isEdit={false}
					handleClose={() => {
						setExcrowForm({ show: false, company: null, property: null });
					}}
					onSubmit={handleSubmitExcrow}
					doShowLoader={doShowLoader}
					settlementCompanies={[]}
				/>
			)}
			{showSettleForm.show && (
				<AddSettlementForm
					loadCounties={loadCounties}
					doGetCities={doGetCities}
					show={showSettleForm.show}
					initialValues={{
						property: showSettleForm.property,
						companyType: CompanyType.find((item) => item.label === "Settlement"),
					}}
					isEdit={false}
					handleClose={() => setShowSettleForm({ show: false, property: null })}
					onSubmit={handleSubmitSettlement}
					companyTypes={[]}
				/>
			)}
			{confirmDeleteSeller.show && (
				<Confirm
					onYes={confirmDeleteSellerAction}
					onNo={(e) => {
						setConfirmDeleteSeller({ show: false, id: null });
					}}
					show={confirmDeleteSeller.show}
				/>
			)}
			{confirmDeleteProperty.show && (
				<Confirm
					onYes={confirmDeletePropertyAction}
					onNo={(e) => {
						setConfirmDeleteProperty({ show: false, id: null });
					}}
					show={confirmDeleteProperty.show}
					message="There are income and transactions related to this property. Please remove or edit the information as needed."
				/>
			)}
			{confirmAddressEdit && (
				<Confirm
					onYes={confirmAddressEditAction}
					onNo={(e) => {
						noConfirmAddressEditAction();
					}}
					title="Confirm Update"
					message="There are active or new exchanges related to this address, Do you want to continue?"
					show={confirmAddressEdit ? true : false}
				/>
			)}
			<form onSubmit={handleSubmit(onSubmitPropertyInfo)}>
				<FieldArray
					name="properties"
					props={{
						reloadProperty,
						replacementProperties,
						populateSettlementAddress,
						setAddressEdit,
						isAddressEdit,
						submitAddress,
						onDeleteSeller,
						createSeller: onCreateSeller,
						onDeleteProperty,
						popupExcrowContacts,
						createRelinquishProperty,
						showExcrowForm,
						showSettlementPopup,
						saveProperty,
						loadUsers,
						doShowLoader,
						doGetUser,
						settlemntCompanies,
						loadCounties,
						doSearchExcrowByCompany,
						formValues,
						doGetCities,
						states,
						change,
						cities,
						setReloadProperty,
						isLocked,
						getEscrowAgentData,
						escrowAgent,
						doGetEscrowAgent,
						doExcrowByCompany,
						resetFormField,
					}}
					component={PropertyForm}
				/>

				{!isLocked && formValues && formValues.properties && formValues.properties.length ? (
					<div className="mb-4 d-flex justify-content-end">
						{autoSaveLoadingCount > 0 ? (
							<Button className="form-button-primary inactive">
								<span className="loading">Saving</span>
							</Button>
						) : (
							<Button type="submit" className="form-button-primary">
								Submit
							</Button>
						)}
					</div>
				) : null}
			</form>
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		Common: { states = [], CompanyType, Modules, counties = [], cites: cities = [] },
		form: { replacementPropertyForm },
	} = state;
	const formValues = replacementPropertyForm && replacementPropertyForm.values;

	return {
		states,
		counties,
		cities,
		Modules,
		CompanyType,
		formValues,
	};
};

const mapDispatchToProps = {
	doGetReplacementProperty: getReplacementProperty,
	doShowLoader: showLoader,
	doGetCities: getCities,
	doCreateProperty: createProperty,
	doUpdateProperty: updateProperty,
	doSubmitProperty: submitProperty,
	doUpdateAddress: updateAddress,
	doCreateAddress: createAddress,
	doGetEntityByType: getEntityByType,
	doRelatedExchangeExistsForCompany: relatedExchangeExistsForCompany,
	doGetUser: getUser,
	doSearchExcrowByCompany: searchExcrowByCompany,
	doGetCounties: getCounties,
	doCreateEntity: createEntity,
	doCreateEscrow: createEscrow,
	doDeleteProperty: deleteProperty,
	doCreateSeller: createSeller,
	doDeleteDealer: deleteDealer,
	doGetEntity: getEntity,
	doGetEscrowAgent: getEscrowAgent,
	doExcrowByCompany: excrowByCompany,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "replacementPropertyForm",
		enableReinitialize: true,
	})(ReplacementProperyInfo)
);
