import { SETTINGS } from "../types";
import fetchHandler from "../../utils/fetchHandler";
const mockData = {
	companyList: [{ value: 1, label: "Spectrum Exchange Corp." }],
	exchangeTypes: [
		{ value: 1, label: "Delayed" },
		{ value: 2, label: "Simultanius" },
		{ value: 3, label: "Reverse" },
	],
};
export const getCompanyList = (values, successHandler, errorHandler) => {
	// const fetchOptions = {
	// 	url: `users?${serialize(values)}`,
	// 	method: "GET",
	// 	secure: true,
	// 	actionType: SETTINGS.GET_COMPANY_LIST,
	// };
	// return fetchHandler(fetchOptions, successHandler, errorHandler);

	successHandler && successHandler(values);
	return {
		type: SETTINGS.GET_COMPANY_LIST,
		payload: mockData.companyList,
	};
};
export const getExchangeTypes = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `general/types/9`,
		method: "GET",
		secure: true,
		actionType: SETTINGS.GET_EXCHANGE_TYPES,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const getCompanyTypes = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `general/types/6`,
		method: "GET",
		secure: true,
		actionType: SETTINGS.GET_COMPANY_TYPES,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
