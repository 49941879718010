import { COMMON } from "../types";

export const resetField = (key, value, successHandler = null) => {
	successHandler && successHandler(value);
	return {
		type: COMMON.RESET_FIELD,
		key,
		value,
	};
};
export const showLoader = (value, successHandler = null) => {
	successHandler && successHandler(value);
	return {
		type: COMMON.SHOW_LOADER,
		payload: value,
	};
};
