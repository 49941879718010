import React from "react";
import UserInfoTable from "../../SharedComponts/FormElements/UserInfoTable";
import { Card } from "react-bootstrap";
import Layout from "../../SharedComponts/Layout";
import { Heading } from "../../SharedComponts/FormElements";
import { useHistory } from "react-router-dom";

import { detailColumnFn } from "./helper";

const Details = (props) => {
	const { showEdit, breadcrumbs, initialValues, setIsEdit } = props;

	const InfoItem = ({ label, value }) => {
		return (
			<div className="info-item col-md-3">
				<div className="label">{label} </div>
				<div className="value"> {value}</div>
			</div>
		);
	};

	const history = useHistory();
	const detailColumn = detailColumnFn(history);

	return (
		<Layout breadcrumbs={breadcrumbs} className="user-details admin-details" title={`Escrow - ${initialValues.name}`}>
			<Card>
				<Card.Title className="info-card-title">
					<Heading title="Basic Information" />
					<div className="user-action-icons">
						<span
							className="user-action-icon"
							onClick={() => {
								setIsEdit(true);
								showEdit();
							}}>
							<span className="icon-icon-edit" />
						</span>
					</div>
				</Card.Title>

				<Card.Body className="info-card-body">
					<div className="user-info-wrap">
						<InfoItem label="Escrow Name" value={initialValues.name} />
						<InfoItem label="Settlement Company" value={initialValues.company ? initialValues.company.label : ""} />
						<InfoItem label="Email" value={initialValues.emailAddress} />
						<InfoItem label="Phone" value={initialValues.phoneNumber} />
						<InfoItem label="Status" value={initialValues.status ? initialValues.status.label : ""} />
						<InfoItem
							label="Address"
							value={
								initialValues.address && initialValues.address.streetAddress
									? initialValues.address.streetAddress
									: ""
							}
						/>
						<InfoItem
							label="City"
							value={initialValues.address && initialValues.address.city ? initialValues.address.city.label : ""}
						/>
						<InfoItem
							label="State"
							value={
								initialValues.address && initialValues.address.state ? initialValues.address.state.label : ""
							}
						/>
						<InfoItem
							label="Zip Code"
							value={initialValues.address && initialValues.address ? initialValues.address.zipCode : ""}
						/>
					</div>
				</Card.Body>
			</Card>
			{initialValues && initialValues.exchangeDtoList && initialValues.exchangeDtoList.length ? (
				<Card>
					<Card.Title className="info-card-title">
						<Heading title="Related Exchanges" />
					</Card.Title>
					<Card.Body className="info-card-body">
						<UserInfoTable
							rowHeadItems={[]}
							columns={detailColumn}
							data={initialValues.exchangeDtoList}
							dataAccessor={null}
						/>
					</Card.Body>
				</Card>
			) : null}
		</Layout>
	);
};

export default Details;
