import { TEMPLATES } from "../types";
import fetchHandler from "../../utils/fetchHandler";
// import { serialize } from "../../utils/helper";

export function setTemplateFilter(filter, successHandler) {
	successHandler && successHandler();
	return {
		type: TEMPLATES.SET_FILTER,
		payload: filter,
	};
}
export function setTemplateFormInputs(filter, successHandler) {
	successHandler && successHandler();
	return {
		type: TEMPLATES.SET_TEMPLATE_FORM_INPUT,
		payload: filter,
	};
}
export const getTemplateList = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `templates`,
		method: "POST",
		secure: true,
		actionType: TEMPLATES.GET_TEMPLATE_LIST,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const createTemplate = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `template`,
		method: "POST",
		secure: true,
		actionType: TEMPLATES.CREATE_TEMPLATE,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const updateTemplate = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `template`,
		method: "PUT",
		secure: true,
		actionType: TEMPLATES.UPDATE_TEMPLATE,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const deleteTemplate = (id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `template/${id}`,
		method: "DELETE",
		secure: true,
		actionType: TEMPLATES.DELETE_TEMPLATE,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const copyTemplate = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `copytemplate`,
		method: "POST",
		secure: true,
		actionType: TEMPLATES.COPY_TEMPLATE,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getTeplatePlaceholderMenu = (id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `templatefields/${id}`,
		method: "GET",
		secure: true,
		actionType: TEMPLATES.GET_PLACEHOLDER_MENU,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
