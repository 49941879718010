import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";

import { resetField, sendLoginCredentials, updateLoginCredentials, showLoader, sendOtpCredentials } from "../../store/actions";

import FormControl from "../SharedComponts/FormControl";
import { errorMessage } from "../../utils/errorMessage";
import { required, email } from "../../constant/formValidators";

import "./session.scss";
import OTPInput from "react-otp-input";

const LSVariable = process.env.REACT_APP_LS_VAR;

const OtpAuth = ({
    doSendLoginCredentials,
    doUpdateLoginCredentials,
    doSendOtpCredentials,
    handleSubmit,
    history,
    doShowLoader,
    doResetField,
}) => {
    const [otp, setOtp] = useState('');
    const [timer, setTimer] = useState(120);

    useEffect(() => {
        const countdown = setTimeout(() => {
            if (timer > 0) {
                setTimer(timer - 1);
            } else {
                history.push("/login");
            }
        }, 1000);
        return () => clearTimeout(countdown);
    }, [timer, history]);

    const handleOtpChange = (otpValue) => {
        setOtp(otpValue);
    };

    const handleSubmitForm = () => {
        const userId = localStorage.getItem('userId');
        doShowLoader(true);
        const successHandler = (event) => { 
            event && event.result && doResetField("Auth.accessToken", event.result);
			const now = new Date();
			const item = {
				...event.result,
				expiry: now.getTime() + 86370 * 1000,
			};
			localStorage.setItem(LSVariable, JSON.stringify(item));
			doShowLoader(false);
			const updateSuccessHandler = () => {
				history.push("/");
			};
			doUpdateLoginCredentials(item, updateSuccessHandler);
        };
    
        const postData = {
            userId: userId,
            twoFactorCode: otp
        };
    
        const errorHandler = () => {
           errorMessage(10528)
            doShowLoader(false);
        };
        doSendOtpCredentials({ ...postData }, successHandler, errorHandler);
    };
    

    return (
        <div className="sign-in-wrap">
            <div className="sign-in-container">
                <div className="login-logo"></div>

                <form className="sign-in-form sign-in-form mt-0 ml-4 mr-4 " onSubmit={handleSubmit(handleSubmitForm)}>
                    <div className="row">
                        <div className="col-12 sub-title">
                            <h2>OTP Verification</h2>
                            <hr className="heading-hr" />
                        </div>
                        <OTPInput
                            value={otp}
                            onChange={handleOtpChange}
                            numInputs={4}
                            renderSeparator={<span>-</span>}
                            renderInput={(props) => <input {...props} />}
                            inputStyle={{ width: "50%", height: "70px" }}
                            containerStyle={{ marginBottom: "20px", padding: "25px" }}
                        />

                        <div className="col-12">
                            <p style={{ textAlign: "center", color: "#5CA9FF" }}>{`Time remaining: ${Math.floor(timer / 60)}:${timer % 60 < 10 ? '0' : ''}${timer % 60}`}</p>
                            <button className="form-button-primary w-100" type="submit" disabled={otp.length !== 4}>
                                Done
                            </button>
                        </div>
                        <div className="col-12 content-center">
                        </div>
                        <div className="col-12 content-center">
                            <Link className="forgot-password mt-4" to="/login">
                                Back to Login
                            </Link>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

const mapDispatchToProps = {
    doSendLoginCredentials: sendLoginCredentials,
    doUpdateLoginCredentials: updateLoginCredentials,
    doSendOtpCredentials: sendOtpCredentials,
    doShowLoader: showLoader,
    doResetField: resetField,
};

export default connect(
    null,
    mapDispatchToProps
)(
    reduxForm({
        form: "OtpAuthForm",
    })(OtpAuth)
);
