import React from "react";
import CreatableSelect from "react-select/creatable";

const RenderField = ({ options, isMulti, input, id, placeholder, meta: { touched, error, warning }, isDisabled }) => {
	const handleChange = (selectedOption) => {
		input.onChange(selectedOption);
	};

	return (
		<div htmlFor={id} className="custom-select-box-wrap">
			<CreatableSelect
				components={{
					DropdownIndicator: null,
					NoOptionsMessage: () => {
						return "Type and hit enter";
					},
				}}
				placeholder={placeholder ? placeholder : "Select"}
				onChange={handleChange}
				onInputChange={handleChange}
				// options={options}
				menuIsOpen={false}
				className="custom-select-box"
				isMulti={true}
				// onBlur={() => input.onBlur(input.value)}
				// onFocus={() => input.onFocus(input.value)}
				value={input.value}
				name={input.name}
				isDisabled={isDisabled ? isDisabled : false}
			/>
			{touched && ((error && <span className="form-error-span">{error}</span>) || (warning && <span>{warning}</span>))}
		</div>
	);
};

export default RenderField;
