import React from "react";
import { Field } from "redux-form";
import lodash from "lodash";
import moment from "moment";
const currencyFormatter = require("currency-formatter");

const InfoItem = ({ label, wrapperClassName, isEditable, formData, accessor, required, isEdit, isCurrency, ...model }) => {
	let value = "-";
	if (accessor) {
		value = lodash.get(
			formData,
			model.type === "asyncSelect" || model.type === "asyncCreatableSelect" || model.type === "select"
				? accessor + ".label"
				: accessor,
			"-"
		);
		if (model.type === "date") {
			value = value ? moment(value).format("MM/DD/yyyy") : "-";
		} else if (isCurrency) {
			value =
				value === 0 || value
					? currencyFormatter.format(value, {
							code: "USD",
							precision: 2,
					  })
					: "-";
		} else {
			value = value === 0 || value ? value : "-";
		}
	}

	return (
		<div className={wrapperClassName ? wrapperClassName : "info-item col-3"}>
			<div className="label">
				{label} {required ? <span className="required-label">*</span> : null}{" "}
			</div>
			<div className="value">
				{isEdit ? (
					isEditable ? (
						<Field disabled={!isEdit} {...model} />
					) : model.type === "textarea" ? (
						<textarea className="form-field" value={value} readOnly />
					) : (
						<input className="form-field" type="text" readOnly value={value} />
					)
				) : (
					value
				)}
			</div>
		</div>
	);
};
export default InfoItem;
