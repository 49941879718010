import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Button, Row, Col } from "react-bootstrap";

const AdvancedFilter = (props) => {
	const { handleSubmit, handleClose, submitting, formFields, reset, clearAllFilter, change } = props;

	return (
		<div className="advanced-filter-container row">
			<div onClick={handleClose} className="close-btn-round">
				<span className="icon-close"></span>
			</div>
			<form className="filter-form" onSubmit={handleSubmit}>
				<Row>
					{formFields.map((item, index) => {
						return item.hideField ? null : (
							<Col xs={4} lg={4} md={4} key={index}>
								<div key={index} className="form-group">
									<div className="form-lable">
										{item.label}
										{item.required ? <span className="required-label">*</span> : null}
									</div>
									{item.type === "date" ? (
										<Field
											onClear={() => {
												change(item.name, null);
											}}
											{...item}
										/>
									) : (
										<Field {...item} />
									)}
								</div>
							</Col>
						);
					})}
				</Row>
				<div className="d-flex flex-row-reverse mt-55">
					<Button className="form-button-apply pr-0" type="submit" disabled={submitting}>
						APPLY
					</Button>

					<Button
						className="form-button-light pr-0 mr-2"
						onClick={() => {
							reset();
							clearAllFilter();
						}}
						disabled={submitting}>
						RESET
					</Button>
				</div>
			</form>
		</div>
	);
};

export default connect(
	null,
	null
)(
	reduxForm({
		form: "advancedFilterForm",
		enableReinitialize: true,
	})(AdvancedFilter)
);
