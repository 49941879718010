import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Heading } from "../FormElements";

const Slider = (props) => {
	const { show, handleClose, title, closeButton } = props;
	const [modalShow, setmodalShow] = useState(show);
	const [isClosing, setClosing] = useState(false);

	useEffect(() => {
		let timer;
		if (show) {
			setmodalShow(true);
		} else {
			setClosing(true);
			timer = setTimeout(() => {
				setClosing(false);
				setmodalShow(false);
			}, 500);
		}
		return () => {
			if (timer) {
				clearTimeout(timer);
			}
		};
	}, [show]);

	return (
		<>
			<Modal
				show={modalShow}
				animation={false}
				className={isClosing ? "slideOutRight" : "slideInRight"}
				dialogClassName="slider"
				onHide={handleClose}>
				<Modal.Header className="form-modal-header">
					<Modal.Title className="form-title">
						{/* <span className="left-item">{title}</span> */}
						<Heading title={title} />
						{closeButton ? (
							<div className="right-button">
								<div onClick={handleClose} className="close-btn-round close-btn-round-slider">
									<span className="icon-close"></span>
								</div>
							</div>
						) : null}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="slider-body">{props.children}</Modal.Body>
			</Modal>
		</>
	);
};
export default Slider;
