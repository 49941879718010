import React from "react";

import FormControl from "../../../SharedComponts/FormControl";
import { required, currency, validDate, max20, isValidAmount, number } from "../../../../constant/formValidators";
import { Date as DateWrap } from "../../../../utils/formatter";
import { dateFormat } from "../../../../constant/common";
import { Currency } from "../../../../utils/formatter";

export const formFields = (companyList, bankList, isLocked) => {
	return [
		{
			name: "bank",
			label: "Depository",
			component: FormControl,
			type: "select",
			className: "col-md-4 pr-4",
			options: bankList,
			validate: [required],
			required: true,
			isEdit: true,
			isEditable: !isLocked,
		},
		{
			name: "accountNumber",
			label: "Account Number",
			component: FormControl,
			type: "text",
			className: "col-md-4 pr-4",
			validate: [required, max20],
			required: true,
			isEdit: true,
			isEditable: !isLocked,
		},
		{
			name: "exchangeCompany",
			label: "Exchange Company",
			component: FormControl,
			type: "select",
			options: companyList,
			className: "col-md-4 pr-0",
			validate: [required],
			required: true,
			isEdit: true,
			isEditable: !isLocked,
		},
		{
			name: "notes",
			label: "Notes",
			component: FormControl,
			type: "textarea",
			className: "w-100 pr-0",
			wrapperClassName: "info-item col-3 pr-0",
			rows: "1",
			cols: "38",
			isEdit: true,
			isEditable: !isLocked,
		},
	];
};
export const transactionFormFields = ({
	exchangeAddressList,
	transactionFromToList,
	onPropertyChange,
	actionMode,
	incomeCategory,
	paymentStatus,
	formValues,
	change,
}) => {
	return [
		{
			name: "transactionDate",
			label: "Transaction Date",
			component: FormControl,
			type: "date",
			validate: [validDate],
			required: true,
			hideField: actionMode === 2,
			onClear: () => {
				change("transactionDate", null);
			},
		},
		{
			name: "exchangeProperty",
			label: "Property Address",
			component: FormControl,
			type: "select",
			onChange: (selected) => {
				onPropertyChange(selected);
			},
			options: exchangeAddressList,
			validate: [required],
			required: true,
		},
		{
			name: "toOrFrom",
			label: actionMode === 1 ? "To/From" : "From",
			component: FormControl,
			type: "select",
			options: transactionFromToList,
			validate: [required],
			required: true,
		},
		{
			name: "dueDate",
			label: "Due Date",
			component: FormControl,
			type: "date",
			validate: [validDate],
			required: true,
			hideField: actionMode === 1,
			onClear: () => {
				change("dueDate", null);
			},
		},
		{
			name: "incomeCategory",
			label: "Category",
			component: FormControl,
			type: "select",
			options: incomeCategory,
			validate: [required],
			required: true,
			hideField: actionMode === 1,
		},
		{
			label: "Amount",
			name: "amount",
			component: FormControl,
			type: "text",
			validate: [currency],
			hideField: actionMode === 1,
		},
		{
			name: "status",
			label: "Status",
			component: FormControl,
			type: "select",
			options: paymentStatus,
			validate: [required],
			required: true,
			hideField: actionMode === 1,
		},
		{
			name: "paidDate",
			label: "Date of Payment",
			component: FormControl,
			type: "date",
			hideField: actionMode === 1,
			onClear: () => {
				change("paidDate", null);
			},
		},
		{
			label: "Credit/DollarsIn",
			name: "dollarsIn",
			component: FormControl,
			type: "text",
			validate: [currency, isValidAmount, number],
			hideField: actionMode === 2,
			readOnly: actionMode === 1 && formValues && formValues.dollarsOut && formValues.dollarsOut !== "",
		},
		{
			label: "Debit/DollarsOut",
			name: "dollarsOut",
			component: FormControl,
			type: "text",
			validate: [currency, isValidAmount, number],
			hideField: actionMode === 2,
			readOnly: actionMode === 1 && formValues && formValues.dollarsIn && formValues.dollarsIn !== "",
		},
		{
			name: "notes",
			label: "Notes",
			component: FormControl,
			type: "textarea",
			rows: "2",
			cols: "38",
			hideField: actionMode === 2,
		},
	];
};

export const columns = ({ deleteItem, editItem, isLocked = false }) => {
	const columnsArray = [
		{
			label: "Date",
			accessor: "transactionDate",
			className: "col-transactionDate",
			headerClassName: "col-transactionDate-header",
			render: (row) => <DateWrap utcTime={row.transactionDate} format={dateFormat} noConvert={true} />,
			width: "10%",
		},
		{
			label: "Property Address",
			accessor: "propertyAddress",
			className: "col-propertyAddress",
			headerClassName: "col-propertyAddress-header",
			width: "25%",
			render: (row) => <span className={row.propertyDeleted ? "ppty-deleted" : ""}>{row.propertyAddress}</span>,
		},
		{
			label: "To/From",
			accessor: "toOrFrom",
			className: "col-toFrom",
			headerClassName: "col-toFrom-header",
			width: "15%",
		},
		{
			label: "Credit/DollarsIn",
			accessor: "dollarsIn",
			className: "col-dollarsIn",
			headerClassName: "col-dollarsIn-header",
			render: (row) => <Currency amount={row.dollarsIn} />,
			width: "10%",
		},
		{
			label: "Debit/DollarsOut",
			accessor: "dollarsOut",
			className: "col-dollarsOut",
			headerClassName: "col-dollarsOut-header",
			width: "10%",
			render: (row) => <Currency amount={row.dollarsOut} />,
		},
		{
			label: "Balance",
			accessor: "balance",
			className: "col-balance",
			headerClassName: "col-balance-header",
			width: "10%",
			render: (row) => <Currency amount={row.balance} />,
		},
		{
			label: "Notes",
			accessor: "message",
			className: "col-notes",
			headerClassName: "col-notes-header",
			width: "10%",
		},
	];
	if (!isLocked) {
		columnsArray.push({
			label: "Action",
			className: "col-actions   action-cols",
			headerClassName: "col-actions-header",
			width: "10%",
			sortable: false,
			render: (row) =>
				!isLocked ? (
					<>
						<span
							className="user-action-icon"
							onClick={() => {
								editItem(row.id, 1);
							}}>
							<span className="icon-icon-edit"></span>
						</span>
						<span
							className="user-action-icon"
							onClick={() => {
								deleteItem(row.id, 1);
							}}>
							<span className="icon-icon-delete"></span>
						</span>
					</>
				) : (
					<>---</>
				),
		});
	}
	return columnsArray;
};
export const columnsIncome = ({ deleteItem, editItem, isLocked = false }) => {
	const columnsArray = [
		{
			label: "Escrow Number",
			accessor: "escrowNumber",
			className: "col-escrowNumber",
			headerClassName: "col-escrowNumber-header",
			width: "10%",
			render: (row) => row.escrowNumber,
		},
		{
			label: "From",
			accessor: "toOrFrom",
			className: "col-toFrom",
			headerClassName: "col-toFrom-header",
			width: "15%",
		},
		{
			label: "Property Address",
			accessor: "propertyAddress",
			className: "col-propertyAddress",
			headerClassName: "col-propertyAddress-header",
			width: "15%",
			render: (row) => <span className={row.propertyDeleted ? "ppty-deleted" : ""}>{row.propertyAddress}</span>,
		},
		{
			label: "Due Date",
			accessor: "dueDate",
			className: "col-dueDate",
			headerClassName: "col-dueDate-header",
			render: (row) => <DateWrap utcTime={row.dueDate} format={dateFormat} noConvert={true} />,
			width: "10%",
		},
		{
			label: "Category",
			accessor: "category",
			className: "col-category",
			headerClassName: "col-category-header",
			width: "10%",
		},
		{
			label: "Amount",
			accessor: "amount",
			className: "col-amount",
			headerClassName: "col-amount-header",
			width: "10%",
			render: (row) => <Currency amount={row.amount} />,
		},
		{
			label: "Status",
			accessor: "status",
			className: "col-status",
			headerClassName: "col-status-header",
			width: "10%",
		},
		{
			label: "Payment Date",
			accessor: "paidDate",
			className: "col-paidDate",
			headerClassName: "col-paidDate-header",
			render: (row) => <DateWrap utcTime={row.paidDate} format={dateFormat} noConvert={true} />,
			width: "10%",
		},
	];
	if (!isLocked) {
		columnsArray.push({
			label: "Action",
			className: "col-actions  text-right action-cols",
			headerClassName: "col-actions-header",
			width: "10%",
			sortable: false,
			render: (row) =>
				!isLocked ? (
					<>
						<span
							className="user-action-icon"
							onClick={() => {
								editItem(row.id, 2);
							}}>
							<span className="icon-icon-edit"></span>
						</span>
						<span
							className="user-action-icon"
							onClick={() => {
								deleteItem(row.id, 2);
							}}>
							<span className="icon-icon-delete"></span>
						</span>
					</>
				) : (
					<>---</>
				),
		});
	}
	return columnsArray;
};
