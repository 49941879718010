import React from "react";
const renderField = ({ id, input, label, type, placeholder, meta: { touched, error, warning } }) => {
	return (
		<div className="custom-radio">
			<input className={"radio-input"} {...input} type={"radio"} />
			<label htmlFor={input.id} className="checkmark">
				<span className="radio-bullet" />
			</label>
		</div>
	);
};
export default renderField;
