import { ACCOUNTING } from "../types";
export default (
	state = {
		transactionFilters: {
			sortColumn: "id",
			sortDirection: "desc",
			pageIndex: 1,
			pageSize: 10,
			searchKey: "",
			extraParams: {},
		},
		incomeFilters: {
			sortColumn: "id",
			sortDirection: "desc",
			pageIndex: 1,
			pageSize: 10,
			searchKey: "",
			extraParams: {},
		},
		transactionList: [],
		incomeList: [],
	},
	action
) => {
	switch (action.type) {
		case ACCOUNTING.GET_ALL_TRANSACTION_LIST:
			return {
				...state,
				transactionList: action.payload && action.payload.result ? action.payload.result : [],
			};
		case ACCOUNTING.GET_ALL_INCOME_LIST:
			return {
				...state,
				incomeList: action.payload && action.payload.result ? action.payload.result : [],
			};
		case ACCOUNTING.GET_ALL_ADDRESS_LIST:
			return {
				...state,
				addressList: action.payload && action.payload.result ? action.payload.result : [],
			};
		case ACCOUNTING.SET_TRANSACTION_FILTER:
			return {
				...state,
				transactionFilters: action.payload ? action.payload : {},
			};
		case ACCOUNTING.SET_INCOME_FILTER:
			return {
				...state,
				incomeFilters: action.payload ? action.payload : {},
			};

		default:
			return state;
	}
};
