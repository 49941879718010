import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import Layout from "../SharedComponts/Layout";
import { Field, reduxForm } from "redux-form";
import FormControl from "../SharedComponts/FormControl";
import { Card, Button, ProgressBar } from "react-bootstrap";
import { Heading } from "../SharedComponts/FormElements";
import { showLoader, getDashboard, getBartChart, getLastActivities } from "../../store/actions";
import { errorMessage } from "../../utils/errorMessage";
import addPersonIcon from "../../assets/images/add-person-logo.jpg";
import transactionIcon from "../../assets/images/dollar-trasaction-logo.jpg";
import reportIcon from "../../assets/images/report-logo.jpg";
import exchangeIcon from "../../assets/images/Closed.png";
import { CurrencyKMB } from "../../utils/formatter";
import DoughnutChart from "./doughnutchart";
import BarChart from "./barchart";
import RecentlyModifiedExchanges from "./RecentlyModifiedExchanges";
import NextEvents from "./NextEvents";
import TopEscrows from "./TopEscrows";
import Activities from "./Activities";
import TopSales from "./TopSales";
import "./dashboard.scss";
import Tooltip from "../SharedComponts/ToolTip";
import noData from "../../assets/images/no-data.png";

const Dashboard = ({
	history,
	doughnutData,
	activeExchangesInIdentification,
	activeExchangePercent,
	identificationCompletedActiveExchanges,
	activeExchanges,
	exchangeBalance,
	exchangeCount,
	newExchanges,
	valuesModifiedExchanges,
	valuesNextEvents,
	valuesTopEscrows,
	valuesTopSales,
	barchart,
	lastActivities,
	doShowLoader,
	doGetDashboard,
	doGetBartChart,
	doGetLastActivities,
	barchartFilter,
	change,
}) => {
	useEffect(() => {
		doShowLoader(true);

		const activitySuccessCB = (result) => {
			doShowLoader(false);
		};

		const activityFailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		const successCB = (result) => {
			doGetLastActivities(activitySuccessCB, activityFailCB);
		};
		const failCB = (result) => {
			errorMessage(result);
			doGetLastActivities(activitySuccessCB, activityFailCB);
		};

		doGetDashboard(successCB, failCB);
	}, [doGetDashboard, doGetLastActivities, doShowLoader]);

	const [showBarchart, setShowBarchart] = useState(false);

	useEffect(() => {
		barchartFilter.length && change("revenue-month", barchartFilter[0]);
		barchartFilter.length && onMonthChange(barchartFilter[0]);
		// eslint-disable-next-line
	}, [barchartFilter]);

	const onMonthChange = (values) => {
		setShowBarchart(false);
		doShowLoader(true);
		const { value } = values;
		const successCB = (result) => {
			setShowBarchart(true);
			doShowLoader(false);
		};
		const failCB = (result) => {
			setShowBarchart(true);
			errorMessage(result);
			doShowLoader(false);
		};

		doGetBartChart(value, successCB, failCB);
	};

	const routToExchanges = (id) => {
		id ? history.push(`/exchanges/${id}`) : history.push(`/exchanges`);
	};
	const rightActionButtons = [
		{
			component: () => {
				return (
					<Button className="form-button-primary" onClick={() => history.push("/add-exchange")}>
						<span className="add-new-plus">+</span> New Exchange
					</Button>
				);
			},
		},
	];

	return (
		<Layout
			title="Dashboard"
			breadcrumbs={[{ label: "Dashboard" }]}
			rightActionButtons={rightActionButtons}
			className="dashboard-management">
			<div className="exchange-summary-wrap display-flex">
				<Card className="exchange-summary-card">
					<div className="count-wrap">
						<div className="count-exchanges flex-28">
							<div className="text-bold">Exchanges</div>
							<p>{exchangeCount}</p>
						</div>

						<div className="count-active flex-28">
							<div>Active</div>
							<p>{activeExchanges}</p>
						</div>

						<div className="count-new flex-28">
							<div>New</div>
							<p>{newExchanges}</p>
						</div>
						<div>
							<img src={exchangeIcon} alt="Exchange-icon" />
						</div>
					</div>

					<div className="active-exchanges">Active Exchanges</div>
					<ProgressBar now={activeExchangePercent} />
					<div className="exchanges-progress">
						<div className="active-percent" style={{ width: `${activeExchangePercent}%` }}>
							<Tooltip
								message={"In Identification"}
								component={<span className="breadcrumbs-link">{activeExchangesInIdentification}</span>}
							/>
						</div>
						<div className="balance-percent" style={{ width: `${100 - activeExchangePercent}%` }}>
							{!!identificationCompletedActiveExchanges && (
								<Tooltip
									message={"Identification Completed"}
									component={
										<span className="breadcrumbs-link">{identificationCompletedActiveExchanges}</span>
									}
								/>
							)}
						</div>
					</div>
				</Card>

				<Card className="exchange-summary-card  pr-2">
					<div className="text-bold">Exchange Balance</div>

					<div className="display-flex">
						<div className="exchange-balance"> {<CurrencyKMB amount={exchangeBalance} />}</div>
						<div className="funds-chart">
							<DoughnutChart data={doughnutData} />
						</div>
					</div>
					<div className="transaction">
						<span className="rout-link" onClick={() => history.push("/accounting-transactions")}>
							VIEW TRANSACTIONS
						</span>
					</div>
				</Card>

				<Card className="exchange-action-card" onClick={() => history.push("/people-exchangers")}>
					<img src={addPersonIcon} alt="" />
					<p> Add Exchanger </p>
				</Card>

				<Card className="exchange-action-card" onClick={() => history.push("/reports")}>
					<img src={reportIcon} alt="" />
					<p>Generate Reports </p>
				</Card>

				<Card className="exchange-action-card" onClick={() => history.push("/accounting-income")}>
					<img src={transactionIcon} alt="" />
					<p>View Income </p>
				</Card>
			</div>

			<div className="recent-item-wrap">
				<Card className="recent-exchange-card">
					<RecentlyModifiedExchanges exchangeData={valuesModifiedExchanges} routToExchanges={routToExchanges} />
				</Card>

				<Card className="next-event-card">
					<NextEvents eventData={valuesNextEvents} routToExchanges={routToExchanges} />
				</Card>
			</div>

			<div className="revenue-escrows-wrap">
				<Card className="revenue-card">
					<Heading title="Billed vs Paid" />

					{barchart ? (
						<>
							<div className="revenue-month">
								<Field
									name="revenue-month"
									component={FormControl}
									type="select"
									className="col-md-2 pr-4"
									onChange={(selected) => {
										onMonthChange(selected);
									}}
									options={barchartFilter}
								/>
							</div>
							<div className="revenue-chart">{barchart && showBarchart && <BarChart data={barchart} />}</div>
						</>
					) : (
						<img src={noData} className="dashboard-no-data-card" alt="noData" />
					)}
				</Card>

				<Card className="escrows-card">
					<TopEscrows topEscrowsData={valuesTopEscrows} />
				</Card>
			</div>

			<div className="activity-sales-wrap">
				<Card className="activity-card">
					<Activities lastActivitiesData={lastActivities} routToExchanges={routToExchanges} />
				</Card>

				<Card className="top-sales-card">
					<TopSales topSalesData={valuesTopSales} />
				</Card>
			</div>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	const {
		Common: { barchartFilter = [] },
		Dashboard: {
			common: {
				doughnutData: { labels = [], datasets = {}, exchangeBalance = 0 },
				valuesExchangeSummary: {
					activeExchangesInIdentification = 0,
					identificationCompletedActiveExchanges = 0,
					activeExchanges = 0,
					exchangeCount = 0,
					newExchanges = 0,
				},
				valuesNextEvents: { events = [] },
				valuesModifiedExchanges = [],
				valuesTopEscrows = [],
				valuesTopSales = [],
			},
			barchart = {},
			lastActivities = [],
		},
	} = state;
	const activeExchangePercent = activeExchanges ? (activeExchangesInIdentification / activeExchanges) * 100 : 0;
	if (barchartFilter.length > 0) {
		barchartFilter.sort((a, b) => {
			return a.value - b.value;
		});
	}
	return {
		doughnutData: { labels, datasets },
		activeExchangesInIdentification,
		activeExchangePercent,
		identificationCompletedActiveExchanges,
		activeExchanges,
		exchangeBalance,
		exchangeCount,
		newExchanges,
		valuesModifiedExchanges,
		valuesNextEvents: events,
		valuesTopEscrows,
		valuesTopSales,
		barchart,
		barchartFilter,
		lastActivities,
	};
};
const mapDispatchToProps = {
	doGetDashboard: getDashboard,
	doGetBartChart: getBartChart,
	doGetLastActivities: getLastActivities,
	doShowLoader: showLoader,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "DashboardForm",
		enableReinitialize: true,
	})(Dashboard)
);
