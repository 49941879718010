import { normalizeNumber, required, percent } from "../../../constant/formValidators";
import FormControl from "../../SharedComponts/FormControl";
import moment from "moment";

export const basicInfoFormFields = ({ isEdit, loadEntities, exchangeStatus, exchangeType, change, exchangerType }) => {
	return [
		{
			name: "exchangeNumber",
			label: "Exchange Number",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			isEdit,
			accessor: "exchangeNumber",
		},
		{
			name: "exchangeType",
			label: "Exchange Type",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			options: exchangeType,
			validate: [required],
			isEdit,
			isEditable: true,
			accessor: "exchangeType",
		},
		{
			name: "exchangerType",
			label: "Exchanger Type",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			accessor: "exchangerType",
			onChange: (selected) => {
				change("entityName", null);
			},
			validate: [required],
			options: exchangerType,
			isEdit,
			isEditable: true,
		},
		{
			name: "entityName",
			label: "Entity Name",
			component: FormControl,
			validate: [required],
			type: "asyncSelect",
			loadOptions: loadEntities,
			className: "form-control form-field",
			isEdit,
			isEditable: true,
			accessor: "entityName",
		},

		{
			name: "exchangeStatus",
			label: "Status",
			component: FormControl,
			className: "form-control form-field",
			type: "select",
			options: exchangeStatus,
			isEdit,
			accessor: "exchangeStatus",
			isEditable: true,
		},
		{
			name: "rateOrShareQuoted",
			label: "Rate/Share Quoted (%)",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			isEdit,
			isEditable: true,
			normalize: normalizeNumber,
			accessor: "rateOrShareQuoted",
			validate: [percent],
		},
		{
			name: "accountBalance",
			label: "Account Balance",
			component: FormControl,
			className: "form-control form-field",
			accessor: "accountBalance",
			isCurrency: true,
			isEdit,
		},
	];
};
export const dateFormFields = ({ isEdit, change, formValues }) => {
	return [
		{
			name: "openDate",
			label: "Open date",
			accessor: "openDate",
			component: FormControl,
			type: "date",
			maxDate: new Date(),
			className: "form-control form-field",
			isEdit,
			isEditable: true,
		},
		{
			name: "rel1CloseDate",
			accessor: "rel1CloseDate",
			label: "Rel1 Close Date",
			component: FormControl,
			disabled: !(formValues && formValues.openDate),
			minDate: formValues && formValues.openDate,
			type: "date",
			onChange: (selected) => {
				change("day45", moment(moment(selected).add(45, "days")).toDate());
				change("day180", moment(moment(selected).add(180, "days")).toDate());
			},
			className: "form-control form-field",
			isEdit,
			isEditable: true,
			onClear: () => {
				change("rel1CloseDate", null);
			},
		},
		{
			name: "day45",
			accessor: "day45",
			label: "45th Day",
			disabled: !(formValues && formValues.rel1CloseDate),
			component: FormControl,
			onChange: (selected) => {
				change("actualDDate", null);
				change("finalCloseDate", null);
			},
			type: "date",
			className: "form-control form-field",
			isEdit,
			isEditable: true,
			onClear: () => {
				change("day45", null);
			},
		},
		{
			name: "actualDDate",
			accessor: "actualDDate",
			label: "Actual ID date",
			component: FormControl,
			disabled: !(formValues && formValues.rel1CloseDate),
			maxDate: formValues && formValues.day45,
			minDate: formValues && formValues.rel1CloseDate,
			className: "form-control form-field",
			type: "date",
			isEdit,
			isEditable: true,
			onClear: () => {
				change("actualDDate", null);
			},
		},
		{
			name: "day180",
			accessor: "day180",
			label: "180th Day",
			component: FormControl,
			type: "date",
			onChange: (selected) => {
				change("finalCloseDate", null);
			},
			className: "form-control form-field",
			isEdit,
			isEditable: true,
			onClear: () => {
				change("day180", null);
			},
		},
		{
			name: "finalCloseDate",
			accessor: "finalCloseDate",
			label: "Final Close Date",
			component: FormControl,
			maxDate: formValues && formValues.day180,
			minDate: formValues && formValues.rel1CloseDate,
			className: "form-control form-field",
			type: "date",
			isEdit,
			isEditable: true,
			onClear: () => {
				change("finalCloseDate", null);
			},
		},
	];
};
