import React from "react";

import { MoreOptions } from "../../SharedComponts/FormElements";

import FormControl from "../../SharedComponts/FormControl";
import { required, email, USPhoneNumber, normalizePhone } from "../../../constant/formValidators";

export const columns = ({ onEditUser = null, onDeleteUser = null }) => [
	{
		name: "Name",
		accessor: "displayName",
		className: "col-user-name",
		headerClassName: "col-user-name-header",
		render: (row) => row.displayName,
	},
	{
		name: "Contact Type",
		accessor: "roleName",
		className: "col-roleName",
		headerClassName: "col-roleName-header",
	},
	{
		name: "Exchanger",
		accessor: "contactExchangerName",
		className: "col-contactExchangerName",
		headerClassName: "col-contactExchangerName-header",
	},
	{
		name: "Phone",
		accessor: "phoneNumber",
		className: "col-phone",
		headerClassName: "col-phone-header",
	},
	{
		name: "Email",
		accessor: "emailAddress",
		className: "col-email",
		headerClassName: "col-email-header",
	},
	{
		name: "Actions",
		className: "col-actions text-right",
		headerClassName: "col-actions-header",
		sortable: false,
		render: (row) => {
			return (
				<MoreOptions
					options={[
						{ label: "Edit", onClick: () => onEditUser(row) },
						{ label: "Delete", onClick: () => onDeleteUser(row) },
					]}
				/>
			);
		},
	},
];

export const formFields = (exchangerList, contactRoles) => {
	return [
		{
			name: "name",
			label: "Name",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			validate: [required],
			required: true,
		},
		{
			name: "emailAddress",
			label: "Email Address",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			validate: [email],
		},
		{
			name: "phoneNumber",
			label: "Phone",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			validate: [USPhoneNumber],
			normalize: normalizePhone,
		},
		{
			name: "contactType",
			label: "Contact Type",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			options: contactRoles,
			validate: [required],
			required: true,
		},
		{
			name: "exchanger",
			label: "Exchanger",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			options: exchangerList,
		},
	];
};
