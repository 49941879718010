import React from "react";
import { Currency } from "../../../utils/formatter";

const exchangerColRender = (row) => {
	return (
		<>
			{row.exchanger ? row.exchanger : "-"}
			{row.exchangerProperty ? <div className="exchanger-property">{row.exchangerProperty}</div> : null}
		</>
	);
};

export const columnsModifiedExchanges = (routToExchanges) => [
	{
		label: "EXCHANGE NUMBER",
		className: "col-exchange-number",
		width: "15%",
		rowWidth: "15%",
		render: (row) =>
			row.exchangeNo ? (
				<span className="rout-link" onClick={() => routToExchanges && routToExchanges(row.guid)}>
					{row.exchangeNo}
				</span>
			) : (
				"-"
			),
	},
	{
		label: "EXCHANGER",
		className: "col-exchanger",
		width: "21%",
		rowWidth: "21%",
		render: (row) => exchangerColRender(row),
	},
	{
		label: "EXCHANGE TYPE",
		className: "col-exchange-type",
		accessor: "exchangeType",
		width: "12%",
		rowWidth: "12%",
	},
	{
		label: "DAY IN EXCHANGE",
		className: "col-day-in-exchange",
		accessor: "dayInExchange",
		width: "12%",
		rowWidth: "12%",
		render: (row) => <span>{row.dayInExchange ? row.dayInExchange : 0}</span>,
	},
	{
		label: "LAST MILESTONE",
		className: "col-last-milestone",
		accessor: "lastMileStone",
		width: "17%",
		rowWidth: "17%",
	},
	{
		label: "FEES DUE",
		className: "col-fee-due",
		accessor: "feeDue",
		width: "10%",
		rowWidth: "10%",
		render: (row) => (
			<span>
				<Currency amount={row.feeDue} />
			</span>
		),
	},

	{
		label: "NO.OF PENDING TASKS",
		className: "col-pending-tasks",
		accessor: "pendingTasks",
		width: "13%",
		rowWidth: "11",
	},
];
