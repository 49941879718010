import React from "react";

import FormControl from "../../../SharedComponts/FormControl";
import Tooltip from "../../../SharedComponts/ToolTip";
import { Date as DateWrap } from "../../../../utils/formatter";
import { dateFormat } from "../../../../constant/common";
import { required, validDate } from "../../../../constant/formValidators";

export const columns = ({
	setEditMode,
	setEditId,
	saveTask,
	editMode,
	editId,
	adminUsers,
	taskStatusList,
	deleteItem,
	isLocked = false,
	formValues,
}) => {
	const columnArray = [
		{
			label: "Task",
			name: "taskName",
			accessor: "taskName",
			className: "col-taskName",
			headerClassName: "col-taskName-header",
			width: "30%",
			render: (row) => (
				<>
					{row.description ? (
						<Tooltip
							message={`${row.description}`}
							component={<span className="cursor-pointer">{row.taskName}</span>}
						/>
					) : (
						<span>{row.taskName}</span>
					)}
				</>
			),
		},
		{
			label: "Responsibility",
			accessor: "responsibleUser",
			name: "responsibleUser",
			className: "col-responsibleUser",
			headerClassName: "col-responsibleUser-header",
			width: "15%",
			render: (row) => row.responsibleUser.label,
			formField: true,
			component: FormControl,
			type: "select",
			options: adminUsers,
		},
		{
			label: "Status",
			accessor: "taskStatus",
			name: "taskStatus",
			className: "col-taskStatus",
			headerClassName: "col-taskStatus-header",
			render: (row) => <span className={`status-card status-${row.taskStatus.label}`}>{row.taskStatus.label}</span>,
			width: "15%",
			formField: true,
			component: FormControl,
			type: "select",
			options: taskStatusList,
		},
		{
			label: "Completed Date",
			accessor: "completedDate",
			name: "completedDate",
			className: "col-completedDate",
			headerClassName: "col-completedDate-header",
			render: (row) => <DateWrap utcTime={row.completedDate} format={dateFormat} noConvert={true} />,
			width: "15%",
			formField: true,
			component: FormControl,
			type: "date",
			maxDate: new Date(),
		},
		{
			label: "Completed By",
			accessor: "completedBy",
			name: "completedBy",
			className: "col-completedBy",
			headerClassName: "col-completedBy-header",
			width: "15%",
			render: (row) => row.completedBy.label,
			formField: true,
			component: FormControl,
			type: "select",
			options: adminUsers,
		},
	];
	if (!isLocked) {
		columnArray.push({
			label: "Action",
			className: "col-actions text-right action-cols",
			headerClassName: "col-actions-header",
			width: "10%",
			sortable: false,
			render: (row) => {
				return !isLocked ? (
					editMode && row.id === editId ? (
						<>
							<span
								className="user-action-icon"
								onClick={() => {
									saveTask(row);
								}}>
								<span className="icon-tick"></span>
							</span>

							<span
								className="user-action-icon"
								onClick={() => {
									setEditMode(false);
									setEditId(0);
								}}>
								<span className="icon-close"></span>
							</span>
						</>
					) : (
						<>
							<span
								className="user-action-icon"
								onClick={() => {
									setEditMode(true);
									setEditId(0);
									setTimeout(() => {
										setEditId(row.id);
									}, 1000);
								}}>
								<span className="icon-icon-edit"></span>
							</span>
							<span
								className="user-action-icon"
								onClick={() => {
									deleteItem(row.id);
								}}>
								<span className="icon-icon-delete"></span>
							</span>
						</>
					)
				) : (
					<>---</>
				);
			},
		});
	}
	return columnArray;
};

export const formFields = ({ adminUsers, taskStatusList, formValues, change }) => [
	{
		label: "Task",
		name: "taskName",
		className: "col-taskName",
		headerClassName: "col-taskName-header",
		component: FormControl,
		type: "text",
		validate: [required],
		required: true,
	},
	{
		label: "Responsibility",
		name: "responsibleUser",
		className: "col-responsibleUser",
		headerClassName: "col-responsibleUser-header",
		component: FormControl,
		type: "select",
		options: adminUsers,
	},
	{
		label: "Description",
		name: "description",
		className: "col-responsibleUser",
		headerClassName: "col-responsibleUser-header",
		component: FormControl,
		type: "textarea",
	},
	{
		label: "Status",
		accessor: "taskStatus",
		name: "taskStatus",
		className: "col-taskStatus",
		component: FormControl,
		type: "select",
		options: taskStatusList,
	},
	{
		label: "Completed Date",
		name: "completedDate",
		className: "col-completedDate",
		component: FormControl,
		type: "date",
		maxDate: new Date(),
		validate: formValues && formValues.taskStatus && formValues.taskStatus.value === 502 ? [validDate] : [],
		onClear: () => {
			change("completedDate", null);
		},
		required: formValues && formValues.taskStatus && formValues.taskStatus.value === 502,
	},
	{
		label: "Completed By",
		name: "completedBy",
		className: "col-completedBy",
		component: FormControl,
		type: "select",
		options: adminUsers,
		validate: formValues && formValues.taskStatus && formValues.taskStatus.value === 502 ? [required] : [],
		required: formValues && formValues.taskStatus && formValues.taskStatus.value === 502,
	},
];
