import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { formFields } from "./helper";
import { Button } from "react-bootstrap";
import { errorMessage } from "../../../../utils/errorMessage";
import { successMessage } from "../../../../utils/successMessage";
import { InfoItem } from "../../../SharedComponts/FormElements";

import { showLoader, getEntityByType, createBankBasicInfo, updateBankBasicInfo } from "../../../../store/actions";

const BasicInfo = (props) => {
	const {
		handleSubmit,
		doShowLoader,
		doGetEntityByType,
		submitting,
		doCreateBankBasicInfo,
		doUpdateBankBasicInfo,
		exchangeDetail,
		initialValues,
		isLocked,
		bankList,
		change,
	} = props;

	const [companyList, setCompanyList] = useState([]);

	useEffect(() => {
		if (exchangeDetail && exchangeDetail.id) {
			const successCB = (response) => {
				setCompanyList(response.result);
				const defaultCompany = response.result.find((item) => item.isDefault);
				if (defaultCompany) {
					change("exchangeCompany", { ...defaultCompany });
				}
			};
			const failCB = (result) => {
				errorMessage(result);
			};

			doGetEntityByType({ typeId: 229, isInactiveReq: "false" }, successCB, failCB);
		}
	}, [doGetEntityByType, exchangeDetail, change]);

	const handleSubmitBasic = (values) => {
		doShowLoader(true);
		const successCB = ({ result }) => {
			doShowLoader(false);
			successMessage(404);
		};
		const FailCB = (result) => {
			doShowLoader(false);
			errorMessage(result);
		};
		if (initialValues && initialValues.id) {
			doUpdateBankBasicInfo({ ...values, exchangeId: exchangeDetail.id, id: initialValues.id }, successCB, FailCB);
		} else {
			doCreateBankBasicInfo({ ...values, exchangeId: exchangeDetail.id }, successCB, FailCB);
		}
	};

	return (
		<form className="w-100" onSubmit={handleSubmit(handleSubmitBasic)}>
			<div className="row mr-0 ml-0 w-100">
				{formFields(companyList, bankList, isLocked).map((item, index) => {
					return <InfoItem key={index} {...item} />;
				})}
			</div>
			{!isLocked && (
				<div className="d-flex justify-content-end mb-4">
					<Button type="submit" disabled={submitting} className="form-button-primary">
						SAVE
					</Button>
				</div>
			)}
		</form>
	);
};

const mapDispatchToProps = {
	doGetEntityByType: getEntityByType,
	doCreateBankBasicInfo: createBankBasicInfo,
	doUpdateBankBasicInfo: updateBankBasicInfo,
	doShowLoader: showLoader,
};
const mapStateToProps = (state) => {
	const {
		Exchange: { exchangeDetail = {}, isLocked, bankList = [] },
	} = state;
	return {
		exchangeDetail,
		isLocked,
		bankList,
	};
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "TransactionBasicInfoForm",
		enableReinitialize: true,
	})(BasicInfo)
);
