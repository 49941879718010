import React from "react";
import { Row } from "react-bootstrap";
import { dateFormat } from "../../../constant/common";
const moment = require("moment");

const AppliedFilter = (props) => {
	const { handleClose, values, advancedFilters } = props;
	const getDateFilterText = () => {
		if (values["fromDate"] && values["toDate"]) {
			return `${values["filterBy"].label} - ${moment(values.fromDate).format(dateFormat).toLocaleString()} To  ${moment(
				values.toDate
			)
				.format(dateFormat)
				.toLocaleString()}`;
		} else if (values["fromDate"]) {
			return `${values["filterBy"].label} - From ${moment(values.fromDate).format(dateFormat).toLocaleString()}`;
		} else if (values["toDate"]) {
			return `${values["filterBy"].label} - To ${moment(values.toDate).format(dateFormat).toLocaleString()}`;
		}
		return false;
	};
	const getText = (item) => {
		const key = item.name;
		if (values && values[key] && (values[key] !== "" || values[key] !== null) && key !== "fromDate" && key !== "toDate") {
			if (
				values &&
				values[key] &&
				key === "filterBy" &&
				(values["fromDate"] || values["toDate"]) &&
				(values[key] !== "" || values[key] !== null)
			) {
				return getDateFilterText();
			} else {
				return `${item.label} - ${values[key].label}`;
			}
		} else {
			return false;
		}
	};

	return (
		<Row className="applied-filter-container advanced-filter-container align-items-start">
			{advancedFilters.map((item, index) => {
				return getText(item) ? (
					<div key={index} className="filter-tag">
						{getText(item)}
						<span
							onClick={() => {
								handleClose(item.name);
							}}
							className="close-icon">
							X
						</span>
					</div>
				) : null;
			})}
		</Row>
	);
};

export default AppliedFilter;
