import { ENTITY } from "../types";

export default (
	state = {
		filters: {
			sortColumn: "creationTime",
			sortDirection: "desc",
			pageIndex: 1,
			pageSize: 10,
			searchKey: "",
		},
	},
	action
) => {
	switch (action.type) {
		case ENTITY.SET_FILTER:
			return {
				...state,
				filters: action.payload ? action.payload : {},
			};
		case ENTITY.GET_ENTITY_LIST:
			return {
				...state,
				entities: action.payload ? action.payload.result : [],
			};
		case ENTITY.GET_ENTITY_DETAILS:
			return {
				...state,
				entityDetails: action.payload ? action.payload.result : {},
			};

		default:
			return state;
	}
};
