import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";

import Layout from "../../SharedComponts/Layout";
import { Table, Pagination } from "../../SharedComponts/FormElements/table";
import { columns as columnsFn } from "./helper";
import {
	setTaskFilter,
	getTaskList,
	updateTask,
	createTask,
	showLoader,
	deleteTask,
	getExchangeTypes,
} from "../../../store/actions";

import SearchField from "../../SharedComponts/FormElements/searchTable";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";

import AddEdit from "./AddEdit";
import "./tasks.scss";
import Confirm from "../../SharedComponts/FormElements/confirm";

const TaskList = ({
	doSetFilter,
	doGetTaskList,
	filters,
	history,
	recordsTotal,
	taskList,
	doShowLoader,
	doGetExchangeTypes,
	exchangeTypes,
	doCreateTask,
	doUpdateTask,
	doDeleteTask,
}) => {
	const [showPopup, setshowPopup] = useState(false);
	const [isEdit, setIsEdit] = useState(true);
	const [currentRecord, setCurrentRecord] = useState({});
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [listReload, setListReload] = useState(false);
	const [currentDeleteRecord, setCurrentDeleteRecord] = useState(null);

	const handleClose = () => setshowPopup(false);
	const handleShow = () => setshowPopup(true);
	const handleSubmit = async (value) => {
		doShowLoader(true);
		const successCB = (result) => {
			doShowLoader(false);
			successMessage(!isEdit ? 209 : 210);
			doSetFilter({
				...filters,
				pageIndex: 1,
			});
			handleClose();
		};
		const FailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		const postData = {
			...value,
		};

		currentRecord.id && isEdit && doUpdateTask({ id: currentRecord.id, ...postData }, successCB, FailCB);
		(!currentRecord.id || !isEdit) && doCreateTask({ ...postData }, successCB, FailCB);
	};

	useEffect(() => {
		doShowLoader(true);
		const backendFilter = { ...filters };
		const successCB = (result) => {
			doShowLoader(false);
		};
		const FailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		doGetTaskList(backendFilter, successCB, FailCB);
	}, [filters, listReload, doGetTaskList, doShowLoader]);

	useEffect(() => {
		doGetExchangeTypes();
	}, [doGetExchangeTypes]);

	const onEditTask = (row) => {
		const { exchangeTypeId, exchangeType, ...currentRecord } = row;
		currentRecord.exchangeTypeData = {
			label: exchangeType,
			value: exchangeTypeId,
		};
		setCurrentRecord({ ...currentRecord });
		setIsEdit(true);
		handleShow();
	};

	const addTask = () => {
		setIsEdit(false);
		setCurrentRecord({});
		handleShow();
	};
	const onDeleteTask = (row) => {
		setCurrentDeleteRecord(row.id);
		setConfirmDelete(true);
	};
	const confirmDeleteAction = () => {
		doShowLoader(true);
		const failCB = (result) => {
			doShowLoader(false);
			setCurrentDeleteRecord(null);
			setConfirmDelete(false);
			errorMessage(result);
		};
		const successCB = (result) => {
			doShowLoader(false);
			setCurrentDeleteRecord(null);
			setConfirmDelete(false);
			successMessage(211);
			setListReload(!listReload);
		};
		doDeleteTask(currentDeleteRecord, successCB, failCB);
	};

	const rightActionButtons = [
		{
			component: () => {
				return (
					<Button className="form-button-primary" onClick={addTask}>
						<span className="add-new-plus">+</span> New Task
					</Button>
				);
			},
		},
	];
	const columns = columnsFn({
		onEditTask,
		onDeleteTask,
	});
	return (
		<>
			<Layout
				title="Tasks"
				breadcrumbs={[
					{
						label: "Configurations",
						onClick: () => {
							const path = `/settings-master`;
							history.push(path);
						},
					},
					{ label: "Tasks" },
				]}
				rightActionButtons={rightActionButtons}
				className="task-page">
				<>
					<SearchField
						initialValue={filters.searchKey}
						onSearch={(searchKey) =>
							doSetFilter({
								...filters,
								searchKey,
								pageIndex: 1,
							})
						}
						className={`flex-fill mb-0`}
						placeholder="Enter your query"
					/>
					<Table
						columns={columns}
						data={taskList}
						onSort={(e) =>
							e.by !== filters.sortColumn || e.order !== filters.sortDirection
								? doSetFilter({
										...filters,
										sortColumn: e.by,
										sortDirection: e.order,
										pageIndex: 1,
								  })
								: null
						}
						sortBy={filters.sortColumn}
						sortDir={filters.sortDirection}
					/>
					<Pagination
						total={recordsTotal}
						count={filters.pageSize}
						page={filters.pageIndex}
						onChange={(e) => {
							doSetFilter({ ...filters, pageIndex: e });
						}}
						onPageCountChange={(e) => {
							doSetFilter({ ...filters, pageSize: e, pageIndex: 1 });
						}}
					/>
				</>
				<Confirm
					onYes={confirmDeleteAction}
					onNo={(e) => {
						setConfirmDelete(false);
					}}
					show={confirmDelete ? true : false}
				/>
			</Layout>

			{showPopup ? (
				<AddEdit
					show={showPopup}
					initialValues={currentRecord}
					isEdit={isEdit}
					handleClose={handleClose}
					onSubmit={handleSubmit}
					exchangeTypes={exchangeTypes}
				/>
			) : null}
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		Task: { taskList: { data: taskList = [], recordsTotal = 0 } = { data: [] }, filters = {} },
		Settings: { exchangeTypes = [] },
	} = state;
	return {
		taskList,
		filters,
		recordsTotal,
		exchangeTypes,
	};
};
const mapDispatchToProps = {
	doGetTaskList: getTaskList,
	doCreateTask: createTask,
	doUpdateTask: updateTask,
	doSetFilter: setTaskFilter,
	doShowLoader: showLoader,
	doGetExchangeTypes: getExchangeTypes,
	doDeleteTask: deleteTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskList);
