import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Card, Tabs, Tab } from "react-bootstrap";
import { Heading } from "../../../SharedComponts/FormElements";
import { errorMessage } from "../../../../utils/errorMessage";
import { Button } from "react-bootstrap";
import {
	showLoader,
	getBankBasicInfo,
	getExchangeTransactionList,
	getExchangePropertyAddressList,
	createExchangeTransaction,
	getExchangeTransaction,
	updateExchangeTransaction,
	deleteExchangeTransaction,
	getExchangeIncomeList,
	createExchangeIncome,
	getExchangeIncome,
	updateExchangeIncome,
	deleteExchangeIncome,
	getExchangeBankList,
} from "../../../../store/actions";
import BasicInfo from "./basicInfoForm";
import { columns as columnsFn, columnsIncome as columnsFnIncome } from "./helper";

import AddTransaction from "./AddTransaction";
import Confirm from "../../../SharedComponts/FormElements/confirm";
import { successMessage } from "../../../../utils/successMessage";
import "./transactions.scss";
import { formatDateToISOString, getDateFromDatePicker, getDatePickerDateFromtDate } from "../../../../utils/helper";

const TransactionAndIncome = (props) => {
	const {
		doShowLoader,
		doGetBankBasicInfo,
		exchangeDetail,
		bankBasicInfo,
		doGetExchangeTransactionList,
		doGetExchangeIncomeList,
		exchangeTransactionList,
		exchangeIncomeList,
		doGetExchangePropertyAddressList,
		doCreateExchangeTransaction,
		doGetExchangeTransaction,
		doUpdateExchangeTransaction,
		doDeleteExchangeTransaction,
		doCreateExchangeIncome,
		doGetExchangeIncome,
		doUpdateExchangeIncome,
		doDeleteExchangeIncome,
		doGetExchangeBankList,
		isLocked,
		bankList,
	} = props;

	const [showSlider, setShowSlider] = useState(false);
	const [transactionDetails, setShowTransactionDetails] = useState({});
	const [reload, setReload] = useState(false);
	const [editId, setEditId] = useState(0);
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [actionMode, setActionMode] = useState(1); //1 - Transaction 2 - Income
	useEffect(() => {
		if (exchangeDetail && exchangeDetail.id) {
			doShowLoader(true);
			const successCB = (response) => {
				doShowLoader(false);
			};
			const failCB = (result) => {
				errorMessage(result);
				doShowLoader(false);
			};
			doGetExchangeBankList({ searchKey: "" });
			doGetBankBasicInfo(exchangeDetail.id, successCB, failCB);
		}
	}, [doGetBankBasicInfo, doGetExchangeBankList, doShowLoader, exchangeDetail]);

	useEffect(() => {
		if (exchangeDetail && exchangeDetail.id) {
			doShowLoader(true);
			const successCB = (response) => {
				doGetExchangeIncomeList(
					exchangeDetail.guid,
					(result) => {
						doShowLoader(false);
					},
					(result) => {
						doShowLoader(false);
						errorMessage(result);
					}
				);
			};
			const failCB = (result) => {
				errorMessage(result);
				doShowLoader(false);
			};

			doGetExchangeTransactionList(exchangeDetail.guid, successCB, failCB);
		}
	}, [doGetExchangeTransactionList, doGetExchangeIncomeList, doShowLoader, exchangeDetail, reload]);

	const deleteItem = (id, mode = 1) => {
		setActionMode(mode);
		setEditId(id);
		setConfirmDelete(true);
		setShowSlider(false);
	};

	const confirmDeleteAction = () => {
		doShowLoader(true);
		const successCB = (result) => {
			setConfirmDelete(false);
			doShowLoader(false);
			successMessage(actionMode === 1 ? 501 : 502);
			setReload(!reload);
			setEditId(0);
		};
		const FailCB = (result) => {
			setConfirmDelete(false);
			errorMessage(result);
			doShowLoader(false);
			setEditId(0);
		};
		actionMode === 1
			? doDeleteExchangeTransaction(editId, successCB, FailCB)
			: doDeleteExchangeIncome(editId, successCB, FailCB);
	};

	const editItem = (id, mode = 1) => {
		setActionMode(mode);
		doShowLoader(true);
		setEditId(id);
		const successCB = (response) => {
			doShowLoader(false);
			loadLookUp();
			if (mode === 1) {
				const getData = {
					...response.result,
					transactionDate: getDatePickerDateFromtDate(response.result.transactionDate),
				};
				setShowTransactionDetails(getData);
			} else {
				const getData = {
					...response.result,
					dueDate: getDatePickerDateFromtDate(response.result.dueDate),
					paidDate: getDatePickerDateFromtDate(response.result.paidDate),
				};
				setShowTransactionDetails(getData);
			}

			setShowSlider(true);
		};
		const failCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};
		mode === 1 ? doGetExchangeTransaction(id, successCB, failCB) : doGetExchangeIncome(id, successCB, failCB);
	};
	const columns = columnsFn({ deleteItem, editItem, isLocked });
	const columnsIncome = columnsFnIncome({ deleteItem, editItem, isLocked });

	const renderRow = (row, tab = "transaction") => {
		const columnsSet = tab === "transaction" ? columns : columnsIncome;
		return columnsSet.map(({ accessor, width, render, className }, index) => {
			if (render) {
				return (
					<div key={index} className={`table-col ${className ? className : ""}`} style={width ? { width } : {}}>
						{render(row)}
					</div>
				);
			}
			return (
				<div key={index} className={`table-col ${className ? className : ""}`} style={width ? { width } : {}}>
					{row[accessor] ? row[accessor] : "-"}
				</div>
			);
		});
	};

	const handleSubmitTransaction = (values) => {
		doShowLoader(true);
		const successCB = (response) => {
			doShowLoader(false);
			setShowSlider(false);
			successMessage(editId && editId > 0 ? (actionMode === 1 ? 505 : 505) : actionMode === 1 ? 503 : 504);
			setReload(!reload);
			setEditId(0);
		};
		const failCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
			//setEditId(0);
		};

		if (actionMode === 1) {
			const postData = {
				...values,
				exchangeId: exchangeDetail.id,
				transactionDate: formatDateToISOString(values.transactionDate),
			};
			editId && editId > 0
				? doUpdateExchangeTransaction({ ...postData, id: editId }, successCB, failCB)
				: doCreateExchangeTransaction(postData, successCB, failCB);
		} else {
			const postData = {
				...values,
				exchangeId: exchangeDetail.id,
				dueDate: formatDateToISOString(values.dueDate),
				paidDate: formatDateToISOString(values.paidDate),
			};
			editId && editId > 0
				? doUpdateExchangeIncome({ ...postData, id: editId }, successCB, failCB)
				: doCreateExchangeIncome(postData, successCB, failCB);
		}
	};

	const handleClose = () => {
		setShowSlider(false);
		setEditId(0);
	};
	const loadLookUp = () => {
		if (exchangeDetail && exchangeDetail.id) {
			doShowLoader(true);
			const successCB = (response) => {
				doShowLoader(false);
			};
			const failCB = (result) => {
				errorMessage(result);
				doShowLoader(false);
			};

			doGetExchangePropertyAddressList(exchangeDetail.id, successCB, failCB);
		}
	};

	return (
		<>
			<Card.Title className="info-card-title">
				<Heading title="Basic Information" />
			</Card.Title>

			<Card.Body className="info-card-body d-flex flex-row">
				<BasicInfo
					initialValues={
						bankBasicInfo && bankBasicInfo.id
							? { ...bankBasicInfo }
							: {
									bank: bankList.filter((item) => {
										return item.isDefault;
									})[0],
							  }
					}
				/>
			</Card.Body>

			<Card.Title className="info-card-title">
				<Heading title="Bank Transactions and Charges" />
			</Card.Title>

			<Card.Body className="info-card-body d-flex flex-row bank-tab">
				<Tabs
					unmountOnExit={true}
					className="table-header"
					defaultActiveKey="bankTransactions"
					id="uncontrolled-tab-example1">
					<Tab eventKey="bankTransactions" title="Bank Transactions">
						{!isLocked && (
							<div className="add-button">
								<Button
									className="form-button-secondary small btn btn-primary"
									onClick={() => {
										setShowTransactionDetails({});
										setActionMode(1);
										setShowSlider(true);
										loadLookUp();
									}}>
									+ Add Bank Transaction
								</Button>
							</div>
						)}

						{exchangeTransactionList && exchangeTransactionList.length ? (
							<div className="task-table">
								<div className="table-head">
									{columns.length
										? columns.map(({ label, className, width }, index) => (
												<div
													key={index}
													className={`head-item ${className ? className : ""}`}
													style={width ? { width } : {}}>
													{label}
												</div>
										  ))
										: null}
								</div>
								{exchangeTransactionList.map((row, index) => {
									return (
										<div
											key={index}
											className={`table-row w-100 ${index % 2 === 0 ? "row-even" : "row-odd"}`}>
											{renderRow(row)}
										</div>
									);
								})}
							</div>
						) : (
							<div className="no-data-table no-data-table-task">No items found</div>
						)}
					</Tab>
					<Tab eventKey="income" title="Income">
						{!isLocked && (
							<div className="add-button">
								<Button
									className="form-button-secondary small btn btn-primary"
									onClick={() => {
										setShowTransactionDetails({});
										setActionMode(2);
										setShowSlider(true);
										loadLookUp();
									}}>
									+ Add Income
								</Button>
							</div>
						)}

						{exchangeIncomeList && exchangeIncomeList.length ? (
							<div className="task-table">
								<div className="table-head">
									{columnsIncome.length
										? columnsIncome.map(({ label, className, width }, index) => (
												<div
													key={index}
													className={`head-item ${className ? className : ""}`}
													style={width ? { width } : {}}>
													{label}
												</div>
										  ))
										: null}
								</div>
								{exchangeIncomeList.map((row, index) => {
									return (
										<div
											key={index}
											className={`table-row w-100 ${index % 2 === 0 ? "row-even" : "row-odd"}`}>
											{renderRow(row, "income")}
										</div>
									);
								})}
							</div>
						) : (
							<div className="no-data-table no-data-table-task">No items found</div>
						)}
					</Tab>
				</Tabs>
			</Card.Body>
			{showSlider ? (
				<AddTransaction
					actionMode={actionMode}
					initialValues={transactionDetails}
					show={showSlider}
					handleClose={handleClose}
					onSubmit={handleSubmitTransaction}
				/>
			) : null}
			<Confirm
				onYes={confirmDeleteAction}
				onNo={(e) => {
					setConfirmDelete(false);
				}}
				show={confirmDelete ? true : false}
			/>
		</>
	);
};
const mapStateToProps = (state) => {
	const {
		Exchange: {
			exchangeDetail = {},
			bankBasicInfo,
			exchangeTransactionList = [],
			exchangeIncomeList = [],
			isLocked,
			bankList = [],
		},
	} = state;
	return {
		exchangeDetail,
		bankBasicInfo,
		exchangeTransactionList,
		exchangeIncomeList,
		isLocked,
		bankList,
	};
};

const mapDispatchToProps = {
	doShowLoader: showLoader,
	doGetBankBasicInfo: getBankBasicInfo,
	doGetExchangeTransactionList: getExchangeTransactionList,
	doGetExchangePropertyAddressList: getExchangePropertyAddressList,
	doCreateExchangeTransaction: createExchangeTransaction,
	doGetExchangeTransaction: getExchangeTransaction,
	doUpdateExchangeTransaction: updateExchangeTransaction,
	doDeleteExchangeTransaction: deleteExchangeTransaction,
	doGetExchangeIncomeList: getExchangeIncomeList,
	doCreateExchangeIncome: createExchangeIncome,
	doGetExchangeIncome: getExchangeIncome,
	doUpdateExchangeIncome: updateExchangeIncome,
	doDeleteExchangeIncome: deleteExchangeIncome,
	doGetExchangeBankList: getExchangeBankList,
};
export default connect(mapStateToProps, mapDispatchToProps)(TransactionAndIncome);
