import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";

import { sendForgotPasswordCredentials, showLoader } from "../../store/actions";

import FormControl from "../SharedComponts/FormControl";
import { errorMessage } from "../../utils/errorMessage";
import { successMessage } from "../../utils/successMessage";
import { required, email } from "../../constant/formValidators";

import "./session.scss";
const ForgotPassword = ({ doSendForgotPasswordCredentials, handleSubmit, history, doShowLoader }) => {
	const submitForgotAction = (values) => {
		doShowLoader(true);
		const successHandler = () => {
			doShowLoader(false);
			//history.push("/signin");
			successMessage(521);
		};
		const errorHandler = (event) => {
			errorMessage(event);
			doShowLoader(false);
		};
		doSendForgotPasswordCredentials({ ...values }, successHandler, errorHandler);
	};

	return (
		<div className="sign-in-wrap">
			<div className="sign-in-container forgot">
				<div className="login-logo"></div>
				<form className="sign-in-form mt-0 ml-4 mr-4" onSubmit={handleSubmit(submitForgotAction)}>
					<div className="row">
						<div className="col-12">
							<h2>Forgot Password</h2>
							<hr className="heading-hr" />
						</div>
						<div className="col-12">
							<div className="form-group">
								<div className="form-lable">Email Address</div>

								<Field
									name="userNameOrEmailAddress"
									component={FormControl}
									type="text"
									className="form-control form-field"
									placeholder="Email"
									validate={[required, email]}
								/>
							</div>
						</div>

						<div className="col-12">
							<button className="form-button-primary w-100" type="submit">
								Submit
							</button>
						</div>
						<div className="col-12 content-center">
							<Link className="forgot-password mt-4" to="/login">
								Back to Login
							</Link>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

const mapDispatchToProps = {
	doSendForgotPasswordCredentials: sendForgotPasswordCredentials,
	doShowLoader: showLoader,
};

export default connect(
	null,
	mapDispatchToProps
)(
	reduxForm({
		form: "SignInForm",
	})(ForgotPassword)
);
