import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Layout from "../SharedComponts/Layout";
import { Card } from "react-bootstrap";
import { Heading, SubHeading } from "../SharedComponts/FormElements";
import { Field, reduxForm } from "redux-form";
import { Button } from "react-bootstrap";
import { get } from "lodash";
import { getFormValues } from "redux-form";
import FormControl from "../SharedComponts/FormControl";
import { formFields } from "./helper";
import EmailSlider from "./EmailSlider";
import { downloadBase64PdfFile } from "../../utils/helper";
import { errorMessage } from "../../utils/errorMessage";
import { successMessage } from "../../utils/successMessage";
import { getDateFromDatePicker } from "../../utils/helper";

import PdfViewer from "../SharedComponts/PdfViewer";

import {
	getExchangerNames,
	getExchangeNumbers,
	getExchangeReport,
	getTransactionReport,
	sendEmail,
	showLoader,
} from "../../store/actions";

import "./reports.scss";
const InfoItem = ({ label, required, fieldForRpt, reportTyp, ...model }) => {
	return fieldForRpt === reportTyp ? (
		<div className="info-item w-16 pr-4">
			<div className="label">
				{label} {required ? <span className="required-label">*</span> : null}
			</div>
			<div className="value">
				<Field {...model} />
			</div>
		</div>
	) : null;
};

const Reports = (props) => {
	const {
		handleSubmit,
		submitting,
		reset,
		weekRange,
		formValues,
		doGetExchangerNames,
		doGetExchangeNumbers,
		doGetExchangeReport,
		doGetTransactionReport,
		doSendEmail,
		doShowLoader,
		change,
	} = props;

	const [reportTyp, setReportType] = useState(1);
	const [exchangerNameList, setExchangerNameList] = useState([]);
	const [exchangeNumberList, setExchangeNumberList] = useState([]);
	const [exchangerNameDropdown, setExchangerNamedown] = useState([]);
	const [exchangeNumberDropdown, setExchangeNumberDropdown] = useState([]);
	const [reportPdfbase64, setReportPdf] = useState(null);
	const [showEmailPopup, setEmailshowPopup] = useState(false);

	useEffect(() => {
		doShowLoader(true);
		const ExchangerSuccessCB = (e) => {
			doShowLoader(false);
			setExchangerNameList(e.result);
			setExchangerNamedown(e.result);
		};
		const ExchangerFailCB = (e) => {
			errorMessage(e);
			doShowLoader(false);
		};

		const ExchangeSuccessCB = (e) => {
			setExchangeNumberList(e.result);
			setExchangeNumberDropdown(e.result);
			doGetExchangerNames(null, ExchangerSuccessCB, ExchangerFailCB);
		};
		const ExchangeFailCB = (e) => {
			errorMessage(e);
			doShowLoader(false);
		};
		doGetExchangeNumbers(null, ExchangeSuccessCB, ExchangeFailCB);
	}, [doShowLoader, doGetExchangerNames, doGetExchangeNumbers]);

	const getExchangeNumber = (e) => {
		doShowLoader(true);
		const successCB = (e) => {
			doShowLoader(false);
			setExchangeNumberDropdown(e.result);

			change(reportTyp === 1 ? "report.exchangeNumber" : "report.bankExchangeNumber", e && e.result && e.result[0]);
		};
		const failCB = (e) => {
			errorMessage(e);
			doShowLoader(false);
		};

		doGetExchangeNumbers(e.value, successCB, failCB);
	};

	const getExchangerName = (e) => {
		doShowLoader(true);
		const successCB = (e) => {
			doShowLoader(false);
			setExchangerNamedown(e.result);
			change(reportTyp === 1 ? "report.exchangerName" : "report.bankExchangeName", e && e.result && e.result[0]);
		};
		const failCB = (e) => {
			errorMessage(e);
			doShowLoader(false);
		};

		doGetExchangerNames(e.value, successCB, failCB);
	};

	const handleSubmitForm = (values) => {
		if (reportTyp === 1) {
			if (isObjectNonEmpty(values) && (values.report.exchangerName || values.report.exchangeNumber)) {
				const successCB = (e) => {
					doShowLoader(false);
					if (e && e.result) {
						const { name, contentType, content } = e.result;
						setReportPdf({
							base64String: content,
							name: name,
							contentType: contentType,
							attachmentType: 575,
						});
					}
				};

				const failCB = (e) => {
					errorMessage(e);
					doShowLoader(false);
				};

				const postData = {
					...values.report,
					dateFrom: getDateFromDatePicker(values.report.dateFrom),
					dateTo: getDateFromDatePicker(values.report.dateTo),
				};

				doShowLoader(true);
				doGetTransactionReport(postData, successCB, failCB);
			} else {
				errorMessage(201);
			}
		} else {
			if (isObjectNonEmpty(values)) {
				const { bankExchangeName, bankExchangeNumber } = values.report;

				const successCB = (e) => {
					doShowLoader(false);
					if (e && e.result) {
						const { name, contentType, content } = e.result;
						setReportPdf({
							base64String: content,
							name: name,
							contentType: contentType,
							attachmentType: 575,
						});
					}
				};

				const failCB = (e) => {
					errorMessage(e);
					doShowLoader(false);
				};

				const postData = {
					exchangerName: bankExchangeName,
					exchangeNumber: bankExchangeNumber,
				};
				doShowLoader(true);
				doGetExchangeReport(postData, successCB, failCB);
			} else {
				errorMessage(202);
			}
		}
	};

	const exportReport = () => {
		const reportType = reportTyp === 1 ? "Transaction Report" : "Exchange Report";
		if (reportPdfbase64 && reportPdfbase64.base64String) {
			downloadBase64PdfFile(reportPdfbase64.base64String, reportType);
		}
	};
	const showEmail = (row) => {
		setEmailshowPopup(true);
	};
	const sendReportEmail = (values) => {
		doShowLoader(true);
		const successCB = (result) => {
			setEmailshowPopup(false);
			doShowLoader(false);
			successMessage(401);
		};
		const FailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};
		doSendEmail(
			{
				...values,
				attachments: [reportPdfbase64],
			},
			successCB,
			FailCB
		);
	};
	const handleEmailClose = () => {
		setEmailshowPopup(false);
	};

	const isObjectNonEmpty = (object) => {
		return Object.keys(object).length !== 0;
	};

	const toBase64 = (data) => {
		return `data:application/pdf;base64,${data}`;
	};
	const reportActionButtons = reportPdfbase64 ? (
		<div className="w-100 pb-5">
			<Button className="form-button-primary float-right" onClick={() => exportReport()}>
				DOWNLOAD AS PDF
			</Button>
			<Button className="form-button-secondary float-right mr-3" onClick={() => showEmail()}>
				SEND EMAIL
			</Button>
		</div>
	) : null;

	return (
		<Layout title="Reports" breadcrumbs={[{ label: "Reports" }]} className="user-details admin-details">
			<Card className="pl-0 pr-0">
				<Card.Title className="info-card-title plr-25">
					<Heading title="Select Type" />
				</Card.Title>
				<Card.Body className="info-card-body d-flex flex-row ">
					<div className="d-flex flex-row w-100 plr-25">
						<FormControl
							type="radio"
							label="Transaction Report"
							input={{
								name: "reportTypeTransaction",
								id: 1,
								checked: reportTyp === 1,
								value: reportTyp,
								onChange: (val) => {
									setReportType(1);
									setReportPdf(null);
									setExchangeNumberDropdown(exchangeNumberList);
									setExchangerNamedown(exchangerNameList);
									reset();
								},
							}}
							meta={{ touched: false, error: false, warning: false }}
						/>
						<span className={`${reportTyp === 1 ? "active-black-color" : ""} label report-type-radio `}>
							{" "}
							Transaction Report
						</span>
						<FormControl
							type="radio"
							label="Exchange Report"
							input={{
								name: "reportTypeExchange",
								id: 2,
								checked: reportTyp === 2,
								value: reportTyp,
								onChange: (val) => {
									setReportType(2);
									setReportPdf(null);
									setExchangeNumberDropdown(exchangeNumberList);
									setExchangerNamedown(exchangerNameList);
									reset();
								},
							}}
							meta={{ touched: false, error: false, warning: false }}
						/>
						<span className={`${reportTyp === 2 ? "active-black-color" : ""} label report-type-radio `}>
							{" "}
							Exchange Report
						</span>
					</div>
					<div className="d-flex flex-row plr-25">
						<SubHeading title="Filters" />
					</div>
					<form className="w-100 pt-4 row mr-0 ml-0 plr-25" onSubmit={handleSubmit(handleSubmitForm)}>
						{formFields(
							weekRange,
							getExchangeNumber,
							getExchangerName,
							formValues,
							exchangeNumberDropdown,
							exchangerNameDropdown,
							change
						).map((item, index) => {
							return <InfoItem key={index} {...item} reportTyp={reportTyp} />;
						})}

						<div className="float-buttons mt-28">
							<Button className="form-button-primary" type="submit" disabled={submitting}>
								GENERATE
							</Button>
							<Button
								className="form-button-light mr-2"
								onClick={() => {
									setExchangeNumberDropdown(exchangeNumberList);
									setExchangerNamedown(exchangerNameList);
									reset();
								}}
								disabled={submitting}>
								RESET
							</Button>
						</div>
					</form>
					<div className="w-100 pt-4 mr-0 ml-0">
						{reportPdfbase64 ? <PdfViewer pdf={toBase64(reportPdfbase64.base64String)} width={1150} /> : null}
					</div>
				</Card.Body>
			</Card>
			{reportActionButtons}

			{showEmailPopup ? (
				<EmailSlider
					onSubmit={sendReportEmail}
					reportPdfbase64={reportPdfbase64}
					show={showEmailPopup}
					handleClose={handleEmailClose}
				/>
			) : null}
		</Layout>
	);
};

const mapStateToProps = (state) => {
	return {
		weekRange: get(state, "Common.WeekRange", null),
		formValues: getFormValues("ReportForm")(state),
	};
};

const mapDispatchToProps = {
	doGetExchangerNames: getExchangerNames,
	doGetExchangeNumbers: getExchangeNumbers,
	doGetExchangeReport: getExchangeReport,
	doGetTransactionReport: getTransactionReport,
	doSendEmail: sendEmail,
	doShowLoader: showLoader,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "ReportForm",
		enableReinitialize: true,
	})(Reports)
);
