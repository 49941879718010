import React from "react";
import UserInfoTable from "../../SharedComponts/FormElements/UserInfoTable";
import { Card } from "react-bootstrap";
import Layout from "../../SharedComponts/Layout";
import { Heading } from "../../SharedComponts/FormElements";

import { getExchangerTypeId } from "../../../utils/helper";
import { detailColumnFn, rowHeadItems } from "../../settings/entities/helper";

import { useHistory } from "react-router-dom";

const Details = (props) => {
	const { showEdit, breadcrumbs, initialValues, setIsEdit } = props;

	const InfoItem = ({ label, value }) => {
		return (
			<div className="info-item col-md-3">
				<div className="label">{label} </div>
				<div className="value"> {value}</div>
			</div>
		);
	};

	const history = useHistory();

	const detailColumn = detailColumnFn(history);
	return (
		<Layout breadcrumbs={breadcrumbs} className="user-details admin-details" title={`Exchanger - ${initialValues.name}`}>
			<Card>
				<Card.Title className="info-card-title">
					<Heading title="Basic Information" />

					{initialValues.exchangerType && initialValues.exchangerType.value === getExchangerTypeId("Individual") ? (
						<div className="user-action-icons">
							<span
								className="user-action-icon"
								onClick={() => {
									setIsEdit(true);
									showEdit();
								}}>
								<span className="icon-icon-edit" />
							</span>
						</div>
					) : null}
				</Card.Title>
				{initialValues.exchangerType && initialValues.exchangerType.value === getExchangerTypeId("Individual") ? (
					<Card.Body className="info-card-body">
						<div className="user-info-wrap">
							<InfoItem label="Exchanger Name" value={initialValues.name} />
							<InfoItem label="Title" value={initialValues.jobTitle} />
							<InfoItem label="Email" value={initialValues.emailAddress} />
							<InfoItem label="Phone" value={initialValues.phoneNumber} />
							<InfoItem label="Phone 2" value={initialValues.phoneNumber2} />
							<InfoItem label="Fax" value={initialValues.fax} />
							<InfoItem label="SSN" value={initialValues.ssn} />
							<InfoItem label="Status" value={initialValues.status ? initialValues.status.label : ""} />
							<InfoItem
								label="Address"
								value={
									initialValues.address && initialValues.address.streetAddress
										? initialValues.address.streetAddress
										: ""
								}
							/>
							<InfoItem
								label="City"
								value={
									initialValues.address && initialValues.address.city ? initialValues.address.city.label : ""
								}
							/>
							<InfoItem
								label="State"
								value={
									initialValues.address && initialValues.address.state
										? initialValues.address.state.label
										: ""
								}
							/>
							<InfoItem
								label="Zip Code"
								value={initialValues.address && initialValues.address ? initialValues.address.zipCode : ""}
							/>
							{/* <InfoItem
								label="County"
								value={
									initialValues.address && initialValues.address.county
										? initialValues.address.county.label
										: ""
								}
							/> */}
						</div>
					</Card.Body>
				) : (
					<Card.Body className="info-card-body">
						<div className="user-info-wrap">
							<InfoItem label="Exchanger Name" value={initialValues.name} />
							<InfoItem
								label="Address"
								value={
									initialValues.address && initialValues.address.streetAddress
										? initialValues.address.streetAddress
										: ""
								}
							/>
							<InfoItem
								label="City"
								value={
									initialValues.address && initialValues.address.city ? initialValues.address.city.label : ""
								}
							/>
							<InfoItem
								label="State"
								value={
									initialValues.address && initialValues.address.state
										? initialValues.address.state.label
										: ""
								}
							/>
							<InfoItem
								label="Zip Code"
								value={initialValues.address && initialValues.address ? initialValues.address.zipCode : ""}
							/>
							{/* <InfoItem
								label="County"
								value={
									initialValues.address && initialValues.address.county
										? initialValues.address.county.label
										: ""
								}
							/> */}
							<InfoItem label="Status" value={initialValues.status ? initialValues.status.label : ""} />
							<InfoItem label="Tax ID" value={initialValues.taxId} />
						</div>
					</Card.Body>
				)}
			</Card>
			{initialValues && initialValues.exchangeDtoList && initialValues.exchangeDtoList.length ? (
				<Card>
					<Card.Title className="info-card-title">
						<Heading title="Related Exchanges" />
					</Card.Title>
					<Card.Body className="info-card-body">
						<UserInfoTable
							rowHeadItems={
								initialValues.exchangerType &&
								initialValues.exchangerType.value === getExchangerTypeId("Individual")
									? []
									: rowHeadItems()
							}
							columns={detailColumn}
							data={initialValues.exchangeDtoList}
							dataAccessor={
								initialValues.exchangerType &&
								initialValues.exchangerType.value === getExchangerTypeId("Individual")
									? null
									: "exchangeDtoList"
							}
						/>
					</Card.Body>
				</Card>
			) : null}
		</Layout>
	);
};

export default Details;
