import React from "react";
import Select, { components } from "react-select";

const RenderField = ({
	options,
	addable,
	addAction = () => {},
	addLabel,
	isMulti,
	input,
	id,
	placeholder,
	meta: { touched, error, warning },
	isDisabled,
}) => {
	const handleChange = (selectedOption) => {
		input.onChange(selectedOption);
	};
	const customStyles = {
		indicatorSeparator: () => ({ display: "none" }),
	};
	const MenuList = (props) => {
		return (
			<>
				<components.MenuList {...props}>{props.children}</components.MenuList>
				<div
					className="add-action-wrap"
					onClick={() => {
						props.setValue();
						addAction();
					}}>
					<span className="add-icon">{`+ `}</span>
					<span className="add-action-label">{addLabel}</span>
				</div>
			</>
		);
	};
	return (
		<div htmlFor={id} className="custom-select-box-wrap">
			<Select
				placeholder={placeholder ? placeholder : "Select"}
				onChange={handleChange}
				options={options}
				components={{ IndicatorSeparator: null, MenuList: addable ? MenuList : components.MenuList }}
				className="custom-select-box"
				isMulti={isMulti}
				onBlur={() => input.onBlur(input.value)}
				onFocus={() => input.onFocus(input.value)}
				value={input.value}
				name={input.name}
				style={customStyles}
				isDisabled={isDisabled ? isDisabled : false}
			/>
			{touched && ((error && <span className="form-error-span">{error}</span>) || (warning && <span>{warning}</span>))}
		</div>
	);
};

export default RenderField;
