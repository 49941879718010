import React from "react";
import { Heading } from "../../SharedComponts/FormElements";
import { TimelineHours } from "../../../utils/formatter";
import noData from "../../../assets/images/no-data.png";

const Activities = ({ lastActivitiesData, routToExchanges = null }) => {
	return (
		<>
			<Heading title="Activities" />

			{lastActivitiesData && lastActivitiesData.length ? (
				<div className="userActivities">
					<ul className="timeline">
						{lastActivitiesData.map((item, index) => (
							<li key={index}>
								<span className="day-time">
									<TimelineHours utcTime={item.createdOn} />
								</span>
								<p>
									{item.description}
									{item.exchangeNumber && (
										<span
											onClick={() => item.guid && routToExchanges && routToExchanges(item.guid)}
											className="exc-id rout-link">
											{` ${item.exchangeNumber}`}
										</span>
									)}
								</p>
							</li>
						))}
					</ul>
				</div>
			) : (
				<img src={noData} className="dashboard-no-data-card" alt="noData" />
			)}
		</>
	);
};
export default Activities;
