import React, { useState, useEffect } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import { uid } from "uid";
import { downloadPdfFile } from "../../../../utils/helper";
import { columns as columnsFn } from "./helper";
import { errorMessage } from "../../../../utils/errorMessage";
import { successMessage } from "../../../../utils/successMessage";
import { toBase64 } from "../../../../utils/helper";
import Confirm from "../../../SharedComponts/FormElements/confirm";
import { Heading } from "../../../SharedComponts/FormElements";
import PdfViewer from "../../../SharedComponts/PdfViewer";

import {
	getExchangedocumentList,
	getDocumentList,
	updateDocument,
	deleteDocument,
	showLoader,
	getExchangePropAddressList,
	uploadDocument,
} from "../../../../store/actions";
import { connect } from "react-redux";
import UploadDocumentSlider from "./UploadDocumentSlider";
import "./documentTab.scss";

const DocumentTab = (props) => {
	const {
		setShoWGenerateDocumnt,
		editGeneratedDocument,
		doShowLoader,
		doGetExchangedocumentList,
		doDeleteDocument,
		exchangeDetail,
		DealType,
		doGetExchangePropAddressList,
		doUploadDocument,
		isLocked,
	} = props;

	const [exchangeDocxList, setExchangeDocxList] = useState([]);
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [currentDocument, setCurrentDocument] = useState(null);
	const [showViewModal, setShowViewModal] = useState(false);
	const [propertyAddressList, setpropertyAddressList] = useState([]);
	const [showUploadDocxSlider, setShowUploadDocxSlider] = useState(false);
	const [reload, setReload] = useState(false);

	useEffect(() => {
		doShowLoader(true);
		const successCB = (e) => {
			e && e.result && e.result.data && setExchangeDocxList(e.result.data);
			doShowLoader(false);
		};
		const failCB = (e) => {
			errorMessage(e);
			doShowLoader(false);
		};
		doGetExchangedocumentList(exchangeDetail.id, successCB, failCB);
	}, [doShowLoader, doGetExchangedocumentList, exchangeDetail.id, reload]);

	const onView = (row) => {
		setCurrentDocument(row);
		setShowViewModal(true);
	};

	const onEdit = (row) => {
		editGeneratedDocument(row.id);
	};

	const onDelete = (row) => {
		setCurrentDocument(row);
		setConfirmDelete(true);
	};

	const onConfirmDelete = () => {
		doShowLoader(true);
		setConfirmDelete(false);
		const successCB = (e) => {
			doShowLoader(false);
			successMessage(231);
			setCurrentDocument(null);
			setReload(!reload);
		};
		const failCB = (e) => {
			errorMessage(e);
			setCurrentDocument(null);
			doShowLoader(false);
		};
		doDeleteDocument(currentDocument.id, successCB, failCB);
	};
	const onDownload = (row) => {
		if (row && row.documentUrl) {
			downloadPdfFile(row.documentUrl, row.documentName);
		}
	};

	const getPropertyAddress = (e) => {
		doShowLoader(true);
		const successCB = (e) => {
			e && e.result && setpropertyAddressList(e.result);
			doShowLoader(false);
		};
		const failCB = (e) => {
			errorMessage(e);
			doShowLoader(false);
		};
		e && e.value && doGetExchangePropAddressList(exchangeDetail.id, e.value, successCB, failCB);
	};

	const promiseAction = (postData) => {
		return new Promise(function (resolve, reject) {
			const successCB = (e) => {
				resolve(e);
			};

			const failCB = (e) => {
				errorMessage(e);
			};

			doUploadDocument(postData, successCB, failCB);
		});
	};
	const uploadDocument = (values) => {
		const { propertyAddress, fileInput } = values;

		const commonData = {
			exchangeId: exchangeDetail.id,
			propertyId: propertyAddress.value,
		};

		if (!fileInput || fileInput.length === 0) {
			errorMessage(401);
			return;
		}

		doShowLoader(true);
		const promiseList = fileInput.map(async (file) => {
			const { name, type } = file;

			const base64Result = await toBase64(file).catch((e) => Error(e));
			if (base64Result instanceof Error) {
				doShowLoader(false);
				console.error("Pdf convert error: ", base64Result.message);
				errorMessage(base64Result.message);
				return;
			}
			const fileName = `${exchangeDetail.exchangeNumber}_${name.substring(0, name.lastIndexOf("."))}_${uid(5)}`;
			const postData = {
				...commonData,
				fileName,
				fileExtension: ".pdf",
				fileContentType: type,
				base64Document: base64Result.substr(base64Result.indexOf(",") + 1),
				fileURL: 1,
			};
			return promiseAction(postData);
		});

		Promise.all(promiseList).then((values) => {
			doShowLoader(false);
			setShowUploadDocxSlider(false);
			successMessage(230);
			setReload(!reload);
		});
	};

	const handleUploadDocxClose = () => {
		setShowUploadDocxSlider(false);
	};
	const columns = columnsFn({ onEdit, onDelete, onDownload, onView });

	const renderRow = (row) => {
		return columns.map(({ accessor, width, render, className }, index) => {
			if (render) {
				return (
					<div key={index} className={`table-col ${className ? className : ""}`} style={width ? { width } : {}}>
						{render(row)}
					</div>
				);
			}
			return (
				<div key={index} className={`table-col ${className ? className : ""}`} style={width ? { width } : {}}>
					{row[accessor] ? row[accessor] : "-"}
				</div>
			);
		});
	};
	return (
		<>
			<div className="row w-100 add-row">
				<Button
					className={`form-button-secondary btn btn-primary mr-2 ${isLocked ? "form-button-primary inactive" : ""}`}
					onClick={() => setShoWGenerateDocumnt(true)}>
					GENERATE DOCUMENT
				</Button>
				<Button className="form-button-primary btn btn-primary" onClick={() => setShowUploadDocxSlider(true)}>
					UPLOAD DOCUMENTS
				</Button>
			</div>

			<Card.Body className="info-card-body d-flex flex-row document-tab">
				{exchangeDocxList && exchangeDocxList.length ? (
					<div className="task-table">
						<div className="table-head">
							{columns.length
								? columns.map(({ label, className, width }, index) => (
										<div
											key={index}
											className={`head-item ${className ? className : ""}`}
											style={width ? { width } : {}}>
											{label}
										</div>
								  ))
								: null}
						</div>
						{exchangeDocxList.map((row, index) => {
							return (
								<div key={index} className={`table-row w-100 ${index % 2 === 0 ? "row-even" : "row-odd"}`}>
									{renderRow(row)}
								</div>
							);
						})}
					</div>
				) : (
					<div className="no-data-table no-data-table-task">No items found</div>
				)}
				{showUploadDocxSlider ? (
					<UploadDocumentSlider
						onSubmit={uploadDocument}
						show={showUploadDocxSlider}
						handleClose={handleUploadDocxClose}
						propertyTypeList={DealType}
						propertyAddressList={propertyAddressList}
						getPropertyAddress={getPropertyAddress}
					/>
				) : null}
			</Card.Body>

			<Confirm
				onYes={onConfirmDelete}
				onNo={(e) => {
					setConfirmDelete(false);
				}}
				show={confirmDelete ? true : false}
			/>
			<Modal
				show={showViewModal}
				className="document-pdf-modal"
				centered
				size="lg"
				backdrop="static"
				onHide={() => setShowViewModal(false)}>
				<Modal.Header closeButton>
					<Heading title={currentDocument ? currentDocument.documentName : null} />
				</Modal.Header>
				<Modal.Body>{currentDocument ? <PdfViewer pdf={currentDocument.documentUrl} width={755} /> : null}</Modal.Body>
				<Modal.Footer>
					<Button
						className="form-button-primary btn btn-primary"
						onClick={() => {
							setShowViewModal(false);
							setCurrentDocument(null);
						}}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		Exchange: { exchangeDetail = {} },
		Common: { DealType = {} },
	} = state;
	return {
		exchangeDetail,
		DealType,
	};
};
const mapDispatchToProps = {
	doShowLoader: showLoader,
	doGetExchangedocumentList: getExchangedocumentList,
	doGetDocumentList: getDocumentList,
	doUpdateDocument: updateDocument,
	doDeleteDocument: deleteDocument,
	doGetExchangePropAddressList: getExchangePropAddressList,
	doUploadDocument: uploadDocument,
};
export default connect(mapStateToProps, mapDispatchToProps)(DocumentTab);
