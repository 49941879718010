import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { get } from "lodash";

import Slider from "../../../SharedComponts/Slider";
import { excrowFormFields } from "./helper";
import SliderForm from "../../../SharedComponts/SliderForm";
const AddEdit = (props) => {
	const { show, handleClose, handleSubmit, submitting, reset, isEdit, settlementCompanies } = props;

	return (
		<Slider
			show={show}
			title={"Add Agent"}
			handleClose={() => {
				reset();
				handleClose();
			}}>
			<SliderForm handleSubmit={handleSubmit} submitting={submitting} handleClose={handleClose}>
				{excrowFormFields(settlementCompanies, isEdit).map((item, index) => {
					return item.hideField ? null : (
						<div key={index} className="form-group">
							<div className="form-lable">
								{item.label}
								{item.required ? <span className="required-label">*</span> : null}
							</div>
							<Field {...item} />
						</div>
					);
				})}
			</SliderForm>
		</Slider>
	);
};
const mapStateToProps = (state) => {
	return {
		states: get(state, "Common.states", null),
		counties: get(state, "Common.counties", null),
		cites: get(state, "Common.cites", null),
	};
};

export default connect(
	mapStateToProps,
	null
)(
	reduxForm({
		form: "EscrowAddForm",
		enableReinitialize: true,
	})(AddEdit)
);
