import { PROPERTIES } from "../types";
export default (
	state = {
		propertyFilters: {
			sortColumn: "propertyId",
			sortDirection: "desc",
			pageIndex: 1,
			pageSize: 10,
			searchKey: "",
			extraParams: {},
		},
		propertyList: [],
		buyerSellerList: [],
		escrowNumber: [],
	},
	action
) => {
	switch (action.type) {
		case PROPERTIES.GET_ALL_PROPERTY_LIST:
			return {
				...state,
				propertyList: action.payload && action.payload.result ? action.payload.result : [],
			};
		case PROPERTIES.GET_BUYER_SELLER_LIST:
			return {
				...state,
				buyerSellerList: action.payload && action.payload.result ? action.payload.result : [],
			};
			case PROPERTIES.GET_ESCROW_NUMBER:
			return {
				...state,
				escrowNumber: action.payload && action.payload.result ? action.payload.result : [],
			};
		case PROPERTIES.SET_PROPERTY_FILTER:
			return {
				...state,
				propertyFilters: action.payload ? action.payload : {},
			};

		default:
			return state;
	}
};
