import React from "react";
import SearchField from "../../../../SharedComponts/FormElements/searchTable";
import { Pagination, Table } from "../../../../SharedComponts/FormElements/table";
const FirstStage = (props) => {
	const { filters, doSetFilter, columns, templateList, recordsTotal } = props;
	return (
		<>
			<SearchField
				initialValue={filters.searchKey}
				onSearch={(searchKey) =>
					doSetFilter({
						...filters,
						searchKey,
						pageIndex: 1,
					})
				}
				className={`flex-fill mb-0`}
				placeholder="Enter your query"
			/>
			<Table
				columns={columns}
				data={templateList}
				onSort={(e) =>
					e.by !== filters.sortColumn || e.order !== filters.sortDirection
						? doSetFilter({
								...filters,
								sortColumn: e.by,
								sortDirection: e.order,
								pageIndex: 1,
						  })
						: null
				}
				sortBy={filters.sortColumn}
				sortDir={filters.sortDirection}
			/>
			<Pagination
				total={recordsTotal}
				count={filters.pageSize}
				page={filters.pageIndex}
				onChange={(e) => {
					doSetFilter({ ...filters, pageIndex: e });
				}}
				onPageCountChange={(e) => {
					doSetFilter({ ...filters, pageSize: e, pageIndex: 1 });
				}}
			/>
		</>
	);
};

export default FirstStage;
