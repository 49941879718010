import React, { useState } from "react";
import { Accordion, Button, Card } from "react-bootstrap";
import { FieldArray } from "redux-form";


import { AccordionSlider, Heading, InfoItem, SubSectionHeading } from "../../../SharedComponts/FormElements";

import {
	propertyBasicFormFields,
	settlementBasicDetailFields,
	settlementAddressFields,
	settlementContactFields,
} from "./helper";
// const BuyerForm = () => <></>;
import DealerForm from "./DealerForm";

const Property = ({
	relinquishedProperties,
	reloadProperty,
	setReloadProperty,
	populateSettlementAddress,
	setAddressEdit,
	isAddressEdit,
	submitAddress,
	onDeleteBuyer,
	createBuyer,
	popupExcrowContacts,
	showExcrowForm,
	saveProperty,
	doShowLoader,
	doGetUser,
	loadUsers,
	property,
	propertyFormValues,
	loadCounties,
	settlemntCompanies,
	getEscrowAgentData,
	escrowAgent,
	setEscrowAgent,
	doGetCities,
	doSearchExcrowByCompany,
	doExcrowByCompany,
	showSettlementPopup,
	states,
	change,
	cities,
	isLocked,
	resetFormField,
}) => {
	const loadExcrowAgents = (searchKey, callback) => {
		if (propertyFormValues.settlementInformation && propertyFormValues.settlementInformation.settlementCompany) {
			const companyId = propertyFormValues.settlementInformation.settlementCompany.value;
			const successCB = ({ result }) => {
				callback(result);
			};

			const FailCB = (result) => {
				callback([]);
			};
			if (searchKey.length > 1) {
				doSearchExcrowByCompany({ companyId, searchKey }, successCB, FailCB);
			} else {
				callback([]);
			}
		} else {
			callback([]);
		}
	};


	return (
		<>
			<Card.Title className="info-card-title">
				<SubSectionHeading title="Basic Details" />
			</Card.Title>
			<Card.Body className="info-card-body">
				{propertyBasicFormFields({
					property,
					saveProperty,
					cities,
					states,
					doGetCities,
					loadCounties,
					change,
					isLocked,
				}).map((item, index) => (
					<InfoItem key={index} formData={propertyFormValues} {...item} />
				))}
			</Card.Body>
			<Card.Title className="info-card-title">
				<Heading title="Settlement Information" />
				<Button onClick={() => resetFormField(property)}>Reset</Button>
			</Card.Title>
			<Card.Title className="info-card-title">
				<SubSectionHeading title="Basic Details" />
			</Card.Title>
			<Card.Body className="info-card-body">
				{settlementBasicDetailFields({
					populateSettlementAddress,
					popupExcrowContacts,
					property,
					saveProperty,
					showExcrowForm,
					propertyFormValues,
					showSettlementPopup,
					settlemntCompanies,
					getEscrowAgentData,
					escrowAgent,
					setEscrowAgent,
					loadExcrowAgents,
					change,
					isLocked,
				}).map((item, index) => (
					<InfoItem key={index} {...item} formData={propertyFormValues} />
				))}
			</Card.Body>
			<Card.Title className="info-card-title">
				<SubSectionHeading title="Contact Details" />
			</Card.Title>
			<Card.Body className="info-card-body">
				{settlementContactFields({ property, saveProperty, propertyFormValues, isLocked }).map((item, index) => (
					<InfoItem key={index} formData={propertyFormValues} {...item} />
				))}
			</Card.Body>

			<Card className="address-card">
				<Card.Title className="info-card-title">
					<SubSectionHeading title="Address Information" />
					{!!(
						isAddressEdit !== Number(property.split("[")[1].split("]")[0]) &&
						propertyFormValues &&
						propertyFormValues.settlementInformation &&
						propertyFormValues.settlementInformation.settlementCompany &&
						propertyFormValues.settlementInformation.settlementCompany.value
					) &&
						!isLocked && (
							<div className="user-action-icons">
								<span
									className="user-action-icon"
									onClick={() => setAddressEdit(Number(property.split("[")[1].split("]")[0]))}>
									<span className="icon-icon-edit" />
								</span>
							</div>
						)}
				</Card.Title>
				<Card.Body className="info-card-body">
					{settlementAddressFields(
						{ property, doShowLoader, saveProperty, loadCounties, doGetCities, states, cities, change, isLocked },
						isAddressEdit === Number(property.split("[")[1].split("]")[0])
					).map((item, index) => (
						<InfoItem key={index} formData={propertyFormValues?.settlementInformation} {...item} />
					))}
					{isAddressEdit === Number(property.split("[")[1].split("]")[0]) && (
						<div className="mb-4 d-flex w-100 justify-content-end">
							<Button
								className="button-light"
								onClick={() => {
									saveProperty(isAddressEdit, false, true, true);
									setAddressEdit(null);
								}}>
								Cancel
							</Button>
							<Button
								onClick={() => submitAddress(Number(property.split("[")[1].split("]")[0]))}
								className="button-primary">
								Submit
							</Button>
						</div>
					)}
				</Card.Body>
			</Card>
			<FieldArray
				name={`${property}.dealerDtoList`}
				props={{
					property,
					relinquishedProperty: relinquishedProperties.properties[Number(property.split("[")[1].split("]")[0])],
					onDeleteBuyer,
					createBuyer,
					saveProperty,
					doShowLoader,
					doGetUser,
					loadUsers,
					doGetCities,
					cities,
					states,
					change,
					isLocked,
					propertyFormValues: propertyFormValues,
				}}
				component={DealerForm}
			/>
		</>
	);
};

const PropertForm = ({
	reloadProperty,
	relinquishedProperties,
	populateSettlementAddress,
	setAddressEdit,
	isAddressEdit,
	submitAddress,
	onDeleteBuyer,
	createBuyer,
	onDeleteProperty,
	popupExcrowContacts,
	showExcrowForm,
	createRelinquishProperty,
	doShowLoader,
	doGetUser,
	loadUsers,
	fields,
	meta: { error },
	settlemntCompanies,
	getEscrowAgentData,
	escrowAgent,
	setEscrowAgent,
	states,
	doSearchExcrowByCompany,
	doExcrowByCompany,
	doGetCities,
	showSettlementPopup,
	saveProperty,
	change,
	cities,
	formValues,
	loadCounties,
	setReloadProperty,
	isLocked,
	resetFormField
}) => {

	const [actveKey, setActiveKey] = useState("property_0");

	return (
		<>
			<Card.Title className="info-card-title align-items-baseline">
				{fields.length ? <Heading title="Property Information" /> : null}
				{!isLocked && (
					<Button
						className="form-button-secondary"
						onClick={() =>
							createRelinquishProperty(() => {
								setActiveKey(`property_${relinquishedProperties.properties.length}`);
							})
						}>
						<span className="add-new-plus">+</span> Add Property
					</Button>
				)}
			</Card.Title>
			<Card.Body className="p-0">
				<Accordion activeKey={actveKey}>
					{fields.map((property, index) => (
						<AccordionSlider
							onClick={(key) => {
								if (key === actveKey) {
									setActiveKey("");
								} else {
									setActiveKey(key);
								}
							}}
							defaultKey={fields.length}
							key={index}
							eventKey={`property_${index}`}
							index={index}
							subTitle={fields.get(index)?.name}
							onDelete={
								!isLocked
									? () => {
										onDeleteProperty(index, () => {
											setActiveKey(`property_${relinquishedProperties.properties.length - 2}`);
										});
									}
									: null
							}
							title={`Relinquished Property ${index + 1}`}
							content={""}>
							<Property
								relinquishedProperties={relinquishedProperties}
								reloadProperty={reloadProperty}
								setReloadProperty={setReloadProperty}
								populateSettlementAddress={populateSettlementAddress}
								setAddressEdit={setAddressEdit}
								isAddressEdit={isAddressEdit}
								onDeleteBuyer={onDeleteBuyer}
								submitAddress={submitAddress}
								createBuyer={createBuyer}
								popupExcrowContacts={popupExcrowContacts}
								showExcrowForm={showExcrowForm}
								saveProperty={saveProperty}
								doShowLoader={doShowLoader}
								showSettlementPopup={showSettlementPopup}
								doGetUser={doGetUser}
								loadUsers={loadUsers}
								loadCounties={loadCounties}
								propertyFormValues={formValues?.properties ? formValues.properties[index] : {}}
								property={property}
								doGetCities={doGetCities}
								doSearchExcrowByCompany={doSearchExcrowByCompany}
								doExcrowByCompany={doExcrowByCompany}
								change={change}
								settlemntCompanies={settlemntCompanies}
								escrowAgent={escrowAgent}
								setEscrowAgent={setEscrowAgent}
								states={states}
								cities={cities}
								isLocked={isLocked}
								getEscrowAgentData={getEscrowAgentData}
								resetFormField={resetFormField}
							/>
						</AccordionSlider>
					))}
				</Accordion>
			</Card.Body>
		</>
	);
};
export default PropertForm;
