import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, getFormValues } from "redux-form";
import { get } from "lodash";

import Slider from "../../../SharedComponts/Slider";
import { transactionFormFields } from "./helper";
import SliderForm from "../../../SharedComponts/SliderForm";
import { showLoader, getExchangeFromToList } from "../../../../store/actions";
import { errorMessage } from "../../../../utils/errorMessage";

const AddTransaction = (props) => {
	const {
		show,
		handleClose,
		handleSubmit,
		submitting,
		reset,
		exchangeAddressList,
		transactionFromToList,
		doShowLoader,
		doGetExchangeFromToList,
		actionMode,
		incomeCategory,
		paymentStatus,
		formValues,
		initialValues,
		change,
	} = props;
	const onPropertyChange = (propertyObject) => {
		doShowLoader(true);
		const successCB = (result) => {
			doShowLoader(false);
		};
		const failCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};
		doGetExchangeFromToList(propertyObject.value, successCB, failCB);
	};

	useEffect(() => {
		if (initialValues && initialValues.id && initialValues.exchangeProperty && initialValues.exchangeProperty.value) {
			onPropertyChange(initialValues.exchangeProperty);
		}
		// eslint-disable-next-line
	}, [initialValues]);

	return (
		<Slider
			show={show}
			title={`${initialValues && initialValues.id ? "Edit" : "Add"} ${actionMode === 1 ? "Bank Transaction" : "Income"}`}
			handleClose={() => {
				reset();
				handleClose();
			}}>
			<SliderForm handleSubmit={handleSubmit} submitting={submitting} handleClose={handleClose}>
				{transactionFormFields({
					exchangeAddressList,
					transactionFromToList,
					onPropertyChange,
					actionMode,
					incomeCategory,
					paymentStatus,
					formValues,
					change,
				}).map((item, index) => {
					return item.hideField ? null : (
						<div key={index} className="form-group">
							<div className="form-lable">
								{item.label}
								{item.required ? <span className="required-label">*</span> : null}
							</div>
							<Field {...item} />
						</div>
					);
				})}
			</SliderForm>
		</Slider>
	);
};
const mapStateToProps = (state) => {
	return {
		exchangeAddressList: get(state, "Exchange.exchangeAddressList", []),
		transactionFromToList: get(state, "Exchange.transactionFromToList", []),
		incomeCategory: get(state, "Common.incomeCategory", []),
		paymentStatus: get(state, "Common.paymentStatus", []),
		formValues: getFormValues("TransactionAddForm")(state),
	};
};
const mapDispatchToProps = {
	doShowLoader: showLoader,
	doGetExchangeFromToList: getExchangeFromToList,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "TransactionAddForm",
		enableReinitialize: true,
	})(AddTransaction)
);
