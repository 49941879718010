import React from "react";

import Layout from "../SharedComponts/Layout";
import "./settings.scss";
import { Link } from "react-router-dom";
const Settings = ({ handleSubmit, history }) => {
	return (
		<Layout title="Configurations" breadcrumbs={[{ label: "Configurations" }]} className="configurations-listing-page">
			<div className="row w-100 ml-0 mr-0 p-0">
				<div className="col-12 p-0 sub-title">
					<h2>Modules</h2>
					<hr className="heading-hr" />
				</div>
				<div className="row w-100 col-12 ml-0 p-0 d-flex  flex-md-row flex-sm-column flex--card">
					<div className="d-flex align-items  settings-card card1">
						<Link className="w-100" to="/settings-master/entities">
							<div className="w-100 card-content">
								<div className="h2 w-100">Entities</div>
								<div className="d-flex flex-row w-100">
									<p className="w-75 text pt-2 mb-2 pr-2">Configure the list and types of companies.</p>
									<span className="w-25">
										<span className="round-button">
											<span className={`icon-entities`}></span>
										</span>
									</span>
								</div>
							</div>
						</Link>
					</div>
					<div className="d-flex align-items   settings-card card2">
						<Link className="w-100" to="/settings-master/tasks">
							<div className="w-100 card-content">
								<div className="h2 w-100">Tasks</div>
								<div className="d-flex flex-row w-100">
									<p className="w-75 text pt-2 mb-2 pr-2">Configure the list of tasks for exchanges.</p>
									<span className="w-25">
										<span className="round-button">
											<span className={`icon-notes`}></span>
										</span>
									</span>
								</div>
							</div>
						</Link>
					</div>
					<div className="d-flex align-items  settings-card card3">
						<Link className="w-100" to="/settings-master/template">
							<div className="w-100 card-content">
								<div className="h2 w-100">Templates</div>
								<div className="d-flex flex-row w-100">
									<p className="w-75 text pt-2 mb-2 pr-2">Configure the list of templates.</p>
									<span className="w-25">
										<div className="round-button">
											<span className={`icon-template`}></span>
										</div>
									</span>
								</div>
							</div>
						</Link>
					</div>
					<div className="d-flex align-items  settings-card card4">
						<Link className="w-100" to="/settings-master/pdf">
							<div className="w-100 card-content">
								<div className="h2 w-100">PDF Configuration</div>
								<div className="d-flex flex-row w-100">
									<p className="w-75 text pt-2 mb-2 pr-2">Configure the PDF generation settings.</p>
									<span className="w-25">
										<div className="round-button">
											<span className={`icon-pdf`}></span>
										</div>
									</span>
								</div>
							</div>
						</Link>
					</div>
				</div>

				<div className="col-12 mt-4 sub-title">
					<h2>Transactions</h2>
					<hr className="heading-hr" />
				</div>
				<div className="row w-100 col-12 ml-0 p-0 d-flex flex-md-row flex-sm-column">
					<div className="d-flex align-items  settings-card  card1">
						<Link className="w-100" to="/settings-master/banks">
							<div className="w-100 card-content">
								<div className="h2 w-100">Banks</div>
								<div className="d-flex flex-row w-100">
									<p className="w-75 text pt-2 mb-2 pr-2">Configure the list of banks.</p>
									<span className="w-25">
										<span className="round-button">
											<span className={`icon-bank`}></span>
										</span>
									</span>
								</div>
							</div>
						</Link>
					</div>
					<div className="d-flex align-items  settings-card  card2">
						<Link className="w-100" to="/settings-master/fees">
							<div className="w-100 card-content">
								<div className="h2 w-100">Fees</div>
								<div className="d-flex flex-row w-100">
									<p className="w-75 text pt-2 mb-2 pr-2">Configure the fee amount.</p>
									<span className="w-25">
										<span to="/settings-master/fees" className="round-button">
											<span className={`icon-dollar`}></span>
										</span>
									</span>
								</div>
							</div>
						</Link>
					</div>
				</div>
			</div>
		</Layout>
	);
};
export default Settings;
