import fetchHandler from "../../utils/fetchHandler";
import { COMMON, DASHBOARD } from "../types";

export const getFunFact = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `https://cat-fact.herokuapp.com/facts/random?animal_type=dog&amount=1`,
		method: "GET",
		secure: true,
		actionType: COMMON.FUN_FACT,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getDashboard = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `dashboard`,
		method: "GET",
		secure: true,
		actionType: DASHBOARD.GET_DASHBOARD_DATA,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getBartChart = (filterId, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `barchart/${filterId}`,
		method: "GET",
		secure: true,
		actionType: DASHBOARD.GET_BARCHART_DATA,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getLastActivities = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `lastActivities`,
		method: "GET",
		secure: true,
		actionType: DASHBOARD.GET_LAST_ACTIVITIES,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
