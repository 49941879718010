import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import { Heading } from "../../SharedComponts/FormElements";
import leftArrowIcon from "../../../assets/images/left-arrow.png";
import rightArrowIcon from "../../../assets/images/right-arrow.png";
import noData from "../../../assets/images/no-data.png";
const NextEvents = ({ eventData, routToExchanges = null }) => {
	const [activeTab, setActiveTab] = useState(0);
	const btnOnlick = (index) => {
		setActiveTab(index);
	};

	const nextIcon = (
		<button className="caro--Icon prev" onClick={() => btnOnlick(0)} disabled={eventData.length <= 5 || activeTab === 0}>
			<img src={leftArrowIcon} height="15" width="15" alt="Carousel Arrow" />
		</button>
	);
	const prevIcon = (
		<button className="caro--Icon next" onClick={() => btnOnlick(1)} disabled={eventData.length <= 5 || activeTab === 1}>
			<img src={rightArrowIcon} height="15" width="15" alt="Carousel Arrow" />
		</button>
	);

	return (
		<>
			<Heading title="Next 10 Events" />
			{eventData && eventData.length ? (
				<>
					<div className="events">
						<div className="carousel-controls">
							{nextIcon}
							{prevIcon}
						</div>
						<Carousel interval={null} nextIcon={null} prevIcon={null} wrap={true} activeIndex={activeTab}>
							<Carousel.Item>
								{eventData.slice(0, 5).map((event, index) => {
									const events = (
										<div className="Event__block" key={index}>
											<div className="Event__block--date">
												<div className="Dot_blue"></div>
												<span>{event.month}</span>
												<div className="Event__block--num">{event.day}</div>
											</div>
											<div className="Event__block--item">
												<div>
													{event.description}
													<span
														className="rout-link"
														onClick={() =>
															event.guid && routToExchanges && routToExchanges(event.guid)
														}>
														{event.exchangeNumber}
													</span>
												</div>
												<ul>
													<li>
														<div className="Event__block--texter exchanger">
															<span>Exchanger:</span> {event.exchanger}
														</div>
													</li>
													<li>
														<div className="Event__block--texter name">
															<span>Name:</span> {event.name}
														</div>
													</li>
													<li>
														<div className="Event__block--texter email">
															<span>Email:</span> {event.emailId}
														</div>
													</li>
												</ul>
											</div>
										</div>
									);
									return events;
								})}
							</Carousel.Item>
							{eventData.length > 5 && (
								<Carousel.Item>
									{eventData.slice(5, 10).map((event, index) => {
										const events = (
											<div className="Event__block" key={index}>
												<div className="Event__block--date">
													<div className="Dot_blue"></div>
													<span>{event.month}</span>
													<div className="Event__block--num">{event.day}</div>
												</div>
												<div className="Event__block--item">
													<div>
														{`${event.description} `}
														<span
															className="rout-link"
															onClick={() =>
																event.guid && routToExchanges && routToExchanges(event.guid)
															}>
															{event.exchangeNumber}
														</span>
													</div>
													<ul>
														<li>
															<div className="Event__block--texter exchanger">
																<span>Exchanger:</span> {event.exchanger}
															</div>
														</li>
														<li>
															<div className="Event__block--texter name">
																<span>Name:</span> {event.name}
															</div>
														</li>
														<li>
															<div className="Event__block--texter email">
																<span>Email:</span> {event.emailId}
															</div>
														</li>
													</ul>
												</div>
											</div>
										);
										return events;
									})}
								</Carousel.Item>
							)}
						</Carousel>
					</div>
				</>
			) : (
				<img src={noData} className="dashboard-no-data-card" alt="noData" />
			)}
		</>
	);
};
export default NextEvents;
