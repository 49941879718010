import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import Slider from "../../SharedComponts/Slider";
import { templateCopyFormFields } from "./helper";
import SliderForm from "../../SharedComponts/SliderForm";
const AddEdit = (props) => {
	const { show, handleClose, handleSubmit, submitting, reset } = props;

	return (
		<Slider
			show={show}
			title={"Copy Template"}
			handleClose={() => {
				reset();
				handleClose();
			}}>
			<SliderForm handleSubmit={handleSubmit} submitting={submitting} handleClose={handleClose}>
				{templateCopyFormFields().map((item, index) => {
					return (
						<div key={index} className="form-group">
							<div className="form-lable">
								{item.label}
								{item.required ? <span className="required-label">*</span> : null}
							</div>
							<Field {...item} />
						</div>
					);
				})}
			</SliderForm>
		</Slider>
	);
};
const mapStateToProps = ({ Common: { ExchangeType: exchangeTypes = [], templateTypes = [] } }) => ({
	exchangeTypes,
	templateTypes,
});
export default connect(
	mapStateToProps,
	null
)(
	reduxForm({
		form: "templateCopyForm",
		enableReinitialize: true,
	})(AddEdit)
);
