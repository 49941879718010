import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Dashboard from "../components/dashboard";
import Reports from "../components/reports";
import Properties from "../components/properties";
import Transactions from "../components/transactions";
import Income from "../components/income";
import Settings from "../components/settings";
import Exchanges from "../components/exchanges";
import CreateExchange from "../components/exchanges/create";
import ExchangesView from "../components/exchanges/view";
import Exchangers from "../components/userManagement/exchangers";
import Admins from "../components/userManagement/admin";
import Escrow from "../components/userManagement/escrow";
import Contacts from "../components/userManagement/contacts";
import Profile from "../components/profile";
import Tasks from "../components/settings/tasks";
import Fees from "../components/settings/fees";
import Banks from "../components/settings/banks";
import Templates from "../components/settings/templates";
import Entities from "../components/settings/entities";
import Pdf from "../components/settings/pdf";
import Demos from "../components/demos";
import Notification from "../components/notification";

export const MainRouter = ({ userData, location }) => {
	return userData && userData.userId ? (
		<Switch location={location}>
			<Route exact path="/">
				<Redirect to="/dashboard" />
			</Route>
			<Route exact path="/demos" component={Demos} />
			<Route exact path="/dashboard" component={Dashboard} />
			<Route exact path="/profile" component={Profile} />
			<Route exact path="/reports" component={Reports} />
			<Route exact path="/properties" component={Properties} />
			<Route exact path="/accounting-transactions" component={Transactions} />
			<Route exact path="/accounting-income" component={Income} />
			<Route exact path="/exchanges" component={Exchanges} />
			<Route exact path="/add-exchange" component={CreateExchange} />
			<Route exact path="/exchanges/:id" component={ExchangesView} />
			<Route exact path="/people-exchangers" component={Exchangers} />
			<Route exact path="/people-escrow" component={Escrow} />
			<Route exact path="/people-contacts" component={Contacts} />
			<Route exact path="/settings-admin" component={Admins} />
			<Route exact path="/settings-master" component={Settings} />
			<Route exact path="/settings-master/tasks" component={Tasks} />
			<Route exact path="/settings-master/fees" component={Fees} />
			<Route exact path="/settings-master/banks" component={Banks} />
			<Route exact path="/settings-master/template" component={Templates} />
			<Route exact path="/settings-master/entities" component={Entities} />
			<Route exact path="/settings-master/pdf" component={Pdf} />
			<Route exact path="/notifications" component={Notification} />
			<Route render={() => <Redirect to={{ pathname: "/dashboard" }} />} />
		</Switch>
	) : null;
};
