import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { connect } from "react-redux";
import { get } from "lodash";

import {
	getDocument,
	getExchangePropertyAddressList,
	getTemplateList,
	setTemplateFilter,
	showLoader,
	updateDocument,
	shareViaDocusign,
	generateDocument,
	sendEmail,
	updateDocumentStatus,
	getDocusigntoemaillist,
} from "../../../../../store/actions";
import { errorMessage } from "../../../../../utils/errorMessage";
import Prompt from "../../../../SharedComponts/FormElements/Prompt";
import Input from "../../../../SharedComponts/FormControl/Input";

import FirstStage from "./FirstStage";
import { columns as columnsFn } from "./helper";
import SecondStage from "./SecondStage";
import ThirdStage from "./ThirdStage";
import PreviewStage from "./PreviewStage";
import EmailShareSlider from "../EmailShareSlider";
import "./genratedocumemt.scss";
import { successMessage } from "../../../../../utils/successMessage";

const GenerateDocumentWizard = (props) => {
	const {
		templateList,
		doSetFilter,
		documentId,
		filters,
		recordsTotal,
		doShowLoader,
		doGetTemplateList,
		exchangeDetail,
		exchangeAddressList,
		doGetExchangePropertyAddressList,
		doGetDocument,
		doUpdateDocument,
		doShareViaDocusign,
		setShoWGenerateDocumnt,
		doGenerateDocument,
		doSendEmail,
		setDefaultTab,
		doUpdateDocumentStatus,
		doGetDocusigntoemaillist,
	} = props;
	const [selectedTemplate, setSelectedTemplate] = useState({});
	const [stage, setStage] = useState(1);
	const [document, setDocument] = useState(null);
	const [documentDraftHtml, setDocumentDraftHtml] = useState(null);
	const [isHeaderFooterReq, setHeaderFooterReq] = useState(false);
	const [showPrompt, setShowPrompt] = useState(false);
	const [documentDraftName, setdocumentDratName] = useState("");
	const [menu, setMenu] = useState(null);
	const [property, setProperty] = useState(null);
	const [showEmailSlider, setshowEmailSlider] = useState(false);
	const [showConfirmPopup, setshowConfirmPopup] = useState({ show: false });

	const stages = [
		{ label: "Select Template", position: 0, labelPosition: 33 },
		{ label: "Preview Template", position: 33, labelPosition: 40 },
		{ label: "Customize Template", position: 66, labelPosition: 40 },
		{ label: "Save and Share", position: 97, labelPosition: 26 },
	];

	const Point = ({ position, label, active, index, labelPosition }) => {
		const pointRender = () => {
			return (
				<div className={`no-hand point ${active ? "active" : ""}`}>
					{index + 1}
					{!!label && (
						<div className="label" style={labelPosition ? { left: `-${labelPosition}px` } : {}}>
							{label}
						</div>
					)}
				</div>
			);
		};
		return <React.Fragment>{pointRender()}</React.Fragment>;
	};

	useEffect(() => {
		if (document) {
			setdocumentDratName(document.documentName ? document.documentName : "");
			if (document.isHeaderFooterReq) {
				setHeaderFooterReq(true);
			} else {
				setHeaderFooterReq(false);
			}
		}
	}, [document]);

	useEffect(() => {
		if (documentId) {
			doShowLoader(true);

			const successCB = (response) => {
				doShowLoader(false);
				setDocument(response.result);
				setStage(3);
			};
			const FailCB = (result) => {
				errorMessage(result);
				doShowLoader(false);
			};

			doGetDocument(documentId, successCB, FailCB);
		}
	}, [documentId, doGetDocument, doShowLoader]);

	useEffect(() => {
		doShowLoader(true);

		const successCB = (result) => {
			doShowLoader(false);
		};
		const FailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		doGetTemplateList(
			{
				...filters,
				extraParams: {
					exchangeType: exchangeDetail.exchangeType,
					templateStatus: { value: 701, label: "Published" },
				},
			},
			successCB,
			FailCB
		);

		doShowLoader(true);
		doGetExchangePropertyAddressList(
			exchangeDetail.id,
			() => {
				doShowLoader(false);
			},
			() => {
				doShowLoader(false);
			}
		);
	}, [filters, doGetTemplateList, exchangeDetail, doGetExchangePropertyAddressList, doShowLoader]);
	const onChooseTemplate = (row) => {
		setSelectedTemplate(row);
	};

	const onNext = () => {
		if (stage === 1) {
			if (!selectedTemplate || !selectedTemplate.id) {
				errorMessage(501);
				return;
			}
		}
		if (stage === 2) {
			if (selectedTemplate && selectedTemplate.isPropertyAllowed) {
				if (!property) {
					errorMessage(502);
					return;
				}
			}
		}
		if (stage < 4) {
			if (stage === 2) {
				if (document && document.id) {
					submitDocument(() => {
						setStage(stage + 1);
					});
				} else {
					setShowPrompt(true);
				}
			} else if (stage === 3) {
				submitDocument(() => {
					setStage(4);
				});
			} else {
				setStage(stage + 1);
			}
		}
	};
	const onPrevious = () => {
		if (stage !== 1 && stage !== 3) {
			setStage(stage - 1);
		}
	};
	const onPreview = () => {
		setShowPrompt(true);
	};
	const submitDocument = (cb) => {
		doShowLoader(true);
		const successCB = (response) => {
			doShowLoader(false);
			setDocument(response.result);
			cb();
			setShowPrompt(false);
		};
		const FailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
			setShowPrompt(false);
			cb();
		};
		const propertyId = property ? property.value : null;
		doUpdateDocument({ ...document, isHeaderFooterReq, propertyId, documentHTML: documentDraftHtml }, successCB, FailCB);
	};
	const cancelSubmitDocumnt = () => {
		setShowPrompt(false);
		setdocumentDratName(document && document.documentName ? document.documentName : "");
	};

	let progress = 0;
	if (stage === 2) {
		progress = 33;
	} else if (stage === 3) {
		progress = 66;
	} else if (stage === 4) {
		progress = 100;
	}
	const createDocument = () => {
		const successCB = (response) => {
			doShowLoader(false);
			setDocument(response.result);
			setStage(3);
			setShowPrompt(false);
		};
		const FailCB = (result) => {
			doShowLoader(false);
			errorMessage(result);
		};
		if (!documentDraftName || documentDraftName.trim() === "") {
			errorMessage(507);
			return;
		}
		doShowLoader(true);
		doGenerateDocument(
			{
				exchangeId: exchangeDetail.id,
				documentName: documentDraftName,
				templateId: selectedTemplate.id,
				propertyId: property ? property.value : null,
			},
			successCB,
			FailCB
		);
	};
	const docusignShare = () => {
		doShowLoader(true);
		const successCB = (response) => {
			doShowLoader(false);
			if (response.result && response.result.emails.length > 0) {
				setshowConfirmPopup({
					show: true,
					emails: response.result.emails,
					message: `${response.result.docName} will be shared to the following recipients:`,
				});
			} else {
				errorMessage(506);
			}
		};

		const FailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};
		doGetDocusigntoemaillist(document.id, successCB, FailCB);
	};
	const shareViaEmail = () => {
		setshowEmailSlider(true);
	};
	const sendEmail = (values) => {
		doShowLoader(true);
		const successCB = (result) => {
			const onSuccess = (response) => {
				setshowEmailSlider(false);
				doShowLoader(false);
				successMessage(401);
				setDefaultTab("document");
				setShoWGenerateDocumnt(false);
				setDocument(null);
			};
			const onFail = (response) => {
				errorMessage(response);
				doShowLoader(false);
			};

			doUpdateDocumentStatus(
				{
					...document,
					documentStatus: {
						value: 1002,
						label: "Shared via email",
					},
				},
				onSuccess,
				onFail
			);
		};
		const FailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};
		doSendEmail(
			{
				...values,
				objectId: document.id,
				attachments: [{ attachmentUrl: document.documentUrl, name: `${document.documentName}.pdf` }],
			},
			successCB,
			FailCB
		);
	};
	const shareDocument = () => {
		doShowLoader(true);
		const successCB = (response) => {
			doShowLoader(false);
			successMessage(515);
			setDefaultTab("document");
			setShoWGenerateDocumnt(false);
			setDocument(null);
		};
		const FailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};
		doShareViaDocusign(
			{
				documentId: document.id,
				exchangeId: exchangeDetail.id,
			},
			successCB,
			FailCB
		);
	};
	const cancelShare = () => {
		setshowConfirmPopup({ show: false });
	};
	const columns = columnsFn({ onChooseTemplate, selectedTemplate });

	return (
		<>
			<Card className="page-card">
				<div className="time-line progress-stage">
					<div className={`start-img ${stage === 1 || stage === 3 ? "disabled" : ""}`} onClick={onPrevious}>
						<span className="stage-nav mr-1">
							<span className="icon-Left-Arrow"></span>
						</span>
						<span className="stage-nav-text mr-1">Previous</span>
					</div>
					<div className="time-progress" now={progress}>
						<div className="progress-bar" style={{ width: `${progress}%` }} />
						<div className="stage-wrap">
							{stages.map((item, index) => {
								return <Point key={index} index={index} active={stage > index} {...item} />;
							})}
						</div>
					</div>
					<div className={`end-img ${stage === 4 ? "disabled" : ""}`} onClick={onNext}>
						<span className="stage-nav-text ">Next</span>
						<span className="stage-nav ml-1">
							<span className="icon-Right-Arrow"></span>
						</span>
					</div>
				</div>
			</Card>

			{stage === 1 && (
				<FirstStage
					templateList={templateList}
					columns={columns}
					doSetFilter={doSetFilter}
					filters={filters}
					recordsTotal={recordsTotal}
				/>
			)}
			{stage === 2 && (
				<SecondStage
					document={document}
					setProperty={setProperty}
					property={property}
					documentDraftHtml={documentDraftHtml}
					setDocumentDraftHtml={setDocumentDraftHtml}
					setDocument={setDocument}
					exchangeAddressList={exchangeAddressList}
					template={selectedTemplate}
					exchangeDetail={exchangeDetail}
					onPreview={onPreview}
					menu={menu}
					setMenu={setMenu}
				/>
			)}
			{stage === 3 && (
				<ThirdStage
					document={document}
					isHeaderFooterReq={isHeaderFooterReq}
					setHeaderFooterReq={setHeaderFooterReq}
					setProperty={setProperty}
					property={property}
					documentDraftHtml={documentDraftHtml}
					setDocumentDraftHtml={setDocumentDraftHtml}
					setDocument={setDocument}
					exchangeAddressList={exchangeAddressList}
					template={selectedTemplate}
					exchangeDetail={exchangeDetail}
					onPreview={onPreview}
					menu={menu}
					setMenu={setMenu}
					submitDocument={submitDocument}
					docusignShare={docusignShare}
				/>
			)}
			{stage === 4 && (
				<PreviewStage
					document={document}
					exchangeDetail={exchangeDetail}
					docusignShare={docusignShare}
					shareViaEmail={shareViaEmail}
				/>
			)}
			<Card.Body className="info-card-body d-flex flex-row"></Card.Body>
			{showPrompt && (
				<Prompt
					title={"What should the document be named ?"}
					show={showPrompt}
					onYes={createDocument}
					onNo={cancelSubmitDocumnt}
					positiveText="Save"
					negativeText="Cancel">
					<Input
						input={{ onChange: (e) => setdocumentDratName(e.target.value), value: documentDraftName }}
						meta={{}}
					/>
				</Prompt>
			)}
			{showEmailSlider && (
				<EmailShareSlider
					show={showEmailSlider}
					handleClose={() => {
						setshowEmailSlider(false);
					}}
					onSubmit={sendEmail}
					document={document}
				/>
			)}

			{showConfirmPopup.show && (
				<Prompt show={showConfirmPopup.show} title={"Confirm"} onYes={shareDocument} onNo={cancelShare}>
					{showConfirmPopup.message}
					<ol>
						{showConfirmPopup.emails.map((email, index) => (
							<li key={`index`}>{email}</li>
						))}
					</ol>
				</Prompt>
			)}
		</>
	);
};

const mapDispatchToProps = {
	doGetTemplateList: getTemplateList,
	doSetFilter: setTemplateFilter,
	doShowLoader: showLoader,
	doGetExchangePropertyAddressList: getExchangePropertyAddressList,
	doGetDocument: getDocument,
	doUpdateDocument: updateDocument,
	doGenerateDocument: generateDocument,
	doShareViaDocusign: shareViaDocusign,
	doSendEmail: sendEmail,
	doGetDocusigntoemaillist: getDocusigntoemaillist,
	doUpdateDocumentStatus: updateDocumentStatus,
};
const mapStateToProps = (state) => {
	const {
		Template: { templateList: { data: templateList = [], recordsTotal = 0 } = { data: [] }, filters = {} },
	} = state;
	const exchangeAddressList = get(state, "Exchange.exchangeAddressList", []);

	return {
		templateList,
		filters,
		recordsTotal,
		exchangeAddressList,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GenerateDocumentWizard);
