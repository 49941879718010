import { NotificationManager } from "react-notifications";
import { errorCodes } from "../constant/errorCodes";

export const errorMessage = (event, customMessage) => {
	let code = null;
	let messageList = null;

	switch (typeof event) {
		case "number":
		case "string":
			code = event;
			break;
		case "object":
			event && event.result && !event.result.responseValue && (messageList = { ...event });

			code = event && event.error && event.error.code ? event.error.code : null;
			break;
		default:
			const error = event.error || event.result;
			code = error && error.code;
	}

	const delay = 10000;
	if (messageList && !code) {
		return NotificationManager.error(customMessage ? customMessage(event.result) : `Something went wrong!`, "", delay);
	} else if (code || code === 0) {
		const message = errorCodes.filter((i) => i.code === code);
		return NotificationManager.error(
			message[0] ? message[0].message : `Something went wrong!, Please contact technical support with code - ${code}`,
			"",
			delay
		);
	}
	NotificationManager.error("Something went wrong!", "", delay);
};
