import React from "react";
import { Button } from "react-bootstrap";
import CustomScroll from "react-custom-scrollbars";

const SliderForm = (props) => {
	const { submitting, handleClose, handleSubmit, children, hideSubmit } = props;
	return (
		<form className="slider-form" onSubmit={handleSubmit}>
			<CustomScroll style={{ height: "calc(100vh - 125px)" }}>
				<div className="slider-form-fields">{children}</div>
			</CustomScroll>

			{!hideSubmit ? (
				<div className="form-button">
					<Button className="form-button-primary" type="submit" disabled={submitting}>
						Submit
					</Button>

					<Button className="form-button-light mr-2" onClick={handleClose} disabled={submitting}>
						Cancel
					</Button>
				</div>
			) : null}
		</form>
	);
};
export default SliderForm;
