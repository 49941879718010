import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { updateNotification, showLoader, resetField, reloadNotification } from "../../../store/actions";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";
import "./notificationDropdown.scss";

const renderRow = (row, columns) => {
	return columns.map(({ rowWidth, render, className }, index) => {
		const width = rowWidth;
		return (
			<div key={index} className={`table-col ${className ? className : ""}`} style={width ? { width } : {}}>
				{render(row)}
			</div>
		);
	});
};

const notifColumn = [
	{
		width: "100%",
		rowWidth: "100%",
		render: (row) => (
			<>
				<span className="icon-Notification row-icon" />
				<span className="message">{row.message ? row.message : null}</span>
			</>
		),
	},
];

const NotificationDropdown = (props) => {
	const history = useHistory();
	const { notifications, doUpdateNotification, doShowLoader, doReloadNotification, doResetField } = props;
	const [notifMessageList, setnotifMessageList] = useState([]);
	useEffect(() => {
		notifications && setnotifMessageList(notifications);
	}, [notifications]);

	const markAsRead = () => {
		doShowLoader(true);

		const failCB = (e) => {
			doShowLoader(false);
			errorMessage(e);
		};

		const successCB = (e) => {
			doReloadNotification();
			doResetField("Notification.unreadNotifCount", 0);
			doShowLoader(false);
			successMessage(603);
		};

		doUpdateNotification({ isClearAll: true, id: [0] }, successCB, failCB);
	};
	return (
		<>
			<div className="notification-dropdown-menu">
				{notifMessageList && notifMessageList.length ? (
					<div className="clear-notif" onClick={() => markAsRead()}>
						Clear All
					</div>
				) : null}
				<div className="task-table">
					<div className="table-head">
						<div className={`head-item`} style={{ width: "100" }}>
							Notifications
						</div>
					</div>
					{notifMessageList && notifMessageList.length ? (
						<div className="table-body">
							{notifMessageList.map((row, index) => {
								return (
									<div key={index} className={`table-row w-100 ${index % 2 === 0 ? "row-even" : "row-odd"}`}>
										{renderRow(row, notifColumn)}
									</div>
								);
							})}
						</div>
					) : (
						<div className="empty-notif">No notifications to show</div>
					)}
				</div>

				{notifMessageList && notifMessageList.length ? (
					<Button
						className="form-button-secondary float-right mr-3 view-all-btn"
						onClick={() => history.push("/notifications")}>
						View All
					</Button>
				) : null}
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		Notification: { notifications },
	} = state;
	return {
		notifications,
	};
};

const mapDispatchToProps = {
	doUpdateNotification: updateNotification,
	doShowLoader: showLoader,
	doReloadNotification: reloadNotification,
	doResetField: resetField,
};
export default connect(mapStateToProps, mapDispatchToProps)(NotificationDropdown);
