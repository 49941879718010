import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { get } from "lodash";

import Layout from "../../SharedComponts/Layout";
import { Table, Pagination } from "../../SharedComponts/FormElements/table";
import { columns as columnsFn } from "./helper";
import {
	setAdminFilter,
	getUsers,
	showLoader,
	createUser,
	getUser,
	updateUser,
	deleteUser,
	getEntityByType,
	sendPasswordresetmail,
} from "../../../store/actions";

import Confirm from "../../SharedComponts/FormElements/confirm";
import SearchField from "../../SharedComponts/FormElements/searchTable";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";
import { userTypes } from "../../../constant/common";
import { getRoleID, getRole, getStatus } from "../../../utils/helper";
import AdminDetails from "./AdminDetails";
import AddEdit from "./AddEdit";

import "../admin.scss";
import "../userdetail.scss";

const AdminList = ({
	doSetFilter,
	filters,
	history,
	userDetails,
	doGetUsers,
	recordsTotal,
	userList,
	doShowLoader,
	doCreateUser,
	doGetUser,
	doUpdateUser,
	doDeleteUser,
	doGetEntityByType,
	doSendPasswordresetmail,
	cardTotalCount,
}) => {
	const [isList, setIsList] = useState(true);
	const [showPopup, setshowPopup] = useState(false);
	const [isEdit, setIsEdit] = useState(true);
	const [currentRecord, setCurrentRecord] = useState({});
	const [listReload, setListReload] = useState(false);
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [currentDeleteRecord, setCurrentDeleteRecord] = useState(null);
	const [companyList, setCompanyList] = useState({});

	const handleClose = () => setshowPopup(false);
	const handleShow = () => setshowPopup(true);
	const handleSubmit = async (value) => {
		doShowLoader(true);
		const successCB = (result) => {
			doShowLoader(false);
			successMessage(!isEdit ? 201 : 202);
			setListReload(!listReload);
			handleClose();
			!isEdit && setIsList(true);
			!isList && onSelectUser(currentRecord);
		};
		const failCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		const postData = {
			...value,
			userRoleId: !isEdit ? getRole("Admin") : currentRecord.userRoleId,
			userName: !isEdit ? value.emailAddress : currentRecord.userName,
			isActive: !isEdit ? true : currentRecord.isActive,
			status: !isEdit ? getStatus("Active") : value.status,
		};
		currentRecord.id && isEdit && doUpdateUser(postData, successCB, failCB);
		(!currentRecord.id || !isEdit) && doCreateUser({ ...postData }, successCB, failCB);
	};

	useEffect(() => {
		doShowLoader(true);
		const backendFilter = { ...filters, "ExtraParams.RoleId": getRoleID("Admin") };
		const successCB = (result) => {
			doShowLoader(false);
		};
		const failCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		userDetails.id && doGetUsers(backendFilter, successCB, failCB);
	}, [filters, userDetails, doGetUsers, doShowLoader, listReload]);

	useEffect(() => {
		const successCB = (e) => {
			setCompanyList(e.result);
		};

		doGetEntityByType({ typeId: 229, isInactiveReq: "false" }, successCB);
	}, [doGetEntityByType]);

	const loggedInAs = (type) => {
		return userDetails.userType === userTypes[type];
	};

	const onSelectUser = (row) => {
		doShowLoader(true);
		const successCB = (e) => {
			setCurrentRecord(e.result);
			doShowLoader(false);
			setIsList(false);
		};
		const failCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		doGetUser(row.id, successCB, failCB);
	};
	const onEditUser = (row) => {
		doShowLoader(true);
		const successCB = (e) => {
			setIsEdit(true);
			setCurrentRecord(e.result);
			doShowLoader(false);
			handleShow();
		};
		const failCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		doGetUser(row.id, successCB, failCB);
	};

	const onDeleteUser = (row) => {
		setCurrentDeleteRecord(row.id);
		setConfirmDelete(true);
	};

	const confirmDeleteAction = () => {
		doShowLoader(true);
		const failCB = (result) => {
			doShowLoader(false);
			setCurrentDeleteRecord(null);
			setConfirmDelete(false);
			errorMessage(result);
		};
		const successCB = (result) => {
			doShowLoader(false);
			setCurrentDeleteRecord(null);
			setConfirmDelete(false);
			successMessage(205);
			setListReload(!listReload);
		};
		doDeleteUser(currentDeleteRecord, successCB, failCB);
	};
	const sendActivationLink = (user) => {
		doShowLoader(true);
		const failCB = (result) => {
			doShowLoader(false);
			errorMessage(result);
		};
		const successCB = (result) => {
			doShowLoader(false);
			successMessage(514);
		};
		doSendPasswordresetmail(user.id, successCB, failCB);
	};

	const addUser = () => {
		setIsEdit(false);
		setCurrentRecord({});
		handleShow();
	};

	const rightActionButtons = [
		{
			component: () => {
				return (
					<Button className="form-button-primary" onClick={addUser}>
						<span className="add-new-plus">+</span> New User
					</Button>
				);
			},
		},
	];
	const columns = columnsFn({
		onSelectUser,
		onEditUser,
		onDeleteUser,
		sendActivationLink,
	});
	return loggedInAs("SA") ? (
		<>
			{isList ? (
				<Layout
					title="Users"
					breadcrumbs={[{ label: "Users" }]}
					rightActionButtons={rightActionButtons}
					className="user-list user-listing-page">
					<>
						<SearchField
							initialValue={filters.searchKey}
							onSearch={(searchKey) =>
								doSetFilter({
									...filters,
									searchKey,
									pageIndex: 1,
								})
							}
							className={`flex-fill mb-0`}
							placeholder="Enter your query"
						/>
						<Table
							columns={columns}
							total={cardTotalCount}
							data={userList}
							onSort={(e) =>
								e.by !== filters.sortColumn || e.order !== filters.sortDirection
									? doSetFilter({
											...filters,
											sortColumn: e.by,
											sortDirection: e.order,
											pageIndex: 1,
									  })
									: null
							}
							sortBy={filters.sortColumn}
							sortDir={filters.sortDirection}
						/>
						<Pagination
							total={recordsTotal}
							count={filters.pageSize}
							page={filters.pageIndex}
							onChange={(e) => {
								doSetFilter({ ...filters, pageIndex: e });
							}}
							onPageCountChange={(e) => {
								doSetFilter({ ...filters, pageSize: e, pageIndex: 1 });
							}}
						/>
					</>
				</Layout>
			) : (
				<AdminDetails
					user={currentRecord}
					breadcrumbs={[
						{
							label: "Users",
							onClick: () => {
								setIsList(true);
							},
						},
						{ label: "User Details" },
					]}
					initialValues={currentRecord}
					setIsList={setIsList}
					showEdit={handleShow}
					setIsEdit={setIsEdit}
				/>
			)}
			{showPopup ? (
				<AddEdit
					show={showPopup}
					initialValues={currentRecord}
					isEdit={isEdit}
					handleClose={handleClose}
					onSubmit={handleSubmit}
					companyList={companyList}
				/>
			) : null}
			<Confirm
				onYes={confirmDeleteAction}
				onNo={(e) => {
					setConfirmDelete(false);
				}}
				show={confirmDelete ? true : false}
			/>
		</>
	) : null;
};

const mapStateToProps = (state) => {
	return {
		userList: get(state, "User.userList.data", null),
		filters: get(state, "User.adminFilter", {}),
		userDetails: get(state, "Auth.userDetails", {}),
		recordsTotal: get(state, "User.userList.recordsTotal", 0),
		cardTotalCount: get(state, "User.userList.additionalParam.totalCount", 0),
	};
};
const mapDispatchToProps = {
	doSetFilter: setAdminFilter,
	doGetUsers: getUsers,
	doShowLoader: showLoader,
	doCreateUser: createUser,
	doGetUser: getUser,
	doUpdateUser: updateUser,
	doDeleteUser: deleteUser,
	doGetEntityByType: getEntityByType,
	doSendPasswordresetmail: sendPasswordresetmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminList);
