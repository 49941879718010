import React, { useState } from "react";
import { Popover, OverlayTrigger, Accordion, Card, useAccordionToggle } from "react-bootstrap";
import InfoItem from "./InfoItem";

function CustomToggle({ children, eventKey, onClick }) {
	const decoratedOnClick = useAccordionToggle(eventKey, () => {
		return onClick ? onClick(eventKey) : null;
	});

	return (
		<div className="listing-down-arrow" onClick={decoratedOnClick}>
			{children}
		</div>
	);
}
export const MoreOptions = ({ options }) => {
	const [show, setShow] = useState(false);

	return (
		<OverlayTrigger
			trigger="click"
			placement="top"
			className="sidemenu-dropdown"
			rootClose={true}
			show={show}
			onToggle={() => {
				setShow(!show);
			}}
			overlay={
				<Popover id="popover-positioned-bottom" className={`more-option-popover`}>
					<Popover.Content>
						<div className="more-option-list">
							{options.map(({ hide, label, onClick }, index) =>
								hide ? null : (
									<div
										key={index}
										className="more-option-item"
										onClick={() => {
											onClick();
											setShow(false);
										}}>
										{label}
									</div>
								)
							)}
						</div>
					</Popover.Content>
				</Popover>
			}>
			<div className="listing-actions">
				<span className={`icon-more`}></span>
			</div>
		</OverlayTrigger>
	);
};

export const AccordionSlider = ({
	title,
	children,
	eventKey,
	isActive,
	subTitle = "",
	content,
	index,
	onClick,
	onDelete = null,
}) => {
	return (
		<Card className={`${!isActive && "accordian-inactive"}`}>
			<Card.Header>
				<Card.Title className={`info-card-title`}>
					<div className="d-flex align-items-center">
						<div>
							<span className="h2 title">{`${title} `}</span>
							{subTitle && <span className="sub-title mb-0 ml-1">{` : ${subTitle}`}</span>}
						</div>
					</div>
					<div className="d-flex">
						{onDelete && (
							<div className="listing-down-arrow" onClick={onDelete}>
								<span className={`icon-icon-delete`}></span>
							</div>
						)}
						<CustomToggle onClick={onClick} eventKey={eventKey}>
							<span className={`icon-down-arrow`}></span>
						</CustomToggle>
					</div>
				</Card.Title>
			</Card.Header>
			<Accordion.Collapse eventKey={eventKey}>
				<Card.Body>
					{content}
					{children}
				</Card.Body>
			</Accordion.Collapse>
		</Card>
	);
};

export const NoteButton = ({ onClick }) => {
	return (
		<div className="form-group position-fixed float-button" onClick={() => (onClick ? onClick() : null)}>
			<div className="note-button">
				<span className={`icon-notes`}></span>
			</div>
		</div>
	);
};

export const Heading = ({ className, title }) => (
	<div className={`${className ? className : ""} heading`}>
		<h2>{title}</h2>
		<hr className="heading-hr" />
	</div>
);
export const SubHeading = ({ className, title }) => (
	<div className={className ? className : "sub-heading"}>
		<h3>{title}</h3>
		<hr className="sub-heading-hr" />
	</div>
);

export const SubSectionHeading = ({ className, title }) => (
	<div className={className ? className : "sub-section-heading"}>
		<h3>{title}</h3>
		<hr className="sub-section-heading-hr" />
	</div>
);

export const InfoButton = ({ label, value }) => {
	return (
		<div className="info-button">
			<span className="label">{label}</span>
			<span className="value">{value}</span>
		</div>
	);
};
export { InfoItem };
