import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import SignIn from "../components/session/signIn";
import ForgotPassword from "../components/session/forgotPassword";
import ResetPassword from "../components/session/resetPassword";
import otpAuth from "../components/session/otpAuth";



export const SessionRouter = ({ location }) => (
	<div className="session-content-loader">
		<div className="session-bg-gradient"></div>
		<Switch location={location}>
			<Route exact path="/signin" component={SignIn} />
			<Route exact path="/authentication" component={otpAuth}/>
			<Route exact path="/forgot-password" component={ForgotPassword} />
			<Route exact path="/resetPassword" component={(props) => <ResetPassword {...props} set={true} />} />
			<Route exact path="/confirmEmail" component={(props) => <ResetPassword {...props} set={false} />} />
			<Redirect to="/signin" />
		</Switch>
	</div>
);
