import React, { useEffect, useRef } from "react";
import Chart from "chart.js";

const DoughnutChart = (props) => {
  const piechartRef = useRef();
  const chartInstance = useRef(null);
  const data = props.data;

  useEffect(() => {
    const { current } = piechartRef;

    if (chartInstance.current) {
      chartInstance.current.data = data;
      chartInstance.current.update();
    } else {
      chartInstance.current = new Chart(current, {
        type: "doughnut",
        options: {
          maintainAspectRatio: true,
          cutoutPercentage: 75,
          elements: {
            arc: {
              borderWidth: 0,
            },
          },
          responsive: false,
          aspectRatio: 2.7,
          legend: {
            display: true,
            position: "left",
            align: "middle",
            labels: {
              boxWidth: 12,
              fontStyle: "normal",
              fontSize: 12,
              paddingRight: 0,
              margin: 0,
            },
          },
          tooltips: {
            enabled: true,
            bodyFontSize: 11,
          },
        },
        data,
      });
    }
  }, [data]);

  return <canvas ref={piechartRef} />;
};

export default DoughnutChart;
