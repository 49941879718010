import React from "react";
import { dateFormat } from "../constant/common";

const currencyFormatter = require("currency-formatter");
const moment = require("moment");

export const Currency = ({ amount, currency = "USD" }) => {
	return (amount || amount === 0) && !isNaN(amount) && amount !== "" ? (
		<span>
			{currencyFormatter.format(amount, {
				code: currency,
				precision: 2,
			})}
		</span>
	) : (
		"---"
	);
};

export const CurrencyK = ({ amount = 0, currency = "USD" }) => {
	return (
		<span>
			{currencyFormatter.format(Math.trunc(amount) / 1000, {
				code: currency,
				precision: 0,
			}) + "K"}
		</span>
	);
};
export const CurrencyKMB = ({ amount = 0, currency = "USD" }) => {
	let shortAmount = Number(amount);
	let postFix = "K";
	if (Math.trunc(amount / 1000000000)) {
		shortAmount = amount / 1000000000;
		postFix = "B";
	} else if (Math.trunc(amount / 1000000)) {
		shortAmount = amount / 1000000;
		postFix = "M";
	} else if (Math.trunc(amount / 1000)) {
		shortAmount = amount / 1000;
	} else {
		postFix = "";
		shortAmount = amount;
	}
	return (
		<span>
			{postFix === "B"
				? `${currencyFormatter.format(shortAmount, {
						code: currency,
						precision: 1,
				  })}${postFix}`
				: `$${
						shortAmount.toFixed(1) % 1 === 0 ? Math.trunc(shortAmount.toFixed(1)) : shortAmount.toFixed(1)
				  }${postFix}`}
		</span>
	);
};

export const Date = ({ utcTime, format = dateFormat, noConvert = false }) => {
	utcTime = utcTime && !noConvert ? moment.utc(utcTime).toDate() : utcTime;
	return noConvert ? (
		utcTime ? (
			<span> {moment(utcTime).format("MM/DD/yyyy")}</span>
		) : (
			"---"
		)
	) : utcTime ? (
		<span>{moment(utcTime).isValid() || !utcTime ? moment(utcTime).format(format).toLocaleString() : { format }}</span>
	) : (
		"---"
	);
};

export const Time = ({ utcTime, format = dateFormat, noConvert = false }) => {
	utcTime = utcTime && !noConvert ? moment.utc(utcTime).toLocaleString() : utcTime;
	return noConvert ? (
		utcTime ? (
			<span> {moment(utcTime).format("MM/DD/yyyy")}</span>
		) : (
			"---"
		)
	) : utcTime ? (
		<span>
			{moment(utcTime).isValid() || !utcTime ? moment(utcTime).format("MM/DD/yyyy hh:mm A").toLocaleString() : { format }}
		</span>
	) : (
		"---"
	);
};

export const TimeIST = ({ utcTime, format = dateFormat, noConvert = false }) => {
	utcTime = utcTime && !noConvert ? moment.utc(utcTime).toLocaleString() : utcTime;
	return (
		<span>
			{moment(utcTime).isValid() || !utcTime ? moment(utcTime).format("MM/DD/yyyy hh:mm A").toLocaleString() : { format }}
		</span>
	);
};

export const TimelineHours = ({ utcTime }) => {
	if (utcTime) {
		const formattedDate = moment.utc(utcTime.split(".")[0]).local();
		const duration = moment.duration(moment().diff(formattedDate));
		if (duration.days()) {
			return `${duration.days()} days ago`;
		} else if (duration.hours()) {
			return `${duration.hours()} hours ago`;
		} else if (duration.minutes()) {
			return `${duration.minutes()} minutes ago`;
		} else {
			return "Now";
		}
	} else {
		return "---";
	}
};
