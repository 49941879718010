import React from "react";

import { MoreOptions } from "../../SharedComponts/FormElements";
import { Date } from "../../../utils/formatter";
import { dateFormat, userStatus } from "../../../constant/common";

import FormControl from "../../SharedComponts/FormControl";
import { required, email, USPhoneNumber, normalizePhone } from "../../../constant/formValidators";

export const columns = ({ onSelectUser, onEditUser = null, onDeleteUser = null }) => [
	{
		name: "Escrow Name",
		accessor: "displayName",
		className: "col-EscrowName",
		headerClassName: "col-EscrowName-header",
		render: (row) => (
			<div className="text-highlighted" onClick={() => onSelectUser(row)}>
				{row.displayName}
			</div>
		),
	},
	{
		name: "Settlement Company",
		accessor: "companyName",
		className: "col-settlementCompany",
		headerClassName: "col-settlementCompany-header",
	},
	{
		name: "Phone",
		accessor: "phoneNumber",
		className: "col-EscrowPhone",
		headerClassName: "col-EscrowPhone-header",
	},
	{
		name: "Email",
		accessor: "emailAddress",
		className: "col-EscrowemailAddress",
		headerClassName: "col-EscrowemailAddress-header",
	},
	{
		name: "Created Date",
		accessor: "creationTime",
		className: "col-EscrowcreationTime",
		headerClassName: "col-EscrowcreationTime-header",
		render: (row) => <Date utcTime={row.creationTime} format={dateFormat} />,
	},
	{
		name: "Actions",
		className: "col-actions text-right",
		headerClassName: "col-actions-header",
		sortable: false,
		render: (row) => {
			return (
				<MoreOptions
					options={[
						{ label: "Edit", onClick: () => onEditUser(row) },
						{ label: "Delete", onClick: () => onDeleteUser(row) },
					]}
				/>
			);
		},
	},
];

export const formFields = (settlementCompanies, isEdit) => {
	return [
		{
			name: "name",
			label: "Escrow Name",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			validate: [required],
			required: true,
		},
		{
			name: "company",
			label: "Settlement Company",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			options: settlementCompanies,
			validate: [required],
			required: true,
		},
		{
			name: "emailAddress",
			label: "Email Address",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			validate: [email, required],
			required: true,
		},
		{
			name: "phoneNumber",
			label: "Phone",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			validate: [USPhoneNumber],
			normalize: normalizePhone,
		},
		{
			name: "status",
			label: "Status",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			options: userStatus,
			hideField: !isEdit,
		},
	];
};

export const detailColumnFn = (history) => [
	{
		name: "Exchange Number",
		accessor: "exchangeNumber",
		render: (row) => (
			<div
				onClick={() => {
					history.push(`/exchanges/${row.guid}`);
				}}
				className="text-highlighted">
				{row.exchangeNumber}
			</div>
		),
		className: "exchange-no",
		width: "14%",
	},
	{
		name: "Escrow Number",
		accessor: "escrowNumber",
		width: "14%",
		render: (row) => <>{row.escrowNumber}</>,
	},
	{
		name: "Exchange Type",
		accessor: "exchangeType.label",
		width: "10%",
		render: (row) => <>{row.exchangeType.label}</>,
	},
	{
		name: "Status",
		accessor: "type",
		render: (row) => <span className={`status-card status-${row.exchangeStatus.label}`}>{row.exchangeStatus.label}</span>,
		width: "10%",
	},
	{
		name: "Open Date",
		accessor: "openDate",
		render: (row) => <Date utcTime={row.openDate} format={dateFormat} />,
		width: "12%",
	},
	{
		name: "45th Day",
		accessor: "day45",
		render: (row) => <Date utcTime={row.day45} format={dateFormat} />,
		width: "12%",
	},
	{
		name: "180th Day",
		accessor: "day180",
		render: (row) => <Date utcTime={row.day180} format={dateFormat} />,
		width: "12%",
	},
	{
		name: "Close date",
		accessor: "finalCloseDate",
		render: (row) => <Date utcTime={row.finalCloseDate} format={dateFormat} />,
	},
];
