import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { showLoader } from "../../../../store/actions";

const InfoItem = ({ label, className, required, change, ...model }) => {
	return (
		<div className={`info-item ${className}`}>
			<div className="value">
				{model.type === "date" ? (
					<Field
						onClear={() => {
							change(model.name, null);
						}}
						{...model}
					/>
				) : (
					<Field {...model} />
				)}
			</div>
		</div>
	);
};

const InlineForm = (props) => {
	const { columns, data, change } = props;

	return (
		<form className="w-100">
			<div className="row mr-0 ml-0 w-100">
				{columns.map((item, index) => {
					const { className, width, render, accessor } = item;
					return item.formField ? (
						<div
							key={index}
							className={`table-col ${className ? className : ""}`}
							style={item.width ? { width } : {}}>
							<InfoItem change={change} key={index} {...item} />
						</div>
					) : render ? (
						<div
							key={index}
							className={`table-col ${className ? className : ""}`}
							style={item.width ? { width } : {}}>
							{render(data)}
						</div>
					) : (
						<div key={index} className={`table-col ${className ? className : ""}`} style={width ? { width } : {}}>
							{data[accessor] ? data[accessor] : "-"}
						</div>
					);
				})}
			</div>
		</form>
	);
};

const mapDispatchToProps = {
	doShowLoader: showLoader,
};

export default connect(
	null,
	mapDispatchToProps
)(
	reduxForm({
		form: "TaskInlineForm",
		enableReinitialize: true,
	})(InlineForm)
);
