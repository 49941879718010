import React from "react";

import { Card } from "react-bootstrap";
import Layout from "../../SharedComponts/Layout";
import { Heading } from "../../SharedComponts/FormElements";
import UserInfoTable from "../../SharedComponts/FormElements/UserInfoTable";
import { detailColumnFn, rowHeadItems } from "./helper";

// import { errorMessage } from "../../../utils/errorMessage";
import { useHistory } from "react-router-dom";

const EnityDetails = (props) => {
	const { showEdit, breadcrumbs, entity, setIsEdit } = props;
	const {
		entityName,
		companyType: { label: companyType, value: companyTypeCode } = { label: "", value: "" },
		address,
		exchangerDetail: { exchangeDtoList },
		status: { label: status },
		taxId,
	} = entity;
	const { streetAddress = "", city, state, zipCode = "" } = address || {
		streetAddress: "",
		city: { label: "" },
		state: { label: "" },
		zipCode: "",
	};
	const rowhead = rowHeadItems(companyTypeCode);
	const InfoItem = ({ label, value }) => {
		return (
			<div className="info-item col-3">
				<div className="label">{label} </div>
				<div className="value"> {value ? value : "-"}</div>
			</div>
		);
	};
	const history = useHistory();
	const detailColumn = detailColumnFn(history);

	return (
		<Layout breadcrumbs={breadcrumbs} className="user-details admin-details" title={`Profile`}>
			<Card>
				<Card.Title className="info-card-title">
					<Heading title="Basic Information" />
					<div className="user-action-icons">
						<span
							className="user-action-icon"
							onClick={() => {
								setIsEdit(true);
								showEdit();
							}}>
							<span className="icon-icon-edit" />
						</span>
					</div>
				</Card.Title>
				<Card.Body className="info-card-body">
					<div className="user-info-wrap form-row">
						<InfoItem label="Entity Name" value={entityName} />
						<InfoItem label="Entity Type" value={companyType} />
						<InfoItem label="Address" value={streetAddress} />
						<InfoItem label="City" value={city?.label} />
						<InfoItem label="State" value={state?.label} />
						<InfoItem label="Status" value={status} />
						<InfoItem label="Tax-id" value={taxId} />
						<InfoItem label="Zip code" value={zipCode} />
					</div>
				</Card.Body>
			</Card>

			{exchangeDtoList && exchangeDtoList.length ? (
				<Card>
					<Card.Title className="info-card-title">
						<Heading title="Related Exchanges" />
					</Card.Title>
					<Card.Body className="info-card-body">
						<UserInfoTable
							rowHeadItems={rowhead}
							columns={detailColumn}
							data={exchangeDtoList}
							dataAccessor="exchangeDtoList"
						/>
					</Card.Body>
				</Card>
			) : null}
		</Layout>
	);
};

export default EnityDetails;
