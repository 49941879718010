import { userRoles, userStatus, exchangerTypes } from "../constant/common";
import moment from "moment";

export const logout = () => {
	const LSVariable = process.env.REACT_APP_LS_VAR;
	localStorage.removeItem(LSVariable);
	window.location.reload();
};
export const toBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});
export const colors = [
	"#f44336",
	"#9C27B0",
	"#3F51B5",
	"#03A9F4",
	"#009688",
	"#8BC34A",
	"#CDDC39",
	"#FFEB3B",
	"#FFC107",
	"#FF9800",
	"#FF5722",
	"#795548",
	"#9E9E9E",
	"#607D8B",
	"#E91E63",
	"#673AB7",
	"#2196F3",
	"#00BCD4",
	"#4CAF50",
];

export const getUserRole = (user) => {
	return userRoles.filter((item) => item.value === user.userRoleId.value);
};

export const getRoleID = (roleName) => {
	return userRoles.filter((item) => item.label === roleName)[0]["value"];
};

export const getRoleIDs = (roleNames) => {
	return userRoles.filter((item) => roleNames.includes(item.label));
};

export const getRole = (roleName) => {
	return userRoles.filter((item) => item.label === roleName)[0];
};

export const getStatus = (status) => {
	return userStatus.filter((item) => item.label === status)[0];
};

export const getExchangerTypeId = (name) => {
	return exchangerTypes.filter((item) => item.label === name)[0]["value"];
};

export const serialize = (obj) => {
	const str = [];
	for (const p in obj) {
		if (obj[p]) {
			obj[p] !== null && str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
		}
	}
	return str.join("&");
};
export const getUtcDateFromDatePicker = (value) => {
	if (value == null) {
		return null;
	}
	//const utc = new Date(value.getFullYear(), value.getMonth(), value.getDate());
	//utc.setHours(0, 0, 0, 0);
	//return moment(value, "YYYY-MM-DDTHH:mm").format();
	return moment(value).utc().format("YYYY-MM-DDTHH:mm:ss.SSS");
};
export const getDateFromDatePicker = (value) => {
	if (value == null) {
		return null;
	}
	//const utc = new Date(value.getFullYear(), value.getMonth(), value.getDate());
	//utc.setHours(0, 0, 0, 0);
	//return moment(value, "YYYY-MM-DDTHH:mm").format();
	return moment(value).format("YYYY-MM-DDT00:00:00.000");
};
export const getDatePickerDateFromtDate = (value) => {
	if (value == null) {
		return null;
	}
	return new Date(value);
};
export const formatDateToISOString = (dateString) => {
	const date = new Date(dateString);
	return date.toISOString();
};
export const getDatePickerDateFromtUtcDate = (value) => {
	if (value == null) {
		return null;
	}
	return moment.utc(value).toDate();
};

export const getSufixOfNumber = (i) => {
	const j = i % 10;
	const k = i % 100;
	if (j === 1 && k !== 11) {
		return i + "st";
	}
	if (j === 2 && k !== 12) {
		return i + "nd";
	}
	if (j === 3 && k !== 13) {
		return i + "rd";
	}
	return i + "th";
};

export const dataURItoBlob = (dataURI) => {
	// convert base64 to raw binary data held in a string
	// doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
	const byteString = atob(dataURI.split(",")[1]);
	// separate out the mime component
	const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
	// write the bytes of the string to an ArrayBuffer
	const ab = new ArrayBuffer(byteString.length);
	// create a view into the buffer
	const ia = new Uint8Array(ab);
	// set the bytes of the buffer to the correct values
	for (let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}
	// write the ArrayBuffer to a blob, and you're done
	const blob = new Blob([ab], { type: mimeString });
	return blob;
};

export const downloadFile = (data, fileName = "report") => {
	const a = document.createElement("a");
	document.body.appendChild(a);
	a.style = "display: none";
	const url = window.URL.createObjectURL(dataURItoBlob(data));
	a.href = url;
	a.download = `${fileName}_${moment(new Date()).format("MM/DD/YYYYTHH:mm:ss")}.xlsx`;
	a.click();
	window.URL.revokeObjectURL(url);
};

export const downloadBase64PdfFile = (data, fileName = "report") => {
	const a = document.createElement("a");
	document.body.appendChild(a);
	a.style = "display: none";
	a.href = `data:application/pdf;base64,${data}`;
	a.target = "_blank";
	a.download = `${fileName}_${moment(new Date()).format("MM/DD/YYYYTHH:mm:ss")}.pdf`;
	a.click();
};

export const downloadPdfFile = (dataURL, fileName = "report") => {
	fetch(dataURL, {
		method: "GET",
	})
		.then((response) => {
			return response.blob();
		})
		.then((blob) => {
			const url = URL.createObjectURL(blob);
			const a = document.createElement("a");
			a.style.display = "none";
			a.href = url;
			a.download = `${fileName}_${moment(new Date()).format("MM/DD/YYYYTHH:mm:ss")}.pdf`;
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(url);
		});
};

export const getCopy = (data) => {
	return JSON.parse(JSON.stringify(data));
};
export const getTimeZoneOffset = () => {
	const d = new Date();
	return d.getTimezoneOffset();
};

export const getLsWithExpiry = (key) => {
	const itemStr = localStorage.getItem(key);

	// if the item doesn't exist, return null
	if (!itemStr) {
		return null;
	}

	const item = JSON.parse(itemStr);
	const now = new Date();

	// compare the expiry time of the item with the current time
	if (now.getTime() > item.expiry) {
		// If the item is expired, delete the item from storage
		// and return null
		localStorage.removeItem(key);
		return null;
	}
	return itemStr;
};
