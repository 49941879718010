import React from "react";
import { Button, Card } from "react-bootstrap";
import { connect } from "react-redux";
import PdfViewer from "../../../../SharedComponts/PdfViewer";
import { createAdditionalSign, getSignatureMenu, showLoader, generateDocument } from "../../../../../store/actions";
// import Select from "react-select";

const SecondStage = (props) => {
	const { document, docusignShare, shareViaEmail, exchangeDetail } = props;

	return (
		<>
			<Card>
				<div className="col-12 editor-head mb-2">
					<div className="head-item">
						<span className="label">Document Name :</span>
						<span className="value"> {document?.documentName}</span>
					</div>
					<div className="head-item">
						<span className="label">Exchange Type :</span>
						<span className="value"> {exchangeDetail?.exchangeType?.label}</span>
					</div>
				</div>
				<PdfViewer pdf={document.documentUrl} showPagination={true} />
				<div className="mb-4 mt-4 d-flex justify-content-end">
					<Button className="form-button-primary" onClick={docusignShare}>
						Share Via Docusign
					</Button>
					<Button className="form-button-primary ml-2" onClick={shareViaEmail}>
						Share Via Email
					</Button>
				</div>
			</Card>
		</>
	);
};

const mapDispatchToProps = {
	doGetSignatureMenu: getSignatureMenu,
	doCreateAdditionalSign: createAdditionalSign,
	doShowLoader: showLoader,
	doGenerateDocument: generateDocument,
};
const mapStateToProps = (state) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SecondStage);
