import FormControl from "../../../SharedComponts/FormControl";
import {
	email,
	normalizePhone,
	normalizeSSN,
	required,
	USPhoneNumber,
	USZipCode,
	percent,
	normalizeNumber,
	maxLength,
	USTaxid,
} from "../../../../constant/formValidators";
import { getRoleID } from "../../../../utils/helper";

export const buyerAddressForm = (
	{ buyer, saveProperty, states, doShowLoader, cities, doGetCities, change, isLocked = false },
	isEdit = true,
	isEditable = !isLocked
) => {
	return [
		{
			name: `${buyer}.address.streetAddress`,
			accessor: "address.streetAddress",
			label: "Address",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			onBlur: () => saveProperty(Number(buyer.split("[")[1].split("]")[0])),
			isEdit,
			isEditable,
		},
		{
			name: `${buyer}.address.state`,
			accessor: "address.state",
			label: "State",
			component: FormControl,
			type: "select",
			options: states,
			className: "form-control form-field",
			onChange: (selected) => {
				doShowLoader(true);
				doGetCities(selected.value, () => {
					doShowLoader(false);
				});
				change(`$${buyer}.address.city`, null);
			},
			isEdit,
			isEditable,
		},
		{
			name: `${buyer}.address.city`,
			accessor: `address.city`,
			label: "City",
			options: cities,
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			onChange: () => saveProperty(Number(buyer.split("[")[1].split("]")[0])),
			isEdit,
			isEditable,
		},
		{
			name: `${buyer}.address.zipCode`,
			accessor: "address.zipCode",
			label: "Zip Code",
			component: FormControl,
			type: "text",
			validate: [USZipCode],
			className: "form-control form-field",
			onBlur: () => saveProperty(Number(buyer.split("[")[1].split("]")[0])),
			isEdit,
			isEditable,
		},
	];
};
export const buyerBasicForm = (
	{ buyer, saveProperty, change, loadUsers, popupBuyerDetails, isLocked = false },
	isEditable = !isLocked
) => {
	return [
		{
			name: `${buyer}.dealer`,
			accessor: `dealer.label`,
			label: "Buyer Name",
			component: FormControl,
			type: "asyncCreatableSelect",
			className: "form-control form-field",
			loadOptions: (searchKey, callback) => loadUsers(getRoleID("Buyer"), searchKey, callback),
			onChange: (selected) => {
				if (selected.label !== selected.value) {
					change(`${buyer}.dealerTitle`, selected.title);
					popupBuyerDetails(buyer, selected.value);
				} else if (selected) {
					change(`${buyer}.dealerTitle`, null);
					change(`${buyer}.phoneNumber`, null);
					change(`${buyer}.fax`, null);
					change(`${buyer}.emailAddress`, null);
					change(`${buyer}.ssnOrTaxId`, null);
				}
				saveProperty(Number(buyer.split("[")[1].split("]")[0]));
			},
			isEdit: true,
			isEditable,
		},
		{
			name: `${buyer}.dealerTitle`,
			accessor: `dealerTitle`,
			label: "Buyer Title",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			onBlur: () => saveProperty(Number(buyer.split("[")[1].split("]")[0])),
			isEdit: true,
			isEditable,
		},
		{
			name: `${buyer}.dealerType`,
			accessor: `dealerType`,
			label: "Buyer Type",
			component: FormControl,
			type: "select",
			options: [
				{ label: "Individual", value: 400 },
				{ label: "Company", value: 401 },
				{ value: 402, label: "Trust or Other" },
			],
			onChange: () => saveProperty(Number(buyer.split("[")[1].split("]")[0])),
			className: "form-control form-field",
			isEdit: true,
			isEditable,
		},
		{
			name: `${buyer}.ssnOrTaxId`,
			accessor: `ssnOrTaxId`,
			label: "SSN /Tax Id",
			component: FormControl,
			normalize: normalizeSSN,
			type: "text",
			className: "form-control form-field",
			onBlur: () => saveProperty(Number(buyer.split("[")[1].split("]")[0])),
			isEdit: true,
			isEditable,
		},
		{
			name: `${buyer}.dealerBy`,
			accessor: `dealerBy`,
			label: "Buyer By",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			onBlur: () => saveProperty(Number(buyer.split("[")[1].split("]")[0])),
			isEdit: true,
			isEditable,
		},
		{
			name: `${buyer}.spouseOrVesting`,
			accessor: `spouseOrVesting`,
			label: "Spouse/Vesting",
			component: FormControl,
			type: "text",
			onBlur: () => saveProperty(Number(buyer.split("[")[1].split("]")[0])),
			className: "form-control form-field",
			isEdit: true,
			isEditable,
		},
		{
			name: `${buyer}.percentageInterest`,
			accessor: `percentageInterest`,
			label: "Interest %",
			component: FormControl,
			onBlur: () => saveProperty(Number(buyer.split("[")[1].split("]")[0])),
			type: "text",
			className: "form-control form-field",
			isEdit: true,
			isEditable,
			normalize: normalizeNumber,
			validate: [percent],
		},
	];
};
export const buyerContactDetails = ({ property, saveProperty, buyer, isLocked = false }, isEditable = !isLocked) => {
	return [
		{
			name: `${buyer}.phoneNumber`,
			accessor: `phoneNumber`,
			label: "Phone",
			component: FormControl,
			normalize: normalizePhone,
			type: "text",
			className: "form-control form-field",
			onBlur: () => saveProperty(Number(buyer.split("[")[1].split("]")[0])),
			isEdit: true,
			isEditable,
		},

		{
			name: `${buyer}.fax`,
			accessor: `fax`,
			label: "Fax",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			onBlur: () => saveProperty(Number(buyer.split("[")[1].split("]")[0])),
			isEdit: true,
			isEditable,
		},
		{
			name: `${buyer}.emailAddress`,
			accessor: `emailAddress`,
			label: "Email",
			component: FormControl,
			type: "text",
			validate: [email],
			onBlur: () => saveProperty(Number(buyer.split("[")[1].split("]")[0])),
			className: "form-control form-field",
			isEdit: true,
			isEditable,
		},
	];
};
export const propertyBasicFormFields = (
	{ property, saveProperty, states, cities, doGetCities, loadCounties, change, isLocked = false },
	isEdit = true,
	isEditable = !isLocked
) => {
	return [
		{
			name: `${property}.propertyId`,
			accessor: `propertyId`,
			label: "Property Id",
			component: FormControl,
			wrapperClassName: "info-item col-4",
			type: "text",
			className: "form-control form-field",
			readOnly: true,
			isEdit,
			isEditable,
		},
		{
			name: `${property}.assesorsParcelNumber`,
			accessor: `assesorsParcelNumber`,
			label: "Assesors Parcel Number",
			component: FormControl,
			wrapperClassName: "info-item col-4",
			type: "text",
			onBlur: () => saveProperty(Number(property.split("[")[1].split("]")[0])),
			className: "form-control form-field",
			isEdit,
			isEditable,
		},
		{
			name: `${property}.closeDate`,
			accessor: `closeDate`,
			label: "Close Date",
			component: FormControl,
			type: "date",
			className: "form-control form-field",
			onBlur: () => saveProperty(Number(property.split("[")[1].split("]")[0])),
			isEdit,
			isEditable,
			onClear: () => {
				change(`${property}.closeDate`, null);
			},
		},
		{
			name: `${property}.legalDescription`,
			accessor: `legalDescription`,
			label: "Legal Description",
			wrapperClassName: "info-item col-12",
			component: FormControl,
			type: "textarea",
			maxLength: 900,
			validate: [maxLength(900)],
			onBlur: () => saveProperty(Number(property.split("[")[1].split("]")[0])),
			className: "form-control form-field",
			isEdit,
			isEditable,
		},

		{
			name: `${property}.address.streetAddress`,
			accessor: `address.streetAddress`,
			label: "Address",
			component: FormControl,
			className: "form-control form-field",
			type: "text",
			onBlur: () => saveProperty(Number(property.split("[")[1].split("]")[0])),
			isEdit,
			isEditable,
		},
		{
			name: `${property}.address.state`,
			accessor: `address.state`,
			label: "State",
			component: FormControl,
			type: "select",
			options: states,
			className: "form-control form-field",
			onChange: (selected) => {
				doGetCities(selected.value);
				change(`${property}.address.city`, null);
			},
			isEdit,
			isEditable,
		},
		{
			name: `${property}.address.city`,
			accessor: "address.city",
			label: "City",
			component: FormControl,
			options: cities,
			type: "select",
			onChange: () => saveProperty(Number(property.split("[")[1].split("]")[0])),
			className: "form-control form-field",
			isEdit,
			isEditable,
		},
		{
			name: `${property}.address.county`,
			accessor: "address.county",
			label: "County",
			component: FormControl,
			type: "asyncSelect",
			onChange: () => saveProperty(Number(property.split("[")[1].split("]")[0])),
			className: "form-control form-field",
			loadOptions: loadCounties,
			isEdit,
			isEditable,
		},
		{
			name: `${property}.address.zipCode`,
			accessor: `address.zipCode`,
			label: "Zip code",
			component: FormControl,
			type: "text",
			validate: [USZipCode],
			className: "form-control form-field",
			onBlur: () => saveProperty(Number(property.split("[")[1].split("]")[0])),
			isEdit,
			isEditable,
		},
	];
};
export const settlementBasicDetailFields = (
	{
		property,
		change,
		populateSettlementAddress,
		showExcrowForm,
		popupExcrowContacts,
		showSettlementPopup,
		saveProperty,
		loadExcrowAgents,
		settlemntCompanies,
		propertyFormValues,
		escrowAgent,
		setEscrowAgent,
		isLocked = false,
	},
	isEdit = true,
	isEditable = !isLocked
) => {
	return [
		{
			name: `${property}.settlementInformation.settlementCompany`,
			accessor: `settlementInformation.settlementCompany`,
			label: "Settlement Company",
			component: FormControl,
			onChange: (selected) => {
				change(`${property}.settlementInformation.escrowAgent`, null);
				change(`${property}.settlementInformation.phoneNumber`, null);
				change(`${property}.settlementInformation.emailAddress`, null);
				change(`${property}. settlementInformation.fax`, null);
				change(`${property}.settlementInformation.address.id`, 40);
				if (selected && selected.value) {
					populateSettlementAddress(property, selected.value);
				}
			},
			type: "select",
			addable: true,
			addLabel: "Add Settlement Company",
			addAction: () => {
				showSettlementPopup(property);
			},
			className: "form-control form-field",
			options: settlemntCompanies,
			isEdit,
			isEditable,
		},
		{
			name: `${property}.settlementInformation.escrowAgent`,
			accessor: `settlementInformation.escrowAgent`,
			label: "Agent Name",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			options: escrowAgent,
			addLabel: "Add Agent",
			addable: (propertyFormValues && 
				propertyFormValues.settlementInformation && 
				propertyFormValues.settlementInformation.settlementCompany &&
				propertyFormValues.settlementInformation.settlementCompany.value !== null &&
				propertyFormValues.settlementInformation.settlementCompany.value !== undefined &&
				propertyFormValues.settlementInformation.settlementCompany.value !== 0),
			addAction: () => {
				showExcrowForm(property);
			},
			onChange: (e) => {
				if (e && e.value) {
					popupExcrowContacts(property, e.value);
				}
			},
			isEdit,
			isEditable,
		},
		{
			name: `${property}.settlementInformation.escrowNumber`,
			accessor: `settlementInformation.escrowNumber`,
			label: "Escrow Number",
			component: FormControl,
			maxLength: 20,
			type: "text",
			className: "form-control form-field",
			onBlur: () => saveProperty(Number(property.split("[")[1].split("]")[0])),
			isEdit,
			isEditable,
		},
		{
			name: `${property}.settlementInformation.contractDate`,
			accessor: `settlementInformation.contractDate`,
			label: "Contract date",
			component: FormControl,
			type: "date",
			className: "form-control form-field",
			onBlur: () => saveProperty(Number(property.split("[")[1].split("]")[0])),
			isEdit,
			isEditable,
			onClear: () => {
				change(`${property}.settlementInformation.contractDate`, null);
			},
		},
	];
};

export const settlementContactFields = (
	{ property, saveProperty, propertyFormValues, isLocked = false },
	isEdit = true,
	isEditable = !isLocked
) => {
	return [
		{
			name: `${property}.settlementInformation.phoneNumber`,
			accessor: `settlementInformation.phoneNumber`,
			label: "Phone",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			normalize: normalizePhone,
			onBlur: () => saveProperty(Number(property.split("[")[1].split("]")[0])),
			isEdit,
			isEditable:
				propertyFormValues &&
				propertyFormValues.settlementInformation &&
				propertyFormValues.settlementInformation.escrowAgent &&
				propertyFormValues.settlementInformation.escrowAgent.value,
		},
		{
			name: `${property}.settlementInformation.fax`,
			accessor: "settlementInformation.fax",
			label: "Fax",
			component: FormControl,
			type: "text",
			onBlur: () => saveProperty(Number(property.split("[")[1].split("]")[0])),
			className: "form-control form-field",
			isEdit,
			isEditable:
				propertyFormValues &&
				propertyFormValues.settlementInformation &&
				propertyFormValues.settlementInformation.escrowAgent &&
				propertyFormValues.settlementInformation.escrowAgent.value,
		},
		{
			name: `${property}.settlementInformation.emailAddress`,
			accessor: "settlementInformation.emailAddress",
			label: "Email",
			component: FormControl,
			validate: [email],
			onBlur: () => saveProperty(Number(property.split("[")[1].split("]")[0])),
			type: "text",
			className: "form-control form-field",
			isEdit,
			isEditable:
				propertyFormValues &&
				propertyFormValues.settlementInformation &&
				propertyFormValues.settlementInformation.escrowAgent &&
				propertyFormValues.settlementInformation.escrowAgent.value,
		},
	];
};

export const settlementAddressFields = (
	{ property, loadCounties, saveProperty, doShowLoader, states, doGetCities, cities, change, isLocked = false },
	isEdit = true,
	isEditable = !isLocked
) => {
	return [
		{
			name: `${property}.settlementInformation.address.streetAddress`,
			label: "Address",
			accessor: "address.streetAddress",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			isEdit,
			isEditable,
		},
		{
			name: `${property}.settlementInformation.address.state`,
			accessor: "address.state",
			label: "State",
			component: FormControl,
			type: "select",
			options: states,
			onChange: (selected) => {
				doShowLoader(true);
				doGetCities(selected.value, () => {
					doShowLoader(false);
				});
				change(`${property}.settlementInformation.address.city`, null);
			},
			className: "form-control form-field",
			isEdit,
			readOnly: true,
			isEditable: true,
		},
		{
			name: `${property}.settlementInformation.address.city`,
			accessor: "address.city",
			label: "City",
			component: FormControl,
			type: "select",
			options: cities,
			className: "form-control form-field",
			isEdit,
			isEditable,
		},
		{
			name: `${property}.settlementInformation.address.zipCode`,
			accessor: "address.zipCode",
			label: "Zip code",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			validate: [USZipCode],
			isEdit,
			isEditable,
		},
	];
};

export const settlementFormFields = ({ states, cities, doShowLoader, doGetCities, change, loadCounties }) => {
	return [
		{
			name: "entityName",
			label: "Entity Name",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			validate: [required],
			required: true,
		},
		{
			name: "address.streetAddress",
			label: "Address",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
		},
		{
			name: "address.state",
			label: "State",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			options: states,
			onChange: (selected) => {
				doShowLoader(true);
				doGetCities(selected.value, () => {
					doShowLoader(false);
				});
				change("address.city", null);
			},
		},

		{
			name: "address.city",
			label: "City",
			component: FormControl,
			type: "select",
			options: cities,
			className: "form-control form-field",
		},
		{
			name: "address.zipCode",
			label: "Zip Code",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			validate: [USZipCode],
		},
		{
			name: "taxId",
			label: "SSN/Tax ID",
			component: FormControl,
			type: "text",
			validate: [USTaxid],
			className: "form-control form-field",
		},
		{
			name: "name",
			label: "Agent Name",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			validate: [required],
			required: true,
		},
		{
			name: "emailAddress",
			label: "Email Address",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			validate: [email, required],
			required: true,
		},
		{
			name: "phoneNumber",
			label: "Phone",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			validate: [USPhoneNumber],
			normalize: normalizePhone,
		},
	];
};

export const excrowFormFields = (settlementCompanies, isEdit) => {
	return [
		{
			name: "name",
			label: "Agent Name",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			validate: [required],
			required: true,
		},
		{
			name: "company",
			label: "Settlement Company",
			component: FormControl,
			type: "select",
			className: "form-control form-field",
			options: settlementCompanies,
			validate: [required],
			isDisabled: true,
			required: true,
		},
		{
			name: "emailAddress",
			label: "Email Address",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			validate: [email, required],
			required: true,
		},
		{
			name: "phoneNumber",
			label: "Phone",
			component: FormControl,
			type: "text",
			className: "form-control form-field",
			validate: [USPhoneNumber],
			normalize: normalizePhone,
		},
	];
};
