import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import Slider from "../SharedComponts/Slider";
import SliderForm from "../SharedComponts/SliderForm";
import FormControl from "../SharedComponts/FormControl";
import { required } from "../../constant/formValidators";
import { Button } from "react-bootstrap";
import { errorMessage } from "../../utils/errorMessage";
import { Date } from "../../utils/formatter";
import { dateFormat } from "../../constant/common";

import { showLoader, getNotes } from "../../store/actions";

const NoteSlider = (props) => {
	const {
		show,
		handleClose,
		submitting,
		handleSubmit,
		reset,
		currentExchange,
		doShowLoader,
		doGetNotes,
		exchangeNotes,
		showAdd,
		setShowAdd,
		deleteNote,
	} = props;

	useEffect(() => {
		doShowLoader(true);

		const backendFilter = { TypeId: 152, Id: currentExchange.id };
		const successCB = (result) => {
			doShowLoader(false);
		};
		const FailCB = (result) => {
			errorMessage(result);
			doShowLoader(false);
		};

		doGetNotes(backendFilter, successCB, FailCB);
	}, [doGetNotes, doShowLoader, currentExchange]);
	return (
		<Slider
			show={show}
			title={`Notes - ${currentExchange.exchangeNumber}`}
			closeButton={true}
			handleClose={() => {
				handleClose();
			}}>
			<SliderForm handleSubmit={handleSubmit} submitting={submitting} handleClose={handleClose} hideSubmit={true}>
				<div className="form-group note-text">
					{showAdd ? (
						<Button
							className="form-button-secondary small btn btn-primary"
							type="submit"
							disabled={submitting}
							onClick={() => {
								reset();
								setShowAdd(false);
							}}>
							+ Add Notes
						</Button>
					) : (
						<>
							<Field
								name="message"
								label="Description"
								component={FormControl}
								type="textarea"
								className="form-control form-field note-text"
								rows="4"
								cols="38"
								validate={[required]}
								placeholder="Add a Note ..."
								required={true}
							/>
							<div className="d-flex flex-row-reverse mt-1">
								<Button className="form-button-apply" type="submit" disabled={submitting}>
									DONE
								</Button>

								<Button
									className="form-button-light mr-2"
									onClick={() => {
										reset();
										setShowAdd(true);
									}}
									disabled={submitting}>
									CANCEL
								</Button>
							</div>
						</>
					)}
					{exchangeNotes.map((item, index) => {
						return (
							<div key={index} className="note-block">
								<div className="content">{item.message}</div>
								<div className="author">
									— {item.createdUser}{" "}
									<span className="date">
										<Date utcTime={item.createdDate} format={dateFormat} />
									</span>
									<div
										className="close-btn-delete"
										onClick={() => {
											deleteNote(item.id);
										}}>
										<span className="icon-icon-delete"></span>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</SliderForm>
		</Slider>
	);
};
const mapDispatchToProps = {
	doShowLoader: showLoader,
	doGetNotes: getNotes,
};
const mapStateToProps = (state) => {
	const {
		Exchange: { exchangeNotes = [] },
	} = state;
	return {
		exchangeNotes,
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "EmailSliderForm",
		enableReinitialize: true,
	})(NoteSlider)
);
