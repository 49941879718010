import { get } from "lodash";
import { getTimeZoneOffset } from "./helper";

const rootURL = process.env.REACT_APP_ROOT_URL;
const LSVariable = process.env.REACT_APP_LS_VAR;

export default function fetchHandler(
	{
		url,
		method = "GET",
		actionType,
		headers,
		body,
		secure = true,
		fileUpload = false,
		extraParams = {},
		addUtcOffset = true,
	},
	successHandler = null,
	errorHandler = null
) {
	return (dispatch, getState) => {
		const triggerSuccessHandler = (response) => {
			dispatch({
				type: actionType,
				payload: response,
				extraParams,
			});
			return successHandler ? successHandler(response) : null;
		};

		const LSString = localStorage.getItem(LSVariable);
		const LSToken = LSString ? JSON.parse(LSString) : null;
		const stateToken = get(getState(), "Auth.accessToken", null);
		const accessToken = stateToken ? stateToken.accessToken : LSToken ? LSToken.accessToken : null;

		let headersData = {
			...headers,
			Accept: fileUpload ? "multipart/form-data" : "application/json",
			"Content-Type": fileUpload ? "multipart/form-data" : "application/json",
		};

		if (secure) {
			headersData = {
				...headersData,
				Authorization: `Bearer ${accessToken}`,
			};
		}
		let requestBody = body;
		if (method === "POST" && body !== "" && addUtcOffset) {
			requestBody = JSON.stringify({ ...JSON.parse(body), utcOffset: getTimeZoneOffset() });
		}
		const request = fetch(`${rootURL}${url}`, {
			method,
			headers: {
				...headersData,
			},
			body: requestBody,
		});

		let status = null;
		request
			.then((data) => {
				status = data.status;
				return data.json();
			})
			.then((response) => {
				if (status && status > 399) {
					return errorHandler ? errorHandler(response) : null;
				} else {
					return triggerSuccessHandler(response);
				}
			})
			.catch((err) => {
				const errorObj = {
					error: {
						url: `${rootURL}${url}`,
						code: "FETCH_FAILED",
						message: err,
					},
				};
				return errorHandler ? errorHandler(errorObj) : null;
			});

		return {
			type: actionType,
			payload: request,
		};
	};
}
