import fetchHandler from "../../utils/fetchHandler";
import { NOTIFICATION } from "../types";

export function setNotificationFilter(filter, successHandler) {
	successHandler && successHandler();
	return {
		type: NOTIFICATION.SET_FILTER,
		payload: filter,
	};
}

export function reloadNotification(successHandler) {
	successHandler && successHandler();
	return {
		type: NOTIFICATION.SET_FLAG,
	};
}

export const getUnreadCount = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `notification/unreadcount`,
		method: "GET",
		secure: true,
		actionType: NOTIFICATION.GET_UNREAD_NOTIF_COUNT,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchNotification = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `notifications`,
		method: "POST",
		secure: true,
		actionType: NOTIFICATION.FETCH_NOTIFICATION,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const fetchNotificationList = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `notifications`,
		method: "POST",
		secure: true,
		actionType: NOTIFICATION.FETCH_NOTIFICATION_LIST,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const updateNotification = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `notification`,
		method: "PUT",
		secure: true,
		actionType: NOTIFICATION.UPDATE_NOTIFICATION,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const deleteNotification = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `notification`,
		method: "DELETE",
		secure: true,
		actionType: NOTIFICATION.DELETE_NOTIFICATION,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
